import React from 'react'
import { ProgressBar, Step } from "react-step-progress-bar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faIdBadge, faThumbsUp, faFileInvoice, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

const MultiStepProgressBar = (props) => {
    var stepPercentage = 0;

    if (props.currentStep === 1) {
        stepPercentage = 0;
    } else if (props.currentStep === 2) {
        stepPercentage = 25;
    } else if (props.currentStep === 3) {
        stepPercentage = 50;
    } else if (props.currentStep === 4) {
        stepPercentage = 75;
    } else if (props.currentStep === 5) {
        stepPercentage = 100;
    }
    else {
        stepPercentage = 0;
    }

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* {index + 1} */}

                        <FontAwesomeIcon icon={faIdBadge} className="fs-5 upload-file atras" color="#eee" />
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* {index + 1} */}
                        <FontAwesomeIcon icon={faUserCircle} className="fs-5 upload-file atras" color="#eee" />
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        <FontAwesomeIcon icon={faFileInvoiceDollar} className="fs-5 upload-file atras" color="#eee" />
                        {/* {index + 1} */}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        <FontAwesomeIcon icon={faFileInvoice} className="fs-5 upload-file atras" color="#eee" />
                        {/* {index + 1} */}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        <FontAwesomeIcon icon={faThumbsUp} className="fs-5 upload-file atras" color="#eee" />
                    </div>
                )}
            </Step>
        </ProgressBar>
    )
}

export default MultiStepProgressBar
