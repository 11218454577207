import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyCuGpNeM_GuTTM8J1Ez5rRQqT6K6Sz1ph8",
  authDomain: "portal-fintechsgr.firebaseapp.com",
  projectId: "portal-fintechsgr",
  storageBucket: "gs://portal-fintechsgr.appspot.com",
  messagingSenderId: "560209080039",
  appId: "1:560209080039:web:f2e095371c478446f4fc42"
};

app.initializeApp(firebaseConfig);

const firebase = app.firestore()
const auth = app.auth()
const storage = app.storage()

export {firebase, auth, storage}