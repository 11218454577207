import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'

//Const
const dataInicial = {
    loading: false,
    cuenta: {},
    sociedadXsocio: {},
    sociedadDeBolsa: {},
    sociedadesDeBolsa: [],
    certificadosPymes: [],
    provincias: [],
    paises: [],
    socios: [],
    contactosNotificaciones: [],
    actualizacionDatos: '',
    actualizacionDatosAlyc: '',
    sociedadBolsaSocio: {},
    crearCuentaSociedad: '',
    modificarSociedadXSocio: '',
    inactivarCuentaMensaje: ''
}

//Types
const CUENTA_EXITO = 'CUENTA_EXITO'
const ACTUALIZAR_CUENTA_EXITO = 'ACTUALIZAR_CUENTA_EXITO'
const ACTUALIZAR_CUENTAALYC_EXITO = 'ACTUALIZAR_CUENTAALYC_EXITO'
const PROVINCIA_EXITO = 'PROVINCIA_EXITO'
const CERTIFICADO_EXITO = 'CERTIFICADO_EXITO'
const SOCIEDADXSOCIO_EXITO = 'SOCIEDADXSOCIO_EXITO'
const SOCIEDADBOLSA_EXITO = 'SOCIEDADBOLSA_EXITO'
const SOCIOS_EXITO = 'SOCIOS_EXITO'
const LOADING = "LOADING"
const LOADING_ACTUALIZACION = 'LOADING_ACTUALIZACION'
const PAISES_FETCH_EXITO = 'PAISES_FETCH_EXITO'
const ERROR = 'ERROR'
const ERROR_ACTUALIZACION_ALYC = 'ERROR_ACTUALIZACION_ALYC'
const ERROR_GENERAL = 'ERROR_GENERAL'
const ACTUALIZAR_CUENTA_NOSIS_EXITO = 'ACTUALIZAR_CUENTA_NOSIS_EXITO'
const SOCIEDAD_BOLSA_SOCIO_EXITO = 'SOCIEDAD_BOLSA_SOCIO_EXITO'
const LOADING_CUENTA_SOCIEDAD_SOCIO = "LOADING"
const CUENTA_SOCIEDAD_SOCIO_EXITO = "CUENTA_SOCIEDAD_SOCIO_EXITO"
const LOADING_MODIFICAR_SOCIEDADXSOCIO = "LOADING_MODIFICAR_SOCIEDADXSOCIO"
const MODIFICAR_SOCIEDADXSOCIO_EXITO = "MODIFICAR_MODIFICAR_SOCIEDADXSOCIO"
const INACTIVAR_CUENTA_EXITO = "INACTIVAR_CUENTA_EXITO"
const CONTACTOS_NOTI_FETCH_EXITO = 'CONTACTOS_NOTI_FETCH_EXITO'
const ACTUALIZAR_MENSAJE_ALYC = 'ACTUALIZAR_MENSAJE_ALYC'
const ACTUALIZAR_MENSAJE_CUENTA = 'ACTUALIZAR_MENSAJE_CUENTA'
//Reducers
export default function cuentaReducers(state = dataInicial, action) {
    switch (action.type) {
        case SOCIEDADBOLSA_EXITO:
            return { ...state, sociedadesDeBolsa: action.payload }
        case SOCIEDADXSOCIO_EXITO:
            return { ...state, sociedadXsocio: action.payload }
        case CERTIFICADO_EXITO:
            return { ...state, certificadosPymes: action.payload }
        case ERROR_ACTUALIZACION_ALYC:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc }
        case ERROR_GENERAL:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case LOADING:
            return { ...state, actualizacionDatos: action.actualizacionDatos, loading: true }
        case LOADING_ACTUALIZACION:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc, actualizacionDatos: action.actualizacionDatos }
        case CUENTA_EXITO:
            return { ...state, cuenta: action.payload }
        case PROVINCIA_EXITO:
            return { ...state, provincias: action.payload }
        case PAISES_FETCH_EXITO:
            return { ...state, paises: action.payload }
        case ACTUALIZAR_CUENTA_EXITO:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case ACTUALIZAR_CUENTAALYC_EXITO:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc }
        case SOCIOS_EXITO:
            return { ...state, socios: action.payload }
        case ACTUALIZAR_CUENTA_NOSIS_EXITO:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case SOCIEDAD_BOLSA_SOCIO_EXITO:
            return { ...state, sociedadBolsaSocio: action.payload, crearCuentaSociedad: action.crearCuentaSociedad, crearCuentaSociedad: 'LOADING', inactivarCuentaSocioMensaje: 'LOADING', modificarSociedadXSocio: 'LOADING'}
        case LOADING_CUENTA_SOCIEDAD_SOCIO:
            return { ...state, crearCuentaSociedad: action.crearCuentaSociedad }
        case CUENTA_SOCIEDAD_SOCIO_EXITO:
            return { ...state, crearCuentaSociedad: action.crearCuentaSociedad }
        case MODIFICAR_SOCIEDADXSOCIO_EXITO:
            return { ...state, modificarSociedadXSocio: action.modificarSociedadXSocio }
        case INACTIVAR_CUENTA_EXITO:
            return { ...state, inactivarCuentaSocioMensaje: action.inactivarCuentaSocioMensaje }
        case CONTACTOS_NOTI_FETCH_EXITO:
            return { ...state, contactosNotificaciones: action.payload }
        case ACTUALIZAR_MENSAJE_ALYC:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc } 
        case ACTUALIZAR_MENSAJE_CUENTA:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        default:
            return { ...state }
    }
}

//Actions
export const obtenerCuenta = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Account?filter=accountid eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: CUENTA_EXITO,
                payload: response.data[0]
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerSocios = () => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        const response = await axios.get(`${UrlApiDynamics}Account?filter=&cuit=${Entidad}`)
        dispatch({
            type: SOCIOS_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerCertificadoPyme = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Certificadopyme?filter=_new_socioparticipe_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: CERTIFICADO_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerSociedadesXsocio = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Sociedaddebolsaporsocio?filter=_new_socio_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: SOCIEDADXSOCIO_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerSociedadeDeBolsa = () => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        // if (sociedadId != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Sociedaddebolsa?filter=&cuit=${Entidad}`)
            // const response = await axios.get(`${UrlApiDynamics}Sociedaddebolsa?filter=new_sociedaddebolsaid eq ${sociedadId}&cuit=${Entidad}`)
            dispatch({
                type: SOCIEDADBOLSA_EXITO,
                payload: response.data
            })
        // }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerProvincias = () => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        const response = await axios.get(`${UrlApiDynamics}Provincias?filter=&cuit=${Entidad}`);
        dispatch({
            type: PROVINCIA_EXITO,
            payload: response.data
        })

    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const actualizarDatosCuenta = (accountid, telefono = "", calle = "", numero = "", piso = "", depto = "", provinciaId = "",
    localidad = "", municipio = "", codigoPostal = "", ganancias = "", pais = "", calificacion = "", firmante = "", estadoDocu = "", estadoSocio = "", contactoNotificaciones = "") => async (dispatch) => {
        dispatch({
            type: LOADING_ACTUALIZACION,
            actualizacionDatos: 'LOADING',
            actualizacionDatosAlyc: 'PENDING',
        })
        try {
            var contactoNotificacionesValue
            if (contactoNotificaciones != "") {
                contactoNotificacionesValue = contactoNotificaciones.value
            }
            var gananciasValue
            if (ganancias != "") {
                gananciasValue = ganancias.value
            }
            var paisValue
            if (pais != "") {
                paisValue = pais.value
            }

            var provinciaValue
            if (provinciaId != "") {
                provinciaValue = provinciaId.value
            }
            debugger
            const response = await axios.post(`${UrlApiDynamics}Account?accountId=${accountid}&telefono=${telefono}&calle=${calle}
                &numero=${numero}&piso=${piso}&depto=${depto}&provinciaId=${provinciaValue}&localidad=${localidad}&municipio=${municipio}
                &codigoPostal=${codigoPostal}&ganancias=${gananciasValue}&pais=${paisValue}&calificacion=${calificacion}&firmante=${firmante}
                &estadoDocumentacion=${estadoDocu}&estadoSocio=${estadoSocio}&contactoNotificaciones=${contactoNotificacionesValue}&cuit=${Entidad}`)
            dispatch({
                type: ACTUALIZAR_CUENTA_EXITO,
                payload: response.data,
                actualizacionDatos: response.data
            })
        } catch (error) {
            dispatch({
                type: ERROR_GENERAL,
                actualizacionDatos: 'ERROR'
            })
        }
    }

export const actualizarDatosAlyc = (accountid, estadoSocio = null, actividad = null, opera = null, montoEstimado = null, proposito = null,
    otros = null, metodoEmision = null, fechaContrato = null, fechaInscripcion = null, numeroInscripcion = null, origenFondos = null, tipoSujeto = null) => async (dispatch) => {
        dispatch({
            type: LOADING_ACTUALIZACION,
            actualizacionDatosAlyc: 'LOADING',
            actualizacionDatos: 'PENDING'
        })

        let socio = null;
        let operaXcuenta = null;
        let origen = null;
        let sujeto = null;

        if (opera !== null) {
            operaXcuenta = opera.value;
        }

        if (estadoSocio !== null) {
            socio = estadoSocio.value;
        }

        if (fechaContrato === '') {
            fechaContrato = null
        }

        if (tipoSujeto !== null) {
            sujeto = tipoSujeto.value;
        }
        // debugger
        // if(origenFondos !== null){
        //     origen = origenFondos.value
        // }
        try {
            const response = await axios.post(`${UrlApiDynamics}Cuentaalyc?accountId=${accountid}&estadoSocio=${socio}
            &actividad=${actividad}&opera=${operaXcuenta}&montoEstimado=${montoEstimado}&proposito=${proposito}&otros=${otros}
            &metodoEmision=${metodoEmision}&fechaContrato=${fechaContrato}&fechaInscripcion=${fechaInscripcion}
            &numeroInscripcion=${numeroInscripcion}&origenFondos=${origenFondos}&tipoSujeto=${sujeto}&cuit=${Entidad}`)
            dispatch({
                type: ACTUALIZAR_CUENTAALYC_EXITO,
                payload: response.data,
                actualizacionDatosAlyc: 'EXITO'
            })
        } catch (error) {
            dispatch({
                type: ERROR_ACTUALIZACION_ALYC,
                actualizacionDatosAlyc: 'ERROR'
            })
        }
    }

export const mensajeActualizacionDatosAlyc = () => (dispatch) => {
    dispatch({
        type: ACTUALIZAR_MENSAJE_ALYC,
        actualizacionDatosAlyc: 'LOADING'
    })
}

export const mensajeActualizacionDatosCuenta = () => (dispatch) => {
    dispatch({
        type: ACTUALIZAR_MENSAJE_CUENTA,
        actualizacionDatos: 'LOADING'
    })
}

export const obtenerPaises = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "new_paises"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_pais'>" +
        "<attribute name='new_paisid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='createdon'/> " +
        "</entity>" +
        "</fetch>";
    try {

        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: PAISES_FETCH_EXITO,
            payload: response.data
        })
    }

    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const actualizarDatosCuentaNosis = (accountid, fechaUltimaConuslta, resultadoUltimaConsulta) => async (dispatch) => {
    dispatch({
        type: LOADING_ACTUALIZACION,
        actualizacionDatos: 'LOADING',
        actualizacionDatosAlyc: 'PENDING',
    })

    try {
        const response = await axios.post(`${UrlApiDynamics}Account?accountId=${accountid}&fechaUltimaConsulta=${fechaUltimaConuslta}&resultadoUltimaConsulta=${resultadoUltimaConsulta}&cuit=${Entidad}`)
        dispatch({
            type: ACTUALIZAR_CUENTA_NOSIS_EXITO,
            payload: response.data,
            actualizacionDatos: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR_GENERAL,
            actualizacionDatos: 'ERROR'
        })
    }
}
export const obtenerSociedadeDeBolsaPorSocio = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING,
    // });

    let entidad = 'new_sociedaddebolsaporsocios'
    var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
        "<entity name='new_sociedaddebolsaporsocio'>" +
        "<attribute name='new_sociedaddebolsaporsocioid' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        "<attribute name='new_socio' />" +
        "<attribute name='new_sociedaddebolsa' />" +
        "<attribute name='new_idbolsa' />" +
        "<attribute name='new_cuentacomitente' />" +
        "<attribute name='statecode' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_socio' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: SOCIEDAD_BOLSA_SOCIO_EXITO,
            payload: response.data,
            CuentaSociedad: 'EXITO',
            crearCuentaSociedad: 'LOADING'
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const CrearSociedadBolsaSocio = (accountid, sociedadDeBolsa, cuentaComitente) => async (dispatch) => {
    dispatch({
        type: LOADING_CUENTA_SOCIEDAD_SOCIO,
        crearCuentaSociedad: 'LOADING'
    })
    
    try {
        const response = await axios.post(`${UrlApiDynamics}Sociedaddebolsaporsocio?cuentaid=${accountid}&sociedadDeBolsa=${sociedadDeBolsa}&cuentaComitente=${cuentaComitente}&cuit=${Entidad}`)
        dispatch({
            type: CUENTA_SOCIEDAD_SOCIO_EXITO,
            crearCuentaSociedad: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            crearCuentaSociedad: 'ERROR'
        })
    }
}

export const modificarSociedadBolsaSocio = (accountid, sociedadDeBolsa, cuentaComitente, sociedadDeBolsaPorSocioId = "") => async (dispatch) => {

    dispatch({
        type: LOADING,
        modificarSociedadXSocio: 'LOADING'
    })
    try {
        // debugger
        const respuesta = await axios.post(`${UrlApiDynamics}Sociedaddebolsaporsocio?cuentaid=${accountid}&sociedadDeBolsa=${sociedadDeBolsa}&cuentaComitente=${cuentaComitente}&sociedadDeBolsaPorSocioId=${sociedadDeBolsaPorSocioId}&cuit=${Entidad}`)
        dispatch({
            type: MODIFICAR_SOCIEDADXSOCIO_EXITO,
            modificarSociedadXSocio: 'EXITO'

        })
    } catch (error) {
        dispatch({
            type: ERROR,
            modificarSociedadXSocio: 'ERROR'
        })
    }
}

export const inactivarSociedadBolsa = (accountid, sociedadDeBolsa, cuentaComitente, sociedadDeBolsaPorSocioId, estado) => async (dispatch) => {

    try {
        const response = await axios.post(`${UrlApiDynamics}Sociedaddebolsaporsocio?cuentaid=${accountid}&sociedadDeBolsa=${sociedadDeBolsa}&cuentaComitente=${cuentaComitente}&sociedadDeBolsaPorSocioId=${sociedadDeBolsaPorSocioId}&estado=${estado}&cuit=${Entidad}`)
        dispatch({
            type: INACTIVAR_CUENTA_EXITO,
            inactivarCuentaSocioMensaje: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            inactivarCuentaSocioMensaje: 'ERROR'
        })
    }
}

export const obtenerContactoDeNotificaciones = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "contacts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='contact'>" +
        "<attribute name='fullname'/>" +
        "<attribute name='telephone1'/> " +
        "<attribute name='contactid'/> " +
        "<link-entity name='new_participacionaccionaria' from='new_cuentacontactovinculado' to='contactid' link-type='inner' alias='aa'>" +
        "<attribute name='new_tipoderelacion'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_cuentaid' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: CONTACTOS_NOTI_FETCH_EXITO,
            payload: response.data
        })
    }

    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}