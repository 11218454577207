export const COLUMNS = [
    {
        Header: 'Línea (Tipo de Operación)',
        footer: 'Línea (Tipo de Operación)',
        accessor: 'new_lineatipodeoperacion',
        Cell: ({ value }) => {
            switch (value) {
                case '100000000':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">General</p> : '-'
                case '1':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Acreedor otro</p> : '-' 
                case '2':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Acreedor socio protector</p> : '-' 
                case '3':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Aval técnico</p> : '-' 
                case '4':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Cheque de Pago Diferido</p> : '-' 
                case '5':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Fideicomiso Financiero</p> : '-' 
                case '6':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Leasing</p> : '-' 
                case '7':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Mercado de futuros y opciones</p> : '-' 
                case '8':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Obligaciones negociables</p> : '-' 
                case '9':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Organismos Internacionales</p> : '-' 
                case '10':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Pagaré bursatil</p> : '-' 
                case '11':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Préstamo</p> : '-' 
                case '12':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Públicas</p> : '-' 
                case '13':
                    return value ? <p className="m-0 texto-lista m-0 fw-bolder">Valores de corto plazo</p> : '-' 
                default:
                    return '---'
            }
        }
    },
    {
        Header: 'Tipo CHPD',
        footer: 'Tipo CHPD',
        accessor: 'new_tipochpd',
        Cell: ({ value }) => {
            switch (value) {
                case '100000000':
                    return <span class="badge px-2 badge-personalizado-naranja">Propio</span>
                    break;
                case '100000001':
                    return <span class="badge px-2 badge-personalizado-amarillo">Tercero</span>
                    break;
                default:
                    return '---'
                    break;
            }
        }
    },
    {
        Header: 'Tope por Línea Comercial',
        footer: 'Tope por Línea Comercial',
        accessor: 'new_topeporlineacomercial',
        Cell: ({ value }) => { return <p className="text-success  m-0 fw-bolder texto-lista m-0">$ {Intl.NumberFormat().format(value)} </p> }
    },
    {
        Header: 'Tope por Línea Comercial USD',
        footer: 'Tope por Línea Comercial USD',
        accessor: 'new_topeporlineacomercialusd',
        Cell: ({ value }) => { return <p className="text-success  m-0 fw-bolder texto-lista m-0">$ {Intl.NumberFormat().format(value)} </p> }
    },
    {
        Header: 'Monto Utilizado por Operación',
        footer: 'Monto Utilizado por Operación',
        accessor: 'new_montoutilizadoporoperacion',
        Cell: ({ value }) => { return <p className="text-success  m-0 fw-bolder texto-lista m-0">$ {Intl.NumberFormat().format(value)} </p> }
    },
    {
        Header: 'Monto Disponible por Operación',
        footer: 'Monto Disponible por Operación',
        accessor: 'new_montodisponibleporoperacion',
        Cell: ({ value }) => { return <p className="text-success  m-0 fw-bolder texto-lista m-0">$ {Intl.NumberFormat().format(value)} </p> }
    },
    {
        Header: 'Razón para el Estado',
        footer: 'Razón para el Estado',
        accessor: 'statuscode',
        Cell: ({ value }) => {
            switch (value) {
                case '1':
                    return <span class="badge px-2 badge-personalizado-amarillo">Inicial</span>
                case '100000000':
                    return <span class="badge px-2 badge-personalizado-naranja">Aprobada</span>
                case '100000001':
                    return <span class="badge px-2 badge-personalizado-verde">Instrumentada</span>
                default:
                    return '---'
            }
        }
    }
]
