import React from 'react'
import Select from 'react-select';
import { useSpring, animated, interpolate } from 'react-spring'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlertCircle } from "react-feather"
import { useDispatch, useSelector } from 'react-redux'
import { obtenerDocumentosOnboardingFETCH } from '../Redux/Documentacion'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const SolicitudAltaPersonal = ({ handleChange, state, token }) => {
    const dispatch = useDispatch()
    const [relacion, setRelacion] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [apellido, setApellido] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [emailNotificaciones, setEmailNotificaciones] = React.useState('')
    const [otro, setOtro] = React.useState('')
    const [cuitcuil, setCuitcuil] = React.useState('')
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const documentosSelector = useSelector(store => store.documentos.documentos)

    const [tiposDeRelacionesOpciones, setTiposDeRelacionesOpciones] = React.useState([
        { value: '100000000', label: 'Titular' },
        { value: '100000001', label: 'Accionista' },
        { value: '100000002', label: 'Beneficiario' },
        { value: '100000003', label: 'Miembro del Directorio' },
        { value: '100000004', label: 'Representante Legal/Apoderado' },
        { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
        { value: '100000005', label: 'Otro' }
    ])

    React.useEffect(async () => {
        if (state) {
            if (state.docuentosDTOs == undefined || state.docuentosDTOs.length == 0 || state.docuentosDTOs == null) {
                if (documentosSelector.length > 0 && llamadaDocumentos === true && token.length > 0) {
                    var filtrados = filtrarDocumentosPorPersoneria(documentosSelector.filter(documento => documento.new_solicituddealta == true), state.personeria)
                    handleChange("docuentosDTOs")(filtrados)
                    // localStorage.setItem("onboarding", JSON.stringify({ ...state, ["docuentosDTOs"]: documentosSelector.filter(item => item.statecode == 0) }))
                } else if (documentosSelector !== undefined && llamadaDocumentos === false) {
                    obtenerTodosDocumentos()
                    setLlamadaDocumentos(true)
                }   
            }
            
            if (state.nombre !== '' && nombre === '') {
                setNombre(state.nombre)
            }
            if (state.apellido !== '' && apellido === '') {
                setApellido(state.apellido)
            }
            if (state.cuitcuil !== '' && cuitcuil === '') {
                setCuitcuil(state.cuitcuil)
            }
            if (state.telefono !== '' && telefono === '') {
                setTelefono(state.telefono)
            }
            if (state.email !== '' && email === '') {
                setEmail(state.email)
            }
            if (state.emailNotificaciones !== '' && emailNotificaciones === '') {
                setEmailNotificaciones(state.emailNotificaciones)
            }
            if (state.relacionCuenta !== '' && relacion === '') {
                var valorRelacion = buscarRelacion(state.relacionCuenta)
                var opcionRelacion = { value: state.relacionCuenta, label: valorRelacion }
                setRelacion(opcionRelacion)
            }
            if (state.otro !== '' && otro === '') {
                setOtro(state.otro)
            }
            if (state.relacionCuenta === '100000005') {
                var x = document.getElementById('div-otro');
                if (state.relacionCuenta === '100000005') {
                    if (x.style.display === "none") {
                        x.style.display = "block";
                    }
                } else {
                    if (x.style.display === "block") {
                        x.style.display = "none";
                    }
                }
            }
            if (state.personeria == "100000000") { //Juridica
                setTiposDeRelacionesOpciones([
                    { value: '100000001', label: 'Accionista' },
                    { value: '100000002', label: 'Beneficiario' },
                    { value: '100000003', label: 'Miembro del Directorio' },
                    { value: '100000004', label: 'Representante Legal/Apoderado' },
                    { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
                    { value: '100000005', label: 'Otro' }
                ])
            } else if (state.personeria == "100000001") {
                setTiposDeRelacionesOpciones([
                    { value: '100000000', label: 'Titular' },
                    { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
                    { value: '100000005', label: 'Otro' }
                ])
            }
        }
    }, [state])

    const obtenerTodosDocumentos = () => {
        dispatch(obtenerDocumentosOnboardingFETCH(token))
    }

    const filtrarDocumentosPorPersoneria = (docs, personeria) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_personeria != null) {
                if (doc.new_personeria == personeria) {
                    documentosFiltrados.push(doc)
                }
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }
    // const tiposDeRelacionesOpciones = [
    //     { value: '100000000', label: 'Titular' },
    //     { value: '100000001', label: 'Accionista' },
    //     { value: '100000002', label: 'Beneficiario' },
    //     { value: '100000003', label: 'Miembro del Directorio' },
    //     { value: '100000004', label: 'Representante Legal/Apoderado' },
    //     { value: '100000007', label: 'Asesor/Estructurador del proyecto' },
    //     { value: '100000005', label: 'Otro' }
    // ]

    const tipoRelacionOnChange = (value) => {
        var x = document.getElementById('div-otro');
        if (value.value === '100000005') {
            if (x.style.display === "none") {
                x.style.display = "block";
            }
        } else {
            if (x.style.display === "block") {
                x.style.display = "none";
            }
        }
        setRelacion(value)
        handleChange("relacionCuenta")(value)
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    const nombreOnChange = (valor) => {
        setNombre(valor.target.value)
        handleChange("nombre")(valor)
    }

    const apellidoOnChange = (valor) => {
        setApellido(valor.target.value)
        handleChange("apellido")(valor)
    }

    const telefonoOnChange = (valor) => {
        setTelefono(valor.target.value)
        handleChange("telefono")(valor)
    }

    const emailOnChange = (valor) => {
        setEmail(valor.target.value)
        handleChange("email")(valor)
    }

    const emailNotificacionesOnChange = (valor) => {
        setEmailNotificaciones(valor.target.value)
        handleChange("emailNotificaciones")(valor)
    }

    const otroOnChange = (valor) => {
        setOtro(valor.target.value)
        handleChange("otro")(valor)
    }

    const cuitcuilOnChange = (valor) => {
        setCuitcuil(valor.target.value)
        handleChange("cuitcuil")(valor)
    }

    const buscarRelacion = (valor) => {
        switch (valor) {
            case '100000000':
                return 'Titular'
            case '100000001':
                return 'Accionista'
            case '100000002':
                return 'Beneficiario'
            case '100000003':
                return 'Miembro del Directorio'
            case '100000004':
                return 'Representante Legal/Apoderado'
            case '100000005':
                return 'Otro'
            case '100000007':
                return 'Asesor/Estructurador del proyecto'
        }
    }

    return (
        <animated.div style={fade}>
            <form className="min-vh-50">
                <div>
                    <h6 className="fw-bolder">Datos de contacto para comunicación</h6>
                    <hr className="hr-width hr-principal" />
                </div>
                <div className="row p-4">
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Nombre</label>
                            <input type="text"
                                name="contacto"
                                id="contacto"
                                className="form-control"
                                onChange={e => nombreOnChange(e)}
                                value={nombre}
                                placeholder="---"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Apellido</label>
                            <input type="text"
                                name="apellido"
                                id="apellido"
                                className="form-control"
                                onChange={e => apellidoOnChange(e)}
                                value={apellido}
                                placeholder="---"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">CUIT/CUIL</label>
                            <input type="text"
                                name="cuitcuil"
                                id="cuitcuil"
                                className="form-control"
                                onChange={e => cuitcuilOnChange(e)}
                                value={cuitcuil}
                                placeholder="(sin espacios ni guiones)"
                                maxLength="11"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Teléfono</label>
                            <input type="text"
                                name="tel"
                                id="tel"
                                className="form-control"
                                onChange={e => telefonoOnChange(e)}
                                value={telefono}
                                placeholder="---"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">
                                Usuario Portal
                            </label>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Este usuario debe ingresarse con formato de correo electrónico y debe ser único. Se usará luego para iniciar sesión cuando se habilite el acceso al portal del socio.</Tooltip>}>
                                <AlertCircle size="20" className="mx-1 mb-1" color="#006BFF" />
                            </OverlayTrigger>
                            {/* <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="icono-information text-warning bg-dark rounded-circle upload-file mx-1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Este usuario debe ingresarse con formato de correo electrónico y debe ser único. Se usará luego para iniciar sesión cuando se habilite el acceso al portal del socio." /> */}
                            <input type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                onChange={e => emailOnChange(e)}
                                value={email}
                                placeholder="Email de usuario"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">
                                Email para Notificaciones
                            </label>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Correo electrónico del contacto donde el sistema enviará las notificaciones y novedades.</Tooltip>}>

                                <AlertCircle size="20" className="mx-1 mb-1" color="#006BFF" />
                            </OverlayTrigger>
                            {/* <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="icono-information text-warning bg-dark rounded-circle upload-file mx-1"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="" /> */}
                            <input type="email"
                                name="emailNotificaciones"
                                id="emailNotificaciones"
                                className="form-control"
                                onChange={e => emailNotificacionesOnChange(e)}
                                value={emailNotificaciones}
                                placeholder="Email Notificaciones"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">
                                Relación con la Cuenta
                            </label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="relacion"
                                onChange={e => tipoRelacionOnChange(e)}
                                name="relacion"
                                options={tiposDeRelacionesOpciones}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="BUSCAR..."
                                value={relacion}
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <div id="div-otro" style={{ display: 'none' }}>
                                <label className="form-label fw-bolder lbl-precalificacion">
                                    Otro
                                </label>
                                <input type="text"
                                    id="otro"
                                    name="otro"
                                    className="form-control"
                                    onChange={e => otroOnChange(e)}
                                    value={otro}
                                    maxLength="99"
                                    placeholder="---"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ animated.div>
    )
}

export default SolicitudAltaPersonal
