const GoogleMapKey = "AIzaSyDwKzicjMvfVsf9SbEAZn9N7i4CxX6YO1I";
const RecaptchaKey = "6Lf92Y4fAAAAACtG0mq9MBWVP0mMyzaJKSA6t7ST";
const UrlApiDynamics = "https://sgroc365api.azurewebsites.net/api/";
const Entidad = "FintechSGRProd";
const UnidadDeNegocio = "FFD76781-30B8-EA11-A812-000D3AC06348"

//Sandbox
// FintechSGR
// 6Lc8jg0cAAAAALskhenfFN_VW6bKcuUhKP44AzAL                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
// const UrlApiDynamics = "https://365hwapi.azurewebsites.net/api/";

//Localhost
// const UrlApiDynamics = "https://localhost:44348/api/";
// const RecaptchaKey = "6LeALiAcAAAAABv5WAeRHUmaf5GfqNSBYn8wTKON";


//////////////NUEVA API//////////////////

const UrlApi = "https://hw365api.azurewebsites.net/"
//Sandbox
// const authMail = ""
// const authPass = ""
//Produccion
const authMail = "admin@fintechsgr.com"
const authPass = "Fintech.2022$"

//////////////NUEVA API//////////////////


export {Entidad, GoogleMapKey, RecaptchaKey, UrlApiDynamics, UnidadDeNegocio, UrlApi, authMail, authPass}