import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerUnidadDeNegocio } from '../Redux/UnidadDeNegocio'
import { IniciarValidacion, ResultadoValidacion } from '../Redux/Nosis'
import { withRouter, useSearchParams, useLocation, BrowserRouter } from "react-router-dom";
import { actualizarDatosCuentaNosis } from '../Redux/Cuenta'
import { actualizarDatosContactoNosis } from '../Redux/Contacto'
import Notificacion from './Notificacion';
import { useSpring, config, animated } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faThumbsUp, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const ValidarIdentidad = (props) => {
    const dispatch = useDispatch()
    const [unidad, setUnidad] = React.useState({})
    const [llamadaUnidadesNegocio, setLlamadaUnidadesNegocio] = React.useState(false)
    const [fechaValidacion, setFechaValidacion] = React.useState('')
    const [resultadoValidacion, setResultadoValidacion] = React.useState('')
    const [descripcion, setDescripcion] = React.useState('')
    const [validacionFinal, setValidacionFinal] = React.useState(false)
    const [ResultadoFinal, setResultadoFinal] = React.useState(false)
    const [dni, setDni] = React.useState('')
    const [show, setShow] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [estado, setEstado] = React.useState('')
    const [estadoChecks, setEstadoChecks] = React.useState(false)
    const [habilitado, setHabilitado] = React.useState(true)
    const [contactid, setContactid] = React.useState('')
    const [validacionHabilitado, setValidacionHabilitado] = React.useState(true)

    // const [searchParams, setSearchParams] = useSearchParams();

    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const unidadesNegocioSelector = useSelector(store => store.unidadesNegocio.unidadesDeNegocio)
    const validacionSelector = useSelector(store => store.validacion.url)
    const resultadoSelector = useSelector(store => store.validacion.resultado)
    const validacionResSelector = useSelector(store => store.cuenta.actualizacionDatos)

    React.useEffect(async () => {
        if (unidadesNegocioSelector.length > 0 && llamadaUnidadesNegocio === true) {
            setUnidad(unidadesNegocioSelector[0])
        } else if (unidadesNegocioSelector.length === 0 && llamadaUnidadesNegocio === false) {
            obtenerUnidadesDeNegocio()
            setLlamadaUnidadesNegocio(true)
        }

        var ticketAux
        const url = window.location.href
        var parametros = url.split('?')
        const queryParams = new URLSearchParams(parametros[1])
        const dniAux = queryParams.get("dni")
        const estadoAux = queryParams.get("estado")
        const contacto = queryParams.get("contactid")

        if (parametros.length > 2) {
            var ticketAux2 = parametros[2].split('=')
            ticketAux = ticketAux2[1]
        }

        setDni(dniAux)
        setEstado(estadoAux)
        setContactid(contacto)

        if (validacionFinal === false) {
            if (ticketAux != undefined && estadoAux == "Finalizado") {
                if (unidadesNegocioSelector.length > 0) {
                    dispatch(ResultadoValidacion(dni, unidadesNegocioSelector[0], estadoAux, ticketAux));
                    setValidacionFinal(true)
                }
            }
        }

        if (validacionSelector != '' && validacionSelector != undefined) {
            window.open(validacionSelector, '_self')
        }

        if (resultadoSelector !== '' && resultadoSelector !== undefined && ResultadoFinal == false) {
            const res = resultadoSelector.split(';')
            debugger
            setFechaValidacion(res[0])
            var descripcionValidacion = respuestaResultadoValidacion(res[2])
            setDescripcion(descripcionValidacion)
            if (res[2] == "OK") {
                setResultadoValidacion(res[2])
            } else {
                setResultadoValidacion("ERROR")
            }

            dispatch(actualizarDatosContactoNosis(contacto, res[0], descripcionValidacion, res[3], res[4]))
            setResultadoFinal(true)
        }

        if (validacionResSelector !== undefined) {
            if (validacionResSelector !== '') {
                // actualizacionExito(validacionResSelector)
                if (document.getElementById("spinner1") != null) {
                    document.getElementById("spinner1").style.display = "none"
                }
                setHabilitado(false)
            }
        }

    }, [unidadesNegocioSelector, validacionSelector, resultadoSelector, validacionResSelector])

    const respuestaResultadoValidacion = (valor) => {
        switch (valor) {
            case "OK":
                return "Todas las validaciones fueron superadas satisfactoriamente"
            case "LIMITE_GENERAL":
                return "Se superó la cantidad de validaciones totales diarias"
            case "LIMITE_ACIERTOS":
                return "Se superó la cantidad de validaciones correctas diarias"
            case "LIMITE_FALLOS":
                return "Se superó la cantidad de validaciones fallidas diarias"
            case "ERR_SERVIDOR":
                return "Error interno en la plataforma de Nosis"
            case "DNI_FOTO_NO_ENCONTRADA":
                return "No se detectó la foto en el frente del DNI"
            case "DNI_PDF417_NO_ENCONTRADO":
                return "No se detectó un PDF417 en frente o dorso del DNI"
            case "DNI_MRZ_NO_ENCONTRADO":
                return "No se detectó un MRZ en frente o dorso del DNI"
            case "DNI_ESTRUCTURA_INVALIDA":
                return "La ubicación de los elementos no coincide con ninguna de versión conocida de DNI"
            case "DNI_DATOS_INVALIDOS":
                return "Los datos encontrados en el DNI no coinciden entre sí, o con los datos registrados en RENAPER"
            case "DNI_FOTO_INVALIDA":
                return "La foto del DNI no coincide con la selfie suministrada"
            case "DNI_PDF417_INVALIDO":
                return "El formato del PDF417 no es reconocido"
            case "DNI_MRZ_INVALIDO":
                return "El formato del MRZ no es reconocido o difieren los dígitos verificadores"
            case "DNI_NO_VIGENTE":
                return "El DNI no está vigente en RENAPER"
            case "SELFIE_ESCENA_NO_COINCIDE":
                return "Las fotos de selfie ingresadas no son homogéneas entre sí en cuanto a fondo o iluminación"
            case "SELFIE_NEUTRAL_NO_COINCIDE":
                return "El rostro neutral no se corresponde con los registros de RENAPER"
            case "SELFIE_SONRISA_NO_COINCIDE":
                return "El rostro sonriendo no se corresponde con el neutral"
            case "SELFIE_NEUTRAL_NO_ENCONTRADA":
                return "No se encontró un rostro neutral en la selfie"
            case "SELFIE_SONRISA_NO_ENCONTRADA":
                return "No se encontró un rostro sonriendo en la selfie"
            case "DNI_FRENTE_NO_ENCONTRADO":
                return "No se encontró frente del dni"
            case "DNI_DORSO_NO_ENCONTRADO":
                return "No se encontró dorso del dni"
            default:
                return "Todas las validaciones fueron superadas"
        }
    }

    const obtenerUnidadesDeNegocio = () => {
        dispatch(obtenerUnidadDeNegocio())
    }

    const validarIdentidad = () => {
        setLoading(true)
        setMensaje("Iniciando Validacion...")
        setError(false)
        setShow(true)
        dispatch(IniciarValidacion(dni, unidad, estado, contactid))
    }

    const finalizar = () => {
        props.history.push('/')
    }

    const springWith = useSpring({
        from: { 'font-size': "-150px", opacity: '0' },
        'font-size': "80px",
        opacity: '1',
        config: config.gentle,
    })

    const validarChecks = () => {
        var checkBox1 = document.getElementById('flexCheckChecked1').checked
        var checkBox2 = document.getElementById('flexCheckChecked2').checked
        var checkBox3 = document.getElementById('flexCheckChecked3').checked
        var checkBox4 = document.getElementById('flexCheckChecked4').checked
        var checkBox5 = document.getElementById('flexCheckChecked5').checked
        if (checkBox1 && checkBox2 && checkBox3 && checkBox4 && checkBox5) {
            setValidacionHabilitado(false)
        } else {
            setValidacionHabilitado(true)
        }
    }

    return (
        <div className="container min-vh-100">
            <h4 className="mt-4">Validación de Identidad - DNI: <span class="badge px-2 badge-personalizado-verde-validacion">{dni}</span></h4>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <div class="mb-3">
                            <label className="form-label lbl-precalificacion requerido">Fecha Última Validación Identidad Renaper</label>
                            <input type="text"
                                id="razon"
                                name="razon"
                                className="form-control form-control-sm"
                                // onChange={e => setCalle(e.target.value)}
                                value={fechaValidacion}
                                placeholder="Fecha Última Validación Identidad Renaper"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <div class="mb-3">
                            <label className="form-label lbl-precalificacion requerido">Resultado Última Validación Identidad Renaper</label>
                            <input type="text"
                                id="razon"
                                name="razon"
                                className="form-control form-control-sm"
                                // onChange={e => setCalle(e.target.value)}
                                value={descripcion}
                                placeholder="Resultado Última Validación Identidad Renaper"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                estado != "Finalizado" ?
                    <div className="row mt-4">
                        <div className="col-12 pt-2 pb-2" >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked1" onChange={() => validarChecks()} />
                                <label class="form-check-label" for="flexCheckChecked1">
                                    Declaro Bajo Juramento que he Leído el CONVENIO DE APERTURA DE CUENTAS DE GESTION Y CUSTODIA y doy conformidad de su contenido aceptando las  condiciones en él establecidas y del cual se me ha entregado una copia.
                                </label>
                            </div>
                        </div>
                        <div className="col-12 pt-2 pb-2" >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" onChange={() => validarChecks()} />
                                <label class="form-check-label" for="flexCheckChecked2">
                                    Declaro Bajo Juramento que he Leído la Nómina de Personas Expuestas Políticamente detallada en la Resolución 134/2018 emitida Por la Unidad de Información Financiera  y de la cual se me ha entregado una copia.
                                </label>
                            </div>
                        </div>
                        <div className="col-12 pt-2 pb-2" >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" onChange={() => validarChecks()} />
                                <label class="form-check-label" for="flexCheckChecked3">
                                    Declaro Bajo Juramento que toda la información proveída a través de este Portal de Onboarding Digital, es real y verdadera y me comprometo a notificar cualquier modificación dentro de los 30 días de ocurrida.
                                </label>
                            </div>
                        </div>
                        <div className="col-12 pt-2 pb-2" >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" onChange={() => validarChecks()} />
                                <label class="form-check-label" for="flexCheckChecked4">
                                    Declaro haber leído y aceptar las Tablas de Comisiones Maximas y Minimas publicadas.
                                </label>
                            </div>
                        </div>
                        <div className="col-12 pt-2 pb-2" >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked5" onChange={() => validarChecks()} />
                                <textarea rows="10" cols="100">
                                    EL USUARIO, EN SU CARÁCTER DE TITULAR DE LA INFORMACIÓN PERSONAL, PRESTA SU CONSENTIMIENTO LIBRE, EXPRESO E INFORMADO PARA LA RECOLECCIÓN Y TRATAMIENTO DE SU INFORMACIÓN PERSONAL CON EL ALCANCE Y EN LOS TÉRMINOS ESTABLECIDOS EN LA POLITICA DE PRIVACIDAD PUBLICADA, CONSTITUYENDO CON SU CONSENTIMIENTO UN ACUERDO LEGAL, VÁLIDO Y VINCULANTE, ENTRE EL USUARIO Y NOSTROS.
                                    SU ACCESO A LA PLATAFORMA, SU REGISTRACIÓN EN LA PLATAFORMA Y LA CONTRATACIÓN Y USO DE LOS SERVICIOS IMPLICA QUE UD. PRESTA SU CONSENTIMIENTO EXPRESO, LIBRE E INFORMADO PARA LA INCLUSIÓN, USO Y TRATAMIENTO DE SUS DATOS PERSONALES. ASIMISMO, TAL CONSENTIMIENTO PODRÁ SER REQUERIDO DE FORMA EXPRESA REALIZANDO “CLICK” A LA ACEPTACIÓN DE ESTA POLÍTICA. LA FALTA DE COMUNICACIÓN FORMAL DEL DESACUERDO CON LOS PRESENTES TÉRMINOS Y CONDICIONES, IMPLICARÁN EL EXPRESO CONSENTIMIENTO DEL USUARIO A LA EXTENSIÓN, MODALIDAD Y PAUTAS FIJADAS EN LA PRESENTE POLÍTICA.
                                    EL SIMPLE ACCESO A LA PLATAFORMA Y LA SOLICITUD DE SERVICIO, NO IMPLICA QUE EL USUARIO SEA ACEPTADO COMO CLIENTE. EL USUARIO DEBERÁ CUMPLIR CON LOS PARÁMETROS ESTABLECIDOS, PARA ACEPTACIÓN DE CLIENTES. LOS MENCIONADOS PARÁMETROS HAN SIDO DEFINIDOS EN BASE A LAS NORMAS ESTABLECIDAS POR LA UNIDAD DE INFORMACION FINANCIERA Y LA COMISION NACIONAL DE VALORES.
                                </textarea>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-2 mt-4">
                            <button
                                id="bnt-iniciar"
                                className="btn btn-primary mb-3"
                                onClick={validarIdentidad}
                                disabled={validacionHabilitado}
                            >
                                Iniciar Validación
                            </button>
                        </div>
                    </div> :
                    <div>
                        <div className="contenedor-spinner mt-4" id="spinner1">
                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                        {
                            resultadoValidacion == "OK" ?
                                <div className="row">
                                    <div className="d-flex justify-content-center w-100">
                                        <animated.div style={springWith}>
                                            <FontAwesomeIcon icon={faCheckCircle} className="upload-file atras ok" color="#eee" />
                                        </animated.div>
                                    </div>
                                    <div className="m-0 p-0">
                                        <div className="text-center">
                                            <h3
                                                className="p-0 text-center text-success"
                                            >¡¡Validación Exitosa!!
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            resultadoValidacion == "ERROR" ?
                                <div className="row">
                                    <div className="d-flex justify-content-center w-100">
                                        <animated.div style={springWith}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="upload-file atras ok text-danger" />
                                        </animated.div>
                                    </div>
                                    <div className="m-0 p-0">
                                        <div className="text-center">
                                            <h3
                                                className="p-0 text-center text-danger"
                                            >¡¡Validación Erronea!!
                                            </h3>
                                            <h6>
                                                {descripcion}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <button
                            className="btn btn-primary bt-lg text-center"
                            id="btn-finalizar"
                            onClick={finalizar}
                            disabled={habilitado}
                        >
                            VOLVER
                        </button>
                    </div>
            }
            <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
        </div >
    )
}

export default withRouter(ValidarIdentidad)
