import React from 'react'
import { withRouter } from 'react-router-dom'
import { Toast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useSpring, animated } from 'react-spring'
import { useDispatch } from 'react-redux'
import { recuperarContraseña } from '../Redux/usuarios'
import { AlertCircle } from "react-feather"
import ReCAPTCHA from 'react-google-recaptcha'
import { RecaptchaKey } from '../Keys'

const RecuperoContrasena = (props) => {
    const dispatch = useDispatch()
    const [mail, setMail] = React.useState('')
    const [show, setShow] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [robot, setRobot] = React.useState(false)

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    const ProcesarRecupero = e => {
        e.preventDefault()
        if (!mail.trim()) {
            document.getElementById("floatingInput").classList.add("is-invalid");
            return
        }
        else {
            document.getElementById("floatingInput").classList.remove('is-invalid')
        }
        if (robot == false) {
            setMensaje("Debes confirmar que no eres un robot!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        document.getElementById("btn_recupero").disabled = true
        dispatch(recuperarContraseña(mail))
        setMensaje("Por favor, revise su correo electrónico para restaurar su contraseña.")
        setError(false)
        setShow(true)
        Redirigir()
    }

    const Redirigir = async () => setTimeout(() => {
        props.history.push('/login')
    }, 5000)

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
            setRobot(true)
        } else {
            valor = "False"
            setRobot(false)
        }
    }

    return (
        <animated.div className="portada" style={fade}>
            <div className="vh-100 vw-100 d-flex justify-content-center align-items-center login">
                <div className="contenedor-spinner" id="spinner-login">
                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div className="p-2 col-12 col-md-5 col-lg-4 col-xl-3" id="login">
                    <div className="mb-5 img-thumbail p-2">
                        {/* <img className="rounded mx-auto d-block logo-login" src={logo} alt="" /> */}
                    </div>
                    <div className="card shadow border-0 h-auto d-flex justify-content-start borde-none pad mt-4">
                        <div className="card-header bg-white h-100 d-flex align-items-center pad borde-none">
                            <div className="col-sm-12 pt-3">
                                <div className="float-start">
                                    <h6 className="m-0 w-1 fw-bold">
                                        Recuperar Contraseña
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <form onSubmit={ProcesarRecupero}>
                                <div className="form-floating mb-3">
                                    <input type="email"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="name@example.com"
                                        onChange={e => setMail(e.target.value)}
                                        value={mail}
                                    />
                                    <label htmlFor="floatingInput">Correo electrónico</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <ReCAPTCHA
                                        className=""
                                        render="explicit"
                                        sitekey={RecaptchaKey}
                                        onChange={e => robotOnChange(e)} />
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <button className="btn btn-primary btn-lg btn-block w-100"
                                            type="button"
                                            id="btn_recupero"
                                            onClick={ProcesarRecupero}
                                        >Recuperar</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 pt-2 m-0">
                                        <p className="fw-bold mt-2 m-0 texto-grafico">
                                            <AlertCircle size="20" className="mx-1 mb-1 float-right" color="#006BFF" />
                                            Verifique el correo no deseado
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <animated.div className="row" style={fade}>
                <div className="col-sm-4 position-fixed top-0 end-0 p-5 noti">
                    <Toast className="half-black" onClose={() => setShow(false)} show={show} delay={3000} autohide color="lime">
                        <Toast.Body className="text-white">
                            <div className="row">
                                <div className="col-sm-1 mx-2">
                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                </div>
                                <div className="col-sm-10 mt-1 ml-5">
                                    {mensaje}
                                </div>
                            </div>
                        </Toast.Body>
                    </Toast>
                </div>
            </animated.div>
        </animated.div>
    )
}

export default withRouter(RecuperoContrasena) 
