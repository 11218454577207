import React from 'react'
import Navbar from './Components/Navbar';
import { auth } from './Firebase'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Footer from './Components/Footer';
import Inicio from './Components/Inicio';
import Login from './Components/Login';
import Garantias from './Components/Garantias';
import Operaciones from './Components/Operaciones';
import Registro from './Components/Registro';
import { leerUsuarioActivo, actualizarAccountid } from './Redux/usuarios'
import { Lineas } from './Components/Lineas';
import { CarpetaDigital } from './Components/CarpetaDigital';
import Notificacion from './Components/Notificacion';
import Cuenta from './Components/Cuenta';
import PrecalificacionCrediticia from './Components/PrecalificacionCrediticia';
import RecuperoContrasena from './Components/RecuperoContrasena';
import RelacionesVinculacion from './Components/RelacionesVinculacion'
import SolicitudAlta from './Components/SolicitudAlta';
import Encuesta from './Components/Encuesta';
import ValidarIdentidad from './Components/ValidarIdentidad';

import { loginToken } from './Redux/Token';
import { useDispatch, useSelector } from 'react-redux'
import TokenContext from './context/TokenContext';

function App() {
  //Consantes
  const dispatch = useDispatch()
  const [loggedUser, setLoggedUser] = React.useState(false)

  const [tkn, setTokenDos] = React.useState('')
  const [llamadaToken, setLLamadaToken] = React.useState(false)
  const tokenSelector = useSelector(store => store.token.token)

  React.useEffect(() => {

    if (Object.keys(tokenSelector).length != 0 && llamadaToken === true) {
      setTokenDos(tokenSelector.token)
    } else if (llamadaToken === false) {
      obtenerToken()
      setLLamadaToken(true)
    }

    const fetchUser = () => {
      auth.onAuthStateChanged(user => {
        if (user) {
          if (localStorage.getItem('usuario')) {
            dispatch(leerUsuarioActivo())
          } else {
            dispatch(actualizarAccountid(user.uid))
          }
          setLoggedUser(true)
        } else {
          setLoggedUser(false)
        }
      })
    }
    fetchUser()
  }, [tokenSelector]);

  const obtenerToken = () => {
    dispatch(loginToken())
  }

  return (
    <Router>
      <div>
        <Navbar loggedUser={loggedUser} />
        <Switch>
          <TokenContext.Provider value={tkn}>
            <Route component={Inicio} path="/" exact />
            <Route component={Login} path="/login" exact />
            <Route component={CarpetaDigital} path="/carpetadigital" exact />
            <Route component={Lineas} path="/lineas" exact />
            <Route component={Garantias} path="/garantias" exact />
            <Route component={Operaciones} path="/operaciones" />
            <Route component={Notificacion} path="/notificacion" />
            <Route component={Cuenta} path="/cuenta" exact />
            <Route component={PrecalificacionCrediticia} path="/precalificacion-crediticia" exact />
            <Route component={RecuperoContrasena} path="/recupero" exact />
            <Route component={Registro} path="/registro" exact />
            <Route component={RelacionesVinculacion} path="/relaciones" exact />
            <Route component={SolicitudAlta} path="/solicitud-alta" exact />
            <Route component={Encuesta} path="/encuesta" exact />
            <Route component={ValidarIdentidad} path="/validaridentidad" exact />
          </TokenContext.Provider>
        </Switch>
        <Footer loggedUser={loggedUser} />
      </div>
    </Router>
  )
}

export default App;
