import React from 'react'
import Moment from 'moment'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const MiCuenta = () => {
    const usuarioSelector = useSelector(store => store.usuarios.user)
    const [usuario, setUsuario] = React.useState(false)

    React.useEffect(() => {
        if (usuarioSelector) {
            setUsuario(usuarioSelector)
        }
    }, [usuarioSelector])

    return (
        <div className="row w-100">
            <div className="col-12 ">
                <div className="px-1">
                    <h6 className="fs-6 text-secondary m-0"><FontAwesomeIcon
                        icon={faCircle}
                        className="fs-6 upload-file"
                        color="#28a745"
                    /> {usuario != undefined ? usuario.Name : '---'} - {usuario != undefined ? usuario.Cuit : '---'}</h6>
                </div>
            </div>
            <div className="col-12 mt-1">
                {
                    usuario.Estado != '100000000' ?
                        <div className="px-1">
                            <h6 className="fs-6 text-secondary m-0"> <FontAwesomeIcon
                                icon={faCircle}
                                className="fs-6 upload-file text-warning"
                            /> Onboarding Pendiente</h6>
                        </div>
                        :
                        <div className="px-1">
                            <h6 className="fs-6 text-secondary m-0"> <FontAwesomeIcon
                                icon={faCircle}
                                className="fs-6 upload-file"
                                color="#28a745"
                            /> Onboarding Completo</h6>
                        </div>
                }
            </div>
        </div>
    )
}

export default MiCuenta
