import React from 'react'
import { useSpring, animated } from 'react-spring'
import { faEllipsisH, faCheckCircle, faTimesCircle, faCloudUploadAlt, faEdit, faInfoCircle, faDownload, faFileDownload, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RecaptchaKey } from '../Keys'
import ReCAPTCHA from 'react-google-recaptcha'
import { Toast, Spinner } from 'react-bootstrap'
import Select from 'react-select';
import { Link } from "react-router-dom"
import shortid from 'shortid'
import Notificacion from '../Components/Notificacion'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerDocumentosOnboardingFETCH, agregarDocumentoAcarpeta } from '../Redux/Documentacion'
import { Trash2, Edit, BatteryCharging, Database, UploadCloud, Folder, Upload, AlertTriangle, PlusSquare, AlertCircle, Download } from "react-feather"
import { ToastContainer, toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const SolicitudAltaAdicional = ({ handleChange, state, documentos, token }) => {
    const dispatch = useDispatch()
    const [cantidadMujeres, setCantidadMujeres] = React.useState('')
    const [empleadas, setEmpleadas] = React.useState('')
    const [discapacitados, setDiscapacitados] = React.useState('')
    const [robot, setRobot] = React.useState(false)
    const [relaciones, setRelaciones] = React.useState([])
    const [show, setShow] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [cuitcuil, setCuitcuil] = React.useState('')
    const [personeria, setPersoneria] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [apellido, setApellido] = React.useState('')
    const [razonSocial, setRazonSocial] = React.useState('')
    const [porcentajeParticipacion, setPorcentajeParticipacion] = React.useState('')
    const [descripcion, setDescripcion] = React.useState('')
    const [documentoId, setDocumentoId] = React.useState('')
    const [documento, setDocumento] = React.useState('')
    const [accionistas, setAccionistas] = React.useState([])
    const [esActualizacion, setEsActualizacion] = React.useState(false)
    const [accionistaId, setAccionistaId] = React.useState('')
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [docs, setDocs] = React.useState([])
    const [habilitarEdicion, setHabilitarEdicion] = React.useState(false)
    const [documentosCargados, setDocumentosCargados] = React.useState(false)

    const documentosSelector = useSelector(store => store.documentos.documentos)

    React.useEffect(() => {
        debugger
        if (documentos != undefined && documentos.length != 0 && documentos != null && token.length > 0) {
            if (state.personeria == "100000000") {
                setDocs(documentos)
                obtenerPeso(documentos)
                handleChange("docuentosDTOs")(documentos)
            } else {
                setDocs(filtrarDocumentos(documentos, state.condicionImpositiva))
                obtenerPeso(filtrarDocumentos(documentos, state.condicionImpositiva))
                handleChange("docuentosDTOs")(filtrarDocumentos(documentos, state.condicionImpositiva))
            }
            setDocumentosCargados(true)
        } else if (state.docuentosDTOs.length > 0) {
            var docs = state.docuentosDTOs
            if (state.personeria == "100000000") {
                setDocs(docs)
                obtenerPeso(docs)
                handleChange("docuentosDTOs")(docs)
            } else {
                var docsFiltrados = filtrarDocumentos(docs, state.condicionImpositiva)
                setDocs(docsFiltrados)
                obtenerPeso(docsFiltrados)
                handleChange("docuentosDTOs")(docsFiltrados)
            }
            setDocumentosCargados(true)
        } else if (documentosSelector.length > 0 && llamadaDocumentos === true && documentosCargados == false) {
            debugger
            var docs = filtrarDocumentosPorPersoneria(documentosSelector.filter(item => item.statecode == 0 && item.new_solicituddealta == true), state.personeria)
            if (state.personeria == "100000000") {
                setDocs(docs)
                obtenerPeso(docs)
                handleChange("docuentosDTOs")(docs)
            } else {
                setDocs(filtrarDocumentos(docs, state.condicionImpositiva))
                obtenerPeso(filtrarDocumentos(docs, state.condicionImpositiva))
                handleChange("docuentosDTOs")(filtrarDocumentos(docs, state.condicionImpositiva))
            }
            setDocumentosCargados(true)
        } else if (llamadaDocumentos === false && documentosCargados == false) {
            obtenerTodosDocumentos()
            setLlamadaDocumentos(true)
        }

        var grillaDocumentos = document.getElementById("grilla-documentos")
        var grillaAccionistas = document.getElementById("grilla-accionistas")

        let opciones = state.servicio.split(',')
        opciones.forEach(opcion => {
            if (opcion === "100000000") { //SGR
                var elementoAccionista = document.getElementById("lbl-accionista")
                elementoAccionista.classList.add("requerido")
                grillaDocumentos.style.display = "block"
                if (state.personeria === "100000000") {
                    grillaAccionistas.style.display = "block"
                }
            }
        });
        var arrayAccionistas = []
        if (state.accionistas.length > 0) {
            state.accionistas.forEach(element => {
                arrayAccionistas.push(element)
            });
            setAccionistas(arrayAccionistas)
        }
        if (state.cantidadMujeresDesicion !== '' && cantidadMujeres === '') {
            setCantidadMujeres(state.cantidadMujeresDesicion)
        }
        if (state.cantidadEmpleadosMujeres !== '' && empleadas === '') {
            setEmpleadas(state.cantidadEmpleadosMujeres)
        }
        if (state.cantidadEmpleadosConDiscapacidad !== '' && discapacitados === '') {
            setDiscapacitados(state.cantidadEmpleadosConDiscapacidad)
        }
        if (state.robot == "True") {
            setRobot(false)
            handleChange("robot")("False")
        }
    }, [state.condicionImpositiva, documentos, documentosSelector])

    const obtenerTodosDocumentos = () => {
        dispatch(obtenerDocumentosOnboardingFETCH(token))
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    const obtenerPeso = (docs = []) => {
        // debugger
        var peso = 0
        docs.forEach(element => {
            if (element.pesoArchivo != undefined && element.pesoArchivo != null
                && element.pesoArchivo != 0 && element.pesoArchivo > 0) {
                peso += element.pesoArchivo
            }
        })
        return peso
    }

    const personeriaOpciones = [
        { value: '100000000', label: 'Jurídica' },
        { value: '100000001', label: 'Humana' }
    ]

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
        } else {
            valor = "False"
        }
        setRobot(true)
        handleChange("robot")(valor)
    }

    const filtrarDocumentos = (docs, condicionImpositiva) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_condicionimpositiva != null) {
                var condiciones = doc.new_condicionimpositiva.split(',')
                condiciones.forEach(item => {
                    if (item == condicionImpositiva) {
                        documentosFiltrados.push(doc)
                    }
                })
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const filtrarDocumentosPorPersoneria = (docs, personeria) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_personeria != null) {
                if (doc.new_personeria == personeria) {
                    documentosFiltrados.push(doc)
                }
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const personeriaOnChange = (valor) => {
        setPersoneria(valor)
        setNombre('')
        setApellido('')
        setRazonSocial('')
        setCuitcuil('')
    }

    const cantidadMujeresDesicionOnChange = (valor) => {
        handleChange("cantidadMujeresDesicion")(valor)
        setCantidadMujeres(valor)
    }

    const cantidadEmpleadosMujeresOnChange = (valor) => {
        handleChange("cantidadEmpleadosMujeres")(valor)
        setEmpleadas(valor)
    }

    const cantidadEmpleadosDiscapacidadOnChange = (valor) => {
        handleChange("cantidadEmpleadosConDiscapacidad")(valor)
        setDiscapacitados(valor)
    }

    const crearAccionista = (e) => {
        document.getElementById("btn-accionista").disabled = true
        e.preventDefault()

        if (personeria === '') {
            document.getElementById("personeria").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("La personeria es requerida!")
            toast.error('La personeria es requerida!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("personeria").classList.remove('is-invalid')
        }
        if (cuitcuil === '') {
            document.getElementById("cuitcuil").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("El CUIT/CUIL es requerido!")
            toast.error('El CUIT/CUIL es requerido!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else if (cuitcuil.length > 11 || cuitcuil.length < 11) {
            setLoading(false)
            document.getElementById("cuitcuil").classList.add("is-invalid");
            // setMensaje("El CUIT/CUIL es incorrecto, debe tener 11 caracteres!")
            toast.error('El CUIT/CUIL es incorrecto, debe tener 11 caracteres!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("cuitcuil").classList.remove('is-invalid')
        }
        //debugger
        if (personeria.value === "100000000") { //Juridica
            if (razonSocial === '') {
                document.getElementById("razonSocial").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("La Razón Social es requerida!")
                toast.error('La Razón Social es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("razonSocial").classList.remove('is-invalid')
            }
        } else {
            if (nombre === '') {
                document.getElementById("nombre").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("El Nombre es requerido!")
                toast.error('El Nombre es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("nombre").classList.remove('is-invalid')
            }
            if (apellido === '') {
                document.getElementById("apellido").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("El apellido es requerido!")
                toast.error('El apellido es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("apellido").classList.remove('is-invalid')
            }
        }

        if (porcentajeParticipacion === '') {
            document.getElementById("participacion").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("El porcentaje de participación es requerido!")
            toast.error('El porcentaje de participación es requerido!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("participacion").classList.remove('is-invalid')
        }

        let tipoRelacion = "100000001" //Accionista
        debugger
        var accionista = {
            uid: '',
            personeria: '',
            cuitcuil: '',
            razonSocial: '',
            nombre: '',
            apellido: '',
            porcentaje: 0,
            descripcion: '',
            tipoRelacion: '',
            relacionDirecta: false
        }
        //debugger
        var validacion = validarPorcentaje(accionistas, porcentajeParticipacion)

        if (validacion) {
            accionista.uid = shortid.generate()
            accionista.cuitcuil = cuitcuil
            accionista.personeria = personeria.value
            if (personeria.value === "100000000") {
                accionista.razonSocial = razonSocial
            } else if (personeria.value === "100000001") {
                accionista.nombre = nombre
                accionista.apellido = apellido
            }
            accionista.porcentaje = porcentajeParticipacion
            accionista.descripcion = descripcion
            accionista.tipoRelacion = tipoRelacion
            accionista.relacionDirecta = false
            debugger
            var todosAccionistas = [
                ...accionistas,
                { ...accionista }
            ]
            handleChange("accionistas")(todosAccionistas)
            setAccionistas(todosAccionistas)
            // setMensaje("Accionista cargado con exito")
            toast.success('Accionista cargado con exito', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(false)
            setShow(true)
            setLoading(false)
            setTimeout(() => {
                setShow(false)
                document.getElementById("btn-accionista").disabled = false
            }, 1800);
            setTimeout(() => {
                document.getElementById("myInput").click();
                LimpiarAccionista()
            }, 2700);
        }
    }

    const eliminarAccionista = (uid) => {
        var accionistasAux = accionistas.filter(item => item.uid === uid)
        var i = accionistas.indexOf(accionistasAux[0])
        if (i !== -1) {
            var accionitasNuevo = []
            var accionitasNuevo = accionistas
            accionitasNuevo.splice(i, 1)
            setAccionistas(accionitasNuevo)
            handleChange("accionistas")(accionitasNuevo)
        }
    }

    const calcularMegabytes = (bytes) => {
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 MB';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const actualizarAccionista = (e) => {
        document.getElementById("btn-accionista").disabled = true
        e.preventDefault()
        if (personeria === '') {
            document.getElementById("personeria").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("La personeria es requerida!")
            toast.error('La personeria es requerida!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("personeria").classList.remove('is-invalid')
        }
        if (cuitcuil === '') {
            document.getElementById("cuitcuil").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("El CUIT/CUIL es requerido!")
            toast.error('El CUIT/CUIL es requerido!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else if (cuitcuil.length > 11 || cuitcuil.length < 11) {
            setLoading(false)
            document.getElementById("cuitcuil").classList.add("is-invalid");
            // setMensaje("El CUIT/CUIL es incorrecto, debe tener 11 caracteres!")
            toast.error('El CUIT/CUIL es incorrecto, debe tener 11 caracteres!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("cuitcuil").classList.remove('is-invalid')
        }
        //debugger
        if (personeria.value === "100000000") { //Juridica
            if (razonSocial === '') {
                document.getElementById("razonSocial").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("La Razón Social es requerida!")
                toast.error('La Razón Social es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("razonSocial").classList.remove('is-invalid')
            }
        } else {
            if (nombre === '') {
                document.getElementById("nombre").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("El Nombre es requerido!")
                toast.error('El Nombre es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("nombre").classList.remove('is-invalid')
            }
            if (apellido === '') {
                document.getElementById("apellido").classList.add("is-invalid");
                setLoading(false)
                // setMensaje("El apellido es requerido!")
                toast.error('El apellido es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setError(true)
                setShow(true)
                document.getElementById("btn-accionista").disabled = false
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("apellido").classList.remove('is-invalid')
            }
        }

        if (porcentajeParticipacion === '') {
            document.getElementById("participacion").classList.add("is-invalid");
            setLoading(false)
            // setMensaje("El porcentaje de participación es requerido!")
            toast.error('El porcentaje de participación es requerido!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("participacion").classList.remove('is-invalid')
        }
        debugger
        var validacion = validarPorcentaje(accionistas, porcentajeParticipacion)
        if (validacion) {
            var accionista = {
                uid: '',
                personeria: '',
                cuitcuil: '',
                razonSocial: '',
                nombre: '',
                apellido: '',
                porcentaje: 0,
                descripcion: '',
                tipoRelacion: '',
                relacionDirecta: false
            }

            const accionistaEditado = accionistas.map(item => item.uid === accionistaId ?
                {
                    uid: accionistaId,
                    personeria: personeria.value,
                    cuitcuil: cuitcuil,
                    razonSocial: razonSocial,
                    nombre: nombre,
                    apellido: apellido,
                    porcentaje: porcentajeParticipacion,
                    descripcion: descripcion,
                    tipoRelacion: '100000001',
                    relacionDirecta: habilitarEdicion
                }
                : item)
            debugger
            setAccionistas(accionistaEditado)
            handleChange("accionistas")(accionistaEditado)
            setAccionistaId('')
            // handleChange("actualizacionaccionistas")(accionistaEditado)
            // setMensaje("Accionista cargado con exito")
            toast.success('Accionista cargado con exito', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(false)
            setShow(true)
            setLoading(false)
            setTimeout(() => {
                setShow(false)
                document.getElementById("btn-accionista").disabled = false
            }, 1800)
            setTimeout(() => {
                document.getElementById("myInput").click();
                LimpiarAccionista()
                setEsActualizacion(false)
            }, 2700);
        }
    }

    const obtenerDocumento = async (e) => {
        let nombreDocumento = ""
        docs.filter(elemento => elemento.new_documentacionid === e).map(item => {
            nombreDocumento = item.new_name
        })
        handleChange("nombreDocumento")(nombreDocumento + ':' + e)
    }

    const LimpiarAccionista = () => {
        setCuitcuil('')
        setRazonSocial('')
        setNombre('')
        setApellido('')
        setDescripcion('')
        setPorcentajeParticipacion('')
    }

    const validarPorcentaje = (accionis, porcen) => {
        let porcentaje = 0.00
        accionis.forEach(element => {
            if (element.uid !== accionistaId) {
                porcentaje = porcentaje + parseFloat(element.porcentaje)
            }
        });
        porcentaje = porcentaje + parseFloat(porcen)
        if (porcentaje > 100) {
            // setMensaje("El porcentaje total de los accionistas no puede ser mayor a 100%")
            toast.error('El porcentaje total de los accionistas no puede ser mayor a 100%', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(true)
            setLoading(false)
            setShow(true)
            document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 2000);
            return false
        } else {
            return true
        }
    }

    const editarAccionista = (id) => {
        setAccionistaId(id)
        setEsActualizacion(true)
        accionistas.filter(accionista => accionista.uid == id).map(item => {
            setRazonSocial(item.razonSocial)
            setNombre(item.nombre)
            setApellido(item.apellido)
            setCuitcuil(item.cuitcuil)
            setPorcentajeParticipacion(item.porcentaje)
            setDescripcion(item.descripcion)
            var persona = completarPersoneria(item.personeria)
            if (persona !== undefined) {
                setPersoneria(persona)
            }
            setHabilitarEdicion(item.relacionDirecta)
        })
    }

    const AbrirModalAccionistaCreacion = () => {
        LimpiarAccionista()
        setEsActualizacion(false)
        setHabilitarEdicion(false)
    }

    const completarPersoneria = (tipoPersona) => {
        switch (tipoPersona) {
            case "100000000":
                return { value: tipoPersona, label: 'Jurídica' }
            case "100000001":
                return { value: tipoPersona, label: 'Humana' }
        }
    }

    return (
        <animated.div style={fade}>
            <form>
                <div>
                    <h6 className="fw-bolder">Información Complementaria</h6>
                    <hr className="hr-width hr-principal" />
                </div>
                <div className="row p-2">
                    <div className="col-sm-12 col-md-6" id="grilla-documentos" style={{ display: 'none' }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <label className="form-label fw-bolder lbl-precalificacion">Documentación Requerida</label>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <p className="float-end fw-bolder">
                                    {
                                        state.documentosCargados
                                    }
                                    /
                                    {
                                        docs.length
                                    }
                                    <UploadCloud size={20} className="mx-2" color="#000" />
                                    {/* <FontAwesomeIcon icon={faCloudUploadAlt} className="fs-6 color-footer-notificacion upload-file mx-1" /> */}
                                </p>
                                <p className="float-end fw-bolder mx-1">
                                    {
                                        calcularMegabytes(obtenerPeso(docs))
                                    }
                                    /20MB
                                    <Database size={20} className="mx-2" color="#000" />
                                </p>
                            </div>
                        </div>
                        <div className="lista-header color-header pad">
                            <ul className="list-group w-100 overflow-auto mh-100 shadow-sm mt-2">
                                {
                                    // documentos.filter(documento => documento.new_personeria == state.personeria && documento.new_solicituddealta == 'true').map(item => {
                                    docs.map(item => {
                                        return (
                                            <li key={item.new_documentacionid} className="list-group-item borde-0 px-3">
                                                <div className="row align-items-center">
                                                    <div className="col-8 col-sm-8 m-0 p-0 text-start">
                                                        {
                                                            item.new_requeridoenportal == true ?
                                                                <p className="m-0 color-textp-lista texto-lista requeridoIzquierda" >
                                                                    {
                                                                        item.documentoCargado ?
                                                                            <FontAwesomeIcon
                                                                                icon={faCheckCircle}
                                                                                className="icono-information text-success bg-light rounded-circle upload-file mx-1"
                                                                            /> : null
                                                                    }

                                                                    {item.new_name}

                                                                    {
                                                                        item.new_descripcion ?
                                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{item.new_descripcion}</Tooltip>}>
                                                                                <AlertCircle size="20" className="mx-1 mb-1" color="#006BFF" />
                                                                            </OverlayTrigger>

                                                                            // <FontAwesomeIcon
                                                                            // icon={faInfoCircle}
                                                                            // className="icono-information text-warning bg-dark rounded-circle upload-file mx-1"
                                                                            // data-bs-toggle="tooltip"
                                                                            // data-bs-placement="right"
                                                                            // title={item.new_descripcion} />
                                                                            :
                                                                            null
                                                                    }

                                                                </p>
                                                                : <p className="m-0 color-textp-lista texto-lista" >
                                                                    {
                                                                        item.documentoCargado ?
                                                                            <FontAwesomeIcon
                                                                                icon={faCheckCircle}
                                                                                className="icono-information text-success bg-light rounded-circle upload-file mx-1"
                                                                            /> : null
                                                                    }

                                                                    {item.new_name}

                                                                    {
                                                                        item.new_descripcion ?
                                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{item.new_descripcion}</Tooltip>}>
                                                                                <AlertCircle size="20" className="mx-1 mb-1" color="#006BFF" />
                                                                            </OverlayTrigger>

                                                                            // <FontAwesomeIcon
                                                                            // icon={faInfoCircle}
                                                                            // className="icono-information text-warning bg-dark rounded-circle upload-file mx-1"
                                                                            // data-bs-toggle="tooltip"
                                                                            // data-bs-placement="right"
                                                                            // title={item.new_descripcion} />
                                                                            :
                                                                            null
                                                                    }

                                                                </p>
                                                        }

                                                    </div>
                                                    <div className="col-2">
                                                        {
                                                            (item.new_urlplantilla !== undefined)
                                                                ?
                                                                <button
                                                                    className="btn float-end p-0"
                                                                    type="button"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="right"
                                                                    title="Descargar Plantilla"
                                                                    onClick={() => window.open(item.new_urlplantilla, "_blank")}>
                                                                    <Download size="20" />
                                                                </button>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-1 m-0"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="right"
                                                        title="Ver Documentos"
                                                    >
                                                        <button
                                                            className="btn float-end p-0"
                                                            type="button"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modalDocumentos"
                                                            onClick={e => obtenerDocumento(item.new_documentacionid)}
                                                        >
                                                            <Folder size={20} />
                                                        </button>
                                                    </div>
                                                    <div className="col-1"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="right"
                                                        title="Subir Documento"
                                                    >
                                                        <button
                                                            className="btn float-end p-0"
                                                            type="button"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            onClick={e => obtenerDocumento(item.new_documentacionid)}
                                                        >
                                                            <Upload size={20} className="mx-2" color="#000" />
                                                        </button>
                                                    </div>
                                                    {/* <div className="col-1 m-0 p-0"> */}
                                                    {/* <div className="dropdown dropstart">
                                                            <button className="btn float-end" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <FontAwesomeIcon id={item.new_documentacionid} icon={faEllipsisH} className="fs-6 text-dark upload-file atras" />
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                                                <button
                                                                    type="button"
                                                                    className="btn dropdown-item text-light"
                                                                    id={item.new_documentacionid}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalDocumentos"
                                                                    onClick={e => obtenerDocumento(e.target.id)}
                                                                >
                                                                    Ver Documentos
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn dropdown-item text-light"
                                                                    id={item.new_documentacionid}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    onClick={e => obtenerDocumento(e.target.id)}
                                                                >
                                                                    Subir Documento
                                                                </button>
                                                            </ul>
                                                        </div> */}
                                                    {/* </div> */}
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" id="grilla-accionistas" style={{ display: 'none' }}>
                        <div className="row">
                            <div className="col-6">
                                <label className="form-label fw-bolder lbl-precalificacion" id="lbl-accionista">Accionistas/Socios</label>
                            </div>
                            <div className="col-6">
                                <span className="d-inline-block float-end" tabindex="0" data-bs-toggle="tooltip" title="Agregar">
                                    <button
                                        className="btn float-end p-0"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ModalAccionistas"
                                        onClick={() => AbrirModalAccionistaCreacion()}
                                    >
                                        <PlusSquare size={22} className="mx-2" color="#000" />
                                    </button>
                                </span>
                            </div>
                            <div className="col-12 mt-2">
                                {
                                    (accionistas.length === 0) ? <p className="fs-6">No hay accionistas cargados</p> : ""
                                }
                                <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                    {
                                        accionistas.map(item => {
                                            return (
                                                <li className="list-group-item borde-0" key={item.uid}>
                                                    <div className="row align-items-center pt-2 pb-2">
                                                        <div className="col-4 col-sm-4 m-0 p-0 text-start">
                                                            {
                                                                (item.razonSocial !== '') ?
                                                                    <p className="m-0 color-textp-lista texto-lista fw-bolder" >{item.razonSocial}</p>

                                                                    :
                                                                    <p className="m-0 color-textp-lista texto-lista fw-bolder" >{item.nombre + ' ' + item.apellido}</p>
                                                            }
                                                        </div>
                                                        <div className="col-3 col-sm-3 m-0 text-center">
                                                            <p className="text-lowercase color-textp-lista m-0 texto-lista fw-bolder">
                                                                {item.cuitcuil}
                                                            </p>
                                                        </div>
                                                        <div className="col-2 col-sm-3 m-0 text-end">

                                                            <p className="text-lowercase color-textp-lista m-0 texto-lista fw-bolder" data-bs-toggle="tooltip" data-bs-placement="right" title="Debés actualizar el porcentaje de participación">
                                                                {
                                                                    item.porcentaje === 0 ? <AlertTriangle size={20} color="#D50000" /> : null
                                                                }
                                                                {item.porcentaje}%
                                                            </p>
                                                        </div>

                                                        <div className="col-1 m-0">
                                                            {item.relacionDirecta == false ?
                                                                <button
                                                                    className="btn float-end p-0"
                                                                    type="button"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalConfirmacion"
                                                                    onClick={() => setAccionistaId(item.uid)}
                                                                >
                                                                    <span
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="right"
                                                                        title="Eliminar Accionista"
                                                                    >
                                                                        <Trash2 size={20} />
                                                                    </span>

                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="col-1"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="right"
                                                            title="Editar Accionista"
                                                        >
                                                            <button
                                                                className="btn float-end p-0"
                                                                type="button"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#ModalAccionistas"
                                                                onClick={e => editarAccionista(item.uid)}>
                                                                <Edit size={20} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-sm-12 col-md-6 mt-3">
                        <div className="mb-2">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Cantidad de Mujeres en Puestos de Toma de Decisión</label>
                            <input type="text"
                                name="mujerDesicion"
                                id="cantidadmuejeresdesicion"
                                className="form-control"
                                onChange={e => cantidadMujeresDesicionOnChange(e.target.value)}
                                value={cantidadMujeres}
                                placeholder="0"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mt-3">
                        <div className="mb-2">
                            <label className="form-label fw-bolder lbl-precalificacion">Cantidad de Empleados Mujeres</label>
                            <input type="text"
                                name="empleadas"
                                id="empleadas"
                                className="form-control"
                                onChange={e => cantidadEmpleadosMujeresOnChange(e.target.value)}
                                value={empleadas}
                                placeholder="0"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-2">
                            <label className="form-label fw-bolder lbl-precalificacion">Cantidad de Personas con Discapacidad</label>
                            <input type="text"
                                name="discapacitados"
                                id="discapacitados"
                                className="form-control"
                                onChange={e => cantidadEmpleadosDiscapacidadOnChange(e.target.value)}
                                value={discapacitados}
                                placeholder="0"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" id="robot" style={{ display: 'block' }}>
                        <div className="mt-2">
                            <ReCAPTCHA
                                className=""
                                render="explicit"
                                sitekey={RecaptchaKey}
                                onChange={e => robotOnChange(e)}
                            />
                        </div>
                    </div>
                    {/* <div className="col-6">
                        <div className="mt-2">
                            <ReCAPTCHA
                                className=""
                                render="explicit"
                                sitekey={RecaptchaKey}
                                onChange={e => robotOnChange(e)}
                            />
                        </div>
                    </div> */}
                </div>
            </form>
            <div className="modal fade bd-example-modal-xl" id="ModalAccionistas" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body bg-light">
                            <div className="row">
                                <div className="col-8">
                                    <h6 className="fw-bolder">Agregar Accionista</h6>
                                    <hr className="hr-width hr-principal" />
                                </div>
                                <div className="col-4">
                                    <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInput"></button>
                                </div>
                            </div>
                            <form name="Alyc" onSubmit={esActualizacion ? actualizarAccionista : crearAccionista}>
                                <div className="row w-auto d-flex justify-content-center p-2">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4" id="div-cuenta">
                                            <div className="mb-2 mt-2">
                                                <label className="form-label fw-bolder lbl-precalificacion requerido">Personería</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="personeria"
                                                    onChange={e => personeriaOnChange(e)}
                                                    name="colors"
                                                    options={personeriaOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Seleccionar..."
                                                    value={personeria}
                                                    isDisabled={habilitarEdicion}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4">
                                            <div className="mb-2 mt-2">
                                                <label className="form-label fw-bolder lbl-precalificacion requerido">CUIT/CUIL</label>
                                                <input type="text"
                                                    id="cuitcuil"
                                                    name="cuitcuil"
                                                    className="form-control"
                                                    onChange={e => setCuitcuil(e.target.value)}
                                                    value={cuitcuil}
                                                    placeholder="(sin espacios ni guiones)"
                                                    maxLength="11"
                                                    disabled={habilitarEdicion}
                                                />
                                            </div>
                                        </div>
                                        {
                                            (personeria.value === "100000000") ?

                                                (
                                                    <div className="col-sm-12 col-md-4" id="div-contacto">
                                                        <div className="mb-2 mt-2">
                                                            <label className="form-label fw-bolder lbl-precalificacion requerido">
                                                                Razón Social
                                                            </label>
                                                            <input type="text"
                                                                id="razonSocial"
                                                                name="razonSocial"
                                                                className="form-control"
                                                                placeholder="---"
                                                                onChange={e => setRazonSocial(e.target.value)}
                                                                value={razonSocial}
                                                                maxLength="99"
                                                            />
                                                        </div>
                                                    </div>
                                                ) :

                                                (
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="mb-2 mt-2">
                                                            <label className="form-label fw-bolder lbl-precalificacion requerido">Nombre</label>
                                                            <input type="text"
                                                                id="nombre"
                                                                name="nombre"
                                                                className="form-control"
                                                                onChange={e => setNombre(e.target.value)}
                                                                value={nombre}
                                                                maxLength="250"
                                                                disabled={habilitarEdicion}
                                                                placeholder="---"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                        }

                                        {
                                            (personeria.value === "100000000")
                                                ?
                                                null
                                                :
                                                (
                                                    <div className="col-sm-12 col-md-4">
                                                        <div className="mb-2 mt-2">
                                                            <label className="form-label fw-bolder lbl-precalificacion requerido">Apellido</label>
                                                            <input type="text"
                                                                id="apellido"
                                                                name="apellido"
                                                                className="form-control"
                                                                onChange={e => setApellido(e.target.value)}
                                                                value={apellido}
                                                                maxLength="99"
                                                                disabled={habilitarEdicion}
                                                                placeholder="---"
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        <div className="col-sm-12 col-md-4">
                                            <div className="mb-2 mt-2">
                                                <label className="form-label fw-bolder lbl-precalificacion requerido">% de Participacion</label>
                                                <input type="number"
                                                    id="participacion"
                                                    name="participacion"
                                                    className="form-control"
                                                    min="0"
                                                    max="100"
                                                    onChange={e => setPorcentajeParticipacion(e.target.value)}
                                                    value={porcentajeParticipacion}
                                                    step="any"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <h6 className="fw-bolder">Observaciones</h6>
                                            <div className="p-2">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-2">
                                                            <textarea
                                                                cols="30"
                                                                rows="5"
                                                                id="descripcion"
                                                                name="descripcion"
                                                                className="form-control"
                                                                onChange={e => setDescripcion(e.target.value)}
                                                                value={descripcion}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    id="btn-accionista"
                                    name="btnSubmitAlyc"
                                    className="btn btn-primary btn-md mt-4"
                                >
                                    {
                                        (esActualizacion == true) ? "Actualizar" : "Agregar"
                                    }

                                </button>
                            </form>
                        </div>
                    </div>
                    {/* <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} /> */}
                    <ToastContainer />
                </div>
            </div>
            <div className="modal fade modal-documentos" id="modalConfirmacion" tabIndex="-1" aria-labelledby="modalConfirmacionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad">
                    <div className="modal-content pad borde-none">
                        <div className="modal-body">
                            <div className="mb-3 h-100">
                                <div className="row">
                                    <div className="d'flex justify-content-center">
                                        <div className="col-12 text-center p-4">
                                            <h6 className="fw-bolder">
                                                ¿Desea eliminar el accionista?
                                            </h6>
                                        </div>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-6 text-center">
                                                <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
                                            </div>
                                            <div className="col-6 text-center">
                                                <button className="btn btn-primary"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => eliminarAccionista(accionistaId)}
                                                >Aceptar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} /> */}
            </div>
        </ animated.div>
    )
}

export default SolicitudAltaAdicional
