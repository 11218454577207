import React from 'react'
import Moment from 'moment'
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    withRouter
} from "react-router-dom";
import { cerrarSesion } from '../Redux/usuarios'
import perfiRandom from '../img/foto-perfil-random.png'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerNotificaciones } from '../Redux/Notificaciones'
import { obtenerCuenta } from '../Redux/Cuenta'
import { useSpring, animated } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignJustify, faAlignCenter, faHistory, faCheckDouble, faClipboardCheck, faFolderOpen, faFolder, faCloudUploadAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import logo from '../img/Logoblanco.png'
import 'moment/locale/es';
import { UploadCloud, Calendar, Users, UserCheck } from "react-feather"


const Navbar = (props) => {
    //Constantes
    const dispatch = useDispatch()
    const [notificaciones, setNotificaciones] = React.useState([])
    const [cuenta, setCuenta] = React.useState(null)
    const [menu, setMenu] = React.useState(false)
    const [llamadaNotificaciones, setLlamadaNotificaciones] = React.useState(false)
    const [llamadaCuentas, setLlamadaCuentas] = React.useState(false)
    const [direccion, setDireccion] = React.useState('')
    const [fotoURL, setFotoURL] = React.useState()
    //Estados
    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const usuarioSelector = useSelector(store => store.usuarios.user)
    // const soloAlycSelector = useSelector(store => store.usuarios.soloALYC)
    const notificacionesSelector = useSelector(store => store.notificaciones.notificaciones)
    const cuentaSelector = useSelector(store => store.cuenta.cuenta)
    const fotoselector = useSelector(store => store.usuarios.photoURL)
    const [usuario, setUsuario] = React.useState(false)
    Moment.locale('es')
    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    React.useEffect(async () => {
        if (activo) {
            if (notificacionesSelector.length > 0 && llamadaNotificaciones === true) {
                console.log(notificacionesSelector)
                setNotificaciones(notificacionesSelector.sort((a, b) => {
                    return parseFloat(a.statecode) - parseFloat(b.statecode)
                }))
            } else if (notificacionesSelector.length === 0 && llamadaNotificaciones === false) {
                obtenerNotificacionesCuenta()
                setLlamadaNotificaciones(true)
            }

            if (Object.keys(cuentaSelector).length > 0 && llamadaCuentas === true) {
                setCuenta(cuentaSelector)
            } else if (Object.keys(cuentaSelector).length === 0 && llamadaCuentas === false) {
                obtenerMiCuenta()
                setLlamadaCuentas(true)
            }
            if (usuarioSelector) {
                setUsuario(usuarioSelector)
            }
            if (fotoselector) {

                setFotoURL(fotoselector)
            }
        }
    }, [activo, notificacionesSelector, cuentaSelector, usuarioSelector, fotoselector, fotoURL])


    const obtenerNotificacionesCuenta = async () => {
        dispatch(obtenerNotificaciones(accountid))
    }

    const obtenerMiCuenta = async () => {
        dispatch(obtenerCuenta(accountid))
    }

    const CerrarSesion = (e) => {
        e.preventDefault()
        setCuenta(null)
        setNotificaciones([])
        setLlamadaCuentas(false)
        setLlamadaNotificaciones(false)
        setFotoURL(undefined)
        dispatch(cerrarSesion())
        props.history.push("/login")
    }

    const botonMenu = () => {
        if (menu === false) {
            setMenu(true)
        } else {
            setMenu(false)
        }
    }
    
    return props.loggedUser ? (
        <animated.div className="shadow" style={fade}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0">
                <div className="container-fluid container-lg">
                    <div className="row align-items-center">
                        <div className="col-3 m-0 p-0">
                            <button className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarToggleExternalContent"
                                aria-controls="navbarToggleExternalContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={botonMenu}
                            >
                                {menu ? <FontAwesomeIcon icon={faAlignCenter} className="fs-3 upload-file atras" style={fade} /> : <FontAwesomeIcon icon={faAlignJustify} className="fs-3 upload-file atras" style={fade} />}
                                {/* <span className="navbar-toggler-icon"></span> */}
                            </button>
                        </div>
                        <div className="col-7 col-sm-7 col-lg-12 px-2 p-0">
                            <div className="w-auto float-start">
                                <Link className="navbar-brand m-0" to="/"><img className="rounded mx-auto d-block logo-menu" src={logo} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        <div className="w-auto d-flex justify-content-start">
                            <ul className="nav">
                                <li className="nav-item dropdown dropdown-on-hover show mt-1">
                                    <div className="btn-group p-0">
                                        <button className="btn boton-notificacion p-0"
                                            data-bs-toggle="dropdown"
                                            data-bs-trigger="hover"
                                            aria-expanded="false"
                                        >
                                            <span className="notification-indicator-primary notification-indicator text-light">
                                                {
                                                    notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").length
                                                }
                                            </span>
                                            <i className="bi bi-bell-fill bell-indicator"></i>
                                        </button>
                                        <div className="dropdown-menu shadow dropdown-menu-end pad borde-none">
                                            <div className="card card-notificacion pad borde-none">
                                                <div className="card-header mt-0 p-0 px-2 bg-white pad border-none bg-primary">
                                                    <h6 className="fw-bolder m-0 color-white">Notificaciones</h6>
                                                    <div className="col-sm-2 separador-notificacion">

                                                    </div>
                                                </div>
                                                <div className="mt-1 rounded">
                                                    <div classNam="mt-4 mb-4">
                                                        <h6 className="p-2 fw-bolder mx-2 fs-6 texto-titulo-notificacion">Pendientes</h6>
                                                    </div>
                                                </div>
                                                <div className="p-0 bg-white  borde-none">
                                                <ul className="list-group w-100 overflow-auto notificaciones-menu shadow-sm">
                                                        {
                                                            
                                                            notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").map(item => {
                                                                return (
                                                                    <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">
                                                                        {/* <Link className=" mr-5 text-decoration-none"
                                                                            to="/carpetadigital"
                                                                        >
                                                                            <div className="contened-notificacion">
                                                                                <div className="row align-items-center w-100">
                                                                                    <div className="col-2">
                                                                                        <UploadCloud size={25} className="mx-1" color="#16BC02" />
                                                                                    </div>
                                                                                    <div className="col-10 d-flex align-items-center">
                                                                                        <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                    </div>
                                                                                    <div className="col-2">

                                                                                    </div>
                                                                                    <div className="col-10">
                                                                                        <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link> */}
                                                                        {
                                                                            item.new_tipodenotificacion === "100000000" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to="/carpetadigital"
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2">
                                                                                                <UploadCloud size={25} className="mx-1" color="#16BC02" />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null}
                                                                        {
                                                                            item.new_tipodenotificacion === "100000006" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to="/cuenta"
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2 d-flex justify-content-center">
                                                                                                <Users size={35} className="mx-1" color="#16BC02" />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null

                                                                        }
                                                                        {
                                                                            item.new_tipodenotificacion === "100000007" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to={`/encuesta?id=${item.activityid}`}
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2 d-flex justify-content-center">
                                                                                                <FontAwesomeIcon icon={faCloudUploadAlt} className="fs-4 text-secondary upload-file atras" style={fade} />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <FontAwesomeIcon icon={faHistory} className="fs-6 text-warning upload-file atras" style={fade} />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null
                                                                        }
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="mt-2 rounded">
                                                    <div classNam="mt-4 mb-4">
                                                        <h6 className="p-2 fw-bolder mx-2 fs-6 texto-titulo-notificacion">Completadas</h6>
                                                    </div>
                                                </div>

                                                <div className="p-0 bg-white  borde-none">
                                                    <ul className="list-group w-100 overflow-auto notificaciones-menu shadow-sm">
                                                        {
                                                            notificaciones.filter(noti => noti.statecode == "1" && noti.new_mostrarenportal == "true").map(item => {
                                                                return (
                                                                    <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">
                                                                        <div className="contened-notificacion">
                                                                            <div className="row align-items-center w-100">
                                                                                <div className="col-2 d-flex justify-content-center">
                                                                                    {
                                                                                        item.new_tipodenotificacion == "100000005" ?
                                                                                            <FontAwesomeIcon icon={faUsers} className="fs-4 text-secondary upload-file atras" style={fade} /> :
                                                                                            <FontAwesomeIcon icon={faFolder} className="fs-4 text-secondary upload-file atras" style={fade} />
                                                                                    }
                                                                                </div>
                                                                                <div className="col-10 d-flex align-items-center">
                                                                                    <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                </div>
                                                                                <div className="col-2">

                                                                                </div>
                                                                                <div className="col-10">
                                                                                    <p className="p-0 m-0 mt-2 texto-lista texto"> <FontAwesomeIcon icon={faClipboardCheck} className="fs-6 text-success upload-file atras" style={fade} /> {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="card-footer bg-white text-muted text-center border-none fw-bolder">
                                                    <p className="color-footer-notificacion pt-1 m-0 texto-notificacion-pendiente">
                                                        {
                                                            (notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length == 1) ?
                                                                notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length + " Notificación Pendiente"
                                                                :
                                                                notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length + " Notificaciones Pendientes"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="dropdown d-flex align-items-center h-100">
                                        <button className="btn boton-notificacion p-0"
                                            data-bs-toggle="dropdown"
                                            data-bs-trigger="hover"
                                            aria-expanded="false"
                                        >
                                            {
                                                fotoURL !== undefined ? (
                                                    <img className="border-secondary p-1 foto-perfil icono-circular"
                                                        src={fotoURL} alt=""
                                                    />
                                                )
                                                    : <img className="border-secondary p-1 foto-perfil icono-circular"
                                                        src={perfiRandom} alt=""
                                                    />
                                            }
                                            {/* <img className="border border-secondary bg-light p-1 foto-perfil rounded-circle"
                                                src={perfiRandom} alt=""
                                            /> */}
                                        </button>
                                        <div className="dropdown-menu mt-3 dropdown-menu-end borde-none pad shadow" aria-labelledby="dropdownMenuButton2">
                                            <div className="card card-notificacion borde-none pad">
                                                <div className="p-2">
                                                    <div className="row border-bottom pb-3">
                                                        <div className="col-2">
                                                            {/* <img className="border-secondary padding-foto-perfil foto-perfil-notificacion rounded-circle"
                                                                src={perfiRandom} alt="" /> */}
                                                            {
                                                                fotoURL !== undefined ? (
                                                                    <img className="border-secondary foto-perfil icono-circular"
                                                                        src={fotoURL} alt=""
                                                                    />
                                                                )
                                                                    : <img className="border-secondary foto-perfil icono-circular"
                                                                        src={perfiRandom} alt=""
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="perfil-nombre m-0 fw-bolder">{cuenta != undefined ? cuenta.Name : ''}</p>
                                                            <p className="perfil-email m-0">{cuenta != undefined ? cuenta.emailaddress1 : ''}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row border-bottom pb-1">
                                                        <div className="col-12 mt-2">
                                                            <Link className=" mr-5 text-decoration-none"
                                                                to="/cuenta"
                                                            >
                                                                <button className="dropdown-item mb-2 p-3 rounded-3 perfil-link fw-bolder">
                                                                    Mi Cuenta
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <Link className=" mr-5 text-decoration-none"
                                                                to="/relaciones"
                                                            >
                                                                <button className="dropdown-item mb-2 p-3 rounded-3 perfil-link fw-bolder">
                                                                    Mis Relaciones
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-12 mt-2">
                                                        <div className="mt-1">
                                                            <button className="dropdown-item mb-1 p-3 rounded-3 perfil-link fw-bolder"
                                                                to="/login"
                                                                onClick={CerrarSesion}
                                                            >
                                                                Cerrar Sesión
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarToggleExternalContent">
                        <div className="w-100 d-flex align-items-center">
                            <div className="m-auto">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink className="fuente text-white nav-link text-center padd-menu-link" to="/cuenta">Mis Datos</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="fuente text-white nav-link text-center padd-menu-link" to="/carpetadigital">Mi Documentación Digital</NavLink>
                                    </li>
                                    {
                                        ((usuario.Estado == '100000000' && usuario.ALYC == false && usuario.SGR == true) || (usuario.Estado == '100000000' && usuario.ALYC == true && usuario.SGR == true)) ?
                                            <li className="nav-item">
                                                <NavLink className="fuente text-white nav-link text-center padd-menu-link" to="/lineas">Mis Líneas</NavLink>
                                            </li>

                                            : null
                                    }
                                    {
                                        ((usuario.Estado == '100000000' && usuario.ALYC == false && usuario.SGR == true) || (usuario.Estado == '100000000' && usuario.ALYC == true && usuario.SGR == true)) ?
                                            <li className="nav-item">
                                                <NavLink className="fuente text-white nav-link text-center padd-menu-link" to="/garantias">Mis Garantías</NavLink>
                                            </li>
                                            : null
                                    }
                                    {
                                        ((usuario.Estado == '100000000' && usuario.ALYC == false && usuario.SGR == true) || (usuario.Estado == '100000000' && usuario.ALYC == true && usuario.SGR == true)) ?
                                            <li className="nav-item pr-5">
                                                <NavLink className="fuente text-white nav-link text-center padd-menu-link" to="/operaciones">Mis Operaciones</NavLink>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </div>

                        {/* PANTALLA FORMATO WEB */}
                        <div className="d-none d-lg-block">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown dropdown-on-hover show mt-1">
                                    <div className="btn-group h-auto d-flex align-items-center p-0">
                                        <button className="btn boton-notificacion"
                                            data-bs-toggle="dropdown"
                                            data-bs-trigger="hover"
                                            aria-expanded="false"
                                        >
                                            <span className="notification-indicator-primary notification-indicator text-light">
                                                {
                                                    notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").length
                                                }
                                            </span>
                                            {/* <span className="notification-indicator-primary notification-indicator text-light"></span> */}
                                            <i className="bi bi-bell-fill bell-indicator text-light"></i>
                                        </button>
                                        <div className="dropdown-menu shadow dropdown-menu-end pad borde-none bg-dark">
                                            <div className="card card-notificacion pad borde-none">
                                                <div className="card-header mt-0 p-0 px-2 border-none bg-dark">
                                                    <h6 className="m-0 pb-2 text-white fw-bolder" style={{ "font-size": "1.1em" }}>Notificaciones</h6>
                                                    {/* <div className="col-sm-2 separador-notificacion">

                                                    </div> */}
                                                </div>
                                                <div className="mt-1 rounded">
                                                    <div classNam="mt-4 mb-4">
                                                        <h6 className="p-1 fw-bolder mx-2 lista-header">Pendientes</h6>
                                                    </div>
                                                </div>
                                                <div className="p-0 bg-white  borde-none">
                                                    <ul className="list-group w-100 overflow-auto notificaciones-menu shadow-sm">
                                                        {
                                                            
                                                            notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").map(item => {
                                                                return (
                                                                    <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">
                                                                        {/* <Link className=" mr-5 text-decoration-none"
                                                                            to="/carpetadigital"
                                                                        >
                                                                            <div className="contened-notificacion">
                                                                                <div className="row align-items-center w-100">
                                                                                    <div className="col-2">
                                                                                        <UploadCloud size={25} className="mx-1" color="#16BC02" />
                                                                                    </div>
                                                                                    <div className="col-10 d-flex align-items-center">
                                                                                        <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                    </div>
                                                                                    <div className="col-2">

                                                                                    </div>
                                                                                    <div className="col-10">
                                                                                        <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link> */}
                                                                        {
                                                                            item.new_tipodenotificacion === "100000000" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to="/carpetadigital"
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2">
                                                                                                <UploadCloud size={25} className="mx-1" color="#16BC02" />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null}
                                                                        {
                                                                            item.new_tipodenotificacion === "100000006" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to="/cuenta"
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2 d-flex justify-content-center">
                                                                                                <Users size={35} className="mx-1" color="#16BC02" />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null

                                                                        }
                                                                        {
                                                                            item.new_tipodenotificacion === "100000007" ?
                                                                                <Link className=" mr-5 text-decoration-none"
                                                                                    to={`/encuesta?id=${item.activityid}`}
                                                                                >
                                                                                    <div className="contened-notificacion">
                                                                                        <div className="row align-items-center w-100">
                                                                                            <div className="col-2 d-flex justify-content-center">
                                                                                                <FontAwesomeIcon icon={faCloudUploadAlt} className="fs-4 text-secondary upload-file atras" style={fade} />
                                                                                            </div>
                                                                                            <div className="col-10 d-flex align-items-center">
                                                                                                <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                            </div>
                                                                                            <div className="col-2">

                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <p className="p-0 m-0 mt-2 texto-lista"> <FontAwesomeIcon icon={faHistory} className="fs-6 text-warning upload-file atras" style={fade} />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link> : null
                                                                        }
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>

                                                {/* NOTIFICACIONES VERSION ANTERIOR */}

                                                {/* {
                                                    notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").length > 0 ?
                                                        <div className="p-0 bg-white  borde-none">
                                                            <ul className="list-group w-100 overflow-scroll modal-notificaciones notificaciones-menu shadow-sm">
                                                                {
                                                                    notificaciones.filter(noti => noti.statecode == "0" && noti.new_mostrarenportal == "true").map(item => {
                                                                        return (
                                                                            <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">

                                                                                {
                                                                                    item.new_tipodenotificacion === "100000000" ?
                                                                                        <Link className=" mr-5 text-decoration-none"
                                                                                            to="/carpetadigital"
                                                                                        >
                                                                                            <div className="contened-notificacion">
                                                                                                <div className="row align-items-center w-100">
                                                                                                    <div className="col-2">
                                                                                                        <UploadCloud size={25} className="mx-1" color="#16BC02" />
                                                                                                    </div>
                                                                                                    <div className="col-10 d-flex align-items-center">
                                                                                                        <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                                    </div>
                                                                                                    <div className="col-12">
                                                                                                        <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                                    </div>
                                                                                                    <div className="col-2">

                                                                                                    </div>
                                                                                                    <div className="col-10">
                                                                                                        <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link> : null}
                                                                                {
                                                                                    item.new_tipodenotificacion === "100000006" ?
                                                                                        <Link className=" mr-5 text-decoration-none"
                                                                                            to="/cuenta"
                                                                                        >
                                                                                            <div className="contened-notificacion">
                                                                                                <div className="row align-items-center w-100">
                                                                                                    <div className="col-2 d-flex justify-content-center">
                                                                                                        <Users size={35} className="mx-1" color="#16BC02" />
                                                                                                    </div>
                                                                                                    <div className="col-10 d-flex align-items-center">
                                                                                                        <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                                    </div>
                                                                                                    <div className="col-12">
                                                                                                        <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                                    </div>
                                                                                                    <div className="col-2">

                                                                                                    </div>
                                                                                                    <div className="col-10">
                                                                                                        <p className="p-0 m-0 mt-2 texto-lista"> <Calendar size={18} className=" mb-1" color="#FFA200" />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link> : null

                                                                                }
                                                                                {
                                                                                    item.new_tipodenotificacion === "100000007" ?
                                                                                        <Link className=" mr-5 text-decoration-none"
                                                                                            to={`/encuesta?id=${item.activityid}`}
                                                                                        >
                                                                                            <div className="contened-notificacion">
                                                                                                <div className="row align-items-center w-100">
                                                                                                    <div className="col-2 d-flex justify-content-center">
                                                                                                        <FontAwesomeIcon icon={faCloudUploadAlt} className="fs-4 text-secondary upload-file atras" style={fade} />
                                                                                                    </div>
                                                                                                    <div className="col-10 d-flex align-items-center">
                                                                                                        <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                                    </div>
                                                                                                    <div className="col-12">
                                                                                                        <p className="card-text card-text-menu fw-bolder texto-notificacion-descripcion"> {item.description}</p>
                                                                                                    </div>
                                                                                                    <div className="col-2">

                                                                                                    </div>
                                                                                                    <div className="col-10">
                                                                                                        <p className="p-0 m-0 mt-2 texto-lista"> <FontAwesomeIcon icon={faHistory} className="fs-6 text-warning upload-file atras" style={fade} />  {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link> : null
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div> : null

                                                } */}
                                                <div className="mt-2 rounded">
                                                    <div classNam="mt-4 mb-4">
                                                        <h6 className="p-1 fw-bolder mx-2 lista-header">Completadas</h6>
                                                    </div>
                                                </div>
                                                <div className="p-0 bg-white  borde-none">
                                                    <ul className="list-group w-100 overflow-auto notificaciones-menu shadow-sm">
                                                        {
                                                            notificaciones.filter(noti => noti.statecode == "1" && noti.new_mostrarenportal == "true").map(item => {
                                                                return (
                                                                    <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">
                                                                        <div className="contened-notificacion">
                                                                            <div className="row align-items-center w-100">
                                                                                <div className="col-2 d-flex justify-content-center">
                                                                                    {
                                                                                        item.new_tipodenotificacion == "100000005" ?
                                                                                            <FontAwesomeIcon icon={faUsers} className="fs-4 text-secondary upload-file atras" style={fade} /> :
                                                                                            <FontAwesomeIcon icon={faFolder} className="fs-4 text-secondary upload-file atras" style={fade} />
                                                                                    }
                                                                                </div>
                                                                                <div className="col-10 d-flex align-items-center">
                                                                                    <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                </div>
                                                                                <div className="col-2">

                                                                                </div>
                                                                                <div className="col-10">
                                                                                    <p className="p-0 m-0 mt-2 texto-lista texto"> <FontAwesomeIcon icon={faClipboardCheck} className="fs-6 text-success upload-file atras" style={fade} /> {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>

                                                {/* NOTIFICACIONES VERSION ANTERIOR */}

                                                {/* <div className="p-0 bg-white  borde-none">
                                                    <ul className="list-group overflow-scroll modal-notificaciones notificaciones-menu shadow-sm">
                                                        {
                                                            notificaciones.filter(noti => noti.statecode == "1" && noti.new_mostrarenportal == "true").map(item => {
                                                                return (
                                                                    <li key={item.activityid} className="border-top border-bottom texto-descripcion-notificacion">

                                                                        <div className="contened-notificacion">
                                                                            <div className="row align-items-center w-100">
                                                                                <div className="col-2 d-flex justify-content-center">
                                                                                    {
                                                                                        item.new_tipodenotificacion == "100000005" ?
                                                                                            <UserCheck size={25} className="pt-1" color="#16BC02" /> :
                                                                                            <UserCheck size={25} className="" color="#16BC02" />
                                                                                    }
                                                                                </div>
                                                                                <div className="col-10 d-flex align-items-center">
                                                                                    <h5 className="card-title card-title-menu fw-bold texto-lista p-0 m-0">{item.subject}</h5>
                                                                                </div>
                                                                                <div className="col-2">

                                                                                </div>
                                                                                <div className="col-10">
                                                                                    <p className="p-0 m-0 mt-2 texto-lista">   <Calendar size={18} className=" mb-1" color="#FFA200" /> {Moment(item.createdon).format("DD/MM/YYYY")}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div> */}
                                                <div className="card-footer bg-white text-muted text-center border-none fw-bolder">
                                                    <p className="color-footer-notificacion pt-1 m-0 texto-notificacion-pendiente">
                                                        {
                                                            (notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length == 1) ?
                                                                notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length + " Notificación Pendiente"
                                                                :
                                                                notificaciones.filter(notificacion => notificacion.statecode == "0" && notificacion.new_mostrarenportal == "true").length + " Notificaciones Pendientes"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="dropdown d-flex align-items-center h-100">
                                        <button className="btn boton-notificacion"
                                            data-bs-toggle="dropdown"
                                            data-bs-trigger="hover"
                                            aria-expanded="false"
                                        >
                                            {/* <img className="border border-secondary bg-light p-1 foto-perfil rounded-circle"
                                                src={perfiRandom} alt=""
                                            /> */}
                                            {
                                                fotoURL !== undefined ? (
                                                    <img className="border-secondary foto-perfil icono-circular"
                                                        src={fotoURL} alt=""
                                                    />
                                                )
                                                    : <img className="border-secondary foto-perfil icono-circular"
                                                        src={perfiRandom} alt=""
                                                    />
                                            }
                                        </button>
                                        <div className="dropdown-menu mt-3 dropdown-menu-end borde-none pad shadow" aria-labelledby="dropdownMenuButton2">
                                            <div className="card card-notificacion borde-none pad">
                                                <div className="p-2">
                                                    <div className="row border-bottom pb-3">
                                                        <div className="col-2">
                                                            {/* <img className="border-secondary padding-foto-perfil foto-perfil-notificacion rounded-circle"
                                                                src={perfiRandom} alt="" /> */}
                                                            {
                                                                fotoURL !== undefined ? (
                                                                    <img className="border-secondary foto-perfil icono-circular"
                                                                        src={fotoURL} alt=""
                                                                    />
                                                                )
                                                                    : <img className="border-secondary foto-perfil icono-circular"
                                                                        src={perfiRandom} alt=""
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="perfil-nombre m-0 fw-bolder">{cuenta != undefined ? cuenta.Name : ''}</p>
                                                            <p className="perfil-email m-0">{cuenta != undefined ? cuenta.emailaddress1 : ''}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row border-bottom pb-1">
                                                        <div className="col-12 mt-2">
                                                            <Link className=" mr-5 text-decoration-none"
                                                                to="/cuenta"
                                                            >
                                                                <button className="dropdown-item mb-2 p-3 rounded-3 perfil-link fw-bolder">
                                                                    Mi Cuenta
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <Link className=" mr-5 text-decoration-none"
                                                                to="/relaciones"
                                                            >
                                                                <button className="dropdown-item mb-2 p-3 rounded-3 perfil-link fw-bolder">
                                                                    Mis Relaciones
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-12 mt-2">
                                                        <div className="mt-1">
                                                            <button className="dropdown-item mb-1 p-3 rounded-3 perfil-link fw-bolder"
                                                                to="/login"
                                                                onClick={CerrarSesion}
                                                            >
                                                                Cerrar Sesión
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-start col-sm-12 col-md-6" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Mi Perfil</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row w-100 justify-content-center">
                        <div className="w-auto">
                            {/* <img className="border border-secondary bg-light p-1 foto-mi-perfil rounded-circle"
                                src={perfiRandom} alt=""
                            /> */}
                            {
                                fotoURL !== undefined ? (
                                    <img className="border-secondary foto-perfil icono-circular"
                                        src={fotoURL} alt=""
                                    />
                                )
                                    : <img className="border-secondary foto-perfil icono-circular"
                                        src={perfiRandom} alt=""
                                    />
                            }
                        </div>
                        <div className="w-100 mt-4">
                            <div className="w-auto text-center p-2">
                                {cuenta != undefined ? cuenta.Name : ''}
                            </div>
                        </div>

                        <div className="w-100 text-center p-2">
                            {cuenta != undefined != 0 ? cuenta.new_nmerodedocumento : ''}
                        </div >
                        <div className="w-100 text-center p-2">
                            {cuenta != undefined != 0 ? cuenta.address1_line1 + cuenta.new_direccion1numero : ''}
                        </div>
                        <div className="w-100 text-center p-2">
                            {cuenta != undefined != 0 ? cuenta.new_localidad : ''}
                        </div>
                        <div className="w-100 text-center p-2">
                            {cuenta != undefined != 0 ? cuenta.address1_postalcode : ''}
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
    ) : <div>

    </div>
}

export default withRouter(Navbar)
