import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'

//Const
const dataInicial = {
    encuestas: [],
    resultado: ""
}

const TODAS_ENCUESTAS_EXITO = 'TODAS_ENCUESTAS_EXITO'
const RESPUESTA_ENCUESTAS_EXITO = 'RESPUESTA_ENCUESTAS_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function encuestasReducers(state = dataInicial, action) {
    switch (action.type) {
        case ERROR:
            return { ...dataInicial }
        case LOADING:
            return { ...state, loading: true }
        case TODAS_ENCUESTAS_EXITO:
            return { ...state, encuestas: action.payload }
        case RESPUESTA_ENCUESTAS_EXITO:
            return { ...state, resultado: action.payload }
        default:
            return { ...state }
    }
}

//Actions
export const obtenerEncuestas = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "new_respuestasdelitemdetemplates"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_respuestasdelitemdetemplate'>" +
        "<attribute name='new_respuestasdelitemdetemplateid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='statecode'/> " +
        "<attribute name='statuscode'/> " +
        "<link-entity name='new_itemdeltemplatedeaencuesta' from='new_itemdeltemplatedeaencuestaid' to='new_itemdetemplatedeencuesta' link-type='inner' alias='ad'>" +
        "<attribute name='new_name'/>" +
        "<attribute name='new_templateasociado'/>" +
        "<attribute name='new_itemdeltemplatedeaencuestaid'/>" +
        "<attribute name='new_ordendentrodeltemplate'/>" +
        "<link-entity name='new_encuesta' from='new_encuestaid' to='new_templateasociado' link-type='inner' alias='aj'>" +
        "<attribute name='new_encuestaid'/>" +
        "<attribute name='new_name'/>" +
        "<attribute name='new_tipodeencuesta'/>" +
        "<attribute name='createdon'/>" +
        "<link-entity name='new_encuestas' from='new_templatedeencuesta' to='new_encuestaid' link-type='inner' alias='ak'>" +
        "<attribute name='new_encuestasid'/>" +
        "<attribute name='new_name'/>" +
        "<attribute name='new_perfildelinversor'/>" +
        "<attribute name='new_puntajedelaencuesta'/>" +
        "<attribute name='statuscode'/>" +
        "<filter type='and'>" +
        "<condition attribute='new_cuenta' operator='eq' value='" + accountid + "' />" +
        "<condition attribute='new_estadoencuesta' operator='eq' value='100000000' />" +
        "</filter>" +
        "</link-entity>" +
        "</link-entity>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";
    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: TODAS_ENCUESTAS_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const responderEncuenta = (respuestas, encuestaid, tareaid) => async (dispatch) => {
    try {
        var stringRespuestas = JSON.stringify(respuestas);
        if (stringRespuestas != "[]") {
            const response = await axios.post(`${UrlApiDynamics}Encuestas?respuestas=${stringRespuestas}&encuestaid=${encuestaid}&cuit=${Entidad}`)
            const responseTarea = await axios.post(`${UrlApiDynamics}Tareas?tarea=${tareaid}&estado=1&cuit=${Entidad}`)
            dispatch({
                type: RESPUESTA_ENCUESTAS_EXITO,
                payload: "EXITO"
            })
        }
    } catch (error) {
        dispatch({
            type: ERROR
        })
    }
}