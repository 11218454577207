import axios from 'axios'
import { auth, firebase, storage } from '../Firebase'
import { Entidad, UrlApiDynamics } from '../Keys'
import { ToastContainer, toast } from 'react-toastify';

//constantes
const dataInicial = {
    loading: false,
    activo: false,
    error: false,
    ALYC: false,
    SGR: false,
    Personeria: '',
    Estado: '',
    Name: '',
    Cuit: '',
    accountid: '',
    resultado: '',
    photoURL: ''
}

//Types auth/wrong-password
const LOADING = 'LOADING'
const USUARIO_ERROR = 'USUARIO_ERROR'
const USUARIO_EXITO = 'USUARIO_EXITO'
const CERRAR_SESION = 'CERRAR_SESION'
const REGISTRO_USUARIO = 'REGISTRO_USUARIO'
const USUARIO_ACTUALIZACION = 'USUARIO_ACTUALIZACION'
const LIMPIAR_ERROR = 'LIMPIAR_ERROR'
const REGISTRO_USUARIO_EXISTENTE = 'REGISTRO_USUARIO_EXISTENTE'
const RECUPERO_EXITO = 'RECUPERO_EXITO'

//Reducers
export default function usuariosReducer(state = dataInicial, action) {
    switch (action.type) {
        case USUARIO_ACTUALIZACION:
            return { ...state, accountid: action.accountid, ALYC: action.ALYC, SGR: action.SGR, photoURL: action.payload }
        case REGISTRO_USUARIO:
            return { ...state, loading: false, user: action.payload, activo: true, accountid: action.accountid, error: false, resultado: action.resultado, ALYC: action.ALYC, SGR: action.SGR, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL }
        case CERRAR_SESION:
            return { ...dataInicial, loading: false, activo: false }
        case USUARIO_EXITO:
            return { ...state, loading: false, user: action.payload, activo: true, accountid: action.accountid, error: false, resultado: action.resultado, ALYC: action.ALYC, SGR: action.SGR, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL }
        case REGISTRO_USUARIO_EXISTENTE:
            return { ...state, resultado: action.resultado }
        case USUARIO_ERROR:
            return { ...dataInicial, error: true, resultado: action.resultado }
        case LIMPIAR_ERROR:
            return { ...state, error: false }
        case LOADING:
            return { ...state, resultado: action.resultado }
        default:
            return { ...state }
    }
}

//Actions
export const loginUsuario = (email, pass) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        var ALYC = false
        var SGR = false
        var Personeria = ''
        let accountid = undefined
        let name = ''
        let estado = ''
        let cuit = ''
        let photoURL = ''
        const respCuenta = await axios.get(`${UrlApiDynamics}Account?filter=emailaddress1 eq '${email}'&cuit=${Entidad}`)
        const resp = await auth.signInWithEmailAndPassword(email, pass)


        await firebase.collection('usuarios').doc(resp.user.uid).get()
            .then((doc) => {
                if (doc.exists) {
                    const documento = doc.data()
                    if (respCuenta.data[0].new_estadodelsocio !== documento.Estado) {
                        firebase.collection('usuarios').doc(resp.user.uid).update("Estado", respCuenta.data[0].new_estadodelsocio)
                        estado = respCuenta.data[0].new_estadodelsocio
                    } else {
                        estado = documento.Estado
                    }
                    accountid = documento.accountid
                    ALYC = documento.ALYC
                    SGR = documento.SGR
                    Personeria = documento.Personeria
                    name = documento.Name
                    cuit = documento.Cuit
                    photoURL = documento.photoURL

                    localStorage.setItem('usuario', JSON.stringify({
                        email: resp.user.email,
                        uid: resp.user.uid,
                        accountid: documento.accountid,
                        ALYC: ALYC,
                        SGR: SGR,
                        Personeria: Personeria,
                        Name: name,
                        Estado: estado,
                        Cuit: cuit,
                        photoURL: documento.photoURL
                    }))
                }
            })

        dispatch({
            type: USUARIO_EXITO,
            accountid: accountid,
            photoURL: photoURL,
            payload: {
                email: resp.user.email,
                uid: resp.user.uid,
                accountid: accountid,
                ALYC: ALYC,
                SGR: SGR,
                Personeria: Personeria,
                Name: name,
                Estado: estado,
                Cuit: cuit,
                photoURL: photoURL
            }
        })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const registrarUsuario = (email, pass) => async (dispatch) => {
    dispatch({
        type: LOADING,
        resultado: 'LOADING',
    })

    try {
        const respMail = await axios.get(`${UrlApiDynamics}Account?filter=emailaddress1 eq '${email}'&cuit=${Entidad}`)
        if (respMail.data.length > 0) {
            var ALYC = false
            var SGR = false
            var Personeria = ''
            const accountid = respMail.data[0].Accountid;
            const servicio = respMail.data[0].new_productoservicio
            const personeria = respMail.data[0].new_personeria
            const name = respMail.data[0].Name
            const estado = respMail.data[0].new_estadodelsocio
            const cuit = respMail.data[0].new_nmerodedocumento
            var servicios = servicio.split(',')
            if (servicios.filter(item => item == "100000001").length > 0) {
                ALYC = true
            }
            if (servicios.filter(item => item == "100000000").length > 0) {
                SGR = true
            }
            if (personeria == '100000000') {
                Personeria = 'J'
            } else {
                Personeria = 'F'
            }
            const resp = await auth.createUserWithEmailAndPassword(email, pass)
            //Crea un registro de usuario
            await firebase.collection('usuarios').doc(resp.user.uid).set({
                email: resp.user.email,
                uid: resp.user.uid,
                accountid: accountid,
                ALYC: ALYC,
                SGR: SGR,
                Personeria: Personeria,
                Name: name,
                Estado: estado,
                Cuit: cuit
            })

            localStorage.setItem('usuario', JSON.stringify({
                email: resp.user.email,
                uid: resp.user.uid,
                accountid: accountid,
                ALYC: ALYC,
                SGR: SGR,
                Personeria: Personeria,
                Name: name,
                Estado: estado
                ,
                Cuit: cuit
            }))

            dispatch({
                type: REGISTRO_USUARIO,
                accountid: accountid,
                resultado: 'EXITO',
                payload: {
                    email: resp.user.email,
                    uid: resp.user.uid,
                    accountid: accountid,
                    ALYC: ALYC,
                    SGR: SGR,
                    Personeria: Personeria,
                    Name: name,
                    Estado: estado,
                    Cuit: cuit

                }
            })
        } else {
            dispatch({
                type: REGISTRO_USUARIO_EXISTENTE,
                resultado: 'ERROR',
            })
        }

    } catch (error) {
        dispatch({
            type: USUARIO_ERROR,
            resultado: 'ERROR'
        })
    }
}

export const cerrarSesion = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        emptyCache()
        //Cierra sesion en firestore
        auth.signOut()
        //Eliminamos el usuario del local storage
        localStorage.removeItem('usuario')
        dispatch({
            type: CERRAR_SESION
        })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const leerUsuarioActivo = () => (dispatch) => {
    if (localStorage.getItem('usuario')) {
        const user = JSON.parse(localStorage.getItem('usuario'))
        dispatch({
            type: USUARIO_EXITO,
            payload: user,
            accountid: user.accountid,
            ALYC: user.ALYC,
            SGR: user.SGR,
            Personeria: user.Personeria,
            Name: user.Name,
            Estado: user.Estado,
            Cuit: user.Cuit,
            photoURL: user.photoURL
        })
    }


}

function emptyCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
}

export const actualizarAccountid = (uid) => async (dispatch) => {
    try {
        await firebase.collection('usuarios').doc(uid).get()
            .then((doc) => {
                if (doc.exists) {
                    const documento = doc.data()
                    dispatch({
                        type: USUARIO_ACTUALIZACION,
                        accountid: documento.accountid,
                        ALYC: documento.ALYC,
                        SGR: documento.SGR,
                        Personeria: documento.Personeria,
                        Name: documento.Name,
                        Estado: documento.Estado,
                        Cuit: documento.Cuit,
                        photoURL: documento.photoURL
                    })
                }
            })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const limpiarError = () => (dispatch) => {
    dispatch({
        type: LIMPIAR_ERROR
    })
}

export const recuperarContraseña = (email) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const resp = await auth.sendPasswordResetEmail(email)

    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const actualizarFotoUid = (imgEditada) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    //uid
    const user = auth.currentUser;
    console.log(user)
    try {
        const imgRef = storage.ref().child(user.uid).child('foto perfil')
        await imgRef.put(imgEditada)
        const imgURL = await imgRef.getDownloadURL()

        await firebase.collection('usuarios').doc(user.uid).update({
            photoURL: imgURL,
        }).then(() => {
            toast.success('Foto subida con éxito!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });


        }).catch((error) => {
            toast.error('Ocurrió un problema: ' + error, {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error)
        })

        dispatch({
            type: USUARIO_ACTUALIZACION,
            payload: imgURL
        })

        



    } catch (error) {
        console.log(error)
    }
}