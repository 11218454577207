import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import usuariosReducer from '../Redux/usuarios'
import notificacionReducer from '../Redux/notificacion'
import limitesReducers from '../Redux/LimitePorLinea'
import garantiasReducers from '../Redux/Garantia'
import operacionesReducers from '../Redux/Operaciones'
import documentosPorCuentaReducers from '../Redux/CarpetaDigital'
import notificacionesReducers from '../Redux/Notificaciones'
import documentosOperacionReducers from '../Redux/DocumentacionPorOperacion'
import documentosReducers from '../Redux/Documentacion'
import cuentaReducers from '../Redux/Cuenta'
import tipoDocumentosReducers from '../Redux/TipoDeDocumento'
import PrecalificacionReducers from '../Redux/PrecalificacionCrediticia'
import divisasReducers from '../Redux/Divisa'
import relacionesReducers from '../Redux/RelacionDeVinculacion'
import actividadesAFIPReducers from '../Redux/ActividadAFIP'
import BancosReducers from '../Redux/CuentasBancarias'
import contactosReducers from '../Redux/Contacto'
import unidadesNegocioReducers from '../Redux/UnidadDeNegocio'
import encuestasReducers from '../Redux/Encuesta'
import validacionesReducers from '../Redux/Nosis'
import tokenReducer from '../Redux/Token'

// const rootReducer = combineReducers({
//     usuarios: usuariosReducer,
//     notificaciones: notificacionReducer,
//     limiteporlinea: limitesReducers,
//     garantias: garantiasReducers,
//     operaciones: operacionesReducers,
//     documentosPorCuenta: documentosPorCuentaReducers,
//     notificaciones: notificacionesReducers,
//     documentosOperacion: documentosOperacionReducers,
//     documentos: documentosReducers,
//     cuenta: cuentaReducers,
//     tiposDocumentos: tipoDocumentosReducers,
//     precalificacion: PrecalificacionReducers,
//     divisas: divisasReducers,
//     relaciones: relacionesReducers,
//     actividadesAFIP: actividadesAFIPReducers,
//     bancos: BancosReducers,
//     contactos: contactosReducers,
//     unidadesNegocio: unidadesNegocioReducers,
//     encuesta: encuestasReducers,
//     validacion: validacionesReducers
// })

const appReducer = combineReducers({
    usuarios: usuariosReducer,
    notificaciones: notificacionReducer,
    limiteporlinea: limitesReducers,
    garantias: garantiasReducers,
    operaciones: operacionesReducers,
    documentosPorCuenta: documentosPorCuentaReducers,
    notificaciones: notificacionesReducers,
    documentosOperacion: documentosOperacionReducers,
    documentos: documentosReducers,
    cuenta: cuentaReducers,
    tiposDocumentos: tipoDocumentosReducers,
    precalificacion: PrecalificacionReducers,
    divisas: divisasReducers,
    relaciones: relacionesReducers,
    actividadesAFIP: actividadesAFIPReducers,
    bancos: BancosReducers,
    contactos: contactosReducers,
    unidadesNegocio: unidadesNegocioReducers,
    encuesta: encuestasReducers,
    validacion: validacionesReducers,
    token: tokenReducer
})

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'CERRAR_SESION') {
      state = undefined;
    }
  
    return appReducer(state, action);
  };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    return store
}
