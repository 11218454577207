import React from 'react'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { obtenerTipoDeDocumentos } from '../Redux/TipoDeDocumento'
import { useSpring, animated, interpolate } from 'react-spring'

const SolicitudAltaGeneral = ({ handleChange, state, token }) => {
    const dispatch = useDispatch()
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [tiposDocumentos, setTiposDocumentos] = React.useState([])
    const [personeria, setPersoneria] = React.useState('')
    const [razonSocial, setRazonSocial] = React.useState('')
    const [cuit, setCuit] = React.useState('')
    const [tipoDocumento, setTipoDocumento] = React.useState('')
    const [productoServicio, setProductoServicio] = React.useState([])

    const tipoDocumentoSelector = useSelector(store => store.tiposDocumentos.tiposDocumentos)

    React.useEffect(async () => {
        if(token.length > 0) {

            if (tipoDocumentoSelector.length > 0 && llamadaDocumentos === true) {
                CompletarOpcionesTipoDocumentos(tipoDocumentoSelector)
                if(state.tiposDeDocumento == undefined || state.tiposDeDocumento.length == 0){
                    handleChange("tiposDeDocumento")(tipoDocumentoSelector)
                }
            } else if (llamadaDocumentos === false) {
                obtenerTiposDocumentos()
                setLlamadaDocumentos(true)
            }
        }
        
        if (state) {
            if (state.servicio !== '' && productoServicio.length === 0) {
                var valorServicio = buscarProducto(state.servicio)
                setProductoServicio(valorServicio)
            }
            if (state.personeria !== '' ) {
                var valorPersoneria = buscarPersoneria(state.personeria)
                var opcionPersoneria = { value: state.personeria, label: valorPersoneria }
                setPersoneria(opcionPersoneria)
            }
            if (state.razonSocial !== '' ) {
                setRazonSocial(state.razonSocial)
            }
            if (state.tipoDocumento !== '') {
                var valorTipo
                if (tipoDocumentoSelector.length > 0) {
                    valorTipo = buscarTipoDocumento(state.tipoDocumento, tipoDocumentoSelector)
                }else{
                    valorTipo = buscarTipoDocumento(state.tipoDocumento, state.tiposDeDocumento)
                }
                var opcionTipoDocumento = { value: state.tipoDocumento, label: valorTipo }
                setTipoDocumento(opcionTipoDocumento)
            }
            if (state.cuit !== '' && cuit === '') {
                setCuit(state.cuit)
            }
        }

    }, [tipoDocumentoSelector, state])

    const obtenerTiposDocumentos = async () => {
        dispatch(obtenerTipoDeDocumentos(token))
    }

    const personeriaOpciones = [
        { value: '100000000', label: 'Jurídica' },
        { value: '100000001', label: 'Humana' }
    ]

    const productosServicios = [
        { value: '100000000', label: 'SGR' },
        { value: '100000001', label: 'ALYC' }
    ]

    const CompletarOpcionesTipoDocumentos = (tipos) => {
        const opcionesDocumentos = [];
        tipos.forEach(element => {
            if(element.new_onboarding === true){
                var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
                opcionesDocumentos.push(tipo);
            }
        });
        setTiposDocumentos(opcionesDocumentos)
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    const personeriaOnChange = (valor) => {
        setPersoneria(valor)
        handleChange("personeria")(valor.value)
    }

    const razonSocialOnChange = (valor) => {
        setRazonSocial(valor.target.value)
        handleChange("razonSocial")(valor)
    }

    const tipoDocumentoOnChange = (valor) => {
        setTipoDocumento(valor)
        handleChange("tipoDocumento")(valor.value)
    }

    const cuitOnChange = (valor) => {
        setCuit(valor.target.value)
        handleChange("cuit")(valor)
    }

    const buscarPersoneria = (valor) => {
        switch (valor) {
            case '100000000':
                return 'Jurídica'
            case '100000001':
                return 'Humana'
        }
    }

    const servicioOnChange = (value) => {
        let valores = '';
        value.forEach(element => {
            if (valores === '') {
                valores = element.value
            } else {
                valores = valores + ',' + element.value
            }
        });
        setProductoServicio(value)
        handleChange("servicio")(valores)
    }

    const buscarTipoDocumento = (valor, tiposDocumentos = []) => {
        var nombreTipo = ''
        tiposDocumentos.forEach(element => {
            if (element.new_tipodedocumentoid === valor) {
                nombreTipo = element.new_name
            }
        });
        return nombreTipo
    }

    const buscarProducto = (valor) => {
        let valores = [];
        let opciones = valor.split(',')
        opciones.forEach(element => {
            switch (element) {
                case '100000000':
                    valores.push({ value: element, label: 'SGR' })
                    break;
                case '100000001':
                    valores.push({ value: element, label: 'ALYC' })
                    break;
            }
        });
        return valores
    }

    return (
        <animated.div style={fade}>
            <form className="min-vh-50">
                <div>
                    <h6 className="fw-bolder">Datos Generales</h6>
                    <hr className="hr-width hr-principal" />
                </div>
                <div className="row p-2">
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-2">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Servicio a Operar</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="productoServicio"
                                onChange={e => servicioOnChange(e)}
                                isMulti
                                name="productos"
                                options={productosServicios}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Buscar..."
                                value={productoServicio}
                                placeholder="BUSCAR..."
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Personería</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="personeria"
                                onChange={e => personeriaOnChange(e)}
                                name="colors"
                                options={personeriaOpciones}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="BUSCAR..."
                                value={personeria}
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Nombre o Razón Social</label>
                            <input type="text"
                                id="razon"
                                name="razon"
                                className="form-control"
                                onChange={e => razonSocialOnChange(e)}
                                value={razonSocial}
                                placeholder="---"
                                maxLength="99"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Tipo de Documento</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="tipoDocumento"
                                onChange={e => tipoDocumentoOnChange(e)}
                                name="colors"
                                options={tiposDocumentos}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="BUSCAR..."
                                value={tipoDocumento}
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">CUIT/CUIL</label>
                            <input type="text"
                                name="cuit"
                                id="cuit"
                                className="form-control"
                                onChange={e => cuitOnChange(e)}
                                value={cuit}
                                placeholder="(sin espacios ni guiones)"
                                maxLength="11"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </ animated.div>
    )
}

export default SolicitudAltaGeneral
