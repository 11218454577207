import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    resultado: '',
    url: ''
}

//Types
const VALIDACION_EXITO = 'VALIDACION_EXITO'
const VALIDACION_RESULTADO_EXITO = 'VALIDACION_RESULTADO_EXITO'
const COMPLETAR_VALIDACION_EXITO = 'COMPLETAR_VALIDACION_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function validacionesReducers(state = dataInicial, action) {
    switch (action.type) {
        case VALIDACION_EXITO:
            return { ...state, url: action.payload }
        case VALIDACION_RESULTADO_EXITO:
            return { ...state, resultado: action.payload }
        case LOADING:
            return { ...state, resultado: action.resultado }
        default:
            return { ...dataInicial }
    }
}

//Actions
export const IniciarValidacion = (dni, unidadDeNegocio, estado, contactid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        var url = "https://onboardingdigital.millscapitalmarkets.com"
        // var url = "http://localhost:3000"
        if (dni != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Validacionidentidad?usuario=${unidadDeNegocio.new_usuarioapionboardingwebnosis}
                &token=${unidadDeNegocio.new_passwordapionboardingwebnosis}&grupo=${unidadDeNegocio.new_grupoonboardingwebnosis}&documento=${dni}
                &cuit=${Entidad}&onboarding=true&estado=${estado}&apiKey=${unidadDeNegocio.new_apikeyonboardingwebnosis}&contactid=${contactid}&urlCallback=${url}`)
            dispatch({
                type: VALIDACION_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

//Actions
export const ResultadoValidacion = (dni, unidadDeNegocio, estado, ticket) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        if (dni != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Validacionidentidad?usuario=${unidadDeNegocio.new_usuarioapionboardingwebnosis}
                &token=${unidadDeNegocio.new_passwordapionboardingwebnosis}&grupo=${unidadDeNegocio.new_grupoonboardingwebnosis}&documento=${dni}
                &cuit=${Entidad}&onboarding=true&estado=${estado}&ticket=${ticket}&apiKey=${unidadDeNegocio.new_apikeyonboardingwebnosis}`)
            dispatch({
                type: VALIDACION_RESULTADO_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}
