import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerEncuestas } from '../Redux/Encuesta'
import ItemDeEncuesta from './ItemDeEncuesta'


const Encuesta = (props) => {
    const dispatch = useDispatch()
    const [encuestas, setEncuestas] = React.useState([])
    const [template, setTemplate] = React.useState([])
    const [items, setItems] = React.useState([])
    const [respuestas, setRespuestas] = React.useState([])
    const [step, setStep] = React.useState(1)
    const [llamadaEncuestas, setLlamadaEncuestas] = React.useState(false)

    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const encuestaSelector = useSelector(store => store.encuesta.encuestas)
    // const resultado = useSelector(store => store.encuesta.resultado)

    React.useEffect(() => {
        if (activo) {
            if (items.length === 0) {
                if (encuestaSelector.length > 0) {
                    setEncuestas(encuestaSelector)
                    var mySet = new Set(encuestaSelector.map(item => item["ad.new_itemdeltemplatedeaencuestaid"]))
                    var itemsAux = []
                    mySet.forEach((ele) => {
                        var Items = []
                        var ItemsAUX = []
                        var nombre = ""
                        var encuestaid = ""
                        var orden = ""
                        // encuestaSelector.filter(item => item["ad.new_itemdeltemplatedeaencuestaid"] == ele).map(item =>
                        //     Items.push(item["new_respuestasdelitemdetemplateid"] + ";" + item["new_name"])
                        // )
                        ItemsAUX = new Set(encuestaSelector.filter(item => item["ad.new_itemdeltemplatedeaencuestaid"] == ele).map(item => 
                            item["new_respuestasdelitemdetemplateid"] + ";" + item["new_name"]))
                        ItemsAUX.forEach((item) => {
                            Items.push(item)})
                        encuestaSelector.filter(item => item["ad.new_itemdeltemplatedeaencuestaid"] == ele).map(item =>
                            nombre = item["ad.new_name"]
                        )
                        encuestaSelector.filter(item => item["ad.new_itemdeltemplatedeaencuestaid"] == ele).map(item =>
                            encuestaid = item["ak.new_encuestasid"]
                        )
                        encuestaSelector.filter(item => item["ad.new_itemdeltemplatedeaencuestaid"] == ele).map(item =>
                            orden = item["ad.new_ordendentrodeltemplate"]
                        )
                        itemsAux.push([ele, nombre, Items, encuestaid, orden])
                    })
                    setItems(itemsAux)
                } else if (encuestaSelector.length === 0 && llamadaEncuestas === false) {
                    if (accountid !== undefined) {
                        obtenerTodasEncuestas()
                        setLlamadaEncuestas(true)
                    }
                }
            }
        }
    }, [activo, accountid, encuestaSelector, items])

    const obtenerTodasEncuestas = async () => {
        dispatch(obtenerEncuestas(accountid))
    }

    return (
        <div className="container vh-100">
            <h6 className="fw-bolder mt-4 fs-4">Test del Inversor</h6>
            <ItemDeEncuesta state={items} props={props} cuenta={accountid} />
        </div>
    )
}

export default Encuesta
