import Moment from 'moment'
import React from 'react'

export const COLUMNS5 = [
    {
        Header: 'Líneas',
        footer: 'Líneas',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className="text-lowercase m-0 color-textp-lista texto-lista fw-bolder"> {value} </p> : '-' }
    },
    {
        Header: 'Monto Disponible',
        footer: 'Monto Disponible',
        accessor: 'new_montodisponibleporoperacion',
        Cell: ({ value }) => { return <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> }
    },
]

