import React from 'react'
import Moment from 'moment'
//en cartera
export const COLUMNSGEC = [
    {
        Header: 'N° Orden',
        footer: 'N° Orden',
        accessor: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value}</p> : '-' }
    },
    {
        Header: 'Nombre',
        footer: 'Nombre',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
    // {
    //     Header: 'Serie',
    //     footer: 'Serie',
    //     accessor: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     Header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessor: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessor: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Dictamen del Aval',
    //     accessor: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        Header: 'Monto',
        footer: 'Monto',
        accessor: 'new_monto',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> : '-' }
    },
    {
        Header: 'Fecha de vencimiento',
        footer: 'Fecha de vencimiento',
        accessor: 'new_fechadevencimiento',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
]
//en gestion
export const COLUMNSGEG = [
    {
        Header: 'N° Orden',
        footer: 'N° Orden',
        accessor: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value}</p> : '-' }
    },
    {
        Header: 'Nombre',
        footer: 'Nombre',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
    // {
    //     Header: 'Serie',
    //     footer: 'Serie',
    //     accessor: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     Header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessor: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessor: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Dictamen del Aval',
    //     accessor: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        Header: 'Monto',
        footer: 'Monto',
        accessor: 'new_monto',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> : '-' }
    },
    {
        Header: 'Fecha de vencimiento',
        footer: 'Fecha de vencimiento',
        accessor: 'new_fechadevencimiento',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
]
//vigentes
export const COLUMNSGV = [
    {
        Header: 'N° Orden',
        footer: 'N° Orden',
        accessor: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value}</p> : '-' }
    },
    {
        Header: 'Nombre',
        footer: 'Nombre',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
    // {
    //     Header: 'Serie',
    //     footer: 'Serie',
    //     accessor: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     Header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessor: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessor: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Dictamen del Aval',
    //     accessor: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        Header: 'Monto',
        footer: 'Monto',
        accessor: 'new_monto',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> : '-' }
    },
    {
        Header: 'Fecha de vencimiento',
        footer: 'Fecha de vencimiento',
        accessor: 'new_fechadevencimiento',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
]

//vencidas
export const COLUMNSGVV = [
    {
        Header: 'N° Orden',
        footer: 'N° Orden',
        accessor: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value}</p> : '-' }
    },
    {
        Header: 'Nombre',
        footer: 'Nombre',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
    // {
    //     Header: 'Serie',
    //     footer: 'Serie',
    //     accessor: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     Header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessor: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessor: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Dictamen del Aval',
    //     accessor: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        Header: 'Monto',
        footer: 'Monto',
        accessor: 'new_monto',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> : '-' }
    },
    {
        Header: 'Fecha de vencimiento',
        footer: 'Fecha de vencimiento',
        accessor: 'new_fechadevencimiento',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
]
//afrontadas
export const COLUMNSGA = [
    {
        Header: 'N° Orden',
        footer: 'N° Orden',
        accessor: 'new_ndeordendelagarantiaotorgada',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value}</p> : '-' }
    },
    {
        Header: 'Nombre',
        footer: 'Nombre',
        accessor: 'new_name',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
    // {
    //     Header: 'Serie',
    //     footer: 'Serie',
    //     accessor: '_new_nmerodeserie',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },

    // {
    //     Header: 'Socio Participe',
    //     footer: 'Socio Participe',
    //     accessor: '_new_socioparticipe_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Acreedor',
    //     footer: 'Acreedor',
    //     accessor: 'new_acreedor',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }

    // },
    // {
    //     Header: 'Dictamen del Aval',
    //     accessor: 'new_dictamendelaval_value',
    //     Cell: ({ value }) => { return value ? <p className="m-0 texto-lista texto-lookups m-0 fw-bolder"> {value} </p> : '-' }
    // },
    {
        Header: 'Monto',
        footer: 'Monto',
        accessor: 'new_monto',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0 text-success">$ {Intl.NumberFormat().format(value)} </p> : '-' }
    },
    {
        Header: 'Fecha de vencimiento',
        footer: 'Fecha de vencimiento',
        accessor: 'new_fechadevencimiento',
        Cell: ({ value }) => { return value ? <p className=" m-0 fw-bolder texto-lista m-0">{value} </p> : '-' }
    },
]