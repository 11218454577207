import axios from 'axios'
import { Entidad, UrlApiDynamics, UnidadDeNegocio } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    unidadesDeNegocio: []
}

//Types
const UNIDADESNEGOCIO_EXITO = 'UNIDADESNEGOCIO_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function  unidadesNegocioReducers(state = dataInicial, action) {
    switch (action.type) {
        case UNIDADESNEGOCIO_EXITO:
            return { ...state, unidadesDeNegocio: action.payload }
        default:
            return { ...dataInicial }
    }
}

//Actions
export const obtenerUnidadDeNegocio = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const response = await axios.get(`${UrlApiDynamics}UnidadDeNegocio?filter=businessunitid eq ${UnidadDeNegocio}&cuit=${Entidad}`)
        dispatch({
            type: UNIDADESNEGOCIO_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}