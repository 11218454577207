import axios from 'axios'
import StateManager from 'react-select'
import { Entidad, UrlApiDynamics } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    Bancos: [],
    BancosPorSocios: [],
    crearCuenta: '',
    modificarCuenta: '',
    inactivarCuenta:''
}

//Types
const BANCOS_EXITO = 'BANCOS_EXITO'
const BANCOSXSOCIO_EXITO = 'BANCOSXSOCIO_EXITO'
const CREAR_CUENTA_EXITO = 'CREAR_CUENTA_EXITO'
const MODIFICAR_CUENTA_EXITO = 'MODIFICAR_CUENTA_EXITO'
const LOADING_CUENTA = "LOADING"
const LOADING_MODIFICAR_CUENTA = 'LOADING'
const INACTIVAR_CUENTA_EXITO = 'INACTIVAR_CUENTA_EXITO'
const ERROR = 'ERROR'

//Reducers
export default function BancosReducers(state = dataInicial, action) {
    switch (action.type) {
        case BANCOS_EXITO:
            return { ...state, Bancos: action.payload }
        case BANCOSXSOCIO_EXITO:
            return { ...state, BancosPorSocios: action.payload, crearCuenta: action.crearCuenta, modificarCuenta: action.modificarCuenta, inactivarCuentaMensaje: action.inactivarCuentaMensaje }
        case CREAR_CUENTA_EXITO:
            return { ...state, crearCuenta: action.crearCuenta }
        case MODIFICAR_CUENTA_EXITO:
            return { ...state, modificarCuenta: action.modificarCuenta }
        case LOADING_CUENTA:
            return { ...state, crearCuenta: action.crearCuenta }
        case LOADING_MODIFICAR_CUENTA:
            return { ...state, modificarCuenta: action.modificarCuenta }
        case INACTIVAR_CUENTA_EXITO:
            return {...state, inactivarCuentaMensaje: action.inactivarCuentaMensaje}
        default:
            return { ...dataInicial }
    }
}

//Actions
export const obtenerBancos = () => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        const response = await axios.get(`${UrlApiDynamics}Bancos?filter=&cuit=${Entidad}`)
        dispatch({
            type: BANCOS_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerBancosXsocio = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })
    
    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Bancosporsocio?filter=_new_socio_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: BANCOSXSOCIO_EXITO,
                payload: response.data,
                crearCuenta: 'LOADING'
            })      
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const CrearCuentaXsocio = (accountid, banco, cbu, nroCuenta = "") => async (dispatch) => {
    dispatch({
        type: LOADING_CUENTA,
        crearCuenta: 'LOADING'
    })
    try {
        debugger
        const response = await axios.post(`${UrlApiDynamics}Bancosporsocio?socio=${accountid}&banco=${banco}&cbu=${cbu}&nroCuenta=${nroCuenta}&cuit=${Entidad}`)
        dispatch({
            type: CREAR_CUENTA_EXITO,
            crearCuenta: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            crearCuenta: 'ERROR'
        })
    }
}


export const modificarCuentaxSocio = (accountid, bancoID, newCbu, numCuen, cuentaid)=> async (dispatch) => {
    dispatch({
        type: LOADING_MODIFICAR_CUENTA,
        modificarCuenta: 'LOADING'
    })

    try {
        const response = await axios.post(`${UrlApiDynamics}Bancosporsocio?socio=${accountid}&banco=${bancoID}&cbu=${newCbu}&nroCuenta=${numCuen}&cuentaid=${cuentaid}&cuit=${Entidad}`)
        dispatch({
            type: MODIFICAR_CUENTA_EXITO,
            modificarCuenta: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            modificarCuenta: 'ERROR'
        })
    }
}

                               
export const inactivarCuenta = (accountid, bancoid, cbu, nroCuenta, cuentaid, estado) => async (dispatch) => {

    try {
        const response = await axios.post(`${UrlApiDynamics}Bancosporsocio?socio=${accountid}&banco=${bancoid}&cbu=${cbu}&nroCuenta=${nroCuenta}&cuentaid=${cuentaid}&estado=${estado}&cuit=${Entidad}`)
        dispatch({
            type: INACTIVAR_CUENTA_EXITO,
            inactivarCuentaMensaje: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            inactivarCuentaMensaje: 'ERROR'
        })
    }
}