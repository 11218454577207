import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerTodasGarantias } from '../Redux/Garantia'
import Moment from 'moment'
import { useSpring, animated } from 'react-spring'
import MiCuenta from './MiCuenta'
import Tabla from './Tabla'

import { COLUMNSGEG, COLUMNSGA, COLUMNSGEC, COLUMNSGV, COLUMNSGVV } from '../Tables/ColumnasGarantias'

const Garantias = (props) => {
    //Constantes
    const dispatch = useDispatch()
    const [garantias, setGarantias] = React.useState([])
    const [llamadaGarantias, setLlamadaGarantias] = React.useState(false)

    //Estados
    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const garantiasSelector = useSelector(store => store.garantias.garantias)
    const loading = useSelector(store => store.garantias.loading)

    //Columnas
    const [garantiasEC, setGarantiasEC] = React.useState([])
    const [garantiasEG, setGarantiasEG] = React.useState([])
    const [garantiasV, setGarantiasV] = React.useState([])
    const [garantiasVen, setGarantiasVen] = React.useState([])
    const [garantiasA, setGarantiasA] = React.useState([])

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    React.useEffect(async () => {
        if (activo) {
            if (garantiasSelector.length > 0) {
                setGarantiasEG(COLUMNSGEG)
                setGarantiasEC(COLUMNSGEC)
                setGarantiasV(COLUMNSGV)
                setGarantiasVen(COLUMNSGVV)
                setGarantiasA(COLUMNSGA)
                setGarantias(garantiasSelector)
                if (document.getElementById("spinner1") !== null) {
                    document.getElementById("spinner1").hidden = true;
                }
                if (document.getElementById("spinner2") !== null) {
                    document.getElementById("spinner2").hidden = true;
                }
                if (document.getElementById("spinner3") !== null) {
                    document.getElementById("spinner3").hidden = true;
                }
                if (document.getElementById("spinner4") !== null) {
                    document.getElementById("spinner4").hidden = true;
                }
                if (document.getElementById("spinner5") !== null) {
                    document.getElementById("spinner5").hidden = true;
                }

            } else if (llamadaGarantias === false) {
                const res = await fetchUser()
                setLlamadaGarantias(true)
                setTimeout(() => {
                    if (document.getElementById("spinner1") !== null) {
                        document.getElementById("spinner1").hidden = true;
                    }
                    if (document.getElementById("spinner2") !== null) {
                        document.getElementById("spinner2").hidden = true;
                    }
                    if (document.getElementById("spinner3") !== null) {
                        document.getElementById("spinner3").hidden = true;
                    }
                    if (document.getElementById("spinner4") !== null) {
                        document.getElementById("spinner4").hidden = true;
                    }
                    if (document.getElementById("spinner5") !== null) {
                        document.getElementById("spinner5").hidden = true;
                    }
                }, 3000);
            }
        }
    }, [activo, garantiasSelector])


    console.log(garantias)

    const fetchUser = async () => {
        const resp = dispatch(obtenerTodasGarantias(accountid))
    }

    return (
        <animated.div className="container min-vh-100" style={fade}>
            <div className="mt-3">
                <MiCuenta />
            </div>
            {/* garantias 2.0 */}
            <div className="row">
                <div className="accordion accordion-flush" id="accordionFlushExampleOne">
                    {/* tabla garantias en cartera */}
                    <div className="col-12 col-lg-12 p-2 mt-3">
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <h6>Garantías En Cartera <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.filter(item => item.statuscode == "100000004").length}</span></h6>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExampleOne">
                                    <div className="mt-2 accordion-body">
                                    {garantias.filter(item => item.statuscode == "100000004").length > 0 ? (<Tabla lineas={garantias.filter(item => item.statuscode == "100000004")} columnas={garantiasEC} titulo={'Garantías En Cartera'} header={true} />) : <p className="color-textp-lista fw-bolder pt-1 pb-2 m-0">No hay garantías en estado En Cartera</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tabla garantias en gestion */}
                    <div className="col-12 col-lg-12 p-2 mt-3">
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">
                            <div className="accordion accordion-flush" id="accordionFlushExampleTwo">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            <h6>Garantías En Gestión <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.filter(item => item.statuscode == "1").length}</span></h6>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExampleTwo">
                                        <div className="mt-2 accordion-body">
                                            {garantias.filter(item => item.statuscode == "1").length > 0 ? (<Tabla lineas={garantias.filter(item => item.statuscode == "1")} columnas={garantiasEG} titulo={'Garantías En Gestión'} header={true} />) : <p className="color-textp-lista fw-bolder pt-1 pb-2 m-0">No hay garantías en estado En Gestión</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tabla garantias vigentes */}
                    <div className="col-12 col-lg-12 p-2 mt-3">
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">
                            <div className="accordion accordion-flush" id="accordionFlushExampleThree">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            <h6>Garantías Vigentes <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.filter(item => item.statuscode == "100000001").length}</span></h6>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExampleThree">
                                        <div className="mt-2 accordion-body">
                                        {garantias.filter(item => item.statuscode == "100000001").length > 0 ? (<Tabla lineas={garantias.filter(item => item.statuscode == "100000001")} columnas={garantiasV} titulo={'Garantías Vigentes'} header={true} />) : <p className="color-textp-lista fw-bolder pt-1 pb-2 m-0">No hay garantías en estado Vigente</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tabla garantias vencida */}
                    <div className="col-12 col-lg-12 p-2 mt-3">
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">
                            <div className="accordion accordion-flush" id="accordionFlushExampleFour">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            <h6>Garantías Vencidas <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.filter(item => item.statuscode == "100000000").length}</span></h6>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExampleFour">
                                        <div className="mt-2 accordion-body">
                                        {garantias.filter(item => item.statuscode == "100000000").length > 0 ? (<Tabla lineas={garantias.filter(item => item.statuscode == "100000000")} columnas={garantiasVen} titulo={'Garantías Vencidas'} header={true} />) : <p className="color-textp-lista fw-bolder pt-1 pb-2 m-0">No hay garantías en estado Vencidas</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tabla garantias afrontada */}
                    <div className="col-12 col-lg-12 p-2 mt-3">
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">
                            <div className="accordion accordion-flush" id="accordionFlushExampleFive">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            <h6>Garantías Afrontadas <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.filter(item => item.statuscode == "100000002").length}</span></h6>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExampleFive">
                                        <div className="mt-2 accordion-body">
                                        {garantias.filter(item => item.statuscode == "100000002").length > 0 ? (<Tabla lineas={garantias.filter(item => item.statuscode == "100000002")} columnas={garantiasA} titulo={'Garantías Afrontadas'} header={true} />) : <p className="color-textp-lista fw-bolder pt-1 pb-2 m-0">No hay garantías en estado Afrontada</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
    )
}

export default Garantias
