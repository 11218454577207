import React from 'react'
import Moment from 'moment'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { Toast } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerLimitePorLinea, obtenerTodosLimitesPorLineas } from '../Redux/LimitePorLinea'
import { obtenerTodasGarantiasInicio } from '../Redux/Garantia'
import { obtenerDocumentosPorCuenta, cargarDocumentacionPorCuenta } from '../Redux/CarpetaDigital'
import { obtenerRelacionesFETCH } from '../Redux/RelacionDeVinculacion'
import { obtenerNotificaciones } from '../Redux/Notificaciones'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faCheckCircle, faTimesCircle, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { useSpring, animated } from 'react-spring'
import GoogleMap from './GoogleMap'
import { COLUMNS4 } from '../Tables/GarantiasInicio'
import { COLUMNS5 } from '../Tables/LineasInicio'
import Tabla from '../Components/Tabla'
import MiCuenta from './MiCuenta'
import { UploadCloud } from "react-feather"

const Inicio = (props) => {
    //Constantes
    const dispatch = useDispatch()
    // const {} =
    const [show, setShow] = React.useState(false)
    const [limites, setLimites] = React.useState([])
    const [garantia, setGarantia] = React.useState({})
    const [garantias, setGarantias] = React.useState([])
    const [limite, setLimite] = React.useState({})
    const [carpetas, setCarpetas] = React.useState([])
    const [notificaciones, setNotificaciones] = React.useState([])
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [isFilePicked, setIsFilePicked] = React.useState(false);
    const [documentoId, setDocumentoId] = React.useState('')
    const [documento, setDocumento] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [load, setLoad] = React.useState(false)
    const [llamadaLimites, setLlamadaLimites] = React.useState(false)
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [llamadaGarantias, setLlamadaGarantias] = React.useState(false)
    const [columnasGarantias, setColumnasGarantias] = React.useState([])
    const [columnasLineas, setColumnasLineas] = React.useState([])
    const [usuario, setUsuario] = React.useState("")
    const [relacionesFETCH, setRelacionesFETCH] = React.useState([])
    const [llamadaRelaciones, setLlamadaRelaciones] = React.useState(false)
    let docPendiente = []

    //Reducers
    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const garantiaSelector = useSelector(store => store.garantias.garantia)
    const garanitasSelector = useSelector(store => store.garantias.garantias)
    const limitesSelector = useSelector(store => store.limiteporlinea.limites)
    const limiteSelector = useSelector(store => store.limiteporlinea.limite)
    const documentosSelector = useSelector(store => store.documentosPorCuenta.documentos)
    const notificacionesSelector = useSelector(store => store.notificaciones.notificaciones)
    const usuarioSelector = useSelector(store => store.usuarios.user)
    const relacionesFetchSelector = useSelector(store => store.relaciones.relacionesFetch)

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    React.useEffect(async () => {
        // debugger
        if (activo) {
            if (garanitasSelector.length === 0 && limitesSelector.length === 0 && documentosSelector.length === 0) {
                if (load === false) {
                    setTimeout(() => {
                        if (document.getElementById("spinner1") != null) {
                            document.getElementById("spinner1").style.display = "block"
                        }
                        if (document.getElementById("spinner2") != null) {
                            document.getElementById("spinner2").style.display = "block"
                        }
                        if (document.getElementById("spinner3") !== null) {
                            document.getElementById("spinner3").style.display = "block"
                        }
                        if (document.getElementById("spinner4") !== null) {
                            document.getElementById("spinner4").style.display = "block"
                        }
                    }, 200);
                    setLoad(true)
                }
            }
            if (garanitasSelector.length > 0) {
                setGarantias(garanitasSelector.sort((a, b) => b - a).slice(0, 4))
                if (document.getElementById("spinner1") != null) {
                    document.getElementById("spinner1").style.display = "none"
                }
            } else if (llamadaGarantias === false) {
                if (document.getElementById("spinner1") != null) {
                    document.getElementById("spinner1").style.display = "block"
                }
                obtenerGarantia()
                setLlamadaGarantias(true)
                setTimeout(() => {
                    if (document.getElementById("spinner1") != null) {
                        document.getElementById("spinner1").style.display = "none"
                    }
                }, 4000);
            }

            // if (garantiaSelector !== undefined && Object.keys(garantiaSelector).length > 0) {
            //     setGarantia(garantiaSelector)
            //     setColumnasGarantias(COLUMNS4)
            //     if (document.getElementById("spinner1") !== null) {
            //         document.getElementById("spinner1").hidden = true;
            //     }
            // }
            // else if (garantiaSelector !== undefined && llamadaGarantias === false) {
            //     // const respG = await obtenerGarantia()
            //     // setLlamadaGarantias(true)

            // } else {
            //     setTimeout(() => {
            //         if (document.getElementById("spinner1") !== null) {
            //             document.getElementById("spinner1").hidden = true;
            //         }
            //     }, 3000);
            // }

            if (limiteSelector !== undefined && Object.keys(limiteSelector).length > 0) {
                setLimite(limiteSelector)
            } else if (limiteSelector !== undefined) {
                const respL = await obtenerLimite()
            }

            if (limitesSelector !== undefined && limitesSelector.length > 0) {
                setLimites(limitesSelector.sort((a, b) => b - a).slice(0, 4))
                setColumnasLineas(COLUMNS5)
                if (document.getElementById("spinner2") != null) {
                    document.getElementById("spinner2").style.display = "none"
                }
            } else if (limitesSelector !== undefined && llamadaLimites == false) {
                const resLim = await obtenerTodosLimites()
                setLlamadaLimites(true)
                setTimeout(() => {
                    if (document.getElementById("spinner2") != null) {
                        document.getElementById("spinner2").style.display = "none"
                    }
                }, 3000);
            }

            if (documentosSelector !== undefined && documentosSelector.length > 0) {
                setCarpetas(documentosSelector)
                if (document.getElementById("spinner3") !== null) {
                    document.getElementById("spinner3").style.display = "none"
                }
            } else if (documentosSelector !== undefined && llamadaDocumentos === false) {
                const resDoc = await obtenerDocumentos()
                setLlamadaDocumentos(true)
                setTimeout(() => {
                    if (document.getElementById("spinner3") !== null) {
                        document.getElementById("spinner3").style.display = "none"
                    }
                }, 3000);
            }

            if (usuarioSelector) {
                setUsuario(usuarioSelector)
            }

            if (relacionesFetchSelector.length > 0 && llamadaRelaciones === true) {
                // debugger
                var relaciones = []
                relacionesFetchSelector.forEach(item => {
                    var relacion = {
                        new_participacionaccionariaid: item.new_participacionaccionariaid,
                        new_tienedomicilioenusa: item["ab.new_tienedomicilioenusa"],
                        new_tienedomiciliofiscalenelexterior: item["ab.new_tienedomiciliofiscalenelexterior"],
                        firstname: item["ab.firstname"],
                        lastname: item["ab.lastname"],
                        statuscode: item["ab.statuscode"],
                        new_cuitcuil: item["ab.new_cuitcuil"],
                        emailaddress1: item["ab.emailaddress1"],
                        lastname: item["ab.lastname"],
                        birthdate: item["ab.birthdate"],
                        familystatuscode: item["ab.familystatuscode"],
                        familystatuscode_value: item["ab.familystatuscode@OData.Community.Display.V1.FormattedValue"],
                        new_nrodedocumentoconyuge: item["ab.new_nrodedocumentoconyuge"],
                        new_nrodedocumento: item["ab.new_nrodedocumento"],
                        address3_line1: item["ab.address3_line1"],
                        spousesname: item["ab.spousesname"],
                        address3_postalcode: item["ab.address3_postalcode"],
                        address3_city: item["ab.address3_city"],
                        address3_stateorprovince: item["ab.address3_stateorprovince"],
                        address3_country: item["ab.address3_country"],
                        new_einss: item["ab.new_einss"],
                        new_nit: item["ab.new_nit"],
                        address1_line1: item["ab.address1_line1"],
                        address1_postalcode: item["ab.address1_postalcode"],
                        address1_city: item["ab.address1_city"],
                        address1_stateorprovince: item["ab.address1_stateorprovince"],
                        address1_country: item["ab.address1_country"],
                        new_tipodedocumentoconyuge: item["ab.new_tipodedocumentoconyuge"],
                        new_tipodedocumentoconyuge_value: item["ab.new_tipodedocumentoconyuge@OData.Community.Display.V1.FormattedValue"],
                        new_generodelconyuge: item["ab.new_generodelconyuge"],
                        new_generodelconyuge_value: item["ab.new_generodelconyuge@OData.Community.Display.V1.FormattedValue"],
                        new_conyugetrabaja: item["ab.new_conyugetrabaja"],
                        new_tiposujetoobligado: item["ab.new_tiposujetoobligado"],
                        new_tiposujetoobligado_value: item["ab.new_tiposujetoobligado@OData.Community.Display.V1.FormattedValue"],
                        new_cuentacontactovinculado: item._new_cuentacontactovinculado_value,
                        tipo: item["_new_cuentacontactovinculado_value@Microsoft.Dynamics.CRM.lookuplogicalname"],
                        new_tipoderelacion_value: item["new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
                        _new_cuentacontactovinculado_value: item["_new_cuentacontactovinculado_value@OData.Community.Display.V1.FormattedValue"],
                        statecode: item.statecode,
                        cuenta_new_nmerodedocumento: item["aa.new_nmerodedocumento"],
                        cuenta_razon_social: item["aa.name"],
                        cuenta_email: item["aa.emailaddress1"],
                        new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                        new_personaexpuestapoliticamente: item["ab.new_personaexpuestapoliticamente"],
                        new_cargofuncinjerarquayenteendondetrabaja: item["ab.new_cargofuncinjerarquayenteendondetrabaja"],
                        new_relacinconlapersonaexpuestapolticamente: item["ab.new_relacinconlapersonaexpuestapolticamente"],
                        new_fechadealtabaja: item["ab.new_fechadealtabaja"],
                        new_fechadebaja: item["ab.new_fechadebaja"],
                        new_entedondetrabaja: item["ab.new_entedondetrabaja"]
                    }
                    relaciones.push(relacion)
                })
                setRelacionesFETCH(relaciones)
                if (document.getElementById("spinner4") !== null) {
                    document.getElementById("spinner4").style.display = "none"
                }
            } else if (relacionesFetchSelector.length === 0 && llamadaRelaciones === false) {
                obtenerRelacionesFetch()
                setLlamadaRelaciones(true)
            }
        }
        else {
            props.history.push('/login')
        }

    }, [activo, limitesSelector, limiteSelector, garantiaSelector, documentosSelector, usuarioSelector, garanitasSelector, relacionesFetchSelector])

    const obtenerNotificacionesPorCuenta = async () => {
        dispatch(obtenerNotificaciones(accountid))
    }

    const obtenerGarantia = async () => {
        dispatch(obtenerTodasGarantiasInicio(accountid))
    }

    const obtenerLimite = async () => {
        dispatch(obtenerLimitePorLinea(accountid))
    }

    const obtenerTodosLimites = async () => {
        dispatch(obtenerTodosLimitesPorLineas(accountid))
    }

    const obtenerDocumentos = async () => {
        dispatch(obtenerDocumentosPorCuenta(accountid))
    }

    const obtenerRelacionesFetch = async () => {
        dispatch(obtenerRelacionesFETCH(accountid))
    }

    const obtenerDocumentoSelect = async (e) => {
        setDocumentoId(e)
        carpetas.filter(elemento => elemento.new_documentacionporcuentaid === e).map(item => {
            setDocumento(item)
        })
    }

    const changeHandler = (event) => {
        setSelectedFiles(event.target.files)
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmission = (e) => {
        e.preventDefault()

        if (documentoId === '') {
            document.getElementById("documento").classList.add('is-invalid')
            setMensaje("El documento es requerido!")
            setError(true)
            setShow(true)
            return
        } else {
            document.getElementById("documento").classList.remove('is-invalid')
            document.getElementById("documento").classList.add("is-valid")
        }
        if (selectedFiles.length === 0) {
            document.getElementById("adjunto").classList.add('is-invalid')
            setMensaje("El archivo adjunto es requerido!")
            setError(true)
            setShow(true)
            return
        } else {
            document.getElementById("adjunto").classList.remove('is-invalid')
            document.getElementById("adjunto").classList.add("is-valid")
        }

        const formData = new FormData();
        for (let index = 0; index < selectedFiles.length; index++) {
            let element = selectedFiles[index];
            formData.append(`body${index}`, element);
        }
        // formData.append('body', selectedFiles);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        dispatch(cargarDocumentacionPorCuenta(formData, config, documentoId, documento))
        setMensaje("La subida del archivo fue exitosa!")
        setError(false)
        setShow(true)
        limpiar()
    };

    const limpiar = async () => setTimeout(() => {
        document.getElementById("documento").classList.remove('is-valid')
        document.getElementById("adjunto").classList.remove('is-valid')
        setDocumentoId('')
        setSelectedFiles([])
        document.getElementById("adjunto").value = "";
        document.docXcuenta.btnSubmit.blur()
    }, 2500)

    const obtenerEstadoDocumentacion = (valor) => {
        switch (valor) {
            case "1":
                return <span class="badge px-2 badge-personalizado-amarillo">Pendiente</span>
            case "100000000":
                return <span class="badge px-2 badge-personalizado-naranja">Recibido</span>
            case "100000001":
                return <span class="badge px-2 badge-personalizado-verde">Aprobado</span>
        }
    }


    return (
        <animated.div className="container min-vh-100" style={fade}>
            <div className="row">
                <div className="col-12">
                    <div className="mt-3 w-100">
                        <MiCuenta />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-sm-12">
                    <div className="card shadow borde-none pad w-100 mt-2 mb-4 pb-3">
                        <div className="col-sm-12 pt-2 m-0">
                            <span className="separador-titulo float-start mt-1 m-0"></span>
                            {
                                usuario.Estado != '100000000' ?
                                    <p className="pt-2 mx-2 pb-2 fw-bolder mt-1 m-0 w-100">
                                        Bienvenido, su proceso de onboarding se encuentra en estado pendiente. Hacé click en la campanita para ver las notificaciones y completá las pendientes.
                                    </p>
                                    :
                                    <p className="pt-2 mx-2 pb-2 fw-bolder mt-1 m-0 w-100">
                                        Resumen de Posición
                                    </p>
                            }
                        </div>
                    </div>
                </div>

            </div>
            {/* tope por linea comercial y monto utilizado general */}
            <div className="row mt-2">
                {
                    usuario.Estado != '100000000' ?
                        null
                        :
                        <div className="col-sm-12 col-md-6 pb-3 m-0">
                            <div className="card p-0 shadow pad borde-none">
                                <div className="card-body">
                                    <div>
                                        <div className="p-0">
                                            <h6 className="color-textp-lista fw-bolder">Tope por línea comercial</h6>
                                            <hr className="hr-width" />
                                        </div>
                                    </div>

                                    <div className="mt-2">
                                        <h2 className="text-success fw-bolder" style={fade}>$  {limite.new_montodisponiblegeneral ? Intl.NumberFormat().format(limite.new_montodisponiblegeneral) : 0}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                {/* {
                    usuario.Estado != '100000000' ?
                        null
                        :
                        <div className="col-sm-12 col-md-2 pt-3 pb-3">

                        </div>
                } */}
                {
                    usuario.Estado != '100000000' ?
                        null
                        :
                        <div className="col-sm-12 col-md-6 pb-3 pad m-0">
                            <div className="card p-0 shadow pad borde-none">
                                <div className="card-body">
                                    <div>
                                        <h6 className="color-textp-lista fw-bolder">Monto utilizado general</h6>
                                        <hr className="hr-width" />
                                    </div>
                                    <div className="mt-2">
                                        <h2 className="fw-bolder text-success">$ {limite.new_topeporlineacomercial ? Intl.NumberFormat().format(limite.new_topeporlineacomercial) : 0}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
            {/* look and feel cards 2.0 */}
            <div className="row">


                <div className="col-sm-6 mb-3">
                    <div className="p-2 mb-3">
                        <div className="p-2 pb-0">
                            <h6 className="fw-bolder">Documentación</h6>
                            <hr className="hr-width hr-principal" />
                        </div>
                        <div className="card shadow p-0 border-0 h-auto d-flex justify-content-start pad">

                            <div className="card doc-cards color-header pad borde-none">
                                <div className="lista-header pad borde-none">
                                    <div className="row p-1 align-items-center">
                                        <div className="col-7 px-3">
                                            Carpeta Digital
                                        </div>
                                        <div className="col-5 text-end text-dark fw-bolder p-0">
                                            <NavLink
                                                className="fuente text-white nav-link text-center padd-menu-link float-end p-1 px-4"
                                                to="/carpetadigital"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                style={{ "cursor": "pointer" }}
                                                title="Carpeta Digital"
                                            >
                                                <UploadCloud size={30} className="mx-2" color="#006BFF" />
                                            </NavLink>

                                        </div>
                                    </div>
                                </div>
                                <div className="contenedor-spinner" id="spinner3" style={{ display: 'none' }}>
                                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx-1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                                <ul className="list-group list-group-flush w-100 overflow-auto lista-body">
                                    {
                                        carpetas.filter(carpeta => carpeta.new_visibleenportal == "true").map(item => {
                                            return (
                                                <li key={item.new_documentacionporcuentaid} className="list-group-item borde-0">
                                                    <div className="row align-items-center pt-2 pb-2">
                                                        <div className="col-sm-12 m-0 p-0 text-start">
                                                            <div className="row align-items-center p-2">
                                                                <div className="col-9 text-start">
                                                                    <p className="m-0 fw-bolder texto-lista m-0 text-lowercase" >
                                                                        {item.new_name}
                                                                        {/* <span className="text-start">
                                                                        <FontAwesomeIcon icon={faFilePdf} className="fs-6 text-dark upload-file" />
                                                                    </span></p> */}
                                                                    </p>
                                                                </div>
                                                                <div className="col-3">
                                                                    {obtenerEstadoDocumentacion(item.statuscode)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="row p-3">
                                    <div className="col-sm-12 text-center c-azul fw-bolder">
                                        <NavLink className="fuente nav-link text-center padd-menu-link" to="/carpetadigital">Ver más</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 mb-3">
                    <div class="accordion-flush shadow" id="accordionExample">
                        {
                            usuario.Estado != '100000000' ?
                                null
                                :
                                <>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Vinculaciones <span class="badge rounded-pill bg-warning text-dark ms-2">{relacionesFETCH.length}</span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className="card p-0 border-0 h-auto d-flex justify-content-start pad mt-4">


                                                    <div className="card doc-cards pad borde-none">
                                                        <div className="lista-header color-header pad">
                                                            <div className="row p-3">
                                                                <div className="col-6 text-start">
                                                                    Cuenta/Contacto Vinculado
                                                                </div>
                                                                <div className="col-6 text-end">
                                                                    Relación
                                                                </div>
                                                            </div>
                                                            <div className="contenedor-spinner" id="spinner4" style={{ display: 'none' }}>
                                                                <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                            </div>
                                                            <ul className="list-group list-group-flush w-100 overflow-auto lista-body">
                                                                {
                                                                    relacionesFETCH.filter((itemRelacion) => itemRelacion.statecode == "0").map(item => {
                                                                        return (
                                                                            <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                                                <div className="row d-flex align-items-center pt-2 pb-2">
                                                                                    <div className="col-6 m-0 p-0 text-start">
                                                                                        <p className="text-lowercase m-0 color-textp-lista mx-2 texto-lista">{item._new_cuentacontactovinculado_value}</p>
                                                                                    </div>
                                                                                    <div className="col-6 m-0 text-end">
                                                                                        <p className="text-lowercase m-0 fw-bolder texto-lista">{item.new_tipoderelacion_value}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            <div className="row p-3">
                                                                <div className="col-sm-12 text-center c-azul fw-bolder">
                                                                    <NavLink className="fuente nav-link text-center padd-menu-link" to="/cuenta">Ver más</NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                Garantias <span class="badge rounded-pill bg-warning text-dark ms-2">{garantias.length}</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className="p-2 mb-3">
                                                    <div className="card p-0 border-0 h-auto d-flex justify-content-start pad">
                                                        <div className="p-2 pb-0">
                                                            <h6 className="fw-bolder">Mis Garantías Recientes</h6>
                                                            <hr className="hr-width hr-principal" />
                                                        </div>
                                                        <div className="card doc-cards pad borde-none">
                                                            <div className="lista-header color-header pad">
                                                                <div className="row p-3">
                                                                    <div className="col-6 col-sm-6 ">
                                                                        Garantías
                                                                    </div>
                                                                    <div className="col-6 col-sm-6 text-end">
                                                                        Fecha vencimiento
                                                                    </div>
                                                                </div>
                                                                <div className="contenedor-spinner" id="spinner1" style={{ display: 'none' }}>
                                                                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx-1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                                </div>
                                                                <ul className="list-group list-group-flush w-100 overflow-auto mh-100 shadow-sm">
                                                                    {
                                                                        garantias.map(item => {
                                                                            return (
                                                                                <li key={item.new_productosid} className="list-group-item borde-0">
                                                                                    <div className="row align-items-center pt-1 pb-1">
                                                                                        <div className="col-8 col-sm-8 m-0 p-0 text-start">
                                                                                            <p className="text-lowercase m-0 color-textp-lista texto-lista" >{item.new_name}</p>
                                                                                        </div>
                                                                                        <div className="col-4 col-sm-4 m-0 text-end">
                                                                                            <p className="text-lowercase color-textp-lista m-0 texto-lista">
                                                                                                {(item.new_fechadevencimiento) ? Moment(item.new_fechadevencimiento).format("DD-MM-YYYY") : '---'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="row p-3">
                                                                    <div className="col-12 color-header text-center c-azul fw-bolder">
                                                                        <NavLink className="fuente nav-link text-center padd-menu-link" to="/garantias">Ver más</NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Líneas <span class="badge rounded-pill bg-warning text-dark ms-2">{limites.length}</span>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className="p-2 mb-3">
                                                    <div className="card p-0 border-0 h-auto d-flex justify-content-start pad">
                                                        <div className="p-2 pb-0">
                                                            <h6 className="fw-bolder">Mis Líneas Recientes</h6>
                                                            <hr className="hr-width hr-principal" />
                                                        </div>
                                                        <div className="card doc-cards pad borde-none">
                                                            <div className="lista-header color-header pad">
                                                                <div className="row p-3">
                                                                    <div className="col-6 col-sm-6 ">
                                                                        Líneas
                                                                    </div>
                                                                    <div className="col-6 col-sm-6 text-end">
                                                                        Monto Disponible
                                                                    </div>
                                                                </div>
                                                                <div className="contenedor-spinner" id="spinner1" style={{ display: 'none' }}>
                                                                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx-1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                                </div>
                                                                <ul className="list-group list-group-flush w-100 overflow-auto mh-100 shadow-sm">
                                                                    {
                                                                        limites.map(item => {
                                                                            return (
                                                                                <li key={item.new_productosid} className="list-group-item borde-0">
                                                                                    <div className="row align-items-center pt-1 pb-1">
                                                                                        <div className="col-8 col-sm-8 m-0 p-0 text-start">
                                                                                            <p className="text-lowercase m-0 color-textp-lista texto-lista" >{item.new_name}</p>
                                                                                        </div>
                                                                                        <div className="col-4 col-sm-4 m-0 text-end">
                                                                                            <p className="text-lowercase color-textp-lista m-0 texto-lista">
                                                                                                $ {Intl.NumberFormat().format(item.new_montodisponibleporoperacion)}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="row p-3">
                                                                    <div className="col-sm-12 text-center c-azul fw-bolder">
                                                                        <NavLink className="fuente nav-link text-center padd-menu-link" to="/lineas">Ver más</NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>




            {/* <div className="col-sm-12">
                <div className="card shadow borde-none pad w-100 mb-4 pt-3 pb-3">
                    <div className="col-sm-12 m-0">
                        <span className="separador-titulo float-start m-0"></span>
                        <p className="pt-2 mx-2 pb-2 fw-bolder m-0 w-100">
                            Contactanos
                        </p>
                    </div>
                </div>
            </div>
            <div className="pb-5">
                <div className="row">
                    <div className="col-sm-8 mt-3">
                        <div className="card doc-cards shadow w-100 h-100 pad borde-none">
                            <div className="alto-mapa p-2">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <div className="card doc-cards shadow w-100 h-100 pad borde-none">
                            <div className="alto-mapa p-2">
                                <div className="row h-25 align-items-center justify-content-center">
                                    <div className="col-sm-12 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" className="bi bi-geo-alt-fill color-secundario" viewBox="0 0 16 16">
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="row h-25 align-items-center justify-content-center">
                                    <div className="col-sm-12 text-center">
                                        <h6 className="fw-bolder">Salguero 2745, piso 3</h6>
                                        <h6 className="fw-bolder">Ciudad Autónoma de Buenos Aires</h6>
                                        <h6 className="fw-bolder">Argentina</h6>
                                    </div>
                                </div>
                                <div className="row h-25 align-items-center justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" className="bi bi-telephone-inbound-fill color-secundario" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </div>
                                <div className="row h-25 align-items-center justify-content-center">
                                    <div className="col-sm-12 text-center">
                                        <h6 className="fw-bolder">+54 11 3988 3982</h6>
                                        <h6 className="fw-bolder">+54 11 3988 3982</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="modal fade bd-example-modal-lg" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad">
                    <div className="modal-content pad borde-none">
                        {/* <div className="modal-header color-header pad">
                            <h6 className="modal-title fw-bolder" id="exampleModalLabel">Cargar Documentacion</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div className="modal-body">
                            <form name="docXcuenta" onSubmit={handleSubmission}>
                                <div className="mb-3 h-100">
                                    <div>
                                        <h6 className="fw-bolder">Cargar Documentación</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                        id="documento"
                                        aria-label="form-select-lg example"
                                        onChange={e => obtenerDocumentoSelect(e.target.value)}
                                        value={documentoId}>

                                        <option selected>Seleccionar Documentación Pendiente</option>
                                        {
                                            carpetas.filter((item) => item.new_estado != 100000002 && item.new_estado != 100000003).map(carpeta => {
                                                return (
                                                    <option key={carpeta.new_documentacionporcuentaid}
                                                        className="subtitulo-notificacion p-2"
                                                        data-id={carpeta.new_documentacionporcuentaid}
                                                        onChange={e => (e.target.value)}
                                                        value={carpeta.new_documentacionporcuentaid}>{carpeta.new_name}</option>
                                                )
                                            })//pendiente vencido parcial P100.000.001, V100.000.004, p100.000.000
                                        }
                                    </select>
                                    <div className="form-group">
                                        <input type="file"
                                            name="file"
                                            id="adjunto"
                                            onChange={changeHandler}
                                            multiple
                                        />
                                    </div>
                                    <button type="submit"
                                        name="btnSubmit"
                                        className="btn btn-primary btn-lg mt-4"
                                    >Subir Documentación</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" onClose={() => setShow(false)} show={show} delay={3000} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row">
                                        <div className="col-sm-1 mx-2">
                                            {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                        </div>
                                        <div className="col-sm-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
    )
}

export default withRouter(Inicio)
