import React from 'react'
import { Toast, Spinner, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const Notificacion = ({ show, mensaje, error, loading, progreso = "0%", barra = false }) => {
    return (
        <Toast className="half-black position-fixed noti p-1" show={show} autohide color="lime">
            <Toast.Body className="text-white p-0 pt-2 pb-1">
                <div className="row p-0 d-flex align-items-center">
                    {
                        loading ?
                            <div className="col-1 ">
                                <Spinner animation="border" role="status" variant="primary">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <div className="col-1 ">
                                {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-2 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                            </div>
                    }
                    <div className="col-11 ml-5 text-start">
                        <p className="m-0 p-0 mx-2">{mensaje}</p>
                    </div>
                </div>
            </Toast.Body>
            {
                (loading && barra == true)?
                    <div className="w-100">
                        <div className="progress bar">
                            <div className="progress-bar progress-bar-striped progress-bar-animated " role="progressbar" style={{ "width": progreso }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div> : null
            }
        </Toast>
    )
}

export default Notificacion
