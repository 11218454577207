import React from 'react'
import "react-step-progress-bar/styles.css";
import MultiStepProgressBar from './MultiStepProgressBar'
import SolicitudAltaGeneral from '../Formularios/SolicitudAltaGeneral';
import SolicitudAltaPersonal from '../Formularios/SolicitudAltaPersonal';
import SolicitudAltaCuenta from '../Formularios/SolicitudAltaCuenta'
import SolicitdAltaCompleta from '../Formularios/SolicitdAltaCompleta'
import SolicitudAltaAdicional from '../Formularios/SolicitudAltaAdicional';
import { Toast, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { cargarSolicitudDeAlta } from '../Redux/PrecalificacionCrediticia'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link, NavLink, useHistory } from 'react-router-dom'
import { obtenerDocumentosOnboarding, agregarDocumentoAcarpeta, obtenerDocumentosOnboardingFETCH } from '../Redux/Documentacion'
import { obtenerUnidadDeNegocio } from '../Redux/UnidadDeNegocio'
import shortid from 'shortid'
import Notificacion from './Notificacion';
import { FileText, File } from "react-feather"
import { AlertCircle } from "react-feather"
import { loginToken } from '../Redux/Token';
import { ToastContainer, toast } from 'react-toastify';
import TokenContext from '../context/TokenContext';


const SolicitudAlta = (props) => {
    const dispatch = useDispatch()
    let history = useHistory();
    const [step, setStep] = React.useState(1);
    const [desabilitado, setDesabilitado] = React.useState(true)
    const [show, setShow] = React.useState(false)
    const [showNotiDoc, setShowNotiDoc] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [documentos, setDocumentos] = React.useState([])
    const [documentosFiltrados, setDocumentosFiltrados] = React.useState([])
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [llamadaUnidadesNegocio, setLlamadaUnidadesNegocio] = React.useState(false)
    const [accionistas, setAccionistas] = React.useState([])
    const [esSgr, setEsSgr] = React.useState(false)
    const [accionistaID, setAccionistaID] = React.useState('')
    const [carpetas, setCarpetas] = React.useState([])
    const [progreso, setProgreso] = React.useState('0%')
    const [nombreArchivo, setNombreArchivo] = React.useState([])
    const [eliminando, setEliminando] = React.useState(false)
    const [carpeta, setCarpeta] = React.useState([])
    const [respuesta, setRespuesta] = React.useState(false)
    const [onboardingStorage, setOnboardingStorage] = React.useState(false)
    //Adjuntos
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [archivos, setArchivos] = React.useState([])
    const [selectedFile, setSelectedFile] = React.useState();
    const [documentoId, setDocumentoId] = React.useState('')
    const [nombreDocumento, setNombreDocumento] = React.useState('')
    const [isFilePicked, setIsFilePicked] = React.useState(false);
    const resultado = useSelector(store => store.precalificacion.resultado)
    const documentosSelector = useSelector(store => store.documentos.documentos)
    const unidadesNegocioSelector = useSelector(store => store.unidadesNegocio.unidadesDeNegocio)
    const porcentajeSelector = useSelector(store => store.precalificacion.porcentaje)
    const carpetasSelector = useSelector(store => store.documentos.carpetas)

    const [token, setToken] = React.useState({})
    const [llamadaToken, setLLamadaToken] = React.useState(false)
    const tokenSelector = useSelector(store => store.token.token)

    const [values, setValues] = React.useState({
        personeria: '',
        razonSocial: '',
        tipoDocumento: '',
        cuit: '',
        cuitcuil: '',
        nombre: '',
        apellido: '',
        telefono: '',
        email: '',
        emailNotificaciones: '',
        relacionCuenta: '',
        servicio: '',
        actividadAFIP: '',
        tipoSocietario: '',
        condicionImpositiva: '',
        facturacion: '',
        otro: '',
        robot: '',
        documentosCargados: 0,
        documentosIds: '',
        docuentosDTOs: [],
        documentacionPorCuenta: [],
        cantidadMujeresDesicion: '',
        cantidadEmpleadosMujeres: '',
        cantidadEmpleadosConDiscapacidad: '',
        accionistas: [],
        esSGR: esSgr,
        textoALYC: '',
        textoSGR: '',
        actividadesAFIP: [],
        pesoArchivos: 0,
        estadoOnboarding: '',
        cuitReferidor: '',
        step: 1,
        tiposDeDocumento: [],
        documentos: [],
        archivos: [],
        carpetas: []
    })

    var accionista = {
        uid: '',
        personeria: '',
        cuitcuil: '',
        razonSocial: '',
        nombre: '',
        apellido: '',
        porcentaje: 0,
        descripcion: '',
        tipoRelacion: '',
        relacionDirecta: false
    }

    const tkn = React.useContext(TokenContext)


    React.useEffect(() => {
        // if (onboardingStorage == false) {
        //     if (localStorage.getItem("onboarding") != null) {
        //         var onboarding = localStorage.getItem("onboarding")
        //         var parseo = JSON.parse(onboarding)
        //         setValues(parseo)
        //         if (parseo.step != undefined && parseo.step != null && parseo.step != step) {
        //             setStep(parseo.step)
        //             if (parseo.step >= 2) {
        //                 setDesabilitado(false)
        //             }
        //         }
        //         setOnboardingStorage(true)
        //     }
        // }


        if (Object.keys(tokenSelector).length != 0 && llamadaToken === true) {
            setToken(tokenSelector.token)
        } else if (llamadaToken === false) {
            obtenerToken()
            setLLamadaToken(true)
        }

        if (tkn.length > 0) {
            if (documentosSelector.length > 0 && llamadaDocumentos === true) {
                setDocumentos(documentosSelector)
                if (values.documentos == undefined || values.documentos == null || values.documentos.length == 0) {
                    setValues({ ...values, ["documentos"]: documentosSelector })
                }
            } else if (documentosSelector !== undefined && llamadaDocumentos === false) {
                obtenerTodosDocumentos()
                setLlamadaDocumentos(true)
            }
        }

        if (unidadesNegocioSelector.length > 0 && llamadaUnidadesNegocio === true) {
            values.textoALYC = unidadesNegocioSelector[0].new_textoconfirmacionsolicitudaltaalyc
            values.textoSGR = unidadesNegocioSelector[0].new_textoconfirmacionsolicitudaltasgr
        } else if (unidadesNegocioSelector.length === 0 && llamadaUnidadesNegocio === false) {
            obtenerUnidadesDeNegocio()
            setLlamadaUnidadesNegocio(true)
        }

        if (resultado !== undefined) {
            if (resultado !== '') {
                cargaExito()
            }
        }

        if (values.personeria !== '') {
            if (documentos.length > 0) {
                var filtrados = filtrarDocumentosPorPersoneria(documentos.filter(documento => documento.new_solicituddealta == true), values.personeria)
                // setDocumentosFiltrados(filtrados)
                setValues({ ...values, ["docuentosDTOs"]: filtrados })
                // localStorage.setItem("onboarding", JSON.stringify({ ...values, ["docuentosDTOs"]: filtrados }));
            }
        }

        if (carpetasSelector != undefined && carpetasSelector.length > 0) {
            setCarpetas(carpetasSelector)
        }

        if (porcentajeSelector != undefined && porcentajeSelector != '') {
            setProgreso(porcentajeSelector)
        }
    }, [resultado, documentosSelector, unidadesNegocioSelector, values.personeria, carpetasSelector, porcentajeSelector, respuesta, tokenSelector])


    const obtenerToken = () => {
        dispatch(loginToken())
    }

    const obtenerTodosDocumentos = () => {
        dispatch(obtenerDocumentosOnboardingFETCH(tkn))
    }

    const obtenerUnidadesDeNegocio = () => {
        dispatch(obtenerUnidadDeNegocio())
    }

    const filtrarDocumentosPorPersoneria = (docs, personeria) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_personeria != null) {
                if (doc.new_personeria == personeria) {
                    documentosFiltrados.push(doc)
                }
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const nextStep = () => {
        if (step < 5) {
            var resultadoValidacion = validacion()
            if (resultadoValidacion) {
                if (step === 4) {
                    document.getElementById("btn-enviar").disabled = true

                    // const formData = new FormData();
                    // for (let index = 0; index < archivos.length; index++) {
                    //     let element = archivos[index];
                    //     formData.append(`body${index}`, element);
                    // }
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                        },
                    };
                    setProgreso('20%')
                    dispatch(cargarSolicitudDeAlta(archivos, config, values, carpetas, token))
                    setLoading(true)
                    setMensaje("Aguarde por favor, no cierre la ventana ya que se está procesando la información. Esta tarea puede demorar unos minutos....")
                    // toast.info('Aguarde por favor, no cierre la ventana ya que se está procesando la información. Esta tarea puede demorar unos minutos....', {
                    //     theme: "dark",
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     // progress: progreso,
                    // });
                    setShow(true)
                } else {
                    setStep(step + 1)
                    setValues({ ...values, ["step"]: step + 1 })
                    setDesabilitado(false)
                }
            }
        } else if (step === 5) {
            console.log(values);
        }
    };

    const prevStep = () => {
        if (step >= 2) {
            setStep(step - 1)
            setValues({ ...values, ["step"]: step - 1 })
            if (step === 2) {
                setDesabilitado(true)
                setEsSgr(false)
                setValues({ ...values, ["esSGR"]: false });
            }
        }
    };

    const handleChange = (name) => (e) => {
        if (e.hasOwnProperty('target')) {
            setValues({ ...values, [name]: e.target.value });
            // localStorage.setItem("onboarding", JSON.stringify({ ...values, [name]: e.target.value }));
        } else if (e.hasOwnProperty('value')) {
            setValues({ ...values, [name]: e.value });
            // localStorage.setItem("onboarding", JSON.stringify({ ...values, [name]: e.value }));
        } else {
            if (name === "nombreDocumento") {
                var documentos = e.split(':')
                setNombreDocumento(documentos[0])
                setDocumentoId(documentos[1])
                setCarpeta(carpetas.filter(item => item.documentoid == documentos[1]))
            } else {
                if (name === "accionistas") {
                    setValues({ ...values, ["accionistas"]: e })
                    // localStorage.setItem("onboarding", JSON.stringify({ ...values,["accionistas"]: e }));
                } else if (name === "actualizacionaccionistas") {
                    setValues({ ...values, ["accionistas"]: e });
                    // localStorage.setItem("onboarding", JSON.stringify({ ...values, ["accionistas"]: e }));
                } else if (name == "eliminaraccionistas") {
                    eliminarAccionista(e)
                }
                else {
                    setValues({ ...values, [name]: e });
                    // localStorage.setItem("onboarding", JSON.stringify({ ...values, [name]: e }));
                }
            }
        }
    };

    const operacionEliminar = (nombreArchivo) => {
        setNombreArchivo(nombreArchivo)
        setEliminando(true)
    }

    const eliminarAccionista = (uid) => {
        var accionistasAux = values.accionistas.filter(item => item.uid === uid)
        var i = values.accionistas.indexOf(accionistasAux[0])
        if (i !== -1) {
            values.accionistas.splice(i, 1);
            setValues({ ...values, values });
            // localStorage.setItem("onboarding", JSON.stringify({ ...values, values }));
        }
    }

    const eliminarArchivo = (nombre) => {
        //debugger
        if (archivos.length > 0) {
            var archivo = archivos.filter(item => item.name == nombre)
            var i = archivos.indexOf(archivo[0])
            if (i !== -1) {
                values.pesoArchivos -= archivo[0].size
                archivos.splice(i, 1);
            }
            setArchivos(archivos)
            setEliminando(false)
        }

        if (carpetas.length > 0) {
            var carpeta = carpetas.filter(item => item.documentoid == documentoId)
            if (carpeta.length > 0) {
                var i = carpeta[0].nombreArchivos.indexOf(nombre)
                if (i !== -1) {
                    carpeta[0].nombreArchivos.splice(i, 1);
                    if (carpeta[0].nombreArchivos.length == 0) {
                        var posicion = carpetas.indexOf(carpeta[0]);
                        if (posicion !== -1) {
                            carpetas.splice(posicion, 1);
                            values.documentosCargados = values.documentosCargados - 1
                            var documentoActualizado = []
                            documentoActualizado = values.docuentosDTOs.filter(item => item.new_documentacionid === documentoId)
                            documentoActualizado.map(item => item.documentoCargado = false)
                            documentoActualizado.map(item => item.pesoArchivo = 0)
                            var documentosActualizados = values.docuentosDTOs.map(item => item.new_documentacionid == documentoActualizado.new_documentacionid ? documentoActualizado : item)
                            setValues({ ...values, ["docuentosDTOs"]: documentosActualizados })
                            var docsIds = ''
                            debugger
                            var documentosCargados = values.documentosIds.split(',')
                            documentosCargados.forEach(element => {
                                if (element.trim() != documentoId) {
                                    if (docsIds !== '') {
                                        docsIds = docsIds + ', ' + documentoId
                                    } else {
                                        docsIds = documentoId
                                    }
                                }
                            })
                            values.documentosIds = docsIds
                        }
                    }
                }
                setCarpeta(carpetas.filter(item => item.documentoid == documentoId))
                dispatch(agregarDocumentoAcarpeta(carpetas))
            }
        }
    }

    const changeHandler = (event) => {
        setSelectedFiles(event.target.files)
        setIsFilePicked(true);
    };

    const agregarDocumento = () => {
        document.getElementById("btn-agregardocumento").disabled = true
        //debugger
        if (selectedFiles.length > 0) {
            var peso = 0
            debugger
            for (let index = 0; index < selectedFiles.length; index++) {
                if (selectedFiles[index].size >= 15000000) {
                    // setMensaje("El archivo no puede superar los 15 megas")
                    toast.error('El archivo no puede superar los 15 megas', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShowNotiDoc(true)
                    // setLoading(false)
                    setTimeout(() => {
                        setShowNotiDoc(false)
                        document.getElementById("btn-agregardocumento").disabled = false
                    }, 3000);
                    setSelectedFiles([])
                    document.getElementById("adjunto").value = "";
                    return
                }
                if (selectedFiles[index].name.includes('#') || selectedFiles[index].name.includes('?')
                    || selectedFiles[index].name.includes('*') || selectedFiles[index].name.includes('/')
                    || selectedFiles[index].name.includes('|') || selectedFiles[index].name.includes('"')
                    || selectedFiles[index].name.includes('<') || selectedFiles[index].name.includes('>')) {
                    // setMensaje("El nombre del archivo no debe contener caracteres especiales")
                    toast.error('El nombre del archivo no debe contener caracteres especiales', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShowNotiDoc(true)
                    // setLoading(false)
                    setTimeout(() => {
                        setShowNotiDoc(false)
                        document.getElementById("btn-agregardocumento").disabled = false
                    }, 3000);
                    setSelectedFiles([])
                    document.getElementById("adjunto").value = "";
                    return
                }
                if (archivos.filter(item => item.name == selectedFiles[index].name).length > 0) {
                    // setMensaje("Ya existe un documento cargado con el mismo nombre")
                    toast.error('Ya existe un documento cargado con el mismo nombre', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShowNotiDoc(true)
                    // setLoading(false)
                    setTimeout(() => {
                        setShowNotiDoc(false)
                        document.getElementById("btn-agregardocumento").disabled = false
                    }, 3000);
                    setSelectedFiles([])
                    document.getElementById("adjunto").value = "";
                    return
                }
                peso += selectedFiles[index].size
            }
            peso += values.pesoArchivos
            if (peso >= 20000000) {
                // setMensaje("El peso de los archivos adjuntos supera los 20 megas")
                toast.error('El peso de los archivos adjuntos supera los 20 megas', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShowNotiDoc(true)
                // setLoading(false)
                setTimeout(() => {
                    setShowNotiDoc(false)
                    document.getElementById("btn-agregardocumento").disabled = false
                }, 3000);
                setSelectedFiles([])
                document.getElementById("adjunto").value = "";
                return
            }
            debugger
            var existeDocumento = false
            var adjuntos = []
            if (archivos.length > 0) {
                for (let index = 0; index < archivos.length; index++) {
                    adjuntos.push(archivos[index]);
                }
            }
            if (selectedFiles.length > 0) {
                for (let index = 0; index < selectedFiles.length; index++) {
                    adjuntos.push(selectedFiles[index]);
                    if (carpetas.filter(item => item.documentoid == documentoId).length > 0) {
                        existeDocumento = true
                    }
                }
            }

            if (!existeDocumento && values.documentosCargados > 0) {
                if (values.documentosIds !== '') {
                    var docsIds = values.documentosIds
                    docsIds = docsIds + ', ' + documentoId
                    values.documentosIds = docsIds
                } else {
                    values.documentosIds = documentoId
                }
                values.documentosCargados = values.documentosCargados + 1
                var documentoActualizado = []
                if (values.docuentosDTOs.length > 0) {
                    documentoActualizado = values.docuentosDTOs.filter(item => item.new_documentacionid === documentoId)
                }
                documentoActualizado.map(item => item.documentoCargado = true)
                documentoActualizado.map(item => item.pesoArchivo = peso)
                var documentosActualizados = values.docuentosDTOs.map(item => item.new_documentacionid == documentoActualizado[0].new_documentacionid ? documentoActualizado[0] : item)
                setValues({ ...values, ["docuentosDTOs"]: documentosActualizados })
                // setDocumentosFiltrados(documentosActualizados)
            } else if (values.documentosCargados === 0) {
                values.documentosIds = documentoId
                values.documentosCargados = values.documentosCargados + 1
                var documentoActualizado = []
                if (values.docuentosDTOs.length > 0) {
                    documentoActualizado = values.docuentosDTOs.filter(item => item.new_documentacionid === documentoId)
                }
                documentoActualizado.map(item => item.documentoCargado = true)
                documentoActualizado.map(item => item.pesoArchivo = peso)
                var documentosActualizados = values.docuentosDTOs.map(item => item.new_documentacionid == documentoActualizado[0].new_documentacionid ? documentoActualizado[0] : item)
                setValues({ ...values, ["docuentosDTOs"]: documentosActualizados })
                // setDocumentosFiltrados(documentosActualizados)
            }

            if (carpetas.length > 0) {
                var docsEnCarpeta = carpetas.filter(item => item.documentoid == documentoId)
                if (docsEnCarpeta.length > 0) {
                    for (let index = 0; index < selectedFiles.length; index++) {
                        var archivo = selectedFiles[index];
                        var cantidadNombres = docsEnCarpeta[0].nombreArchivos.filter(item => item == archivo.name).length
                        if (cantidadNombres == 0) {
                            docsEnCarpeta[0].nombreArchivos.push(archivo.name)
                            values.pesoArchivos += archivo.size
                        }
                    }
                } else {
                    var array = carpetas;
                    for (let index = 0; index < selectedFiles.length; index++) {
                        if (array.filter(item => item.documentoid == documentoId).length == 0) {
                            var carpeta = {
                                documentoid: documentoId,
                                nombreArchivos: [selectedFiles[index].name]
                            }
                            array.push(carpeta)
                            values.pesoArchivos += selectedFiles[index].size
                        } else {
                            array.filter(item => item.documentoid == documentoId).map(arc => {
                                var archivo = selectedFiles[index];
                                var cantidadNombres = arc.nombreArchivos.filter(item => item == archivo.name).length
                                if (cantidadNombres == 0) {
                                    arc.nombreArchivos.push(archivo.name)
                                    values.pesoArchivos += selectedFiles[index].size
                                }
                            })
                        }
                    }
                    dispatch(agregarDocumentoAcarpeta(array))
                }
            }
            else {
                var array = [];
                for (let index = 0; index < selectedFiles.length; index++) {
                    if (array.length == 0) {
                        var carpeta = {
                            documentoid: documentoId,
                            nombreArchivos: [selectedFiles[index].name]
                        }
                        array.push(carpeta)
                        values.pesoArchivos += selectedFiles[index].size
                    } else {
                        array.filter(item => item.documentoid == documentoId).map(item => {
                            item.nombreArchivos.push(selectedFiles[index].name)
                            values.pesoArchivos += selectedFiles[index].size
                        })
                    }
                }
                dispatch(agregarDocumentoAcarpeta(array))
            }

            setArchivos(adjuntos)
            // setMensaje("Documento cargado con exito")
            toast.success('Documento cargado con exito', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(false)
            // setShowNotiDoc(true)
            // setLoading(false)
            setTimeout(() => {
                setShowNotiDoc(false)
                document.getElementById("btn-agregardocumento").disabled = false
            }, 1800);
            setTimeout(() => {
                document.getElementById("myInputDoc").click()
                document.getElementById("adjunto").value = ""
            }, 2700);
        } else {
            // setMensaje("Debes elegir un archivo para agregar!")
            toast.error('Debes elegir un archivo para agregar!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(true)
            // setShowNotiDoc(true)
            document.getElementById("btn-agregardocumento").disabled = false
            setTimeout(() => {
                setShowNotiDoc(false)
            }, 3000);
        }
    }

    const cargaExito = () => {
        if (resultado === "EXITO") {
            setValues({ ...values, "estadoOnboarding": resultado })
            // setMensaje("La solicitud fue enviada con exito!")
            toast.success('La solicitud fue enviada con exito!', {
                theme: "dark",
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(false)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
                setStep(5)
            }, 1500);
        }
        else if (resultado === "ERROR AL CREAR CUENTA") {
            // setMensaje("Hubo un error al enviar la solicitud. Por favor, revise los datos!")
            toast.error('Hubo un error al enviar la solicitud. Por favor, revise los datos!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(true)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            // document.getElementById("robot").style.display = "block"
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        } else if (resultado === "ERROR") {
            // setMensaje("La cuenta que intenta ingresar ya existe!")
            toast.error('La cuenta que intenta ingresar ya existe!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(true)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            // document.getElementById("robot").style.display = "block"
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        } else if (resultado === "ERROR CUENTA") {
            // setMensaje("Hubo un error en el proceso de onboarding, disculpe las molestias!")
            toast.error('Hubo un error en el proceso de onboarding, disculpe las molestias!', {
                theme: "dark",
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(true)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            // document.getElementById("robot").style.display = "block"
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        } else if (resultado === "ERROR POST CUENTA") {
            setValues({ ...values, "estadoOnboarding": resultado })
            // setMensaje("La solicitud fue enviada con exito!")
            toast.success('La solicitud fue enviada con exito!', {
                theme: "dark",
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(false)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
                setStep(5)
            }, 1500);
        } else if (resultado == "") {
            setValues({ ...values, "estadoOnboarding": resultado })
            // setMensaje("La solicitud fue enviada con exito!")
            toast.success('La solicitud fue enviada con exito!', {
                theme: "dark",
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // setError(false)
            // setLoading(false)
            // setShow(true)
            setProgreso('0%')
            document.getElementById("btn-enviar").disabled = false
            setDesabilitado(false)
            setTimeout(() => {
                setShow(false)
                setStep(5)
            }, 1500);
        }
    }

    const ValidateCUITCUIL = (cuit) => {
        let acumulado = 0;
        let respuesta = false;
        let digitos = cuit.split('');
        let digito = parseInt(digitos.pop());

        for (let i = 0; i < digitos.length; i++) {
            acumulado += digitos[9 - i] * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }
        respuesta = (digito === verif);
        return respuesta;
    }

    const validacion = () => {
        var resultadoValidacion = true
        if (step === 1) {
            debugger
            let validacionCuit = ValidateCUITCUIL(values.cuit)
            let opciones = values.servicio.split(',')
            opciones.forEach(opcion => {
                if (opcion === "100000000") { //SGR
                    setEsSgr(true)
                    setValues({ ...values, ["esSGR"]: true });
                }
            });
            if (values.servicio === '') {
                document.getElementById("productoServicio").classList.add("is-invalid");
                // setMensaje("El servicio a operar es requerido!")
                toast.error('El servicio a operar es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("productoServicio").classList.remove('is-invalid')
            }
            if (values.personeria === '') {
                document.getElementById("personeria").classList.add("is-invalid");
                // setMensaje("La personería es requerida!")
                toast.error('La personería es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return resultadoValidacion = false
            } else {
                document.getElementById("personeria").classList.remove('is-invalid')
            }
            if (values.razonSocial === '') {
                document.getElementById("razon").classList.add("is-invalid");
                // setMensaje("La razón social es requerida!")
                toast.error('La razón social es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("razon").classList.remove('is-invalid')
            }
            if (values.tipoDocumento === '') {
                document.getElementById("tipoDocumento").classList.add("is-invalid");
                // setMensaje("El tipo de cocumento es requerido!")
                toast.error('El tipo de documento es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("tipoDocumento").classList.remove('is-invalid')
            }
            if (values.cuit === '') {
                document.getElementById("cuit").classList.add("is-invalid");
                // setMensaje("El cuit es requerido!")
                toast.error('El cuit es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (values.cuit.length > 11 || values.cuit.length < 11) {
                document.getElementById("cuit").classList.add("is-invalid");
                // setMensaje("El cuit es incorrecto, debe tener 11 caracteres!")
                toast.error('El cuit es incorrecto, debe tener 11 caracteres!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (!validacionCuit) {
                document.getElementById("cuit").classList.add("is-invalid");
                // setMensaje("El cuit es incorrecto!, Por favor ingrese un cuit valido")
                toast.error('El cuit es incorrecto!, Por favor ingrese un cuit valido', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("cuit").classList.remove('is-invalid')
            }

        } else if (step === 2) {
            if (values.nombre === '') {
                document.getElementById("contacto").classList.add("is-invalid");
                // setMensaje("El nombre de contacto es requerido!")
                toast.error('El nombre de contacto es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("contacto").classList.remove('is-invalid')
            }
            if (values.apellido === '') {
                document.getElementById("apellido").classList.add("is-invalid");
                // setMensaje("El apellido es requerido!")
                toast.error('El apellido es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("apellido").classList.remove('is-invalid')
            }
            let valor = values.cuitcuil * 1
            if (values.cuitcuil === '') {
                document.getElementById("cuitcuil").classList.add("is-invalid");
                // setMensaje("El CUIT/CUIL es requerido!")
                toast.error('El CUIT/CUIL es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (Number.isNaN(valor)) {
                document.getElementById("cuitcuil").classList.add("is-invalid");
                // setMensaje("El cuit/cuil es incorrecto, deben ser numeros!")
                toast.error('El cuit/cuil es incorrecto, deben ser numeros!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (values.cuitcuil.length > 11 || values.cuitcuil.length < 11) {
                document.getElementById("cuitcuil").classList.add("is-invalid");
                // setMensaje("El cuit/cuil es incorrecto, debe tener 11 caracteres!")
                toast.error('El cuit/cuil es incorrecto, debe tener 11 caracteres!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("cuitcuil").classList.remove('is-invalid')
            }
            if (values.telefono === '') {
                document.getElementById("tel").classList.add("is-invalid");
                // setMensaje("El telefono es requerido!")
                toast.error('El telefono es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("tel").classList.remove('is-invalid')
            }
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
            if (values.email === '') {
                document.getElementById("email").classList.add("is-invalid");
                // setMensaje("El email es requerido!")
                toast.error('El email es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (!regex.test(values.email)) {
                document.getElementById("email").classList.add("is-invalid");
                toast.error('El email es invalido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("email").classList.remove('is-invalid')
            }

            if (values.emailNotificaciones === '') {
                document.getElementById("emailNotificaciones").classList.add("is-invalid");
                // setMensaje("El email para notificaciones es requerido!")
                toast.error('El email para notificaciones es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else if (!regex.test(values.emailNotificaciones)) {
                document.getElementById("emailNotificaciones").classList.add("is-invalid");
                toast.error('El email es invalido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("emailNotificaciones").classList.remove('is-invalid')
            }
            if (values.relacionCuenta === '') {
                document.getElementById("relacion").classList.add("is-invalid");
                // setMensaje("El tipo de relación es requerido!")
                toast.error('El tipo de relación es requerido!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("relacion").classList.remove('is-invalid')
                //Si es accionita lo agrega al array
                if (esSgr) {
                    debugger
                    if (values.relacionCuenta === '100000001') {
                        if (accionistaID === '') { //Comprobar si ya se agrego el accionista para determinar si es creacion o actualizacion
                            agregarAccionista()
                        } else {
                            actualizarAccionista(accionistaID)
                        }
                    }
                }
            }
        } else if (step === 3) {
            if (values.actividadAFIP === '') {
                document.getElementById("actividad").classList.add("is-invalid");
                // setMensaje("La actividad AFIP es requerida!")
                toast.error('La actividad AFIP es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("actividad").classList.remove('is-invalid')
            }
            if (values.personeria === '100000000') {
                if (values.tipoSocietario === '') {
                    document.getElementById("tipoSocietario").classList.add("is-invalid");
                    // setMensaje("El tipo societario es requerido!")
                    toast.error('El tipo societario es requerido!', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShow(true)
                    setTimeout(() => {
                        setShow(false)
                    }, 3000);
                    return
                } else {
                    if (values.condicionImpositiva != '') {
                        setValues({ ...values, ["condicionImpositiva"]: "" });
                    }
                    console.log(values)
                    document.getElementById("tipoSocietario").classList.remove('is-invalid')
                }
            } else if (values.personeria === '100000001') {
                if (values.condicionImpositiva === '') {
                    document.getElementById("condicionImpositiva").classList.add("is-invalid");
                    // setMensaje("La condicion impositiva es requerida!")
                    toast.error('La condicion impositiva es requerida!', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShow(true)
                    setTimeout(() => {
                        setShow(false)
                    }, 3000);
                    return
                } else {
                    if (values.tipoSocietario != '') {
                        setValues({ ...values, ["tipoSocietario"]: "" });
                    }
                    console.log(values)
                    document.getElementById("condicionImpositiva").classList.remove('is-invalid')
                }
            }

            if (values.facturacion === '') {
                document.getElementById("facturacion").classList.add("is-invalid");
                // setMensaje("La facturación es requerida!")
                toast.error('La facturación es requerida!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("facturacion").classList.remove('is-invalid')
            }
        } else if (step === 4) {
            if (esSgr && values.personeria === "100000000" && values.accionistas.length === 0) {
                // setMensaje("Debes agregar al menos un accionista!")
                toast.error('Debes agregar al menos un accionista!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            }
            debugger
            var porcentaje = 0
            values.accionistas.forEach(element => {
                porcentaje = porcentaje + parseFloat(element.porcentaje)
            });
            if (esSgr) {
                if (values.personeria !== '' && values.personeria === "100000001") {
                    if (values.condicionImpositiva !== "" && values.condicionImpositiva === "100000002") {
                        var requeridosCargados = true
                        values.docuentosDTOs.filter(documento => documento.new_condicionimpositiva == "100000002").forEach(doc => {
                            if (doc.new_requeridoenportal != null && doc.new_requeridoenportal == true) {
                                var documentosCargados = values.documentosIds.split(',')
                                if (documentosCargados.filter(item => item.trim() == doc.new_documentacionid).length == 0) {
                                    requeridosCargados = false
                                }
                            }
                        }) //Otro
                        if (requeridosCargados == false) {
                            // if (documentosFiltrados.filter(documento => documento.new_condicionimpositiva == "100000002").length !== values.documentosCargados) {
                            // setMensaje("Debes cargar todos los documentos!")
                            toast.error('Debes cargar todos los documentos!', {
                                theme: "dark",
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            // setError(true)
                            // setShow(true)
                            setTimeout(() => {
                                setShow(false)
                            }, 3000);
                            return
                        }
                    } else {
                        var requeridosCargados = true
                        values.docuentosDTOs.filter(documento => documento.new_condicionimpositiva != "100000002").forEach(doc => {
                            if (doc.new_requeridoenportal != null && doc.new_requeridoenportal == true) {
                                var documentosCargados = values.documentosIds.split(',')
                                if (documentosCargados.filter(item => item.trim() == doc.new_documentacionid).length == 0) {
                                    requeridosCargados = false
                                }
                            }
                        })
                        if (requeridosCargados == false) {
                            // if (documentosFiltrados.filter(documento => documento.new_condicionimpositiva != "100000002").length !== values.documentosCargados) {
                            // setMensaje("Debes cargar todos los documentos!")
                            toast.error('Debes cargar todos los documentos!', {
                                theme: "dark",
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            // setError(true)
                            // setShow(true)
                            setTimeout(() => {
                                setShow(false)
                            }, 3000);
                            return
                        }
                    }
                }
                else {
                    var requeridosCargados = true
                    values.docuentosDTOs.filter(documento => documento.new_condicionimpositiva != "100000002").forEach(doc => {
                        if (doc.new_requeridoenportal != null && doc.new_requeridoenportal == true) {
                            var documentosCargados = values.documentosIds.split(',')
                            if (documentosCargados.filter(item => item.trim() == doc.new_documentacionid).length == 0) {
                                requeridosCargados = false
                            }
                        }
                    })
                    if (requeridosCargados == false) {
                        // if (documentosFiltrados.length !== values.documentosCargados) {
                        // setMensaje("Debes cargar todos los documentos!")
                        toast.error('Debes cargar todos los documentos!', {
                            theme: "dark",
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // setError(true)
                        // setShow(true)
                        setTimeout(() => {
                            setShow(false)
                        }, 3000);
                        return
                    }
                }
                if (porcentaje !== 100 && values.personeria === "100000000") {
                    // setMensaje("El porcentaje de los accionistas debe ser 100%")
                    toast.error('El porcentaje de los accionistas debe ser 100%', {
                        theme: "dark",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setError(true)
                    // setShow(true)
                    setTimeout(() => {
                        setShow(false)
                    }, 3000);
                    return
                }
            }
            if (values.robot == '' || values.robot == "False") {
                // setMensaje("Debes confirmar que no eres un robot!")
                toast.error('Debes confirmar que no eres un robot!', {
                    theme: "dark",
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setError(true)
                // setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            }
        }

        return resultadoValidacion
    }

    const agregarAccionista = () => {
        debugger
        var existe = false
        if (values.accionistas.length > 0) {
            if (values.accionistas.filter(item => item.cuitcuil == values.cuitcuil).length > 0) {
                existe = true
            }
        }
        if (existe == false) {
            let tipoRelacion = "100000001" //Accionista
            accionista.uid = shortid.generate()
            setAccionistaID(accionista.uid)
            accionista.personeria = "100000001"
            accionista.cuitcuil = values.cuitcuil
            if (accionista.razonSocial != '') {
                accionista.razonSocial = values.razonSocial
            } else if (values.nombre != '' && values.apellido != '') {
                accionista.nombre = values.nombre
                accionista.apellido = values.apellido
            }
            accionista.tipoRelacion = tipoRelacion
            accionista.relacionDirecta = true
            values.accionistas.push(accionista)
        }
    }

    const actualizarAccionista = (id) => {
        const accionistaEditado = values.accionistas.map(item => item.uid === id ?
            {
                uid: id,
                personeria: "100000001",
                cuitcuil: values.cuitcuil,
                razonSocial: item.razonSocial,
                nombre: values.nombre,
                apellido: values.apellido,
                porcentaje: item.porcentaje,
                descripcion: item.descripcion,
                tipoRelacion: '100000001',
                relacionDirecta: true
            }
            : item)
        values.accionistas = accionistaEditado
    }

    const finalizar = () => {
        props.history.push('/')
        setTimeout(() => {
            props.history.go(0)
        }, 10)
    }

    const finalizarOnboarding = () => {
        localStorage.removeItem("onboarding")
        props.history.push('/')
    }

    return (
        <div className="container-fluid w-100 d-flex justify-content-center">
            <div className="w-75 vh-75 min-vh-100">
                <div className="card shadow borde-none pad w-100 mt-3 pt-3 pb-3">
                    <div className="row">
                        <div className="col-8 m-0">
                            <span className="separador-titulo-precalificacion float-start m-0"></span>
                            <p className="pt-2 mx-2 pb-2 fw-bolder m-0 w-100">
                                Solicitud de Alta. Los siguientes datos son obligatorios para la solicitud.
                            </p>
                        </div>
                        <div className="col-4">
                            <div className="p-2">
                                {/* <button
                                    className="btn btn-primary active float-end"
                                    onClick={finalizarOnboarding}
                                >
                                    Finalizar Onboarding
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="card shadow p-5 border-0 h-auto pad w-100 mt-4 mb-2">
                        <div>
                            <MultiStepProgressBar currentStep={step} />
                        </div>
                    </div>
                </div>
                <div className="card shadow borde-none pad mt-3 mb-5">
                    <div className="row">
                        <div className="col-12 p-4">
                            <div>
                                {
                                    {
                                        1: <SolicitudAltaGeneral handleChange={handleChange} state={values} token={tkn} />,
                                        2: <SolicitudAltaPersonal handleChange={handleChange} state={values} token={tkn} />,
                                        3: <SolicitudAltaCuenta handleChange={handleChange} state={values} token={tkn} />,
                                        4: <SolicitudAltaAdicional handleChange={handleChange} state={values} documentos={documentosFiltrados} token={tkn} />,
                                        5: <SolicitdAltaCompleta handleChange={handleChange} state={values} />,
                                    }[step]
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-2 bg-light">
                        {
                            step <= 4 ? (
                                <div className="row">
                                    <div className="col-6 d-flex justify-content-center">
                                        <button disabled={desabilitado} className="btn btn-primary float-start active   " onClick={prevStep}>
                                            Anterior
                                        </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center">
                                        <button className="btn btn-primary active"
                                            onClick={nextStep}
                                            id="btn-enviar"
                                        >
                                            {step === 4 ? "Enviar" : "Siguiente"}
                                        </button>
                                    </div>
                                </div>
                            ) :
                                (
                                    <button
                                        className="btn btn-primary active"
                                        onClick={finalizar}
                                    >
                                        Finalizar
                                    </button>
                                )
                        }
                        <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} progreso={progreso} barra={true} />
                        <ToastContainer />
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-lg" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad">
                    <div className="modal-content pad borde-none">
                        <div className="modal-body bg-light pad">
                            {/* <form name="docXcuenta"> */}
                            <div className="h-100">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Cargar Documentación</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputDoc"></button>
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label fw-bolder lbl-precalificacion requerido">Documento</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={nombreDocumento}
                                        data-id={documentoId}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="custom-input-file col-12 mt-2">
                                            <div className="form-group borde_discontinuo">
                                                <input type="file"
                                                    name="file"
                                                    id="adjunto"
                                                    onChange={changeHandler}
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <button type="button"
                                    name="btnSubmit"
                                    className="btn btn-primary active mt-4"
                                    onClick={agregarDocumento}
                                    id="btn-agregardocumento"
                                >Agregar Documentación</button>
                            </div>
                            <div className="row">
                                <div className="col-12 pt-2 m-0">
                                    <p className="fw-bold mt-2 m-0 text-start texto-grafico">
                                        <AlertCircle size="20" className="mx-1 mb-1 float-right" color="#006BFF" />
                                        El tamaño máximo permitido por archivo es de 15 MB
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Notificacion show={showNotiDoc} mensaje={mensaje} error={error} loading={loading} /> */}
                </div>
            </div>
            <div className="modal fade modal-documentos" id="modalDocumentos" tabIndex="-1" aria-labelledby="modalDocumentosLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad">
                    <div className="modal-content pad borde-none">
                        <div className="modal-body">
                            {/* <form name="docXcuenta"> */}
                            <div className="mb-3 h-100">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">{nombreDocumento}</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputDoc"></button>
                                    </div>
                                </div>
                                {
                                    eliminando ?
                                        <div className="row">
                                            <div className="d'flex justify-content-center">
                                                <div className="col-12 text-center p-4">
                                                    <h6 className="fw-bolder">
                                                        ¿Desea eliminar {nombreArchivo}?
                                                    </h6>
                                                </div>
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-6 text-center">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => setEliminando(false)}
                                                        >Cancelar</button>
                                                    </div>
                                                    <div className="col-6 text-center">
                                                        <button className="btn btn-primary"
                                                            onClick={() => eliminarArchivo(nombreArchivo)}
                                                        >Aceptar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <ul className="list-group list-group-horizontal mt-3 d-flex align-items-center">
                                            {
                                                carpeta.length > 0 ?
                                                    carpeta[0].nombreArchivos.map(item => {
                                                        return (
                                                            <li className="list-group-item border-0">
                                                                <div className="text-center">
                                                                    <File size="35" color="#000" />
                                                                    {/* {<FontAwesomeIcon icon={faFile} className="fs-1 upload-file atras" color="#002376" />} */}
                                                                </div>
                                                                <div className="text-center">
                                                                    <p className="fw-bolder pt-2">{item}</p>
                                                                    <button
                                                                        className="btn btn-sm btn-primary"
                                                                        setEliminando
                                                                        onClick={() => operacionEliminar(item)}
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) : <p className="fw-bolder text-start">No hay archivos</p>
                                            }
                                        </ul>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {/* <Notificacion show={showNotiDoc} mensaje={mensaje} error={error} loading={loading} /> */}
            </div>
        </div>
    )
}

export default withRouter(SolicitudAlta)
