import axios from 'axios'
import {Entidad, UrlApiDynamics, UrlApi} from '../Keys'

//Const
const dataInicial = {
    loading: false,
    actividades: []
}

const TODAS_ACTIVIDADES_EXITO = 'TODAS_ACTIVIDADES_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function actividadesAFIPReducers(state = dataInicial, action){
    switch (action.type) {
        case ERROR: 
            return { ...dataInicial }
        case LOADING:
            return { ...state, loading: true }
        case TODAS_ACTIVIDADES_EXITO:
            return { ...state, actividades: action.payload, loading: false }
        default:
            return { ...state }
    }
}

//Actions
export const obtenerActividadesAFIP = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    
    try {
        // const response = await axios.get(`${UrlApiDynamics}Actividadafip?filter=&cuit=${Entidad}`)
        var entidad = "new_actividadafips"
        var fetch = "<fetch output-format='xml-platform' mapping='logical' distinct='false'>" +
            "<entity name='new_actividadafip'>" +
            "<attribute name='new_name' />" +
            "<attribute name='new_codigo' />" +
            "<attribute name='new_actividadafipid' />" +
            "<order attribute='new_name' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODAS_ACTIVIDADES_EXITO,
            payload: response.data
        })      
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}