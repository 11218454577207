import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    documentos: [],
    carpetas: []
}

const archivos = {
    documentoid: '',
    adjuntos: FileList
}

const DOCUMENTO_EXITO = 'DOCUMENTO_EXITO'
const TODOS_DOCUMENTO_EXITO = 'TODOS_DOCUMENTO_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function documentosReducers(state = dataInicial, action) {
    switch (action.type) {
        case ERROR:
            return { ...dataInicial }
        case LOADING:
            return { ...state, loading: true }
        case DOCUMENTO_EXITO: 
            return {...state, carpetas: action.payload}
        case TODOS_DOCUMENTO_EXITO:
            return { ...state, documentos: action.payload, loading: false }
        default:
            return { ...state }
    }
}

export const agregarDocumentoAcarpeta = (carpetas) => (dispatch) => { 
    try {
        dispatch({
            type: DOCUMENTO_EXITO,
            payload: carpetas
        })
    } catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

//Actions
export const obtenerDocumentos = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const response = await axios.get(`${UrlApiDynamics}Documentacion?filter=&cuit=${Entidad}`)
        dispatch({
            type: TODOS_DOCUMENTO_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerDocumentosOnboarding = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const response = await axios.get(`${UrlApiDynamics}Documentacion?filter=new_onboarding eq true&cuit=${Entidad}`)
        dispatch({
            type: TODOS_DOCUMENTO_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerDocumentosOnboardingFETCH = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "new_documentacions"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_documentacion'>" +
        "<attribute name='new_documentacionid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='new_codigo'/> " +
        "<attribute name='new_condicionimpositiva'/> " +
        "<attribute name='new_descripcion'/> " +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_grupoeconomico'/> " +
        "<attribute name='new_solicituddealta'/>" +
        "<attribute name='new_personeria'/> " +
        "<attribute name='new_tipodedocumento'/> " +
        "<attribute name='new_tipodefiador'/> " +
        "<attribute name='new_urlplantilla'/> " +
        "<attribute name='new_onboarding'/> " +
        "<attribute name='statecode'/> " +
        "<attribute name='new_requeridoenportal'/> " +
        "<order attribute ='new_codigo' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_onboarding' operator='eq' value='1' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>"
    try {
        // const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        const response = await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        )
        dispatch({
            type: TODOS_DOCUMENTO_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}