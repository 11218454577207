import React from 'react'
import Moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerDocumentosPorCuenta, cargarDocumentacionPorCuenta } from '../Redux/CarpetaDigital'
import { obtenerDocumentos } from '../Redux/Documentacion'
import { faEllipsisH, faCheckCircle, faTimesCircle, faInfoCircle, faFileDownload, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toast, Spinner } from 'react-bootstrap'
import { useSpring, animated } from 'react-spring'
import { Link } from "react-router-dom";
import MiCuenta from './MiCuenta'
import { CompletarNotificacion, obtenerNotificaciones } from '../Redux/Notificaciones'
import Notificacion from './Notificacion'
import { Trash2, Edit, BatteryCharging, Database, UploadCloud, Folder, Upload, AlertTriangle, PlusSquare, AlertCircle, Download, Eye, Cloud } from "react-feather"

export const CarpetaDigital = () => {
    //Constantes
    const dispatch = useDispatch()
    const [carpetas, setCarpetas] = React.useState([])
    const [documentos, setDocumentos] = React.useState([])
    const [documentoId, setDocumentoId] = React.useState('')
    const [documento, setDocumento] = React.useState('')
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const [showAlerta, setShowAlerta] = React.useState(false)
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [isFilePicked, setIsFilePicked] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [llamadaDocumentosCuentas, setLlamadaDocumentosCuentas] = React.useState(false)
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [cargando, setCargando] = React.useState(false)
    const [llamadaNotificaciones, setLlamadaNotificaciones] = React.useState(false)
    const [notificaciones, setNotificaciones] = React.useState([])
    //Estados
    const documentosCuentasSelector = useSelector(store => store.documentosPorCuenta.documentos)
    const documentosSelector = useSelector(store => store.documentos.documentos)
    const cargaDocumento = useSelector(store => store.documentosPorCuenta.cargaDocumento)
    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const resultadoSelector = useSelector(store => store.notificaciones.resultado)
    const notificacionesSelector = useSelector(store => store.notificaciones.notificaciones)


    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    React.useEffect(async () => {
        if (activo) {
            if (documentosCuentasSelector.length > 0 && llamadaDocumentosCuentas === true) {
                setCarpetas(documentosCuentasSelector)
                if (cargando == true) {
                    if (documentosCuentasSelector.filter((carpeta) => carpeta.statuscode == 1).length == 0) {
                        completarNotificacionDocumentacion()
                        setCargando(false)
                        setTimeout(() => {
                            setMensaje("Gracias por subir toda la documentación solicitada. A la brevedad nos comunicaremos con usted!")
                            setError(false)
                            setShowAlerta(true)
                        }, 1500);
                        setTimeout(() => {
                            setShowAlerta(false)
                            obtenerNotificacionesCuenta()
                        }, 6500);
                    }
                }
                if (document.getElementById("spinner1") !== null) {
                    document.getElementById("spinner1").hidden = true;
                }
                if (document.getElementById("spinner2") !== null) {
                    document.getElementById("spinner2").hidden = true;
                }
            } else if (llamadaDocumentosCuentas === false) {
                obtenerDocumentosCuenta()
                setLlamadaDocumentosCuentas(true)
            }

            if (documentosSelector.length > 0) {
                setDocumentos(documentosSelector)
            } else if (llamadaDocumentos === false) {
                obtenerTodosDocumentos()
                setLlamadaDocumentos(true)
            }

            if (notificacionesSelector.length > 0) {
                setNotificaciones(notificacionesSelector)
            } else if (notificacionesSelector.length === 0 && llamadaNotificaciones === false) {
                obtenerNotificacionesCuenta()
                setLlamadaNotificaciones(true)
            }


            if (cargaDocumento !== undefined) {
                if (cargaDocumento !== '') {
                    subidaExito()
                }
            }
        }
    }, [activo, documentosCuentasSelector, cargaDocumento, documentosSelector, notificacionesSelector])

    const obtenerDocumentosCuenta = async () => {
        dispatch(obtenerDocumentosPorCuenta(accountid))
    }

    const obtenerNotificacionesCuenta = async () => {
        dispatch(obtenerNotificaciones(accountid))
    }

    const obtenerTodosDocumentos = async () => {
        dispatch(obtenerDocumentos())
    }

    const obtenerDocumento = async (e) => {
        debugger
        setDocumentoId(e)
        carpetas.filter(elemento => elemento.new_documentacionporcuentaid === e).map(item => {
            setDocumento(item.new_name)
        })
    }

    const completarNotificacionDocumentacion = () => {
        var estado = "1"
        var noti = ''
        notificaciones.filter(item => item.new_tipodenotificacion == "100000000" && item.statecode == "0").map(item => {
            noti = item.activityid
        })
        dispatch(CompletarNotificacion(noti, estado))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const changeHandler = (event) => {
        setSelectedFiles(event.target.files)
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmission = (e) => {
        debugger
        document.getElementById("btn-subirdocumentacion").disabled = true
        e.preventDefault()

        if (documentoId === '') {
            document.getElementById("nombreDoc").classList.add('is-invalid')
            setMensaje("El documento es requerido!")
            setError(true)
            setShow(true)
            document.getElementById("btn-subirdocumentacion").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("nombreDoc").classList.remove('is-invalid')
            document.getElementById("nombreDoc").classList.add("is-valid")
        }
        if (selectedFiles.length === 0) {
            document.getElementById("adjunto").classList.add('is-invalid')
            setMensaje("El archivo adjunto es requerido!")
            setError(true)
            setShow(true)
            document.getElementById("btn-subirdocumentacion").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("adjunto").classList.remove('is-invalid')
            document.getElementById("adjunto").classList.add("is-valid")
        }

        const formData = new FormData();
        for (let index = 0; index < selectedFiles.length; index++) {
            let element = selectedFiles[index];
            formData.append(`body${index}`, element);
        }
        // formData.append('body', selectedFiles);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const respuesta = dispatch(cargarDocumentacionPorCuenta(formData, config, documentoId, documento))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    };

    const subidaExito = () => {
        if (cargaDocumento === 'EXITO') {
            setCargando(true)
            setMensaje("La carga del archivo fue exitosa!")
            setError(false)
            setLoading(false)
            setShow(true)
            document.getElementById("btn-subirdocumentacion").disabled = false
            limpiar()
            obtenerDocumentosCuenta()
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInput").click();
            }, 1800);
            setTimeout(() => {
                obtenerDocumentosCuenta()
                setTimeout(() => {
                    obtenerDocumentosCuenta()
                }, 10000);
            }, 10000);
        } else if (cargaDocumento === 'ERROR') {
            setMensaje("El archivo adjunto fallo!")
            setError(true)
            setLoading(false)
            setShow(true)
            document.getElementById("btn-subirdocumentacion").disabled = false
            limpiar()
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const limpiar = async () => setTimeout(() => {
        document.getElementById("nombreDoc").classList.remove('is-valid')
        document.getElementById("adjunto").classList.remove('is-valid')
        setDocumentoId('')
        setSelectedFiles([])
        document.getElementById("adjunto").value = "";
        document.docXcuenta.btnSubmit.blur()
    }, 1500)

    const obtenerDescripcionDocumento = (docId) => {
        let desc = ''
        documentos.filter(doc => doc.new_documentacionid === docId).map(item => {
            desc = item.new_descripcion
        })
        return desc
    }

    const obtenerUrlPlantilla = (docId) => {
        let url = ''
        documentos.filter(doc => doc.new_documentacionid === docId).map(item => {
            url = item.new_urlplantilla
            console.log(url)
        })
        return url
    }

    const razonParaElEstado = (estado) => {
        var estadoDocumento;
        switch (estado) {
            case '1':
                return <span class="badge px-2 badge-personalizado-amarillo">Pendiente</span>
                break;
            case '100000000':
                return <span class="badge px-2 badge-personalizado-naranja">Recibido</span>
                break;
            case '100000001':
                return <span class="badge px-2 badge-personalizado-verde">Aprobado</span>
                break;
            default:
                estadoDocumento = '---'
                break;
        }
        return estadoDocumento;
    }

    return (
        <animated.div className="container min-vh-100" style={fade}>
            <div className="mt-3">
                <MiCuenta />
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-5 pt-4">
                    <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                        <div className="p-2">
                            <h6 className="fw-bolder">Documentación Enviada</h6>
                            <hr className="hr-width hr-principal" />
                        </div>
                        <div className="card doc-cards pad borde-none">
                            <div className="lista-header color-header pad borde-none">
                                <div className="row p-3">
                                    <div className="col-7">
                                        Documentación
                                    </div>
                                    <div className="col-5 text-end px-4">
                                        Estado
                                    </div>
                                </div>
                                <div className="contenedor-spinner" id="spinner1">
                                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                                <ul className="list-group overflow-scroll lista-body">
                                    {
                                        carpetas.filter((carpeta) => (carpeta.statuscode == 100000000 || carpeta.statuscode == 100000001) && carpeta.new_visibleenportal == "true").map(item => {
                                            return (
                                                <li key={item.new_documentacionporcuentaid} className="list-group-item borde-0">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-9 pt-1 pb-1">
                                                            <p className="mx-1 m-0 fw-bolder subtitulo-notificacion texto-lista d-flex align-items-center">
                                                                {item.new_name}
                                                                {
                                                                    obtenerDescripcionDocumento(item._new_documentoid_value) ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            style={{ "cursor": "help" }}
                                                                            title={obtenerDescripcionDocumento(item._new_documentoid_value)}
                                                                        >
                                                                            <AlertCircle size="20" className="mx-1" color="#006BFF" />
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    obtenerUrlPlantilla(item._new_documentoid_value)
                                                                        ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            title="Descargar Plantilla"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={() => window.open(obtenerUrlPlantilla(item._new_documentoid_value), "_blank")}
                                                                        >
                                                                            <Download size="20" className="mx-1" color="#006BFF" />
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.new_vinculocompartido ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            title="Ver documentación"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={() => window.open(item.new_vinculocompartido, "_blank")}
                                                                        >
                                                                            <Eye size="20" className="mx-1" color="#16BC02" />
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-3 m-0 text-end">
                                                            <p className="text-lowercase m-0 fw-bolder mx-1 texto-lista">{(item.statuscode) ? razonParaElEstado(item.statuscode) : '---'}</p>
                                                        </div>
                                                        {/* <div className="col-1 m-0">
                                                            <div className="dropdown btn-group dropstart">
                                                                <button className="btn float-end" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <FontAwesomeIcon id={item.new_documentacionporcuentaid} icon={faEllipsisH} className="fs-5 text-dark upload-file atras" />
                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                                                    <button
                                                                        type="button"
                                                                        className="btn dropdown-item text-light"
                                                                        id={item.new_vinculocompartido}
                                                                        // data-bs-toggle="modal"
                                                                        // data-bs-target="#ModalLink"
                                                                        onClick={e => e.target.id ? (window.open(e.target.id, "_blank")) : ''}
                                                                    >
                                                                        {item.new_vinculocompartido ? ("Ver Documento") : "Subiendo a Sharepoint..."}
                                                                    </button>
                                                                </ul>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="row p-3">
                                    <div className="col-12 color-header text-center c-azul fw-bolder">
                                        {carpetas.filter((carpeta) => (carpeta.statuscode == 100000000 || carpeta.statuscode == 100000001) && carpeta.new_visibleenportal == "true").length} Documentos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-7 pt-4">
                    <div className="card shadow border-0 d-flex justify-content-start pad">
                        <div className="p-2">
                            <h6 className="fw-bolder">Documentación Pendiente</h6>
                            <hr className="hr-width hr-principal" />
                        </div>
                        <div className="card doc-cards pad borde-none">
                            <div className="lista-header color-header pad">
                                <div className="row p-3">
                                    <div className="col-6">
                                        Documentación
                                    </div>
                                    <div className="col-5 text-end">
                                        Fecha Vencimiento
                                    </div>
                                    <div className="col-1 text-end">

                                    </div>
                                </div>
                                <div className="contenedor-spinner" id="spinner2">
                                    <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                                <ul className="list-group w-100 overflow-auto lista-body">
                                    {
                                        carpetas.filter((carpeta) => carpeta.statuscode == 1 && carpeta.new_visibleenportal == "true").map(item => {
                                            return (
                                                <li key={item.new_documentacionporcuentaid} className="list-group-item h-100 p-0 pt-1 pb-1">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-7 m-0">
                                                            <p className="m-0 fw-bolder texto-lista mx-3 d-flex align-items-center" >
                                                                {item.new_name}
                                                                {
                                                                    obtenerDescripcionDocumento(item._new_documentoid_value) ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            style={{ "cursor": "help" }}
                                                                            title={obtenerDescripcionDocumento(item._new_documentoid_value)}
                                                                        >
                                                                            <AlertCircle size="20" className="mx-1" color="#006BFF" />
                                                                        </span> :
                                                                        null
                                                                }
                                                                {
                                                                    obtenerUrlPlantilla(item._new_documentoid_value)
                                                                        ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            title="Descargar Plantilla"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={() => window.open(obtenerUrlPlantilla(item._new_documentoid_value), "_blank")}
                                                                        >
                                                                            <Download size="20" className="mx-1" color="#006BFF" />
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    item.new_vinculocompartido ?
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="right"
                                                                            title="Ver documentación"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={() => window.open(item.new_vinculocompartido, "_blank")}
                                                                        >
                                                                            <Eye size="20" className="mx-1" color="#16BC02" />
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="col-4 m-0 p-0 text-end">
                                                            <p className="text-lowercase m-0 fw-bolder text-end texto-lista">{(item.new_fechadevencimiento) ? Moment(item.new_fechadevencimiento).format("DD-MM-YYYY") : '---'}</p>
                                                        </div>
                                                        <div className="col-1 m-0">
                                                            <div
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="right"
                                                                title="Subir Documento"
                                                                style={{ "cursor": "pointer" }}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className="btn float-end p-0"
                                                                    id={item.new_documentacionporcuentaid}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                    onClick={e => obtenerDocumento(item.new_documentacionporcuentaid)}
                                                                >
                                                                    <Upload size={20} className="mx-2" color="#000" />
                                                                </button>
                                                            </div>


                                                            {/* <div className="dropdown btn-group dropstart"> */}
                                                            {/* <button className="btn float-end" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <FontAwesomeIcon id={item.new_documentacionporcuentaid} icon={faEllipsisH} className="fs-5 text-dark upload-file atras" />
                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                                                    <button
                                                                        type="button"
                                                                        className="btn dropdown-item text-light"
                                                                        id={item.new_documentacionporcuentaid}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModal"
                                                                        onClick={e => obtenerDocumento(e.target.id)}
                                                                    >
                                                                        Subir Documento
                                                                    </button>
                                                                </ul> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="row p-3">
                                    <div className="col-sm-12 color-header text-center c-azul fw-bolder">
                                        {carpetas.filter((carpeta) => carpeta.statuscode == 1 && carpeta.new_visibleenportal == "true").length} Documentos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
            <div className="modal fade bd-example-modal-" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad borde-none">
                    <div className="modal-content pad">
                        {/* <div className="modal-header color-header pad">
                            <h6 className="modal-title fw-bolder" id="exampleModalLabel">Cargar Documentacion</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div className="modal-body bg-light pad">
                            <form name="docXcuenta" onSubmit={handleSubmission}>
                                <div className="h-100">
                                    <div className="row">
                                        <div className="col-8">
                                            <h6 className="fw-bolder">Cargar Documentación</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                        <div className="col-4">
                                            <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInput"></button>
                                        </div>
                                    </div>
                                    <input type="text"
                                        className="form-control form-control fs-6 fw-bolder"
                                        id="nombreDoc"
                                        onChange={e => (e.target.value)}
                                        value={documento}
                                    />
                                    <div className="form-group pt-3">
                                        <input type="file"
                                            className='fw-bolder requerido'
                                            name="file"
                                            id="adjunto"
                                            onChange={changeHandler}
                                            multiple
                                        />
                                    </div>
                                    <button type="submit"
                                        id="btn-subirdocumentacion"
                                        name="btnSubmit"
                                        className="btn  active btn-primary mt-4"
                                    >Subir Documentación</button>
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-12 pt-2 m-0">
                                    <p className="fw-bold mt-2 m-0 text-start texto-grafico">
                                        <AlertCircle size="20" className="mx-1 mb-1 float-right" color="#006BFF" />
                                        El tamaño máximo permitido por archivo es de 15 MB
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                </div>
            </div>
            <div className="modal fade bd-example-modal-lg" id="ModalLink" tabIndex="-1" aria-labelledby="ModalLinkLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad borde-none">
                    <div className="modal-content pad">
                        <div className="modal-body">
                            {/* <Link onClick={openTab('www.google.com')}> Ver Documentos</Link> */}
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                </div>
            </div>
        </animated.div>
    )
}
