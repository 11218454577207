import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'

//Const
const dataInicial = {
    loading: false,
    id: {},
    resultado: '',
    porcentaje: ''
}

//Types
const CARGA_PRECALIFICACION = 'CARGA_PRECALIFICACION'
const CUENTA_EXISTENTE = 'CUENTA_EXISTENTE'
const LOADING = "LOADING"
const ERROR = 'ERROR'
const LIMPIAR_RESULTADO = 'LIMPIAR_RESULTADO'
const PORCENTAJE = 'PORCENTAJE'

//Reducers
export default function PrecalificacionReducers(state = dataInicial, action) {
    switch (action.type) {
        case ERROR:
            return { ...dataInicial }
        case LOADING:
            return { ...state, resultado: action.resultado }
        case CARGA_PRECALIFICACION:
            return { ...state, id: action.payload, loading: false, resultado: action.resultado }
        case CUENTA_EXISTENTE:
            return { ...state, resultado: action.resultado }
        case LIMPIAR_RESULTADO:
            return { ...state, resultado: action.resultado }
        case PORCENTAJE:
            return { ...state, porcentaje: action.porcentaje }
        default:
            return { ...state }
    }
}

export const cargarPrecalificacionCrediticia = (personeria, razonSocial, cuit, tipoDocumento, telefono, email, nombre, apellido,
    producto, actividad, tipoSocietario, facturacion, tipoRelacion, cantidadMujeres, empleadas, discapacitados, otro = null) => async (dispatch) => {
        dispatch({
            type: LOADING,
            resultado: 'LOADING'
        })
        try {
            const comprobacionRazonSocial = await axios.get(`${UrlApiDynamics}Account?filter=name eq '${razonSocial}'&cuit=${Entidad}`)
            const comprobacionMail = await axios.get(`${UrlApiDynamics}Account?filter=emailaddress1 eq '${email}'&cuit=${Entidad}`)
            if (comprobacionMail.data.length === 0 && comprobacionRazonSocial.data.length === 0) {
                const response = await axios.post(`${UrlApiDynamics}Precalificacion?personeria=${personeria.value}&razonSocial=${razonSocial}
                &cuit=${cuit}&tipoDocumento=${tipoDocumento.value}&telefono=${telefono}&email=${email}&nombreContacto=${nombre}&apellido=${apellido}
                &productoServicio=${producto}&actividadAFIP=${actividad.value}&tipoSocietario=${tipoSocietario.value}&facturacion=${facturacion}
                &tipoRelacion=${tipoRelacion.value}&cantidadMujeres=${cantidadMujeres}&empleadas=${empleadas}&discapacitados=${discapacitados}&otro=${otro}&cuitSgr=${Entidad}`)
                dispatch({
                    type: CARGA_PRECALIFICACION,
                    resultado: 'EXITO',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: CUENTA_EXISTENTE,
                    resultado: 'ERROR'
                })
            }

        } catch (error) {
            dispatch({
                type: ERROR
            })
        }
    }

export const limpiarResultado = () => async (dispatch) => {
    dispatch({
        type: LIMPIAR_RESULTADO,
        resultado: 'PENDIENTE'
    })
}

export const cargarSolicitudDeAlta = (file, config, solicitud, carpetas, token) => async (dispatch) => {
    dispatch({
        type: LOADING,
        resultado: 'LOADING'
    })

    try {
        let estadoOnboarding = "EXITO"
        let accountid = ''
        let invitacion = false
        let esSGR = false
        //Preparamos los documentos que no contienen archivos adjuntos
        var documentacionPorCuenta = solicitud.docuentosDTOs
        if (solicitud.documentosIds != '') {
            var ids = solicitud.documentosIds.split(',')
            ids.forEach(element => {
                var documentacion = documentacionPorCuenta.filter(item => item.new_documentacionid == element.trim())
                var indice = documentacionPorCuenta.indexOf(documentacion[0])
                if (indice != -1) {
                    documentacionPorCuenta.splice(indice, 1)
                }
            })
        }
        //Servico con la que opera la pyme
        let opciones = solicitud.servicio.split(',')
        opciones.forEach(opcion => {
            if (opcion === "100000000") { //SGR
                esSGR = true
            }
        });
        // Fetch y consulta para determinar si la cuenta ya existe
        var entidad = "accounts"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='account'>" +
            "<attribute name='accountid'/>" +
            "<attribute name='new_invitacion'/> " +
            "<filter type='and'>" +
            "<filter type='or'>" +
            "<condition attribute='emailaddress1' operator='eq' value='" + solicitud.email + "' />" +
            "<condition attribute='name' operator='eq' value='" + solicitud.razonSocial + "' />" +
            "<condition attribute='new_nmerodedocumento' operator='eq' value='" + solicitud.cuit + "' />" +
            "</filter>" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const responseValidacion = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        //Si no existe una cuenta con ese email y razon social se crea la solicitud de alta
        if (responseValidacion.data.length === 0 ||
            (responseValidacion.data.length > 0 && responseValidacion.data[0].new_invitacion == true)) {
            dispatch({
                type: PORCENTAJE,
                porcentaje: '30%'
            })
            if (responseValidacion.data.length > 0 && responseValidacion.data[0].new_invitacion != undefined) {
                invitacion = responseValidacion.data[0].new_invitacion
            }

            const responseSolicitud = await axios.post(`https://hw365api.azurewebsites.net/api/onboarding/`,
                {
                    "personeria": solicitud.personeria,
                    "razonSocial": solicitud.razonSocial,
                    "cuit": solicitud.cuit,
                    "cuitcuil": solicitud.cuitcuil,
                    "email": solicitud.email,
                    "telefono": solicitud.telefono,
                    "nombreContacto": solicitud.nombre,
                    "apellido": solicitud.apellido,
                    "tipoDocumento": solicitud.tipoDocumento,
                    "productoServicio": solicitud.servicio,
                    "actividadAFIP": solicitud.actividadAFIP,
                    "monto": "",
                    "tipoRelacion": solicitud.relacionCuenta,
                    "tipoSocietario": solicitud.tipoSocietario,
                    "condicionImpositiva": solicitud.condicionImpositiva,
                    "cantidadMujeres": solicitud.cantidadMujeresDesicion,
                    "empleadas": solicitud.cantidadEmpleadosMujeres,
                    "discapacitados": solicitud.cantidadEmpleadosConDiscapacidad,
                    "otro": solicitud.otro,
                    "sectorEconomico": "",
                    "inicioActividad": "",
                    "resena": "",
                    "emailNotificaciones": solicitud.emailNotificaciones,
                    "invitacion": invitacion.toString(),
                    "cuitReferidor": solicitud.cuitReferidor,
                    "facturacion": solicitud.facturacion,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )

            if (responseSolicitud.status == 200 && responseSolicitud.data != "ERROR") {
                dispatch({
                    type: PORCENTAJE,
                    porcentaje: '45%'
                })

                let solicitud_id = responseSolicitud.data
                if (solicitud_id === undefined || solicitud_id === null) {
                    estadoOnboarding = "ERROR CUENTA"
                }

                if (esSGR === true) {
                    const cuenta = await axios.get(`${UrlApiDynamics}Account?filter=_new_solicituddealta_value eq '${solicitud_id}'&cuit=${Entidad}`)
                    if (cuenta.status == 200 && cuenta.data !== "ERROR") {
                        accountid = cuenta.data[0].Accountid
                        //Se crea la documentacion por cuenta, las notas con sus repectivos adjuntos y la relacion entre ellos. Si es SGR la documentacion se asocia tanto a la solicitud como a la cuenta
                        if (accountid != null && accountid != '') {
                            const configDocumentos = {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'content-type': 'multipart/form-data',
                                },
                            };
                            if (file != null && file.length > 0) {
                                debugger
                                for (let arch of file) {
                                    var formData = new FormData();
                                    formData.append(`body`, arch);
                                    var nombreArchivo = arch.name
                                    var documentoid = carpetas.filter(carp => carp.nombreArchivos == nombreArchivo).map(item => item.documentoid)
                                    await axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
                                    &documento_id=${documentoid}&solicitud_id=${solicitud_id}&invitacion=${invitacion.toString()}`, formData, configDocumentos)
                                        .then(respuesta => {
                                            if (respuesta.data == "ERROR") {
                                                estadoOnboarding = "ERROR POST CUENTA"
                                                crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid}`, token)
                                            }
                                        })
                                        .catch(error => {
                                            crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid} - ${error}`, token)
                                            estadoOnboarding = "ERROR POST CUENTA"
                                        })
                                }
                                // for (let index = 0; index < file.length; index++) {
                                //     let archivo = file[index];
                                //     var formData = new FormData();
                                //     formData.append(`body${index}`, archivo);
                                //     var nombreArchivo = archivo.name
                                //     var documentoid = carpetas.filter(carp => carp.nombreArchivos == nombreArchivo).map(item => item.documentoid)
                                //     axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
                                //     &documento_id=${documentoid}&solicitud_id=${solicitud_id}&invitacion=${invitacion.toString()}`, formData, configDocumentos)
                                //         .then(respuesta => {
                                //             if (respuesta.data == "ERROR") {
                                //                 estadoOnboarding = "ERROR POST CUENTA"
                                //                 crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid}`, token)
                                //             }
                                //         })
                                //         .catch(error => {
                                //             crearExcepcion(`Error al crear documento con adjunto para la cuenta ${accountid} - ${error}`, token)
                                //             estadoOnboarding = "ERROR POST CUENTA"
                                //         })
                                // } 
                                dispatch({
                                    type: PORCENTAJE,
                                    porcentaje: '80%'
                                })
                            }

                            if (documentacionPorCuenta != null && documentacionPorCuenta.length > 0) {
                                var formData = new FormData();
                                debugger
                                for (let elemento of documentacionPorCuenta) {
                                    var docid = elemento.new_documentacionid
                                    await axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
                                    &documento_id=${docid}&solicitud_id=${solicitud_id}&invitacion=false`, formData, configDocumentos)
                                        .then(respuesta => {
                                            if (respuesta.data == "ERROR") {
                                                estadoOnboarding = "ERROR POST CUENTA"
                                            }
                                        })
                                        .catch(error => {
                                            crearExcepcion(`Error al crear documento sin adjunto para la cuenta ${accountid} - ${error}`, token)
                                            estadoOnboarding = "ERROR POST CUENTA"
                                        })
                                }
                                // documentacionPorCuenta.forEach(element => {
                                //     var docid = element.new_documentacionid
                                //     axios.post(`https://hw365api.azurewebsites.net/api/onboarding/documentacionporcuenta?socio_id=${accountid}
                                //     &documento_id=${docid}&solicitud_id=${solicitud_id}&invitacion=false`, formData, configDocumentos)
                                //         .then(respuesta => {
                                //             if (respuesta.data == "ERROR") {
                                //                 estadoOnboarding = "ERROR POST CUENTA"
                                //             }
                                //         })
                                //         .catch(error => {
                                //             crearExcepcion(`Error al crear documento sin adjunto para la cuenta ${accountid} - ${error}`, token)
                                //             estadoOnboarding = "ERROR POST CUENTA"
                                //         })
                                // })
                            }

                            //Se crean los accionistas relacionados a la cuenta creada a partir de la solicitud de alta
                            if (accountid != null && solicitud.accionistas.length > 0) {
                                axios.post(`https://hw365api.azurewebsites.net/api/onboarding/accionistas?cuentaid=${accountid}`,
                                    solicitud.accionistas,
                                    {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
                                    })
                                    .then(respuesta => {
                                        if (respuesta.data == "ERROR") {
                                            estadoOnboarding = "ERROR POST CUENTA"
                                        }
                                    })
                                    .catch(error => {
                                        crearExcepcion(`Error al crear accionistas para la cuenta ${accountid} - ${error}`, token)
                                        estadoOnboarding = "ERROR POST CUENTA"
                                    })
                                dispatch({
                                    type: PORCENTAJE,
                                    porcentaje: '100%'
                                })
                            } else {
                                dispatch({
                                    type: PORCENTAJE,
                                    porcentaje: '100%'
                                })
                            }
                        } else if (cuenta.status != 200) {
                            dispatch({
                                type: CUENTA_EXISTENTE,
                                resultado: 'ERROR CUENTA'
                            })
                            crearExcepcion(`Error al crear accionistas para la cuenta ${accountid} - ${cuenta.data}`, token)
                        } else {
                            estadoOnboarding = "ERROR POST CUENTA"
                        }

                        
                    } else {
                        dispatch({
                            type: PORCENTAJE,
                            porcentaje: '100%'
                        })
                    }
                }
                dispatch({
                    type: CARGA_PRECALIFICACION,
                    resultado: estadoOnboarding
                })
            } else {
                crearExcepcion(`Error al crear solicitud de alta. Pyme: ${solicitud.razonSocial} ${solicitud.cuit}`, token)
                dispatch({
                    type: CUENTA_EXISTENTE,
                    resultado: 'ERROR CUENTA'
                })
            }
        } else {
            crearExcepcion(`La pyme que intenta darse de alta ya existe en el sitema. Pyme: ${solicitud.razonSocial} - ${solicitud.cuit} - ${solicitud.email}`, token)
            dispatch({
                type: CUENTA_EXISTENTE,
                resultado: 'ERROR'
            })
        }
    } catch (error) {
        crearExcepcion(`Excepcion en solicitud de alta - ${solicitud.razonSocial} ${solicitud.cuit}`, token)
        dispatch({
            type: CUENTA_EXISTENTE,
            resultado: 'ERROR CUENTA'
        })
    }
}

const crearExcepcion = (error, token) => {
    axios.post(`https://hw365api.azurewebsites.net/api/excepcion`,
        {
            "descripcion": error
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
}

// export const cargarSolicitudDeAlta = (file, config, solicitud, carpetas, token ) => async (dispatch) => {
//     dispatch({
//         type: LOADING,
//         resultado: 'LOADING'
//     })

//     try {
//         debugger
//         let estadoOnboarding = "EXITO"
//         let accountid = ''
//         let invitacion = false
//         let esSGR = false
//         var stringCarpetas = JSON.stringify(carpetas)
//         var documentacionPorCuenta = solicitud.docuentosDTOs
//         if(solicitud.documentosIds != ''){
//             var ids = solicitud.documentosIds.split(',')
//             ids.forEach(element => {
//                 var documentacion = documentacionPorCuenta.filter(item => item.new_documentacionid == element.trim())
//                 var indice = documentacionPorCuenta.indexOf(documentacion[0])
//                 if (indice != -1) {
//                     documentacionPorCuenta.splice(indice, 1)
//                 }
//             })
//         }
//         var stringDocumentosPorCuenta = JSON.stringify(documentacionPorCuenta)
//         let opciones = solicitud.servicio.split(',')
//             opciones.forEach(opcion => {
//                 if (opcion === "100000000") { //SGR
//                     esSGR = true
//                 }
//             });
//         var entidad = "accounts"
//         var fetch = "<fetch mapping='logical'>" +
//             "<entity name='account'>" +
//             "<attribute name='accountid'/>" +
//             "<attribute name='new_invitacion'/> " +
//             "<filter type='and'>" +
//             "<filter type='or'>" +
//             "<condition attribute='emailaddress1' operator='eq' value='" + solicitud.email + "' />" +
//             "<condition attribute='name' operator='eq' value='" + solicitud.razonSocial + "' />" +
//             "<condition attribute='new_nmerodedocumento' operator='eq' value='" + solicitud.cuit + "' />" +
//             "</filter>" +
//             "</filter>" +
//             "</entity>" +
//             "</fetch>";

//         const responseValidacion = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
//         //Si no existe una cuenta con ese email y razon social se crea la solicitud de alta
//         if (responseValidacion.data.length === 0 || (responseValidacion.data.length > 0 && responseValidacion.data[0].new_invitacion == true)) {
//             dispatch({
//                 type: PORCENTAJE,
//                 porcentaje: '30%'
//             })
//             if (responseValidacion.data.length > 0 && responseValidacion.data[0].new_invitacion != undefined) {
//                 invitacion = responseValidacion.data[0].new_invitacion
//             }
//             const responseSolicitud = await axios.post(`${UrlApiDynamics}Precalificacion?personeria=${solicitud.personeria}&razonSocial=${solicitud.razonSocial}
//                 &cuit=${solicitud.cuit}&cuitcuil=${solicitud.cuitcuil}&tipoDocumento=${solicitud.tipoDocumento}&telefono=${solicitud.telefono}&email=${solicitud.email}&nombreContacto=${solicitud.nombre}&apellido=${solicitud.apellido}
//                 &productoServicio=${solicitud.servicio}&actividadAFIP=${solicitud.actividadAFIP}&facturacion=${solicitud.facturacion}
//                 &tipoRelacion=${solicitud.relacionCuenta}&tipoSocietario=${solicitud.tipoSocietario}&condicionImpositiva=${solicitud.condicionImpositiva}
//                 &otro=${solicitud.otro}&cantidadMujeres=${solicitud.cantidadMujeresDesicion}&empleadas=${solicitud.cantidadEmpleadosMujeres}&discapacitados${solicitud.cantidadEmpleadosConDiscapacidad}
//                 &emailNotificaciones=${solicitud.emailNotificaciones}&invitacion=${invitacion}&cuitReferidor=${solicitud.cuitReferidor}&cuitSgr=${Entidad}`)

//             if (responseSolicitud.status == 200 && responseSolicitud.data != "ERROR") {
//                 dispatch({
//                     type: PORCENTAJE,
//                     porcentaje: '45%'
//                 })

//                 let solicitud_id = responseSolicitud.data
//                 if (solicitud_id === undefined || solicitud_id === null) {
//                     estadoOnboarding = "ERROR CUENTA"
//                 }
                
//                 if (esSGR === true) {
//                     const cuenta = await axios.get(`${UrlApiDynamics}Account?filter=_new_solicituddealta_value eq '${solicitud_id}'&cuit=${Entidad}`)
//                     if (cuenta.status == 200 && cuenta.data !== "ERROR") {
//                         accountid = cuenta.data[0].Accountid
//                         //Se crea la documentacion por cuenta, las notas con sus repectivos adjuntos y la relacion entre ellos. Si es SGR la documentacion se asocia tanto a la solicitud como a la cuenta
//                         if (solicitud_id != null && solicitud.documentosIds != null && accountid != '') {
//                             const respuestaDocumentos = await axios.post(`${UrlApiDynamics}Documentacion?docId=${solicitud.documentosIds}&solicitudId=${solicitud_id}&accountid=${accountid}&carpetas=${stringCarpetas}&invitacion=${invitacion}&cuit=${Entidad}`, file, config)
//                             if (respuestaDocumentos.status == 200 && respuestaDocumentos.data == "ERROR") {
//                                 estadoOnboarding = "ERROR POST CUENTA"
//                             } else if (respuestaDocumentos.status != 200) {
//                                 dispatch({
//                                     type: CUENTA_EXISTENTE,
//                                     resultado: 'ERROR POST CUENTA'
//                                 })
//                             } else {
//                                 dispatch({
//                                     type: PORCENTAJE,
//                                     porcentaje: '80%'
//                                 })
//                             }

//                             await axios.post(`${UrlApiDynamics}Documentosporcuenta?documentos=${stringDocumentosPorCuenta}&accountid=${accountid}&solicitudId=${solicitud_id}&cuit=${Entidad}`)
//                         }
                        

//                         const comprobarCuentaCreada = await axios.get(`${UrlApiDynamics}Account?filter=emailaddress1 eq '${solicitud.email}'&cuit=${Entidad}`)

//                         let cuenta_id = comprobarCuentaCreada.data
//                         //Se crean los accionistas relacionados a la cuenta creada a partir de la solicitud de alta
//                         if (cuenta_id != null) {
//                             var stringAccionistas = JSON.stringify(solicitud.accionistas);
//                             if (stringAccionistas != "[]") {
//                                 const responseAccionistas = await axios.post(`${UrlApiDynamics}Relacionesvinculacion?accountid=${cuenta_id[0].Accountid}&accionistas=${stringAccionistas}&estado=0&cuit=${Entidad}`)
//                                 if (responseAccionistas.status == 200 && responseAccionistas.data != "ERROR") {
//                                     dispatch({
//                                         type: PORCENTAJE,
//                                         porcentaje: '100%'
//                                     })
//                                 }
//                                 else if (responseAccionistas.status == 200 && responseAccionistas.data == "ERROR") {
//                                     estadoOnboarding = "ERROR POST CUENTA"
//                                 } else if (responseAccionistas.status != 200) {
//                                     dispatch({
//                                         type: CUENTA_EXISTENTE,
//                                         resultado: 'ERROR POST CUENTA'
//                                     })
//                                 }
//                             } else {
//                                 dispatch({
//                                     type: PORCENTAJE,
//                                     porcentaje: '100%'
//                                 })
//                             }
//                         }
//                     } else if (cuenta.status != 200) {
//                         dispatch({
//                             type: CUENTA_EXISTENTE,
//                             resultado: 'ERROR CUENTA'
//                         })
//                     } else {
//                         estadoOnboarding = "ERROR POST CUENTA"
//                     }
//                 } else {
//                     dispatch({
//                         type: PORCENTAJE,
//                         porcentaje: '100%'
//                     })
//                 }
//             } else {
//                 estadoOnboarding = "ERROR AL CREAR CUENTA"
//             }

//             dispatch({
//                 type: CARGA_PRECALIFICACION,
//                 resultado: estadoOnboarding
//                 // payload: responseSolicitud.data
//             })

//         } else {
//             dispatch({
//                 type: CUENTA_EXISTENTE,
//                 resultado: 'ERROR'
//             })
//         }

//     } catch (error) {
//         dispatch({
//             type: ERROR
//         })
//     }
// }



// const obtenerContactoPorCuil = async (cuit) => new Promise((resolve, reject) =>{
//     const responseContacto = await axios.get(`${UrlApiDynamics}Contacto?filter=new_cuitcuil eq ${cuit}&cuit=${Entidad}`)
//     if(responseContacto.data.length > 0){
//         resolve({
//             error: false,
//             value: responseContacto.data
//           });
//     }else{
//         reject({
//             error: true,
//             message: "Se ha sacado un 6"
//           });
//     }
// })

const crearSolicitudDeAlta = async (cuenta_id, tipo, vinculado_id, porcentaje, descripcion) => {
    var tipoRelacion = "100000001" //Accionista
    const response = await axios.post(`${UrlApiDynamics}Relacionesvinculacion?accountid=${cuenta_id}&tipo=${tipo}&vinculado=${vinculado_id}
            &tipoRelacion=${tipoRelacion}&porcentajeParticipacion=${porcentaje}&descripcion=${descripcion}&cuit=${Entidad}`)
}

const CrearContacto = async (accionista) => {
    return await axios.post(`${UrlApiDynamics}Contactos?nombre=${accionista.nombre}&apellido=${accionista.apellido}
    &cuitcuil=${accionista.cuitcuil}&cuitSgr=${Entidad}`)
}