import axios from 'axios'
import { Entidad, UrlApiDynamics } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    resultado: '',
    notificaciones: []
}

//Types
const NOTIFICACIONES_EXITO = 'NOTIFICACIONES_EXITO'
const COMPLETAR_NOTIFICACIONES_EXITO = 'COMPLETAR_NOTIFICACIONES_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function notificacionesReducers(state = dataInicial, action) {
    switch (action.type) {
        case NOTIFICACIONES_EXITO:
            return { ...state, notificaciones: action.payload }
        case COMPLETAR_NOTIFICACIONES_EXITO:
            return { ...state, resultado: action.resultado }
        case LOADING:
            return { ...state, resultado: action.resultado }
        default:
            return { ...dataInicial }
    }
}

//Actions
export const obtenerNotificaciones = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Tareas?filter=_regardingobjectid_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: NOTIFICACIONES_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const CompletarNotificacion = (tarea, estado) => async (dispatch) => {
    dispatch({
        type: LOADING,
        resultado: 'LOADING'
    })

    try {
        const response = await axios.post(`${UrlApiDynamics}Tareas?tarea=${tarea}&estado=${estado}&cuit=${Entidad}`)
        dispatch({
            type: COMPLETAR_NOTIFICACIONES_EXITO,
            resultado: 'EXITO'
        })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            resultado: 'ERROR'
        })
    }
}