import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faThumbsUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { useSpring, config, animated } from 'react-spring'

const SolicitdAltaCompleta = ({ state }) => {
    const [mensaje, setMensaje] = React.useState('')
    const [correoSGR, setCorreoSGR] = React.useState(' administracion@sgroneclick.com')
    const [correoAlyc, setCorreoAlyc] = React.useState(' administracion@sgroneclick.com')
    const [esSGR, setEsSGR] = React.useState(false)
    const springWith = useSpring({
        from: { 'font-size': "-150px", opacity: '0' },
        'font-size': "80px",
        opacity: '1',
        config: config.gentle,
    })

    React.useEffect(() => {
        var esSGR = false
        let opciones = state.servicio.split(',')
        opciones.forEach(opcion => {
            if (opcion === "100000000") { //SGR
                esSGR = true
            }
        });
        if (esSGR) {
            if(state.textoSGR != ''){
                setMensaje(state.textoSGR)
            }else{
                setMensaje("Felicitaciones!!!! has completado el primer paso. En breve nos comunicaremos contigo vía correo electrónico para informarte del estado de tu solicitud. Cualquier duda podés enviar un correo informando nombre completo o razón social y Cuit/Cuil al siguiente correo ")
            }
            // localStorage.removeItem("onboarding")
        } else {
            if(state.textoALYC != ''){
                setMensaje(state.textoALYC)
            }else{
                setMensaje("Felicitaciones!!!! has completado el primer paso. En unos minutos recibirás un correo electrónico para que puedas continuar con el proceso. Cualquier duda podés enviar un correo informando nombre completo o razón social y Cuit/Cuil al siguiente correo ")
            }
            // localStorage.removeItem("onboarding")
        }
    }, [state])

    return (
        <div className="row">
            <div className="d-flex justify-content-center w-100">
                <animated.div style={springWith}>
                    <FontAwesomeIcon icon={faCheckCircle} className="upload-file atras ok" color="#eee" />
                </animated.div>
            </div>
            <div>
                <div className="p-4 text-center">
                    <h6 className="fw-bolder mensaje-finalizacion">
                        {mensaje}{state.esSGR ? <span className="text-primary">{correoSGR}</span> : <span className="text-primary">{correoAlyc}</span>}
                    </h6>
                    <h6 className="fw-bolder mensaje-finalizacion">
                        ¡Muchas gracias!
                    </h6>
                </div>
            </div>
        </div>
    )
}

export default SolicitdAltaCompleta
