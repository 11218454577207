import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSpring, animated } from 'react-spring'
import { obtenerRelaciones, CrearRelacion, obtenerRelacionesFETCH, InactivarRelacion } from '../Redux/RelacionDeVinculacion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheckCircle, faTimesCircle, faTrashAlt, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Toast, Spinner } from 'react-bootstrap'
import { obtenerSocios } from '../Redux/Cuenta'
import { obtenerContactos } from '../Redux/Contacto'
import Select from 'react-select';
import { obtenerTipoDeDocumentos } from '../Redux/TipoDeDocumento'
import { obtenerPaises } from '../Redux/Cuenta'
import { obtenerUnidadDeNegocio } from '../Redux/UnidadDeNegocio'
import { IniciarValidacion } from '../Redux/Nosis'
import { withRouter, Link, NavLink, useLocation } from 'react-router-dom'
import Notificacion from './Notificacion'
import { Trash2, Edit, BatteryCharging, Database, UploadCloud, Folder, Upload, AlertTriangle, PlusSquare, AlertCircle, Download, Eye, Cloud } from "react-feather"
import TokenContext from '../context/TokenContext';


const RelacionesVinculacion = (props) => {
    const dispatch = useDispatch()
    const [relaciones, setRelaciones] = React.useState([])
    const [relacionesFETCH, setRelacionesFETCH] = React.useState([])
    const [llamada, setLlamada] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [vinculado, setVinculado] = React.useState('')
    const [tipoRelacion, setTipoRelacion] = React.useState('')
    const [porcentajeParticipacion, setPorcentajeParticipacion] = React.useState('')
    const [porcentajeBeneficiario, setPorcentajeBeneficiario] = React.useState('')
    const [relacion, setRelacion] = React.useState('')
    const [cargo, setCargo] = React.useState('')
    const [descripcion, setDescripcion] = React.useState('')
    const [cuenta, setCuenta] = React.useState('')
    const [tipo, setTipo] = React.useState('')
    const [llamadaSocios, setLlamadaSocios] = React.useState(false)
    const [llamadaRelaciones, setLlamadaRelaciones] = React.useState(false)
    const [llamadaContactos, setLlamadaContactos] = React.useState(false)
    const [nombre, setNombre] = React.useState('')
    const [apellido, setApellido] = React.useState('')
    const [razonSocial, setRazonSocial] = React.useState('')
    const [cuitcuil, setCuitcuil] = React.useState('')
    const [sociosOpciones, setSociosOpciones] = React.useState([])
    const [contactosOpciones, setContactosOpciones] = React.useState([])
    const [domicilioExterior, setDomicilioExterior] = React.useState(false)
    const [domicilioUSA, setDomicilioUSA] = React.useState(false)
    const [direccion, setDireccion] = React.useState('')
    const [cp, setCP] = React.useState('')
    const [ciudad, setCiudad] = React.useState('')
    const [provincia, setProvincia] = React.useState('')
    const [pais, setPais] = React.useState('')
    const [einss, setEinss] = React.useState('')
    const [dni, setDNI] = React.useState('')
    const [fechaNacimiento, setFechaNacimiento] = React.useState('')
    const [lugarNacimiento, setLugarNacimiento] = React.useState('')
    const [correo, setCorreo] = React.useState('')
    const [estadoCivil, setEstadoCivil] = React.useState('')
    const [nombreConyuge, setNombreConyuge] = React.useState('')
    const [docConyuge, setDocConyuge] = React.useState('')
    const [nit, setNit] = React.useState('')
    const [actualizacion, setActualizacion] = React.useState(false)
    const [tipoDeCarga, setTipoDeCarga] = React.useState('')
    const [usuario, setUsuario] = React.useState({})
    const [relacionid, setRelacionid] = React.useState('')
    const [estado, setEstado] = React.useState('')
    const [personaPolitica, setPersonaPolitica] = React.useState('')
    const [funcion, setFuncion] = React.useState('')
    const [ente, setEnte] = React.useState('')
    const [relacionPersona, setRelacionPersona] = React.useState('')
    const [fechaAlta, setFechaAlta] = React.useState('')
    const [fechaBaja, setFechaBaja] = React.useState('')
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [sexoConyuge, setSexoConyuge] = React.useState('')
    const [trabajaConyuge, setTrabajaConyuge] = React.useState(false)
    const [tipoDocumento, setTipoDocumento] = React.useState('')
    const [tiposDocumentos, setTiposDocumentos] = React.useState([])
    const [tiposSujetos, setTiposSujetos] = React.useState([])
    const [tipoSujeto, setTipoSujeto] = React.useState([])
    const [aclaracion, setAclaracion] = React.useState(null)
    const [paises, setPaises] = React.useState([])
    const [opcionesPaises, setOpcionesPaises] = React.useState([])
    const [llamadaPais, setLlamadaPais] = React.useState(false)
    const [unidad, setUnidad] = React.useState({})
    const [llamadaUnidadesNegocio, setLlamadaUnidadesNegocio] = React.useState(false)
    const [contactoid, setContactoid] = React.useState('')
    const [fechaValidacion, setFechaValidacion] = React.useState('')
    const [resultadoValidacion, setResultadoValidacion] = React.useState('')
    const [profesion, setProfesion] = React.useState('')
    const [telefonoLaboral, setTelefonoLaboral] = React.useState('')
    const [correoEstadoCuenta, setCorreoEstadoCuenta] = React.useState('')

    const relacionesSelector = useSelector(store => store.relaciones.relaciones)
    const activo = useSelector(store => store.usuarios.activo)
    const accountid = useSelector(store => store.usuarios.accountid)
    const usuarioSelector = useSelector(store => store.usuarios.user)
    const sociosSelector = useSelector(store => store.cuenta.socios)
    const contactosSelector = useSelector(store => store.contactos.contactos)
    const cracionDatosSelector = useSelector(store => store.relaciones.CreacionDatos)
    const inactivacionDatosSelector = useSelector(store => store.relaciones.InactivacionDatos)
    const relacionesFetchSelector = useSelector(store => store.relaciones.relacionesFetch)
    const tipoDocumentoSelector = useSelector(store => store.tiposDocumentos.tiposDocumentos)
    const paisesSelector = useSelector(store => store.cuenta.paises)
    const unidadesNegocioSelector = useSelector(store => store.unidadesNegocio.unidadesDeNegocio)
    const validacionSelector = useSelector(store => store.validacion.url)

    const tkn = React.useContext(TokenContext)

    React.useEffect(async () => {
        if (activo) {
            if (usuarioSelector !== undefined) {
                setUsuario(usuarioSelector)
            }
            if (relacionesSelector.length > 0) {
                setRelaciones(relacionesSelector)
                if (document.getElementById("spinner6") !== null) {
                    document.getElementById("spinner6").hidden = true;
                }
                if (document.getElementById("spinner7") !== null) {
                    document.getElementById("spinner7").hidden = true;
                }
                if (document.getElementById("spinner8") !== null) {
                    document.getElementById("spinner8").hidden = true;
                }
                if (document.getElementById("spinner9") !== null) {
                    document.getElementById("spinner9").hidden = true;
                }
                if (document.getElementById("spinner10") !== null) {
                    document.getElementById("spinner10").hidden = true;
                }
                if (document.getElementById("spinner11") !== null) {
                    document.getElementById("spinner11").hidden = true;
                }
            } else if (relacionesSelector.length === 0 && llamada === false) {
                setTimeout(() => {
                    obtenerRelacionesPorCuenta(accountid)
                }, 1000);
                setLlamada(true)
                setTimeout(() => {
                    if (document.getElementById("spinner1") !== null) {
                        document.getElementById("spinner1").hidden = true;
                    }
                    if (document.getElementById("spinner2") !== null) {
                        document.getElementById("spinner2").hidden = true;
                    }
                    if (document.getElementById("spinner3") !== null) {
                        document.getElementById("spinner3").hidden = true;
                    }
                    if (document.getElementById("spinner4") !== null) {
                        document.getElementById("spinner4").hidden = true;
                    }
                    if (document.getElementById("spinner5") !== null) {
                        document.getElementById("spinner5").hidden = true;
                    }
                    if (document.getElementById("spinner6") !== null) {
                        document.getElementById("spinner6").hidden = true;
                    }
                }, 3000);
            }

            if (sociosSelector.length > 0 && llamadaSocios === true) {
                completarOpcionesSocios(sociosSelector)
            } else if (sociosSelector.length === 0 && llamadaSocios === false) {
                obtenerTodosSocios()
                setLlamadaSocios(true)
            }

            if (relacionesFetchSelector.length > 0 && llamadaRelaciones === true) {
                debugger
                var relaciones = []
                relacionesFetchSelector.forEach(item => {
                    var relacion = {
                        new_participacionaccionariaid: item.new_participacionaccionariaid,
                        contactid: item["ab.contactid"],
                        new_fechaultimavalidacionidentidadrenaper: item["ab.new_fechaultimavalidacionidentidadrenaper"],
                        new_resultadoultimavalidacionidentidadrenaper: item["ab.new_resultadoultimavalidacionidentidadrenaper"],
                        new_tienedomicilioenusa: item["ab.new_tienedomicilioenusa"],
                        new_tienedomiciliofiscalenelexterior: item["ab.new_tienedomiciliofiscalenelexterior"],
                        firstname: item["ab.firstname"],
                        lastname: item["ab.lastname"],
                        statuscode: item["ab.statuscode"],
                        new_cuitcuil: item["ab.new_cuitcuil"],
                        emailaddress1: item["ab.emailaddress1"],
                        lastname: item["ab.lastname"],
                        birthdate: item["ab.birthdate"],
                        familystatuscode: item["ab.familystatuscode"],
                        familystatuscode_value: item["ab.familystatuscode@OData.Community.Display.V1.FormattedValue"],
                        new_nrodedocumentoconyuge: item["ab.new_nrodedocumentoconyuge"],
                        new_nrodedocumento: item["ab.new_nrodedocumento"],
                        address3_line1: item["ab.address3_line1"],
                        spousesname: item["ab.spousesname"],
                        address3_postalcode: item["ab.address3_postalcode"],
                        address3_city: item["ab.address3_city"],
                        address3_stateorprovince: item["ab.address3_stateorprovince"],
                        address3_country: item["ab.address3_country"],
                        new_einss: item["ab.new_einss"],
                        new_nit: item["ab.new_nit"],
                        address1_line1: item["ab.address1_line1"],
                        address1_postalcode: item["ab.address1_postalcode"],
                        address1_city: item["ab.address1_city"],
                        address1_stateorprovince: item["ab.address1_stateorprovince"],
                        address1_country: item["ab.address1_country"],
                        new_tipodedocumentoconyuge: item["ab.new_tipodedocumentoconyuge"],
                        new_tipodedocumentoconyuge_value: item["ab.new_tipodedocumentoconyuge@OData.Community.Display.V1.FormattedValue"],
                        new_generodelconyuge: item["ab.new_generodelconyuge"],
                        new_generodelconyuge_value: item["ab.new_generodelconyuge@OData.Community.Display.V1.FormattedValue"],
                        new_conyugetrabaja: item["ab.new_conyugetrabaja"],
                        new_tiposujetoobligado: item["ab.new_tiposujetoobligado"],
                        new_tiposujetoobligado_value: item["ab.new_tiposujetoobligado@OData.Community.Display.V1.FormattedValue"],
                        new_paisdeorgenid: item["ab.new_paisdeorgen"],
                        new_paisdeorgen_value: item["ab.new_paisdeorgen@OData.Community.Display.V1.FormattedValue"],
                        new_paisresidenciafiscalenelexteriorid: item["ab.new_paisresidenciafiscalenelexterior"],
                        new_paisresidenciafiscalenelexterior_value: item["ab.new_paisresidenciafiscalenelexterior@OData.Community.Display.V1.FormattedValue"],
                        new_paisid: item["ab.new_pais"],
                        new_pais_value: item["ab.new_pais@OData.Community.Display.V1.FormattedValue"],
                        new_cuentacontactovinculado: item._new_cuentacontactovinculado_value,
                        tipo: item["_new_cuentacontactovinculado_value@Microsoft.Dynamics.CRM.lookuplogicalname"],
                        cuenta_new_nmerodedocumento: item["aa.new_nmerodedocumento"],
                        cuenta_razon_social: item["aa.name"],
                        cuenta_email: item["aa.emailaddress1"],
                        new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                        new_personaexpuestapoliticamente: item["ab.new_personaexpuestapoliticamente"],
                        new_cargofuncinjerarquayenteendondetrabaja: item["ab.new_cargofuncinjerarquayenteendondetrabaja"],
                        new_relacinconlapersonaexpuestapolticamente: item["ab.new_relacinconlapersonaexpuestapolticamente"],
                        new_fechadealtabaja: item["ab.new_fechadealtabaja"],
                        new_fechadebaja: item["ab.new_fechadebaja"],
                        new_entedondetrabaja: item["ab.new_entedondetrabaja"],
                        new_lugardenacimiento: item["ab.new_lugardenacimiento"],
                        new_profesionoficioactividad: item["ab.new_profesionoficioactividad"],
                        telephone1: item["ab.telephone1"],
                        new_correoelectrnicopararecibirestadodecuenta: item["ab.new_correoelectrnicopararecibirestadodecuenta"]
                    }
                    relaciones.push(relacion)
                })
                setRelacionesFETCH(relaciones)
                console.log(relaciones)
            } else if (relacionesFetchSelector.length === 0 && llamadaRelaciones === false) {
                obtenerRelacionesFetch()
                setLlamadaRelaciones(true)
            }

            if (contactosSelector.length > 0 && llamadaContactos === true) {
                completarOpcionesContactos(contactosSelector)
            } else if (sociosSelector.length === 0 && llamadaSocios === false) {
                obtenerTodosContactos()
                setLlamadaContactos(true)
            }

            if (cracionDatosSelector !== undefined) {
                if (cracionDatosSelector !== '') {
                    creacionExito(cracionDatosSelector)
                }
            }

            if (inactivacionDatosSelector !== undefined) {
                if (inactivacionDatosSelector !== '') {
                    inactivacionExito(inactivacionDatosSelector)
                }
            }

            if (tkn.length > 0) {

                if (tipoDocumentoSelector.length > 0 && llamadaDocumentos === true) {
                    CompletarOpcionesTipoDocumentos(tipoDocumentoSelector)
                } else if (llamadaDocumentos === false) {
                    obtenerTiposDocumentos()
                    setLlamadaDocumentos(true)
                }
            }

            if (paisesSelector.length > 0 && cuenta != undefined) {
                // setProvincias(provinciasSelector)
                completarOpcionesPaises(paisesSelector)
                completarPaises(paisesSelector, cuenta)
            } else if (paisesSelector.length === 0 && llamadaPais === false) {
                setLlamadaPais(true)
                obtenerTodosPaises()
            }

            if (unidadesNegocioSelector.length > 0 && llamadaUnidadesNegocio === true) {
                setUnidad(unidadesNegocioSelector[0])
            } else if (unidadesNegocioSelector.length === 0 && llamadaUnidadesNegocio === false) {
                obtenerUnidadesDeNegocio()
                setLlamadaUnidadesNegocio(true)
            }
            // if(validacionSelector != '' && validacionSelector != undefined) {
            //     props.history.push(validacionSelector)
            // }

        }
    }, [activo, relacionesSelector, contactosSelector, sociosSelector, cracionDatosSelector, llamadaSocios, llamadaContactos, relacionesFetchSelector, inactivacionDatosSelector, tipoDocumentoSelector, unidadesNegocioSelector])

    const obtenerRelacionesPorCuenta = async () => {
        dispatch(obtenerRelaciones(accountid))
    }

    const obtenerTodosSocios = async () => {
        dispatch(obtenerSocios())
    }

    const obtenerTodosContactos = async () => {
        dispatch(obtenerContactos())
    }

    const obtenerRelacionesFetch = async () => {
        dispatch(obtenerRelacionesFETCH(accountid))
    }

    const obtenerUnidadesDeNegocio = () => {
        dispatch(obtenerUnidadDeNegocio())
    }

    const cuentaOnChange = (value) => {
        setCuenta(value)
    }

    const contactoOnChange = (value) => {
        setVinculado(value)
    }

    const estadoCivilOnChange = (value) => {
        setEstadoCivil(value)
    }

    const sujetoOnChange = (value) => {
        setTipoSujeto(value)
    }

    const relacionOnChange = (value) => {
        setRelacion(value)
    }

    const obtenerTiposDocumentos = async () => {
        dispatch(obtenerTipoDeDocumentos(tkn))
    }

    const obtenerTodosPaises = async () => {
        dispatch(obtenerPaises())
    }

    const paisOnChange = (value) => {
        setPais(value)
    }

    const tipoOnChange = (valor) => {
        setTipo(valor)
        // if (valor === "Cuenta") {
        //     var x = document.getElementById("div-cuenta");
        //     if (x.style.display === "none") {
        //         x.style.display = "block";
        //     }
        //     ocultar('div-contacto')
        // } else if (valor === "Contacto") {
        //     var x = document.getElementById("div-contacto");
        //     if (x.style.display === "none") {
        //         x.style.display = "block";
        //     }
        //     ocultar('div-cuenta')
        // }
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })
    const CompletarOpcionesTipoDocumentos = (tipos) => {
        const opcionesDocumentos = [];
        tipos.forEach(element => {
            if (element.new_onboarding === true) {
                var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
                opcionesDocumentos.push(tipo);
            }
        });
        setTiposDocumentos(opcionesDocumentos)
    }
    const completarOpcionesSocios = (socios) => {
        const opcionesSocio = []
        socios.forEach(item => {
            var socio = { value: item.Accountid, label: item.Name }
            opcionesSocio.push(socio);
        });
        setSociosOpciones(opcionesSocio)
    }

    const completarOpcionesContactos = (contactos) => {
        const opcionesContactos = []
        contactos.forEach(item => {
            var contacto = { value: item.contactid, label: item.fullname }
            opcionesContactos.push(contacto);
        });
        setContactosOpciones(opcionesContactos)
    }

    const tipoDocumentoOnChange = (valor) => {
        setTipoDocumento(valor)
    }

    const sexoOnChange = (valor) => {
        setSexoConyuge(valor)
    }

    const relacionesOpciones = [
        { value: '0', label: 'Ascendente' },
        { value: '1', label: 'Descendente' }
    ]

    const tiposDeRelacionesOpciones = [
        { value: '100000000', label: 'Titular' },
        { value: '100000001', label: 'Accionista' },
        { value: '100000002', label: 'Beneficiario' },
        { value: '100000003', label: 'Miembro del Directorio' },
        { value: '100000004', label: 'Representante Legal/Apoderado' },
        { value: '100000006', label: 'Fiador' },
        { value: '100000005', label: 'Otro' }
    ]

    const estadoCivilOpciones = [
        { value: '1', label: 'Soltero/a' },
        { value: '2', label: 'Casado/a' },
        { value: '100000000', label: 'Concubinato' },
        { value: '3', label: 'Divorciado/a' },
        { value: '4', label: 'Viudo/a' }
    ]

    const sexoOpciones = [
        { value: '100000000', label: 'Masculino' },
        { value: '100000001', label: 'Femenino' },
        { value: '100000002', label: 'Otro' },
        { value: '100000003', label: 'No Especificar' },
    ]
    const sujetoOpciones = [
        { value: '100000000', label: 'Sujeto Obligado' },
        { value: '100000001', label: 'Sujeto No Obligado' }
    ]

    const completarOpcionesPaises = (paises) => {
        var opciones = []
        paises.forEach(pais => {
            var Pais = { value: pais.new_paisid, label: pais.new_name }
            opciones.push(Pais);
        });
        setOpcionesPaises(opciones)
    }

    const completarPaises = (paisesS, cuenta) => {
        paisesS.filter(pai => pai.new_paisid === cuenta._new_pais_value).map(item => {
            setPais({ value: item.new_paisid, label: item.new_name })
        })
    }

    const TipoDeCarga = (valor) => {
        limpiar()
        setEstado('0')
        setActualizacion(false)
        setTipoDeCarga("Create")
        if (valor === "Titular") {
            setAclaracion('')
            setTipo('Contacto')
            document.getElementById('div-personeria').style.display = 'none'
            document.getElementById('general').style.display = 'block'
        }
        if (valor === "Accionista") {
            setAclaracion('')
            // document.getElementById('general').style.display = 'none'
            ocultar('beneficiario')
            ocultar('directorio')
            document.getElementById('div-personeria').style.display = 'block'
            document.getElementById('accionista').style.display = 'block'
            var tipo = { value: '100000001', label: 'Accionista' }
            setTipoRelacion(tipo)
            setTipo('Cuenta')
        } else if (valor === 'Beneficiario') {
            setAclaracion('Solo lo debes completar en el caso que alguno de los Accionistas / Socios sea otra Persona Juridica. Es importante que por cada Persona Juridica que forme parte la estructura , declares el detalle de los accionistas / socios que la componen, hasta llegar a las personas humanas últimos beneficiarios de la sociedad.')
            document.getElementById('general').style.display = 'block'
            document.getElementById('div-personeria').style.display = 'none'
            var x = document.getElementById("beneficiario");
            if (x.style.display === "none") {
                x.style.display = "block";
            }
            setTipo('Contacto')
            ocultar('accionista')
            ocultar('directorio')
            var tipo = { value: '100000002', label: 'Beneficiario' }
            setTipoRelacion(tipo)
        } else if (valor === 'Directorio') {
            setAclaracion('')
            document.getElementById('general').style.display = 'block'
            var x = document.getElementById("directorio");
            if (x.style.display === "none") {
                x.style.display = "block";
            }

            var x = document.getElementById("div-personeria");
            if (x.style.display === "block") {
                x.style.display = "none";
            }
            setTipo('Contacto')
            ocultar('accionista')
            ocultar('beneficiario')
            var tipo = { value: '100000003', label: 'Miembro del Directorio' }
            setTipoRelacion(tipo)
        } else if (valor === 'Titular') {
            setAclaracion('')
            ocultar('accionista')
            ocultar('beneficiario')
            ocultar('directorio')
            var tipo = { value: '100000000', label: 'Titular' }
            setTipoRelacion(tipo)
        } else if (valor === 'Representante') {
            setAclaracion('')
            document.getElementById('general').style.display = 'block'
            ocultar('accionista')
            ocultar('beneficiario')
            ocultar('directorio')
            var tipo = { value: '100000004', label: 'Representante Legal/Apoderado' }
            setTipoRelacion(tipo)
            var x = document.getElementById("div-personeria");
            if (x.style.display === "block") {
                x.style.display = "none";
            }
            setTipo('Contacto')
        }
    }

    const ocultar = (id) => {
        var x = document.getElementById(id);
        if (x.style !== '') {
            if (x.style.display === "block") {
                x.style.display = "none";
            }
        }
    }

    const inactivarRelacion = (e) => {
        e.preventDefault()
        setEstado('1')
        dispatch(InactivarRelacion(relacionid, "1"));
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }


    const crearRelacionVinculacion = (e) => {
        e.preventDefault()
        debugger
        if (tipo === '') {
            document.getElementById("tipo").classList.add("is-invalid");
            setMensaje("La personeria es requerida!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("tipo").classList.remove('is-invalid')
        }

        if (tipo === 'Cuenta') {
            if (razonSocial === '') {
                document.getElementById("razonSocial").classList.add("is-invalid");
                setMensaje("La personeria es requerida!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("razonSocial").classList.remove('is-invalid')
            }
        } else if (tipo === 'Contacto') {
            if (nombre === '' || nombre == undefined) {
                document.getElementById("nombre").classList.add("is-invalid");
                setMensaje("El nombre es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("nombre").classList.remove('is-invalid')
            }
            if (apellido === '' || apellido == undefined) {
                document.getElementById("apellido").classList.add("is-invalid");
                setMensaje("El apellido es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("apellido").classList.remove('is-invalid')
            }
        }

        if (cuitcuil === '' || cuitcuil == undefined) {
            document.getElementById("cuitcuil").classList.add("is-invalid");
            setMensaje("El CUIT/CUIL es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("cuitcuil").classList.remove('is-invalid')
        }
        if (tipo === 'Contacto') {
            if (dni === '' || dni == undefined) {
                document.getElementById("dni").classList.add("is-invalid");
                setMensaje("El DNI es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("dni").classList.remove('is-invalid')
            }
            if (fechaNacimiento === '' || fechaNacimiento == undefined) {
                document.getElementById("fechaNacimiento").classList.add("is-invalid");
                setMensaje("La fecha de nacimiento es requerida!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("fechaNacimiento").classList.remove('is-invalid')
            }
            if (lugarNacimiento === '' || lugarNacimiento == undefined) {
                document.getElementById("lugarNacimiento").classList.add("is-invalid");
                setMensaje("El lugar de nacimiento es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("lugarNacimiento").classList.remove('is-invalid')
            }
        }
        if (correo === '' || correo == undefined) {
            document.getElementById("correo").classList.add("is-invalid");
            setMensaje("El correo electrónico es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
            document.getElementById("correo").classList.remove('is-invalid')
        }
        if (tipo === 'Contacto') {
            if (estadoCivil === '' || estadoCivil == undefined || estadoCivil.value === undefined) {
                document.getElementById("estadoCivil").classList.add("is-invalid");
                setMensaje("El estado civil es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("estadoCivil").classList.remove('is-invalid')
            }
            if (profesion === '' || profesion == undefined) {
                document.getElementById("profesion").classList.add("is-invalid");
                setMensaje("La Profesión, oficio o actividad es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("profesion").classList.remove('is-invalid')
            }
            if (correoEstadoCuenta === '' || correoEstadoCuenta == undefined) {
                document.getElementById("correoEstadoCuenta").classList.add("is-invalid");
                setMensaje("El correo para el estado de la cuenta es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("correoEstadoCuenta").classList.remove('is-invalid')
            }
            if (telefonoLaboral === '' || telefonoLaboral == undefined) {
                document.getElementById("telefonoLaboral").classList.add("is-invalid");
                setMensaje("El teléfono laboral es requerido!")
                setError(true)
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 3000);
                return
            } else {
                document.getElementById("telefonoLaboral").classList.remove('is-invalid')
            }
        }
        var validacionPorcentaje
        if (tipoRelacion.value === "100000001") {
            var accionistas = relaciones.filter(item => item.statecode == "0" && item.new_tipoderelacion == "100000001" && item.new_participacionaccionariaid != relacionid)
            validacionPorcentaje = validarPorcentaje(accionistas, porcentajeParticipacion)
        }
        if (validacionPorcentaje > 100) {
            setMensaje("El porcentaje total de los accionistas no puede ser mayor a 100%")
            setError(true)
            setLoading(false)
            setShow(true)
            // document.getElementById("btn-accionista").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 2000);
            return
        }
        var vinculadoCon
        if (tipo === 'Cuenta') {
            vinculadoCon = cuenta
        } else if (tipo === 'Contacto') {
            vinculadoCon = vinculado
        }
        document.getElementById("btn-relaciones").disabled = true
        dispatch(CrearRelacion(accountid, tipo, cuitcuil, nombre, apellido, razonSocial, vinculadoCon,
            tipoRelacion, porcentajeParticipacion, relacion, porcentajeBeneficiario, cargo, descripcion,
            dni, fechaNacimiento, lugarNacimiento, correo, estadoCivil, nombreConyuge, docConyuge, domicilioExterior, domicilioUSA, direccion,
            cp, ciudad, provincia, pais, einss, nit, contactoid, tipoDeCarga, relacionid, estado, personaPolitica, funcion, ente,
            relacionPersona, fechaAlta, fechaBaja, tipoDocumento, sexoConyuge, trabajaConyuge, tipoSujeto, profesion, telefonoLaboral, correoEstadoCuenta))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const creacionExito = (resultadoCreacion) => {
        if (resultadoCreacion === 'EXITO') {
            if (tipoDeCarga == "Create") {
                setMensaje("Relación creada con exito!")
            } else if (tipoDeCarga == 'Update') {
                setMensaje("Relación actualizada con exito!")
            }
            document.getElementById("btn-relaciones").disabled = false
            setError(false)
            setLoading(false)
            setShow(true)
            obtenerRelacionesPorCuenta()
            obtenerRelacionesFetch()
            setTimeout(() => {
                setShow(false)
                limpiar()
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInput").click();
            }, 2000);
        } else if (resultadoCreacion === 'ERROR') {
            document.getElementById("btn-relaciones").disabled = false
            setMensaje("La relación no se pudo crear!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const inactivacionExito = (resultadoInactivacion) => {
        if (resultadoInactivacion === 'EXITO') {
            setMensaje("La relación fue eliminada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            obtenerRelacionesPorCuenta()
            obtenerRelacionesFetch()
            setTimeout(() => {
                setShow(false)
                limpiar()
            }, 1500);
            setTimeout(() => {
                document.getElementById("btn-inactivacion").click();
            }, 2000);
        } else if (resultadoInactivacion === 'ERROR') {
            setMensaje("La eliminación falló!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }



    const limpiar = () => {
        setLugarNacimiento('')
        setCargo('')
        setCuenta('')
        setDescripcion('')
        setRelacion('')
        setTipo('')
        setVinculado('')
        setPorcentajeBeneficiario('')
        setPorcentajeParticipacion('')
        setNombre('')
        setApellido('')
        setRazonSocial('')
        setCuitcuil('')
        setDNI('')
        setCorreo('')
        setEstadoCivil('')
        setDocConyuge('')
        setFechaNacimiento('')
        setDireccion('')
        setCP('')
        setProvincia('')
        setCiudad('')
        setPais('')
        setEinss('')
        setNit('')
        setEstado('')
        if (personaPolitica === true) {
            document.getElementById('switchPersona').checked = false
        }
        setPersonaPolitica('')
        setFuncion('')
        setRelacionPersona('')
        setEnte('')
        setFechaAlta('')
        setFechaBaja('')
        if (domicilioUSA === true) {
            document.getElementById('switchUSA').checked = false
        }
        if (domicilioExterior === true) {
            document.getElementById('switch1').checked = false
        }
        setDomicilioUSA(false)
        setDomicilioExterior(false)
        setTipoDocumento('')
        setSexoConyuge('')
        setTipoDocumento('')
        setTrabajaConyuge(false)
        setNombreConyuge('')
        setProfesion('')
        setTelefonoLaboral('')
        setCorreoEstadoCuenta('')
        // ocultar('div-cuenta')
        // ocultar('div-contacto')
    }

    const edicion = (id, valor) => {
        limpiar()
        setActualizacion(true)
        setTipoDeCarga("Update")
        if (valor === "Accionista") {
            setAclaracion('')
            document.getElementById('general').style.display = 'block'
            if (document.getElementById('btn-validacion') !== null) {
                document.getElementById('btn-validacion').style.display = 'none'
            }
            if (document.getElementById('div-validacion') !== null) {
                document.getElementById('div-validacion').style.display = 'none'
            }

            ocultar('beneficiario')
            ocultar('directorio')
            document.getElementById('div-personeria').style.display = 'none'
            document.getElementById('accionista').style.display = 'block'
            var tipo = { value: '100000001', label: 'Accionista' }
            setTipoRelacion(tipo)
            setTipo('Cuenta')
            document.getElementById('general').style.display = 'block'
        } else if (valor === 'Beneficiario') {
            setAclaracion('Solo lo debes completar en el caso que alguno de los Accionistas / Socios sea otra Persona Juridica. Es importante que por cada Persona Juridica que forme parte la estructura , declares el detalle de los accionistas / socios que la componen, hasta llegar a las personas humanas últimos beneficiarios de la sociedad.')
            document.getElementById('general').style.display = 'block'
            document.getElementById('div-personeria').style.display = 'none'
            if (document.getElementById('btn-validacion') !== null) {
                document.getElementById('btn-validacion').style.display = 'none'
            }
            if (document.getElementById('div-validacion') !== null) {
                document.getElementById('div-validacion').style.display = 'none'
            }
            var x = document.getElementById("beneficiario");
            if (x.style.display === "none") {
                x.style.display = "block";
            }
            setTipo('Contacto')
            ocultar('accionista')
            ocultar('directorio')
            setTipo('Contacto')
            var tipo = { value: '100000002', label: 'Beneficiario' }
            setTipoRelacion(tipo)
        } else if (valor === 'Directorio') {
            document.getElementById('general').style.display = 'none'
            var x = document.getElementById("directorio");
            if (x.style.display === "none") {
                x.style.display = "block";
            }
            var x = document.getElementById("div-personeria");
            if (x.style.display === "block") {
                x.style.display = "none";
            }
            if (document.getElementById('btn-validacion') !== null) {
                document.getElementById('btn-validacion').style.display = 'none'
            }
            if (document.getElementById('div-validacion') !== null) {
                document.getElementById('div-validacion').style.display = 'none'
            }
            setTipo('Contacto')
            ocultar('accionista')
            ocultar('beneficiario')
            var tipo = { value: '100000003', label: 'Miembro del Directorio' }
            setTipoRelacion(tipo)
            setTipo('Contacto')
            setAclaracion('')
        } else if (valor === 'Titular') {
            ocultar('accionista')
            ocultar('beneficiario')
            ocultar('directorio')
            var tipo = { value: '100000000', label: 'Titular' }
            if (document.getElementById('btn-validacion') !== null) {
                document.getElementById('btn-validacion').style.display = 'block'
            }
            if (document.getElementById('div-validacion') !== null) {
                document.getElementById('div-validacion').style.display = 'block'
            }
            setTipoRelacion(tipo)
            setTipo('Contacto')
            document.getElementById('general').style.display = 'block'
            setAclaracion('')
        } else if (valor === 'Representante') {
            document.getElementById('general').style.display = 'block'
            ocultar('accionista')
            ocultar('beneficiario')
            ocultar('directorio')
            var tipo = { value: '100000004', label: 'Representante Legal/Apoderado' }
            setTipoRelacion(tipo)
            if (document.getElementById('btn-validacion') !== null) {
                document.getElementById('btn-validacion').style.display = 'block'
            }
            if (document.getElementById('div-validacion') !== null) {
                document.getElementById('div-validacion').style.display = 'block'
            }
            var x = document.getElementById("div-personeria");
            if (x.style.display === "block") {
                x.style.display = "none";
            }
            setTipo('Contacto')
            setAclaracion('')
        }
        relacionesFETCH.filter(item => item.new_participacionaccionariaid == id).map(item => {
            setRelacionid(item.new_participacionaccionariaid)
            setContactoid(item.new_cuentacontactovinculado)
            setNombre(item.firstname)
            setApellido(item.lastname)
            setCorreo(item.emailaddress1)
            setCuitcuil(item.new_cuitcuil)
            setDomicilioUSA(item.new_tienedomicilioenusa)
            setDomicilioExterior(item.new_tienedomiciliofiscalenelexterior)
            setFechaNacimiento(item.birthdate)
            setEstadoCivil({ value: item.familystatuscode, label: item.familystatuscode_value })
            setTipoDocumento({ value: item.new_tipodedocumentoconyuge, label: item.new_tipodedocumentoconyuge_value })
            setSexoConyuge({ value: item.new_generodelconyuge, label: item.new_generodelconyuge_value })
            setTipoSujeto({ value: item.new_tiposujetoobligado, label: item.new_tiposujetoobligado_value })
            setEinss(item.new_einss)
            setNit(item.new_nit)
            setDocConyuge(item.new_nrodedocumentoconyuge)
            setDNI(item.new_nrodedocumento)
            setLugarNacimiento(item.new_lugardenacimiento)
            setEstado(relaciones.filter(rel => rel.new_participacionaccionariaid == item.new_participacionaccionariaid).map(item => item.statecode))
            setPorcentajeParticipacion(relaciones.filter(rel => rel.new_participacionaccionariaid == item.new_participacionaccionariaid).map(item => item.new_porcentajedeparticipacion))
            setPorcentajeBeneficiario(relaciones.filter(rel => rel.new_participacionaccionariaid == item.new_participacionaccionariaid).map(item => item.new_porcentajebeneficiario))
            setCargo(relaciones.filter(rel => rel.new_participacionaccionariaid == item.new_participacionaccionariaid).map(item => item.new_cargo))
            setFechaValidacion(item.new_fechaultimavalidacionidentidadrenaper)
            setResultadoValidacion(item.new_resultadoultimavalidacionidentidadrenaper)
            if (item.new_tienedomiciliofiscalenelexterior == true) {
                setDomicilioExterior(true)
                document.getElementById('switch1').checked = true
                if (item.new_tienedomicilioenusa == true) {
                    document.getElementById('switchUSA').checked = true
                    setDomicilioUSA(true)
                } else {
                    document.getElementById('switchUSA').checked = false
                }
                debugger
                setDireccion(item.address3_line1)
                setCP(item.address3_postalcode)
                setProvincia(item.address3_stateorprovince)
                setCiudad(item.address3_city)
                setPais({ value: item.new_paisresidenciafiscalenelexteriorid, label: item.new_paisresidenciafiscalenelexterior_value })
            } else {
                document.getElementById('switch1').checked = false
                setDireccion(item.address1_line1)
                setCP(item.address1_postalcode)
                setProvincia(item.address1_stateorprovince)
                setCiudad(item.address1_city)
                setPais({ value: item.new_paisid, label: item.new_pais_value })
            }
            if (item.tipo == "contact") {
                setTipo('Contacto')
            } else {
                setTipo('Cuenta')
                setCuitcuil(item.cuenta_new_nmerodedocumento)
                setRazonSocial(item.cuenta_razon_social)
                setCorreo(item.cuenta_email)
            }

            if (item.new_personaexpuestapoliticamente === true) {
                document.getElementById('switchPersona').checked = true
                setPersonaPolitica(true)
            } else {
                document.getElementById('switchPersona').checked = false
            }
            setPersonaPolitica(item.new_personaexpuestapoliticamente)
            setFuncion(item.new_cargofuncinjerarquayenteendondetrabaja)
            setRelacionPersona(item.new_relacinconlapersonaexpuestapolticamente)
            setEnte(item.new_entedondetrabaja)
            setFechaAlta(item.new_fechadealtabaja)
            setFechaBaja(item.new_fechadebaja)
            setTelefonoLaboral(item.telephone1)
            setProfesion(item.new_profesionoficioactividad)
            setCorreoEstadoCuenta(item.new_correoelectrnicopararecibirestadodecuenta)
            if (item.new_conyugetrabaja == true) {
                setTimeout(() => {
                    document.getElementById('switchTrabaja').checked = true
                    setTrabajaConyuge(true)
                }, 800);
            }
            setNombreConyuge(item.spousesname)
        })
    }

    const validarPorcentaje = (accionis, porcen) => {
        let porcentaje = 0
        accionis.forEach(element => {
            porcentaje = porcentaje + parseInt(element.new_porcentajedeparticipacion)
        });
        return porcentaje = porcentaje + parseInt(porcen)
    }

    const validarIdentidad = () => {
        debugger
        setLoading(true)
        setMensaje("Iniciando Validación...")
        setShow(true)
        document.getElementById("myInput").click();
        props.history.push(`/validaridentidad?dni=${dni}&contactid=${contactoid}&estado=inicial`)
    }

    return (
        <animated.div className="container-fluid min-vh-100" style={fade}>
            <div className="row pb-5">
                {
                    usuario.Personeria == 'F' ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Titulares</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Titular">
                                                <button
                                                    className="btn float-end p-0"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Titular')}>
                                                    <PlusSquare size={22} className="mx-2" color="#000" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center">
                                            <div className="col-6 col-sm-6 fw-bolder">
                                                Contacto vinculado
                                            </div>
                                            <div className="col-6 col-sm-6 text-end fw-bolder">

                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner6">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000000 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row">
                                                                <div className="col-8 col-sm-10 m-0 p-0 text-start">
                                                                    <p className="m-0 color-textp-lista texto-lista capitalize" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalRelaciones"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => edicion(item.new_participacionaccionariaid, 'Titular')}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Relación">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivacion"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => setRelacionid(item.new_participacionaccionariaid)}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000000).length} Titulares
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    usuario.Personeria == 'J' ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Accionistas/Socios</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Accionista">
                                                <button
                                                    className="btn float-end p-0"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Accionista')}>
                                                    <PlusSquare size={22} className="mx-2" color="#000" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center">
                                            <div className="col-6 col-sm-6 fw-bolder">
                                                Cuenta/Contacto vinculado
                                            </div>
                                            <div className="col-6 col-sm-6 text-end fw-bolder">
                                                % de Participación
                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner7">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000001 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row align-items-center pt-1 pb-1">
                                                                <div className="col-6 col-sm-6 px-2 m-0 p-0 text-start">
                                                                    <p className="m-0 color-textp-lista texto-lista capitalize" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-4 col-sm-4 px-2 m-0 text-end">
                                                                    <p className="color-textp-lista m-0 texto-lista">
                                                                        {(item.new_porcentajedeparticipacion) ? item.new_porcentajedeparticipacion : '---'}
                                                                    </p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalRelaciones"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => edicion(item.new_participacionaccionariaid, 'Accionista')}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Relación">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivacion"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => setRelacionid(item.new_participacionaccionariaid)}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000001 && itemRelacion.statecode == "0").length} Accionistas
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    usuario.Personeria == 'J' ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Beneficiarios finales personas humanas</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="w-100 ">
                                            <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Beneficiario">
                                                <button
                                                    className="btn float-end p-0"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Beneficiario')}>
                                                    <PlusSquare size={22} className="mx-2" color="#000" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center">
                                            <div className="col-6 col-sm-6 fw-bolder">
                                                Contacto vinculado
                                            </div>
                                            <div className="col-6 col-sm-6 text-end fw-bolder">
                                                Porcentaje
                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner8">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000002 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row align-items-center pt-1 pb-1">
                                                                <div className="col-6 col-sm-6 px-2 m-0 p-0 text-start">
                                                                    <p className="m-0 color-textp-lista texto-lista capitalize" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-4 col-sm-4 px-2 m-0 text-end">
                                                                    <p className="color-textp-lista m-0 texto-lista">
                                                                        {(item.new_porcentajebeneficiario) ? item.new_porcentajebeneficiario : '---'}
                                                                    </p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalRelaciones"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => edicion(item.new_participacionaccionariaid, 'Beneficiario')}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Relación">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivacion"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => setRelacionid(item.new_participacionaccionariaid)}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000002 && itemRelacion.statecode == "0").length} Beneficiarios
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                {
                    usuario.Personeria == 'J' ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Miembros del Directorio</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Miembro">
                                                <button
                                                    className="btn float-end p-0"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Directorio')}>
                                                    <PlusSquare size={22} className="mx-2" color="#000" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center">
                                            <div className="col-6 col-sm-6 fw-bolder">
                                                Contacto vinculado
                                            </div>
                                            <div className="col-6 col-sm-6 text-end fw-bolder">
                                                Cargo
                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner9">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000003 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row align-items-center pt-1 pb-1">
                                                                <div className="col-6 col-sm-6 px-2 m-0 p-0 text-start">
                                                                    <p className="m-0 color-textp-lista texto-lista capitalize" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-4 col-sm-4 px-2 m-0 text-end">
                                                                    <p className="color-textp-lista m-0 texto-lista">
                                                                        {(item.new_cargo) ? (item.new_cargo) : '---'}
                                                                    </p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalRelaciones"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => edicion(item.new_participacionaccionariaid, 'Directorio')}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Relación">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivacion"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => setRelacionid(item.new_participacionaccionariaid)}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000003 && itemRelacion.statecode == "0").length} Miembros
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                {
                    usuario.Personeria == 'J' ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Representantes Legales/Apoderados</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Representante">
                                                <button
                                                    className="btn float-end p-0"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Representante')}>
                                                    <PlusSquare size={22} className="mx-2" color="#000" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center">
                                            <div className="col-6 col-sm-6 fw-bolder">
                                                Contacto vinculado
                                            </div>
                                            <div className="col-6 col-sm-6 text-end">

                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner10">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000004 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row align-items-center pt-1 pb-1">
                                                                <div className="col-6 col-sm-6 px-2 m-0 p-0 text-start ">
                                                                    <p className="m-0 color-textp-lista texto-lista capitalize" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-4 col-sm-4 px-2 m-0 text-end">
                                                                    <p className="color-textp-lista m-0 texto-lista">
                                                                        {/* {(item.new_fechadevencimiento) ? Moment(item.new_fechadevencimiento).format("DD-MM-YYYY") : '---'} */}
                                                                    </p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalRelaciones"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => edicion(item.new_participacionaccionariaid, 'Representante')}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Relación">
                                                                        <span
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivacion"
                                                                            style={{ "cursor": "pointer" }}
                                                                            onClick={e => setRelacionid(item.new_participacionaccionariaid)}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000004 && itemRelacion.statecode == "0").length} Representantes
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                {
                    (usuario.SGR == true) ?
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="p-2">
                                            <h6 className="fw-bolder">Fiadores</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            {/* <div className="dropdown float-end">
                                        <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faEllipsisH} className="fs-5 text-dark upload-file atras float-end" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <button className="btn border-0 adeltante dropdown-item text-light"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ModalRelaciones"
                                                    onClick={e => TipoDeCarga('Representante')}
                                                >
                                                    Agregar Representante
                                                </button>
                                            </li>

                                        </ul>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad">
                                        <div className="row p-3 d-flex align-items-center ">
                                            <div className="col-3 col-sm-4 fw-bolder">
                                                Cuenta/Contacto vinculado
                                            </div>
                                            <div className="col-3 col-sm-2 fw-bolder">
                                                Plazo
                                            </div>
                                            <div className="col-3 col-sm-3 fw-bolder">
                                                Monto
                                            </div>
                                            <div className="col-3 col-sm-3 fw-bolder">
                                                Divisa
                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner11">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                            {
                                                relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000006 && itemRelacion.statecode == "0").map(item => {
                                                    return (
                                                        <li key={item.new_participacionaccionariaid} className="list-group-item borde-0">
                                                            <div className="row align-items-center pt-1 pb-1">
                                                                <div className="col-8 col-sm-8 px-2 m-0 p-0 text-start">
                                                                    <p className="m-0 color-textp-lista texto-lista" >{item.new_name}</p>
                                                                </div>
                                                                <div className="col-4 col-sm-4 px-2 m-0 text-end">
                                                                    <p className="color-textp-lista m-0 texto-lista">
                                                                        {/* {(item.new_fechadevencimiento) ? Moment(item.new_fechadevencimiento).format("DD-MM-YYYY") : '---'} */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {relaciones.filter((itemRelacion) => itemRelacion.new_tipoderelacion == 100000006 && itemRelacion.statecode == "0").length} Fiadores
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className="modal fade bd-example-modal-xl" id="ModalRelaciones" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body bg-light">
                            <div className="row">
                                <div className="col-11">
                                    <h6 className="fw-bolder">{actualizacion == false ? "Agregar" : "Actualizar"} Relación {aclaracion != '' ? <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="icono-information text-warning bg-dark rounded-circle upload-file mx-1"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="right"
                                        title={aclaracion} /> : null}</h6>
                                    <hr className="hr-width hr-principal" />
                                </div>
                                <div className="col-1">

                                    <button type="button"
                                        className="btn-close float-end"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        id="myInput"
                                        onClick={e => limpiar()}
                                    ></button>
                                </div>
                            </div>
                            <form name="Alyc" onSubmit={crearRelacionVinculacion}>
                                <div>
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active text-dark fw-bolder" id="principal-tab" data-bs-toggle="tab" data-bs-target="#principal" type="button" role="tab" aria-controls="principal" aria-selected="true">Resumen</button>
                                        </li>
                                        {
                                            tipo == 'Contacto' ?
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link text-dark fw-bolder" id="detalles-tab" data-bs-toggle="tab" data-bs-target="#detalles" type="button" role="tab" aria-controls="detalles" aria-selected="false">Detalles</button>
                                                </li>
                                                : null
                                        }
                                    </ul>
                                </div>
                                <div className="row w-auto d-flex justify-content-center p-2">
                                    {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active text-dark fw-bolder" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Resumen</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link text-dark fw-bolder" id="datos-tab" data-bs-toggle="tab" data-bs-target="#datos" type="button" role="tab" aria-controls="datos" aria-selected="false">Detalles</button>
                                        </li>
                                    </ul> */}
                                    <div class="tab-content w-100" id="myTabContent">
                                        <div class="tab-pane fade show active modal-Garantia p-1" id="principal" role="tabpanel" aria-labelledby="principal-tab">

                                            <div className="row mt-3 w-100">
                                                <h6 className="fw-bolder">General</h6>
                                                <div className="col-sm-6" id="div-personeria" style={{ display: 'none' }}>
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4">
                                                            <label className="requerido texto-modal-label fw-bolder">Personería</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <select className="form-select form-select-sm titulo-notificacion fw-bolder h6 m-0"
                                                                id="tipo"
                                                                aria-label="form-select-lg example"
                                                                onChange={e => tipoOnChange(e.target.value)}
                                                                value={tipo}
                                                            >
                                                                <option className="p-2" selected value="">--Seleccionar--</option>
                                                                <option className="p-2" value="Cuenta">Jurídica</option>
                                                                <option className="p-2" value="Contacto">Humana</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (tipo === "Cuenta") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                <div className="col-4">
                                                                    <label className="requerido texto-modal-label">Razón Social</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="razonSocial"
                                                                        name="razonSocial"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setRazonSocial(e.target.value)}
                                                                        value={razonSocial}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (tipo === "Contacto") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">Nombre</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="nombre"
                                                                        name="nombre"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setNombre(e.target.value)}
                                                                        value={nombre}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    (tipo === "Contacto")
                                                        ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">Apellido</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="apellido"
                                                                        name="apellido"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setApellido(e.target.value)}
                                                                        value={apellido}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className="col-sm-6">
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="requerido texto-modal-label">CUIT/CUIL</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <input type="text"
                                                                id="cuitcuil"
                                                                name="cuitcuil"
                                                                className="form-control form-control-sm"
                                                                onChange={e => setCuitcuil(e.target.value)}
                                                                value={cuitcuil}
                                                                placeholder="(sin espacios ni guiones)"
                                                                maxLength="11"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (tipo === "Contacto") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">DNI</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="dni"
                                                                        name="dni"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setDNI(e.target.value)}
                                                                        value={dni}
                                                                        placeholder="---"
                                                                        maxLength="11"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    (tipo === "Contacto") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">Fecha de Nacimiento</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="date"
                                                                        id="fechaNacimiento"
                                                                        name="fechaNacimiento"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setFechaNacimiento(e.target.value)}
                                                                        value={fechaNacimiento}
                                                                        placeholder="FECHA DE NACIMIENTO"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    (tipo === "Contacto") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">Lugar de Nacimiento</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="lugarNacimiento"
                                                                        name="lugarNacimiento"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setLugarNacimiento(e.target.value)}
                                                                        value={lugarNacimiento}
                                                                        placeholder="---"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                                <div className="col-sm-6">
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="requerido texto-modal-label">Correo Electrónico</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <input type="text"
                                                                id="correo"
                                                                name="correo"
                                                                className="form-control form-control-sm"
                                                                onChange={e => setCorreo(e.target.value)}
                                                                value={correo}
                                                                placeholder="---"
                                                                maxLength="99"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="requerido texto-modal-label">Profesión, Oficio o Actividad</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <input type="text"
                                                                id="profesion"
                                                                name="profesion"
                                                                className="form-control form-control-sm"
                                                                onChange={e => setProfesion(e.target.value)}
                                                                value={profesion}
                                                                placeholder="---"
                                                                maxLength="99"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="requerido texto-modal-label">Correo Electrónico para recibir Estado de Cuenta</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <input type="text"
                                                                id="correoEstadoCuenta"
                                                                name="correoEstadoCuenta"
                                                                className="form-control form-control-sm"
                                                                onChange={e => setCorreoEstadoCuenta(e.target.value)}
                                                                value={correoEstadoCuenta}
                                                                placeholder="---"
                                                                maxLength="99"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="requerido texto-modal-label">Teléfono Laboral</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <input type="text"
                                                                id="telefonoLaboral"
                                                                name="telefonoLaboral"
                                                                className="form-control form-control-sm"
                                                                onChange={e => setTelefonoLaboral(e.target.value)}
                                                                value={telefonoLaboral}
                                                                placeholder="---"
                                                                maxLength="99"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (tipo === "Contacto") ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="requerido texto-modal-label">Estado Civil</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                        id="estadoCivil"
                                                                        onChange={e => estadoCivilOnChange(e)}
                                                                        value={estadoCivil}
                                                                        name="colors"
                                                                        options={estadoCivilOpciones}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="BUSCAR..."
                                                                        disabled
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    estadoCivil.value == 2 ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                    <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="texto-modal-label">Nombre Conyuge</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="nombreConyuge"
                                                                        name="nombreConyuge"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setNombreConyuge(e.target.value)}
                                                                        value={nombreConyuge}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                                {
                                                    estadoCivil.value == 2 ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                    <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="texto-modal-label">Tipo de Documento</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                        id="tipoDocumento"
                                                                        onChange={e => tipoDocumentoOnChange(e)}
                                                                        name="colors"
                                                                        options={tiposDocumentos}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="BUSCAR..."
                                                                        value={tipoDocumento}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    estadoCivil.value == 2 ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                    <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="texto-modal-label">Nro. de Documento Cónyuge</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <input type="text"
                                                                        id="docConyuge"
                                                                        name="docConyuge"
                                                                        className="form-control form-control-sm"
                                                                        onChange={e => setDocConyuge(e.target.value)}
                                                                        value={docConyuge}
                                                                        placeholder="---"
                                                                        maxLength="8"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                                {
                                                    estadoCivil.value == 2 ?
                                                        <div className="col-sm-6">
                                                            <div className="row mt-2">
                                                                {/* <div className="col-1">
                                                                    <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="texto-modal-label">Género del Cónyuge</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                        id="sexo"
                                                                        onChange={e => sexoOnChange(e)}
                                                                        name="colors"
                                                                        options={sexoOpciones}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="BUSCAR..."
                                                                        value={sexoConyuge}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }


                                                {
                                                    estadoCivil.value == 2 ?
                                                        <div className="col-sm-6">
                                                            <div className="row  mt-2">
                                                                {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                <div className="col-4 pt-1">
                                                                    <label className="texto-modal-label">Cónyuge Trabaja?</label>
                                                                </div>
                                                                <div className="col-8">
                                                                    <div className="form-check form-switch">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="switchTrabaja"
                                                                            onChange={e => setTrabajaConyuge(e.target.checked)}
                                                                            value={trabajaConyuge}
                                                                            placeholder="---"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                                <div className="col-sm-6 mt-3" id="div-tipo" style={{ display: "none" }}>
                                                    <div className="row mt-2">
                                                        {/* <div className="col-1">
                                                            <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                        </div> */}
                                                        <div className="col-4 pt-1">
                                                            <label className="texto-modal-label">Tipo de Relación</label>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="form-check form-switch">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                    id="contacto"
                                                                    // onChange={e => contactoOnChange(e)}
                                                                    value={tipoRelacion}
                                                                    name="colors"
                                                                    options={tiposDeRelacionesOpciones}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="BUSCAR..."
                                                                    disabled
                                                                >
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-4" id="div-tipo" style={{ display: "none" }}>
                                                    <div className="mb-2">
                                                        <label className="form-label fw-bolder lbl-precalificacion requerido">Tipo de Relación</label>
                                                        <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                            id="contacto"
                                                            // onChange={e => contactoOnChange(e)}
                                                            value={tipoRelacion}
                                                            name="colors"
                                                            options={tiposDeRelacionesOpciones}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Buscar..."
                                                            disabled
                                                        >
                                                        </Select>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 mt-3" id="accionista" style={{ display: 'none' }} >
                                                    <hr className="mt-4 mb-4" />
                                                    <h6 className="fw-bolder">Participación Accionaria</h6>
                                                    <div className="p-2">
                                                        <div className="row">
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4 pt-1">
                                                                        <label className="texto-modal-label">% de Participacion</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="form-check form-switch">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                            <input type="text"
                                                                                id="participacion"
                                                                                name="participacion"
                                                                                className="form-control form-control-sm"
                                                                                onChange={e => setPorcentajeParticipacion(e.target.value)}
                                                                                value={porcentajeParticipacion}
                                                                                maxLength="100"
                                                                                placeholder="%"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            {/* <div className="col-4">
                                                                        <label className="requerido texto-modal-label fw-bolder">Relación</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="form-check form-switch">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                            {/* <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                                id="relacion"
                                                                                onChange={e => relacionOnChange(e)}
                                                                                value={relacion}
                                                                                name="colors"
                                                                                options={relacionesOpciones}
                                                                                className="basic-multi-select"
                                                                                classNamePrefix="select"
                                                                                placeholder="Buscar..."
                                                                            >
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3" id="beneficiario" style={{ display: 'none' }}>
                                                    <hr className="mt-5 mb-2" />
                                                    <h6 className="fw-bolder">Beneficiario</h6>
                                                    <div className="p-2">
                                                        <div className="row">
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4 pt-1">
                                                                        <label className="texto-modal-label">Porcentaje</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="form-check form-switch">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                            <input type="text"
                                                                                id="beneficiario"
                                                                                name="beneficiario"
                                                                                className="form-control form-control-sm"
                                                                                onChange={e => setPorcentajeBeneficiario(e.target.value)}
                                                                                value={porcentajeBeneficiario}
                                                                                maxLength="99"
                                                                                placeholder="%"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3 directorio" id="directorio" style={{ display: 'none' }}>
                                                    <hr className="mt-2 mb-2" />
                                                    <h6 className="fw-bolder">Miembro del Directorio</h6>
                                                    <div className="p-2">
                                                        <div className="row">
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4 pt-1">
                                                                        <label className="texto-modal-label">Cargo</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="form-check form-switch">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                            <input type="text"
                                                                                id="cargo"
                                                                                name="cargo"
                                                                                className="form-control form-control-sm"
                                                                                onChange={e => setCargo(e.target.value)}
                                                                                value={cargo}
                                                                                maxLength="99"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <hr className="mt-4 mb-4" />
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6 className="fw-bolder">Observaciones</h6>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="p-2">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="mb-2">
                                                                            <textarea
                                                                                cols="30"
                                                                                rows="5"
                                                                                id="cargo"
                                                                                name="cargo"
                                                                                className="form-control form-control-sm"
                                                                                onChange={e => setDescripcion(e.target.value)}
                                                                                value={descripcion}
                                                                                maxLength="250"
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade show modal-Garantia p-1" id="detalles" role="tabpanel" aria-labelledby="detalles-tab">
                                            <div id="general">
                                                <div className="row mt-3">
                                                    <h6 className="fw-bolder">Detalles</h6>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Tipo de Sujeto Obligado</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                    id="sujeto"
                                                                    onChange={e => sujetoOnChange(e)}
                                                                    value={tipoSujeto}
                                                                    name="colors"
                                                                    options={sujetoOpciones}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Buscar..."
                                                                    disabled
                                                                >
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Tiene Domicilio Fiscal en el Exterior?</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="form-check form-switch">
                                                                    {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="switch1"
                                                                        onChange={e => setDomicilioExterior(e.target.checked)}
                                                                        value={domicilioExterior}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Tiene Domicilio en USA?</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="switchUSA"
                                                                        onChange={e => setDomicilioUSA(e.target.checked)}
                                                                        value={domicilioUSA}
                                                                        placeholder="---"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        domicilioExterior ? */}
                                                    {/* <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                    {/* <div className="col-4">
                                                                <label className="texto-modal-label">Tiene Domicilio en USA?</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="switchUSA"
                                                                        onChange={e => setDomicilioUSA(e.target.checked)}
                                                                        value={domicilioUSA}
                                                                        placeholder="DOMICILIO USA"
                                                                        maxLength="99"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* : null
                                                    } */}

                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Calle/Nro/Piso/Depto</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <input type="text"
                                                                    id="direccion"
                                                                    name="direccion"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => setDireccion(e.target.value)}
                                                                    value={direccion}
                                                                    placeholder="---"
                                                                    maxLength="99"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Código Postal</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <input type="text"
                                                                    id="cp"
                                                                    name="cp"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => setCP(e.target.value)}
                                                                    value={cp}
                                                                    placeholder="---"
                                                                    maxLength="99"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Ciudad</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <input type="text"
                                                                    id="ciudad"
                                                                    name="ciudad"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => setCiudad(e.target.value)}
                                                                    value={ciudad}
                                                                    placeholder="---"
                                                                    maxLength="99"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 ">
                                                        <div className="row mt-3">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">Provincia/Estado</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <input type="text"
                                                                    id="provincia"
                                                                    name="provincia"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => setProvincia(e.target.value)}
                                                                    value={provincia}
                                                                    placeholder="---"
                                                                    maxLength="99"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-4">
                                                                <label className="texto-modal-label">País/Región</label>
                                                            </div>
                                                            <div className="col-8">
                                                                {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                                    id="provincia"
                                                                    onChange={e => paisOnChange(e)}
                                                                    value={pais}
                                                                    name="provincia"
                                                                    options={opcionesPaises}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="BUSCAR"
                                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                                >
                                                                </Select>
                                                                {/* <input type="text"
                                                                    id="pais"
                                                                    name="pais"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => setPais(e.target.value)}
                                                                    value={pais}
                                                                    placeholder="PAÍS/REGIÓN"
                                                                    maxLength="99"
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        domicilioExterior ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">EIN/SS</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="text"
                                                                            id="ein"
                                                                            name="ein"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setEinss(e.target.value)}
                                                                            value={einss}
                                                                            placeholder="---"
                                                                            maxLength="99"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }

                                                    {
                                                        domicilioUSA == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">NIT</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="text"
                                                                            id="nit"
                                                                            name="nit"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setNit(e.target.value)}
                                                                            value={nit}
                                                                            placeholder="---"
                                                                            maxLength="99"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    <hr className="mt-2 mb-2" />
                                                    <h6 className="fw-bolder">Pep</h6>
                                                    <div className="col-sm-12 mt-3">
                                                        <div className="row">
                                                            {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                            <div className="col-2">
                                                                <label className="texto-modal-label">Persona Expuesta Politicamente?</label>
                                                            </div>
                                                            <div className="col-10">
                                                                <div className="form-check form-switch">
                                                                    {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="switchPersona"
                                                                        onChange={e => setPersonaPolitica(e.target.checked)}
                                                                        value={personaPolitica}
                                                                        placeholder="---"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        personaPolitica == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">Cargo/Funcion/Jerarquia</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="text"
                                                                            id="funcion"
                                                                            name="funcion"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setFuncion(e.target.value)}
                                                                            value={funcion}
                                                                            placeholder="---"
                                                                            maxLength="99"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }

                                                    {
                                                        personaPolitica == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">Ente donde Trabaja</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="text"
                                                                            id="ente"
                                                                            name="ente"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setEnte(e.target.value)}
                                                                            value={ente}
                                                                            placeholder="---"
                                                                            maxLength="99"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        personaPolitica == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">Relacion con la persona expuesta</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="text"
                                                                            id="relacionPersona"
                                                                            name="relacionPersona"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setRelacionPersona(e.target.value)}
                                                                            value={relacionPersona}
                                                                            placeholder="---"
                                                                            maxLength="99"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        personaPolitica == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">Fecha de Alta</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="date"
                                                                            id="fechaAlta"
                                                                            name="fechaAlta"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setFechaAlta(e.target.value)}
                                                                            value={fechaAlta}
                                                                            placeholder="Fecha de Alta"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        personaPolitica == true ?
                                                            <div className="col-sm-6 mt-3">
                                                                <div className="row">
                                                                    {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                    <div className="col-4">
                                                                        <label className="texto-modal-label">Fecha de Baja</label>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                        <input type="date"
                                                                            id="fechaBaja"
                                                                            name="fechaBaja"
                                                                            className="form-control form-control-sm"
                                                                            onChange={e => setFechaBaja(e.target.value)}
                                                                            value={fechaBaja}
                                                                            placeholder="Fecha de Baja"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                    {
                                                        usuario.ALYC == true ?
                                                            <div className="row" id="div-validacion" style={{ display: 'none' }}>
                                                                <hr className="mt-2 mb-2" />
                                                                <div className="col-sm-6 mt-3">
                                                                    <div className="row">
                                                                        {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                        <div className="col-4">
                                                                            <h6 className="fw-bolder">Validación de Identidad</h6>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <span className="d-inline-block float-end bg-light" tabindex="0" data-bs-toggle="tooltip" title="Para completar la validación de identidad, debés tener completo el campo DNI, en la pestaña Resumen">
                                                                                <AlertCircle size="20" className="mx-1" color="#006BFF" />
                                                                            </span>
                                                                            {
                                                                                (usuario.ALYC == true && dni != '' && dni != undefined) ?
                                                                                    <button
                                                                                        type="button"
                                                                                        name="validacion"
                                                                                        className="btn btn-primary active"
                                                                                        id="btn-validacion"
                                                                                        onClick={validarIdentidad}
                                                                                    >
                                                                                        Validar Identidad
                                                                                    </button> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mt-3"></div>
                                                                <div className="col-sm-6 mt-3">
                                                                    <div className="row">
                                                                        {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                        <div className="col-4">
                                                                            <label className="texto-modal-label">Fecha Última Validación</label>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                            <input type="text"
                                                                                id="ciudad"
                                                                                name="ciudad"
                                                                                className="form-control form-control-sm"
                                                                                // onChange={e => setCiudad(e.target.value)}
                                                                                value={fechaValidacion}
                                                                                placeholder="---"
                                                                                maxLength="99"
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mt-3">
                                                                    <div className="row">
                                                                        {/* <div className="col-1">
                                                                        <FontAwesomeIcon icon={faLock} style={{ color: '#1160b7' }} className="ms-1" />
                                                                    </div> */}
                                                                        <div className="col-4">
                                                                            <label className="texto-modal-label">Respuesta Última Validación</label>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            {/* <label className="form-label fw-bolder lbl-precalificacion requerido">Tiene Domicilio Fiscal en el Exterior?</label> */}
                                                                            <input type="text"
                                                                                id="ciudad"
                                                                                name="ciudad"
                                                                                className="form-control form-control-sm"
                                                                                // onChange={e => setCiudad(e.target.value)}
                                                                                value={resultadoValidacion}
                                                                                placeholder="---"
                                                                                maxLength="99"
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            id="btn-relaciones"
                                            type="submit"
                                            name="btnSubmitAlyc"
                                            className="btn btn-primary active mt-4"
                                        >
                                            {
                                                actualizacion == false ?
                                                    "Agregar" : "Actualizar"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
            </div>
            <div className="modal fade bd-example-modal-" id="ModalInactivacion" tabIndex="-1" aria-labelledby="ModalInactivacionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered pad borde-none">
                    <div className="modal-content pad">
                        {/* <div className="modal-header color-header pad">
                            <h6 className="modal-title fw-bolder" id="exampleModalLabel">Cargar Documentacion</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div className="modal-body bg-light pad">
                            <form name="docXcuenta" onSubmit={inactivarRelacion}>
                                <div className="mb-3 h-100 w-100">
                                    {/* <div className="row d-flex justify-content-center">
                                        <div className="col-8">
                                            <h6 className="fw-bolder">Cargar Documentación</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                        <div className="col-4">
                                            <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInput"></button>
                                        </div>
                                    </div> */}
                                    <h5 className="text-center mt-4">¿Desea eliminar la relación?</h5>
                                    <div className="text-center">
                                        <button type="submit"
                                            id="btn-subirdocumentacion"
                                            name="btnSubmit"
                                            className="btn  active btn-primary mt-4 mx-4 ext-center"
                                        >Aceptar</button>
                                        <button type="button"
                                            id="btn-inactivacion"
                                            name="btnSubmit"
                                            className="btn active btn-primary mt-4 mx-4 ext-center"
                                            data-bs-dismiss="modal" aria-label="Close"
                                        >Cancelar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                </div>
            </div>
        </animated.div >
    )
}

export default withRouter(RelacionesVinculacion)