import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { responderEncuenta } from '../Redux/Encuesta'
import { Toast, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { withRouter, useLocation } from 'react-router-dom'
import { obtenerNotificaciones } from '../Redux/Notificaciones'

const ItemDeEncuesta = ({ state, props, cuenta }) => {
    const dispatch = useDispatch()
    const search = useLocation().search;
    const tareaid = new URLSearchParams(search).get('id');
    const [respuestas, setRespuestas] = React.useState([])
    const [encuestaid, setEncuestaid] = React.useState([])
    const [show, setShow] = React.useState(false)
    const [showNotiDoc, setShowNotiDoc] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const resultado = useSelector(store => store.encuesta.resultado)

    React.useEffect(() => {
        if (state.length > 0) {
           // debugger
            setEncuestaid(state[0][3])
            if (document.getElementById("spinner1") != null) {
                document.getElementById("spinner1").style.display = "none"
            }
            if (document.getElementById("btn-enviar") != null) {
                document.getElementById("btn-enviar").style.display = "block"
            }
        }
        if (resultado !== undefined) {
            if (resultado !== '') {
                cargaExito()
            }
        }
    }, [state, resultado])

    const obtenerNotificacionesCuenta = async () => {
        dispatch(obtenerNotificaciones(cuenta))
    }

    const responderEncuesta = (e) => {
        e.preventDefault()
        document.getElementById("btn-enviar").disabled = true
        if (respuestas.length == state.length) {
            dispatch(responderEncuenta(respuestas, encuestaid, tareaid))
            setError(false)
            setLoading(true)
            setMensaje("Cargando...")
            setShow(true)
        } else {
            document.getElementById("btn-enviar").disabled = false
            setLoading(false)
            setMensaje("Debes responder todas las preguntas!")
            setShow(true)
            setError(true)
            setTimeout(() => {
                setShow(false)
                setError(false)
            }, 2000);
        }
    }

    const obtenerRespuesta = (e) => {
        var existe = false
        var valor = {
            id: '',
            valor: '',
            orden: ''
        }
        valor.id = e.id
        valor.valor = e.value
        var orden = state.filter(item => item[0] == e.id).map(item => item[4])
        if(orden.length >= 0){
            valor.orden = orden[0]
        }
        if (e.value != "") {
            if (respuestas.length > 0) {
                respuestas.forEach(element => {
                    if (element.id === valor.id) {
                        existe = true
                        var respuestasAUX = respuestas.filter(item => item.id != valor.id)
                        if (respuestasAUX.length > 0) {
                            respuestasAUX.push(valor)
                            setRespuestas(respuestasAUX)
                        } else {
                            setRespuestas([...respuestas, valor])
                        }
                    }
                });
                if (!existe) {
                    setRespuestas([...respuestas, valor])
                }
            } else {
                setRespuestas([...respuestas, valor])
            }
        }else{
            var respuestasAUX = respuestas.filter(item => item.id != valor.id)
            setRespuestas(respuestasAUX)
        }
    }

    const cargaExito = () => {
        if (resultado === "EXITO") {
            setMensaje("La encuesta fue enviada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            document.getElementById("btn-enviar").disabled = false
            obtenerNotificacionesCuenta()
            setTimeout(() => {
                setShow(false)
                props.history.push('/')
                setTimeout(() => {
                    props.history.go(0)
                }, 10)
            }, 1500);
        }
        else if (resultado === "ERROR") {
            setMensaje("La encuesta no pudo ser enviada!")
            setError(true)
            setLoading(false)
            setShow(true)
            document.getElementById("btn-enviar").disabled = false
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    return (
        <div className="p-4">
            <div className="contenedor-spinner" id="spinner1">
                <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
            <form onSubmit={responderEncuesta}>
                {
                    state.map(item => {
                        return (
                            <div className="mt-3">
                                <label className="requerido">{item[1]}</label>
                                <select className="form-select mt-2" id={item[0]}
                                    onChange={e => obtenerRespuesta(e.target)}
                                >
                                    <option value="" selected>Seleccionar</option>
                                    {item[2].map(i => {
                                        return (
                                            <option value={i.split(";")[0]}>{i.split(";")[1]}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        )
                    })
                }
                <div className="mt-3" style={{ display: "none" }}>
                    <input type="text" value={encuestaid} />
                </div>
                <button type="submit"
                    style={{ display: "none" }}
                    id="btn-enviar"
                    className="btn btn-primary active mt-4">
                    Enviar
                </button>
            </form>
            <div className="row">
                <div className="col-8 col-lg-4 position-fixed bottom-0 end-0 p-5 noti">
                    <Toast className="half-black" show={show} autohide color="lime">
                        <Toast.Body className="text-white">
                            <div className="row p-1 d-flex align-items-center">
                                {
                                    loading ?
                                        <Spinner animation="border" role="status" variant="primary">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        <div className="col-1 mx-1">
                                            {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-2 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                        </div>
                                }

                                <div className="col-10 mt-1 ml-5 mx-2">
                                    {mensaje}
                                </div>
                            </div>
                        </Toast.Body>
                    </Toast>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ItemDeEncuesta)
