import React from 'react'
import Moment from 'moment'
import perfiRandom from '../img/foto-perfil-random.png'
import { useDispatch, useSelector } from 'react-redux'
import {
    obtenerCuenta, obtenerCertificadoPyme, obtenerSociedadesXsocio, obtenerSociedadeDeBolsa, obtenerProvincias,
    actualizarDatosCuenta, actualizarDatosAlyc, obtenerSocios, obtenerPaises, obtenerSociedadeDeBolsaPorSocio, CrearSociedadBolsaSocio,
    modificarSociedadBolsaSocio, inactivarSociedadBolsa, obtenerContactoDeNotificaciones, mensajeActualizacionDatosAlyc, mensajeActualizacionDatosCuenta
} from '../Redux/Cuenta'
import { obtenerBancos, obtenerBancosXsocio, CrearCuentaXsocio, modificarCuentaxSocio, inactivarCuenta } from '../Redux/CuentasBancarias'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faCheckCircle, faTimesCircle, faEllipsisV, faCircle } from '@fortawesome/free-solid-svg-icons'
import { useSpring, animated } from 'react-spring'
import { Toast, Spinner } from 'react-bootstrap'
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import RelacionesVinculacion from './RelacionesVinculacion'
import MiCuenta from './MiCuenta'
import { withRouter } from 'react-router-dom'
import Notificacion from './Notificacion'
import { Trash2, Edit, BatteryCharging, Database, UploadCloud, Folder, Upload, AlertTriangle, PlusSquare, AlertCircle, Download, Eye, Cloud } from "react-feather"
import { actualizarFotoUid } from '../Redux/usuarios'
import { auth, firebase } from '../Firebase'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Cuenta = (props) => {

    //Constantes
    const dispatch = useDispatch()
    const [cuenta, setCuenta] = React.useState(null)
    const [certificados, setCertificados] = React.useState([])
    const [sociedadXbolsa, setSociedadXbolsa] = React.useState({})
    const [nombreSociedad, setNombreSociedad] = React.useState('')
    const [nombreCuentaComitente, setNombreCuentaComitente] = React.useState('')
    const [show, setShow] = React.useState(false)
    const [mensaje, setMensaje] = React.useState('')
    const [error, setError] = React.useState(false)
    const [localidad, setLocalidad] = React.useState('')
    const [calle, setCalle] = React.useState('')
    const [codigoPostal, setCodigoPostal] = React.useState('')
    const [numero, setNumero] = React.useState('')
    const [piso, setPiso] = React.useState('')
    const [depto, setDepto] = React.useState('')
    const [provincia, setProvincia] = React.useState('')
    const [provincias, setProvincias] = React.useState([])
    const [municipio, setMunicipio] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [bancos, setBancos] = React.useState([])
    const [bancosXSocio, setBancosXsocio] = React.useState([])
    const [bancoid, setBancoid] = React.useState({})
    const [socioid, setSocioid] = React.useState('')
    const [cbu, setCbu] = React.useState('')
    const [nroCuenta, setNroCuenta] = React.useState('')
    const [opcionesProvincias, setOpcionesProvincias] = React.useState([]);
    const opcionesBancos = []
    const [bancosOpciones, setBancosOpciones] = React.useState([])
    const [sociosOpciones, setSociosOpciones] = React.useState([])
    const [inscripcionGanancias, setInscripcionGanancias] = React.useState({})
    const [esOtros, setEsOtros] = React.useState(false)
    const [cuentaid, setCuentaid] = React.useState('')
    const [newBanco, setNewBanco] = React.useState({})
    const [iDBanco, setIDBanco] = React.useState('')
    const [newCbu, setNewCbu] = React.useState('')
    const [numCuent, setNumCuenta] = React.useState('')
    const [bancoInactivar, setBancoInactivar] = React.useState('')
    const [cbuInactivar, setCbuInactivar] = React.useState('')
    const [numCuentaInactivar, setNumCuentaInactivar] = React.useState('')
    const [cuentaIDInactivar, setCuentaIDInactivar] = React.useState('')
    const [estadoInactivar, setEstadoInactivar] = React.useState('')
    const [socidadBolsaSocio, setSocidadBolsaSocio] = React.useState([])
    const [opcionesSociedadBolsa, setOpcionesSociedadBolsa] = React.useState([])
    const [sociedadID, setSociedadID] = React.useState({})
    const [cuentaComitente, setCuentaComitente] = React.useState([])
    const [newCuentaComitente, setNewCuentaComitente] = React.useState('')
    const [sociedadBolsaid, setSociedadBolsaid] = React.useState('')
    const [newSociedadValue, setNewSociedadValue] = React.useState({})
    const [sociedadBolsaInactivar, setSociedadBolsaInactivar] = React.useState('')
    const [cuentaComitenteInactivar, setCuentaComitenteInactivar] = React.useState('')
    const [sociedadBolsaidInactivar, setSociedadBolsaidInactivar] = React.useState('')
    const [llamadaSociedadesDeBolsa, setLlamadaSociedadesDeBolsa] = React.useState('')
    //Ref
    const newCbuRef = React.createRef(newCbu);
    const newBancoRef = React.createRef(newBanco);
    const numCuentRef = React.createRef(numCuent);
    const RefCuentaComitent = React.createRef(newCuentaComitente)

    const [pais, setPais] = React.useState([])
    const [paises, setPaises] = React.useState([])
    const [opcionesPaises, setOpcionesPaises] = React.useState([])
    const [llamadaPais, setLlamadaPais] = React.useState(false)
    //Alyc
    const [estadoSocio, setEstadoSocio] = React.useState('')
    const [actividadEsperada, setActividadEsperada] = React.useState('')
    const [actividadValor, setActividadValor] = React.useState('')
    const [operaPorCuenta, setOperaPorCuenta] = React.useState('')
    const [montoEstimado, setMontoEstimado] = React.useState('0')
    const [proposito, setProposito] = React.useState([])
    const [otros, setOtros] = React.useState('')
    const [metodoEmision, setMetodoEmision] = React.useState('')
    const [fechaContrato, setFechaContrato] = React.useState('')
    const [fechaInscripcion, setFechaInscripcion] = React.useState('')
    const [numeroInscripcion, setNumeroInscripcion] = React.useState('')
    const [origenFondos, setOrigenFondos] = React.useState('')
    const [tipoSujeto, setTipoSujeto] = React.useState([])
    //Llamadas Api
    const [llamadaSociedad, setLlamadaSociedad] = React.useState(false)
    const [llamadaSociedadXbolsa, setLlamadaSociedadXbolsa] = React.useState(false)
    const [llamadaCertificados, setLlamadaCertificados] = React.useState(false)
    const [llamadaProvincias, setLlamadaProvincias] = React.useState(false)
    const [llamadaBancos, setLlamadaBancos] = React.useState(false)
    const [llamadaBancosXsocio, setLlamadaBancosXsocio] = React.useState(false)
    const [llamadaSociedadXsocio, setLlamadaSociedadXsocio] = React.useState(false)
    const [llamadaSocios, setLlamadaSocios] = React.useState(false)
    const [seteoSocideda, setSeteoSociedad] = React.useState(false)
    const [datosCompletados, setDatosCompletados] = React.useState(false)
    const [usuario, setUsuario] = React.useState(false)
    const [razonSocail, setRazonSocial] = React.useState('')
    const [actualizaALYC, setActualizaALYC] = React.useState(false)
    const [llamadaDatosCuenta, setLlamadaDatosCuenta] = React.useState(false)
    const [contactoNotificaciones, setContactoNotificaciones] = React.useState([])
    const [contactosNotificaciones, setContactosNotificaciones] = React.useState([])
    const [contactosNotificacionesOpciones, setContactosNotificacionesOpciones] = React.useState([])
    const [llamadaContactosNoti, setLlamadaContactosNoti] = React.useState(false)
    const [fotoURL, setFotoURL] = React.useState()
    //Estados
    const activo = useSelector(store => store.usuarios.activo)
    const fotoselector = useSelector(store => store.usuarios.photoURL)
    const accountid = useSelector(store => store.usuarios.accountid)
    const cuentaSelector = useSelector(store => store.cuenta.cuenta)
    const certificadosSelector = useSelector(store => store.cuenta.certificadosPymes)
    const sociedadesXsocioSelector = useSelector(store => store.cuenta.sociedadXsocio)
    const sociedadDebolsa = useSelector(store => store.cuenta.sociedadDeBolsa)
    const provinciasSelector = useSelector(store => store.cuenta.provincias)
    const paisesSelector = useSelector(store => store.cuenta.paises)
    const actualizacionDatosSelector = useSelector(store => store.cuenta.actualizacionDatos)
    const actualizacionDatosAlycSelector = useSelector(store => store.cuenta.actualizacionDatosAlyc)
    const bancosSelector = useSelector(store => store.bancos.Bancos)
    const bancosXsocioSelector = useSelector(store => store.bancos.BancosPorSocios)
    const crarBancoSelector = useSelector(store => store.bancos.crearCuenta)
    const sociosSelector = useSelector(store => store.cuenta.socios)
    const usuarioSelector = useSelector(store => store.usuarios.user)
    const modificarCuentaSocio = useSelector(store => store.bancos.modificarCuenta)
    const SociedadeBolsaSocioSelector = useSelector(store => store.cuenta.sociedadBolsaSocio)
    const SociedadesDeBolsaSelector = useSelector(store => store.cuenta.sociedadesDeBolsa)
    const crearSociedadCuentaSocio = useSelector(store => store.cuenta.crearCuentaSociedad)
    const modificarSociedadXSocios = useSelector(store => store.cuenta.modificarSociedadXSocio)
    const inactivarCuentas = useSelector(store => store.bancos.inactivarCuentaMensaje)
    const inactivarCuentaSociedad = useSelector(store => store.cuenta.inactivarCuentaSocioMensaje)
    const contactosNotificacionesSelector = useSelector(store => store.cuenta.contactosNotificaciones)

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    React.useEffect(async () => {
        if (activo) {
            // debugger
            if (Object.keys(cuentaSelector).length > 0 && datosCompletados === false) {
                setCuenta(cuentaSelector)
                setDatosCompletados(true)
            } else if (Object.keys(cuentaSelector).length == 0 && datosCompletados === false && llamadaDatosCuenta == false) {
                obtenerInfoMiCuenta()
                setLlamadaDatosCuenta(true)
            }

            if (provinciasSelector.length > 0 && cuenta != undefined) {
                // setProvincias(provinciasSelector)
                completarOpcionesProvincias(provinciasSelector)
                completarProvincias(provinciasSelector, cuenta)
            } else if (provinciasSelector.length === 0 && llamadaProvincias === false) {
                setLlamadaProvincias(true)
                obtenerTodasProvincias()
            }

            if (paisesSelector.length > 0 && cuenta != undefined) {
                // setProvincias(provinciasSelector)
                completarOpcionesPaises(paisesSelector)
                completarPaises(paisesSelector, cuenta)
            } else if (paisesSelector.length === 0 && llamadaPais === false) {
                setLlamadaPais(true)
                obtenerTodosPaises()
            }

            if (datosCompletados === false && Object.keys(cuentaSelector).length > 0) {
                completarDatos(cuentaSelector)
                setDatosCompletados(true)
            }

            if (Object.keys(sociedadesXsocioSelector).length != 0 && llamadaSociedad === true) {
                setSociedadXbolsa(sociedadesXsocioSelector)
            } else if (llamadaSociedad === false) {
                obtenerSociedadXbolsa()
                setLlamadaSociedad(true)
            }

            if (certificadosSelector.length > 0 && llamadaCertificados === true) {
                setCertificados(certificadosSelector)
                document.getElementById("spinner1").style.display = "none"
            } else if (llamadaCertificados === false) {
                obtenerTodosCertificadosPymes()
                setLlamadaCertificados(true)
                setTimeout(() => {
                    if (document.getElementById("spinner1") !== null) {
                        document.getElementById("spinner1").hidden = true;
                    }
                }, 3000);
            }

            if (Object.keys(sociedadDebolsa).length === 0 && llamadaSociedadXbolsa === false) {
                if (Object.keys(sociedadXbolsa).length > 0) {
                    obtenerSociedadDeBolsa(sociedadXbolsa[0]._new_sociedaddebolsa_value)
                    setLlamadaSociedadXbolsa(true)
                    if (document.getElementById("spinner2") !== null) {
                        document.getElementById("spinner2").hidden = true;
                    }
                }
            } else if (Object.keys(sociedadDebolsa).length > 0 && llamadaSociedadXbolsa === true) {
                setNombreCuentaComitente(sociedadXbolsa[0].new_cuentacomitente)
                if (document.getElementById("spinner1") !== null) {
                    document.getElementById("spinner1").hidden = true;
                }
            }

            if (sociedadDebolsa.length > 0 && seteoSocideda === false) {
                setNombreSociedad(sociedadDebolsa[0].new_name)
                setSeteoSociedad(true)
                if (document.getElementById("spinner2") !== null) {
                    document.getElementById("spinner2").hidden = true;
                }
            } else {
                setTimeout(() => {
                    if (document.getElementById("spinner2") !== null) {
                        document.getElementById("spinner2").hidden = true;
                    }
                }, 3000);
            }

            if (bancosSelector.length > 0 && llamadaBancos === true) {
                setBancos(bancosSelector)
                completarOpcionesBancos(bancosSelector)
            } else if (llamadaBancos === false) {
                setLlamadaBancos(true)
                obtenerTodoBancos()
            }

            if (bancosXsocioSelector.length > 0 && llamadaBancosXsocio === true) {
                setBancosXsocio(bancosXsocioSelector.filter(item => item.statecode !== '1'))
                if (document.getElementById("spinner3") !== null) {
                    document.getElementById("spinner3").hidden = true;
                }

            } else if (llamadaBancosXsocio === false) {
                setLlamadaBancosXsocio(true)
                obtenerTodosBancosXsocio()
                setTimeout(() => {
                    if (document.getElementById("spinner3") !== null) {
                        document.getElementById("spinner3").hidden = true;
                    }
                }, 3000);
            }

            if (contactosNotificacionesSelector.length > 0 && llamadaContactosNoti === true) {
                setContactosNotificaciones(contactosNotificacionesSelector)
                completarOpcionesContactosNotificaciones(contactosNotificacionesSelector)
                contactosNotificacionesSelector.filter(item => item.contactid == cuentaSelector._new_contactodenotificaciones_value).map(item => {
                    var valor = `${item.fullname} - ${item["aa.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"]}`
                    setContactoNotificaciones({ value: item.contactid, label: valor })
                })
            } else if (llamadaContactosNoti === false) {
                if (accountid !== undefined) {
                    setLlamadaContactosNoti(true)
                    obtenerTodosContactosNotificaciones()
                }
            }

            if (actualizacionDatosSelector !== undefined) {
                if (actualizacionDatosSelector !== '') {
                    actualizacionExito(actualizacionDatosSelector)
                }
            }

            if (actualizacionDatosAlycSelector !== undefined) {
                if (actualizacionDatosAlycSelector !== '') {
                    actualizacionExito(actualizacionDatosAlycSelector)
                }
            }

            if (crarBancoSelector !== undefined) {
                if (crarBancoSelector !== '') {
                    creacionExito(crarBancoSelector)
                }
            }
            if (usuarioSelector) {

                setUsuario(usuarioSelector)
            }
            if (fotoselector) {
                setFotoURL(fotoselector)
            }
            if (modificarCuentaSocio !== undefined) {
                if (modificarCuentaSocio !== '') {
                    modificacionExito(modificarCuentaSocio)
                }
            }
            if (SociedadeBolsaSocioSelector.length > 0 && llamadaSociedadXsocio === true) {
                debugger
                setSocidadBolsaSocio(SociedadeBolsaSocioSelector.filter(item => item.statecode == '0'))
                if (document.getElementById("spinner3") !== null) {
                    document.getElementById("spinner3").hidden = true;
                }
            } else if (llamadaSociedadXsocio === false) {
                if (accountid !== null && accountid !== undefined) {
                    setLlamadaSociedadXsocio(true)
                    obtenerSociedadeDeBolsaPorSocios()
                    setTimeout(() => {
                        if (document.getElementById("spinner3") !== null) {
                            document.getElementById("spinner3").hidden = true;
                        }
                    }, 3000);
                }
            }

            if (SociedadesDeBolsaSelector !== undefined && SociedadesDeBolsaSelector.length > 0) {
                selectSociodadBolsaSocio(SociedadesDeBolsaSelector)
            } else if (llamadaSociedadesDeBolsa == false) {
                setLlamadaSociedadesDeBolsa(true)
                obtenerTodasSociedadDeBolsa()
            }


            if (crearSociedadCuentaSocio !== undefined) {
                if (crearSociedadCuentaSocio !== '') {
                    creacionCuentaSociedadExito(crearSociedadCuentaSocio)
                }
            }
            if (modificarSociedadXSocios !== undefined) {
                if (modificarSociedadXSocios !== '') {
                    modificacionSociedadExito()
                }
            }
            if (inactivarCuentas !== undefined && inactivarCuentas !== '') {
                inactivarCuentasExito()
            }

            if (inactivarCuentaSociedad !== undefined && inactivarCuentaSociedad !== '') {
                inactivarCuentaSociedadExito()
            }

            // if (iDBanco != '') {
            //     bancosOpciones.filter(banco => banco.value === iDBanco).map(item => {
            //         setNewBanco({ value: item.value, label: item.label })
            //     })
            // }
        }

    }, [activo, cuentaSelector, provinciasSelector, sociedadXbolsa, actualizacionDatosSelector, bancosSelector, bancosXsocioSelector,
        actualizacionDatosAlycSelector, sociedadDebolsa, crarBancoSelector, sociosSelector, usuarioSelector, paisesSelector,
        llamadaBancosXsocio, modificarCuentaSocio, SociedadeBolsaSocioSelector, crearSociedadCuentaSocio, modificarSociedadXSocios,
        inactivarCuentas, inactivarCuentaSociedad, contactosNotificacionesSelector, iDBanco, llamadaSociedadXsocio, SociedadesDeBolsaSelector, fotoselector, fotoURL])


    const obtenerTodosCertificadosPymes = async () => {
        dispatch(obtenerCertificadoPyme(accountid))
    }

    const obtenerSociedadXbolsa = async () => {
        dispatch(obtenerSociedadesXsocio(accountid))
    }

    const obtenerSociedadDeBolsa = async (socioId) => {
        dispatch(obtenerSociedadeDeBolsa(socioId))
    }

    const obtenerTodasSociedadDeBolsa = async () => {
        dispatch(obtenerSociedadeDeBolsa())
    }

    const obtenerTodosContactosNotificaciones = async () => {
        dispatch(obtenerContactoDeNotificaciones(accountid))
    }

    const obtenerInfoMiCuenta = async () => {
        obtenerCuenta(accountid)
    }

    const obtenerTodasProvincias = async () => {
        dispatch(obtenerProvincias())
    }

    const obtenerTodosPaises = async () => {
        dispatch(obtenerPaises())
    }

    const obtenerTodoBancos = async () => {
        dispatch(obtenerBancos())
    }

    const obtenerTodosBancosXsocio = async () => {
        dispatch(obtenerBancosXsocio(accountid))
    }

    const obtenerTodosSocios = async () => {
        dispatch(obtenerSocios())
    }
    const obtenerSociedadeDeBolsaPorSocios = async () => {
        dispatch(obtenerSociedadeDeBolsaPorSocio(accountid))
    }

    const completarOpcionesContactosNotificaciones = (contactosNotificaciones) => {
        //debugger
        const opcionesContactos = []
        contactosNotificaciones.forEach(item => {
            var nombre = `${item.fullname} - ${item["aa.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"]}`
            var contactoNotificaciones = { value: item.contactid, label: nombre }
            opcionesContactos.push(contactoNotificaciones);
        });
        setContactosNotificacionesOpciones(opcionesContactos)
    }

    const completarDatos = (cuentas) => {
        if (cuentas != undefined) {
            //General
            setCuenta(cuentas)
            setRazonSocial(cuentas.Name)
            setCalle(cuentas.address1_line1)
            setNumero(cuentas.new_direccion1numero)
            setPiso(cuentas.address1_name)
            setDepto(cuentas.new_direccion1depto)
            setLocalidad(cuentas.new_localidad)
            setMunicipio(cuentas.address1_county)
            setCodigoPostal(cuentas.address1_postalcode)
            setTelefono(cuentas.telephone2)
            //Alyc
            ArmarEstadoSocio(cuentas.new_estadodelsociocalyc)
            ArmarActividad(cuentas.new_actividadesperadadelacuenta)
            ArmarOpera(cuentas.new_operaporcuentapropia)
            if (cuentas.new_montoestimadoainvertir != null) {
                setMontoEstimado(cuentas.new_montoestimadoainvertir)
            }
            ArmarProposito(cuentas.new_propsitodelacuenta)
            // setOtros(cuentas.new_otros)
            ArmarMetodoEmision(cuentas.new_metododeemision)
            if (cuentas.new_fechacontratosocial != undefined) {
                if (cuentas.new_fechacontratosocial.includes('T')) {
                    var fecha = cuentas.new_fechacontratosocial.split('T')
                    setFechaContrato(fecha[0])
                }
            }
            ArmarOrigenDeFondos(cuentas.new_origendelosfondos)
            setOtros(cuentas.new_otros)
            setFechaInscripcion(cuentas.new_fechaddeinscripcinregistral)
            setNumeroInscripcion(cuentas.new_numerodeinscripcinregistral)
            if (cuentas.new_inscripcionganancias != null) {
                setInscripcionGanancias({ value: cuentas.new_inscripcionganancias, label: obtenerValorInscripcionGanancias(cuentas.new_inscripcionganancias) })
            }
            provincias.filter(prov => prov.new_provinciaid === cuentas._new_provincia_value).map(item => {
                setProvincia({ value: item.new_provinciaid, label: item.new_name })
            })
            contactosNotificaciones.filter(item => item.contactid == cuentas._new_contactodenotificaciones_value).map(item => {
                var valor = `${item.fullname} - ${item["aa.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"]}`
                setContactoNotificaciones({ value: item.contactid, label: valor })
            })
            paises.filter(pai => pai.new_paisid === cuentas._new_pais_value).map(item => {
                setPais({ value: item.new_paisid, label: item.new_name })
            })
            setTipoSujeto({ value: cuentas.new_tiposujetoobligado, label: obtenerValorTipoSujeto(cuentas.new_tiposujetoobligado) })
        }
    }

    const completarProvincias = (provinciasS, cuenta) => {
        provinciasS.filter(prov => prov.new_provinciaid === cuenta._new_provincia_value).map(item => {
            setProvincia({ value: item.new_provinciaid, label: item.new_name })
        })
    }

    const completarPaises = (paisesS, cuenta) => {
        paisesS.filter(pai => pai.new_paisid === cuenta._new_pais_value).map(item => {
            setPais({ value: item.new_paisid, label: item.new_name })
        })
    }

    const actualizarDatos = (e) => {
        e.preventDefault()
        if (localidad !== null) {
            if (!localidad.trim()) {
                setLocalidad(localidad)
            }
        }
        document.getElementById("btn-cuenta").disabled = true
        dispatch(actualizarDatosCuenta(accountid, telefono, calle, numero, piso, depto, provincia, localidad, municipio, codigoPostal, inscripcionGanancias, pais, "", "", "", "", "", contactoNotificaciones))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const completarOpcionesProvincias = (provincias) => {
        var opciones = []
        provincias.forEach(prov => {
            var provincia = { value: prov.new_provinciaid, label: prov.new_name }
            opciones.push(provincia);
        });
        setOpcionesProvincias(opciones)
    }

    const completarOpcionesPaises = (paises) => {
        var opciones = []
        paises.forEach(pais => {
            var Pais = { value: pais.new_paisid, label: pais.new_name }
            opciones.push(Pais);
        });
        setOpcionesPaises(opciones)
    }

    const completarOpcionesBancos = (bancos) => {
        const opcionesBancos = []
        bancos.forEach(item => {
            var banco = { value: item.new_bancoid, label: item.new_name }
            opcionesBancos.push(banco);
        });
        setBancosOpciones(opcionesBancos)
    }
    const selectSociodadBolsaSocio = (array) => {
        const opcionesSociedadBolsa = []
        if (array.length >= 1) {
            array.forEach(item => {
                let sociedad = { label: item.new_name, value: item.new_sociedaddebolsaid }
                opcionesSociedadBolsa.push(sociedad)
            })
        }
        let hash = {};
        setOpcionesSociedadBolsa(opcionesSociedadBolsa)
        // setOpcionesSociedadBolsa(opcionesSociedadBolsa.filter(i => hash[i.label] ? false : hash[i.label] = true))
    }
    const completarOpcionesSocios = (socios) => {
        const opcionesSocio = []
        socios.forEach(item => {
            var socio = { value: item.Accountid, label: item.Name }
            opcionesSocio.push(socio);
        });
        setSociosOpciones(opcionesSocio)
    }

    const armarOpcionMultiple = (arrayOpciones = []) => {
        let opcion = null

        if (arrayOpciones.length >= 1) {
            arrayOpciones.forEach(element => {
                if (opcion === null) {
                    opcion = element.value;
                } else {
                    opcion = opcion + ',' + element.value
                }
            });
        }

        return opcion
    }

    const actualizarDatosInfoAlyc = (e) => {
        e.preventDefault()
        let actividad = null
        let propositoCuenta = null
        let origenes = null
        let metodos = null
        if (actividadEsperada.length > 0) {
            actividad = armarOpcionMultiple(actividadEsperada)
        }
        if (proposito.length > 0) {
            propositoCuenta = armarOpcionMultiple(proposito)
        }
        if (origenFondos.length > 0) {
            origenes = armarOpcionMultiple(origenFondos)
        }
        if (metodoEmision.length > 0) {
            metodos = armarOpcionMultiple(metodoEmision)
        }
        setActualizaALYC(true)
        document.getElementById("btm-alyc").disabled = true
        dispatch(actualizarDatosAlyc(accountid, estadoSocio, actividad, operaPorCuenta, montoEstimado, propositoCuenta, otros, metodos,
            fechaContrato, fechaInscripcion, numeroInscripcion, origenes, tipoSujeto))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const crearCuentaxSocio = (e) => {
        e.preventDefault()
        console.log(bancoid)
        if (bancoid === '') {
            setMensaje("El banco es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        } else {
        }
        if (cbu === '') {
            setMensaje("El cbu es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (nroCuenta === '') {
            setMensaje("El numero de cuenta es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        document.getElementById("btn-cuentabancaria").disabled = true
        dispatch(CrearCuentaXsocio(accountid, bancoid.value, cbu, nroCuenta))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const obtenerID = (id) => {
        newCbuRef.current.focus()
        numCuentRef.current.focus()
        setCuentaid(id)
        bancosXSocio.filter(banco => banco.new_bancosporsocioid === id).map(item => {
            bancosOpciones.filter(banco => banco.value === item._new_banco_value).map(item => {
                setNewBanco({ value: item.value, label: item.label })
            })
            setIDBanco(item._new_banco_value)
            setNewCbu(item.new_cbu)
            setNumCuenta(item.new_numerodecuenta)
        })
    }

    const obtenerIDSociedadXSocio = (sociedadId) => {
        RefCuentaComitent.current.focus()
        setSociedadBolsaid(sociedadId)
        socidadBolsaSocio.filter(sociedad => sociedad.new_sociedaddebolsaporsocioid === sociedadId).map(item => {
            setNewCuentaComitente(item.new_cuentacomitente)
            setNewSociedadValue({ label: item['_new_sociedaddebolsa_value@OData.Community.Display.V1.FormattedValue'], value: item._new_sociedaddebolsa_value })

        })
    }

    const modificarCuenta = (e) => {
        e.preventDefault()
        if (newBanco.value === '') {
            setMensaje("El banco es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (newCbu === '') {
            setMensaje("El cbu es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (numCuent === '') {
            setMensaje("El numero de cuenta es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        document.getElementById("btn-modificarbanco").disabled = true
        dispatch(modificarCuentaxSocio(accountid, newBanco.value, newCbu, numCuent, cuentaid))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }


    const inactivarCuentasEstado = (bancoValue, cBU, numCuenta, cuentaid, estado) => {
        setBancoInactivar(bancoValue)
        setCbuInactivar(cBU)
        setNumCuentaInactivar(numCuenta)
        setCuentaIDInactivar(cuentaid)
        setEstadoInactivar(estado)
    }

    const inactivarCuentaExito = () => {
        document.getElementById("btn-eliminarbanco").disabled = true
        dispatch(inactivarCuenta(accountid, bancoInactivar, cbuInactivar, numCuentaInactivar, cuentaIDInactivar, estadoInactivar))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const inactivarSociedadEstado = (sociedadDeBolsa, cuentaComitente, sociedadDeBolsaPorSocioid, estado) => {
        setSociedadBolsaInactivar(sociedadDeBolsa)
        setCuentaComitenteInactivar(cuentaComitente)
        setSociedadBolsaidInactivar(sociedadDeBolsaPorSocioid)
        setEstadoInactivar(estado)
    }

    const inactivarSociedadEstadoExito = () => {
        document.getElementById("btn-elmsociedad").disabled = true
        dispatch(inactivarSociedadBolsa(accountid, sociedadBolsaInactivar, cuentaComitenteInactivar, sociedadBolsaidInactivar, estadoInactivar))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const crearSociedadBolsaSocio = (e) => {
        e.preventDefault()
        if (sociedadID.value === '') {
            setMensaje("Sociedad de Bolsa es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (cuentaComitente === '') {
            setMensaje("Cuenta Comitente es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (parseInt(cuentaComitente) > 2147483647) {
            setMensaje("El numero de cuenta comitentes tiene que ser mas chico!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }

        document.getElementById("btn-sociedad").disabled = true
        dispatch(CrearSociedadBolsaSocio(accountid, sociedadID.value, cuentaComitente))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)

    }

    const modificarSociedadBolsa = (e) => {
        e.preventDefault()
        //debugger
        if (newSociedadValue.value === '') {
            setMensaje("Sociedad de Bolsa es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (newCuentaComitente === '') {
            setMensaje("Cuenta Comitente es requerido!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        if (parseInt(newCuentaComitente) > 2147483647) {
            setMensaje("El numero de cuenta comitentes no puede superar 10 caracteres!")
            setError(true)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
            return
        }
        document.getElementById("btn-modsocieda").disabled = true
        dispatch(modificarSociedadBolsaSocio(accountid, newSociedadValue.value, newCuentaComitente, sociedadBolsaid))
        setLoading(true)
        setMensaje("Cargando...")
        setShow(true)
    }

    const modificacionExito = (resultadoModificacion) => {
        if (resultadoModificacion === 'EXITO') {
            if (document.getElementById("btn-modificarbanco") != null) {
                document.getElementById("btn-modificarbanco").disabled = false
            }
            setMensaje("Cuenta bancaria modificada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaBancosXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputModificarCuenta").click();
                limpiarFormModificarCuenta();
            }, 2000);
        } else if (resultadoModificacion === 'ERROR') {
            if (document.getElementById("btn-modificarbanco") != null) {
                document.getElementById("btn-modificarbanco").disabled = false
            }
            setMensaje("La cuenta bancaria no se pudo modificar!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const creacionExito = (resultadoCreacion) => {
        if (resultadoCreacion === 'EXITO') {
            if (document.getElementById("btn-cuentabancaria") != null) {
                document.getElementById("btn-cuentabancaria").disabled = false
            }
            setMensaje("Cuenta bancaria creada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaBancosXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputCuenta").click();
                limpiarFormCuentaxSocio();
            }, 2000);
        } else if (resultadoCreacion === 'ERROR') {
            if (document.getElementById("btn-cuentabancaria") != null) {
                document.getElementById("btn-cuentabancaria").disabled = false
            }
            setMensaje("La cuenta bancaria no se pudo crear!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }
    const creacionCuentaSociedadExito = (resultadoCreacion) => {
        if (resultadoCreacion === 'EXITO') {
            if (document.getElementById("btn-sociedad") != null) {
                document.getElementById("btn-sociedad").disabled = false
            }
            setMensaje("Sociedad de bolsa creada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaSociedadXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputSociedadDeBolsa").click();
                limpiarFormSociedadBolsaXSocio()
            }, 2000);
        } else if (resultadoCreacion === 'ERROR') {
            if (document.getElementById("btn-sociedad") != null) {
                document.getElementById("btn-sociedad").disabled = false
            }
            setMensaje("La sociedad de bolsa no se pudo crear!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const modificacionSociedadExito = () => {
        if (modificarSociedadXSocios === 'EXITO') {
            if (document.getElementById("btn-modsocieda") != null) {
                document.getElementById("btn-modsocieda").disabled = false
            }
            setMensaje("La sociedad de bolsa se modificó con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaSociedadXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputModificarSociedad").click();
                limpiarFormModificarSociedadXSocio()
            }, 2000);
        } else if (modificarSociedadXSocios === 'ERROR') {
            if (document.getElementById("btn-modsocieda") != null) {
                document.getElementById("btn-modsocieda").disabled = false
            }
            setMensaje("La sociedad de bolsa no se pudo modificar!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const inactivarCuentasExito = () => {
        if (inactivarCuentas === 'EXITO') {
            if (document.getElementById("btn-eliminarbanco") != null) {
                document.getElementById("btn-eliminarbanco").disabled = false
            }
            setMensaje("Cuenta bancaria eliminada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaBancosXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputCuentaInactivar").click();
            }, 2000);
        } else if (inactivarCuentas === 'ERROR') {
            if (document.getElementById("btn-eliminarbanco") != null) {
                document.getElementById("btn-eliminarbanco").disabled = false
            }
            setMensaje("La cuenta bancaria no se pudo eliminar!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const inactivarCuentaSociedadExito = () => {
        if (inactivarCuentaSociedad === 'EXITO') {
            if (document.getElementById("btn-elmsociedad") != null) {
                document.getElementById("btn-elmsociedad").disabled = false
            }

            setMensaje("Sociedad de bolsa eliminada con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setLlamadaSociedadXsocio(false)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInputInactivarSociedad").click();
            }, 2000);
        } else if (inactivarCuentaSociedad === 'ERROR') {
            if (document.getElementById("btn-elmsociedad") != null) {
                document.getElementById("btn-elmsociedad").disabled = false
            }
            setMensaje("La sociedad de bolsa no se pudo eliminar!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const actualizacionExito = (resultadoActualizacion) => {
        if (resultadoActualizacion === 'EXITO') {
            obtenerInfoMiCuenta()
            if (document.getElementById("btn-cuenta") != null) {
                document.getElementById("btn-cuenta").disabled = false
            }
            if (document.getElementById("btm-alyc") != null) {
                document.getElementById("btm-alyc").disabled = false
            }
            dispatch(mensajeActualizacionDatosAlyc())
            dispatch(mensajeActualizacionDatosCuenta())
            setMensaje("Datos actualizados con exito!")
            setError(false)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 1500);
            setTimeout(() => {
                document.getElementById("myInput").click();
            }, 2000);
        } else if (resultadoActualizacion === 'ERROR') {
            if (document.getElementById("btn-cuenta") != null) {
                document.getElementById("btn-cuenta").disabled = false
            }
            if (document.getElementById("btm-alyc") != null) {
                document.getElementById("btm-alyc").disabled = false
            }
            dispatch(mensajeActualizacionDatosAlyc())
            dispatch(mensajeActualizacionDatosCuenta())
            setDatosCompletados(false)
            setActualizaALYC(false)
            setMensaje("Los datos no se actualizaron!")
            setError(true)
            setLoading(false)
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 3000);
        }
    }

    const limpiarFormCuentaxSocio = () => {
        setBancoid({});
        setCbu("");
        setNroCuenta("");
    };

    const limpiarFormModificarCuenta = () => {
        setNewBanco({});
        setNewCbu("");
        setNumCuenta("");
        setCuentaid("");
        setNewSociedadValue({});
    }

    const limpiarFormSociedadBolsaXSocio = () => {
        setSociedadID({});
        setCuentaComitente("")
    }

    const limpiarFormModificarSociedadXSocio = () => {
        setNewSociedadValue({});
        setNewCuentaComitente("");
        setSociedadBolsaid("");
    }

    const EstadoDelSocioOpciones = [
        { value: '100000000', label: 'Inicial' },
        { value: '100000001', label: 'Aprobado para Enviar a Legacy' },
        { value: '100000002', label: 'Enviado a Legacy' }
    ]

    const ActividadesEsperadasOpciones = [
        { value: '100000000', label: 'Compra-Venta de Títulos Públicos' },
        { value: '100000001', label: 'Compra-Venta de Acciones Caución' },
        { value: '100000002', label: 'Colocadora/Tomadora' },
        { value: '100000003', label: 'Opciones' },
        { value: '100000004', label: 'Futuros' },
        { value: '100000005', label: 'FCI-FF' },
        { value: '100000006', label: 'Otros' }
    ]

    const OperaOpciones = [
        { value: 'false', label: 'No' },
        { value: 'true', label: 'Si' }
    ]

    const PropositoOpciones = [
        { value: '100000000', label: 'Inversión' },
        { value: '100000001', label: 'Ahorro' },
        { value: '100000002', label: 'Financiamiento' }
    ]

    const MetodosEmisionesOpciones = [
        { value: '100000000', label: 'Teléfono' },
        // { value: '100000001', label: 'Presencial' },
        { value: '100000002', label: 'E-mail' },
        { value: '100000003', label: 'Online' }
    ]

    const GananciasOpciones = [
        { value: '100000000', label: 'Inscripto' },
        { value: '100000001', label: 'No Inscripto' },
        { value: '100000002', label: 'No Responsable' },
        { value: '100000003', label: 'Extranjero' },
        { value: '100000004', label: 'Monotributo' },
        { value: '100000005', label: 'Inscripto Exento' }
    ]

    const OrigenFondosOpciones = [
        { value: '100000000', label: 'Ahorros de mi Actividad' },
        { value: '100000002', label: 'Venta de Bienes Registrables' },
        { value: '100000001', label: 'Otros' }
    ]

    const sujetoOpciones = [
        { value: '100000000', label: 'Sujeto Obligado' },
        { value: '100000001', label: 'Sujeto No Obligado' }
    ]

    const esatdoSocioOnChange = (value) => {
        setEstadoSocio(value)
    }

    const actividadEsperadaOnChange = (value) => {
        setActividadEsperada(value)
        setActividadValor(value.value)
    }

    const operaOnChange = (value) => {
        setOperaPorCuenta(value)
    }

    const ganciasOnChange = (value) => {
        setInscripcionGanancias(value)
    }

    const origenesOnChange = (value) => {
        setOrigenFondos(value)
        var contieneOtro = false
        value.forEach(element => {
            if (element.value === "100000001") {
                contieneOtro = true
            }
        });
        setEsOtros(contieneOtro)
    }

    const propositoOnChange = (value) => {
        setProposito(value)
    }

    const metodoEmisionOnChange = (value) => {
        setMetodoEmision(value)
    }

    const bancoOnChange = (value) => {
        setBancoid(value)
    }
    const bancoOnChangeModificarCuenta = (value) => {
        setNewBanco(value)
    }
    const sociedadOnChange = (value) => {
        setSociedadID(value)
    }
    const sociedadOnChangeModificar = (value) => {
        setNewSociedadValue(value)
    }
    const socioOnChange = (value) => {
        setSocioid(value)
    }

    const provinciaOnChange = (value) => {
        setProvincia(value)
    }

    const paisOnChange = (value) => {
        setPais(value)
    }

    const sujetoOnChange = (value) => {
        setTipoSujeto(value)
    }

    const obtenerRazonParaElEstado = (razon) => {
        switch (razon) {
            case "1":
                return "Aprobado"
            case "100000000":
                return "Analisis"
            case "100000001":
                return "Inicio"
            case "100000002":
                return "Reprobado"
            default:
                return "Analisis"
        }
    }

    const obtenerValorEstadoDelSocio = (estado) => {
        switch (estado) {
            case "100000000":
                return "Inicial"
            case "100000001":
                return "Aprobado para Enviar a Legacy"
            case "100000002":
                return "Enviado a Legacy"
        }
    }

    const obtenerValorMetodoEmision = (metodo) => {
        switch (metodo) {
            case "100000000":
                return "Teléfono"
            case "100000001":
                return "Presencial"
            case "100000002":
                return "E-mail"
            case "100000003":
                return "Online"
        }
    }

    const obtenerValorPropostio = (valor) => {
        switch (valor) {
            case '100000000':
                return 'Inversión'
            case '100000001':
                return 'Ahorro'
            case '100000002':
                return 'Financiamiento'
        }
    }

    const obtenerValorActividad = (valor) => {
        switch (valor) {
            case "100000000":
                return "Compra-Venta de Títulos Públicos"
            case "100000001":
                return "Compra-Venta de Acciones Caución"
            case "100000002":
                return "Colocadora/Tomadora"
            case "100000003":
                return "Opciones"
            case "100000004":
                return "Futuros"
            case "100000005":
                return "FCI-FF"
            case "100000006":
                return "Otros"
        }
    }

    const obtenerValorOpera = (valor) => {
        switch (valor) {
            case "false":
                return "No"
            case "true":
                return "Si"
        }
    }

    const obtenerValorInscripcionGanancias = (Inscripcion) => {
        switch (Inscripcion) {
            case "100000000":
                return "Inscripto"
            case "100000001":
                return "No Inscripto"
            case "100000002":
                return "No Responsable"
            case "100000003":
                return "Extranjero"
            case "100000004":
                return "Monotributo"
            case "100000005":
                return "Inscripto Exento"

        }
    }

    const obtenerOrigenDeFondos = (origen) => {
        switch (origen) {
            case "100000000":
                return "Ahorros de mi Actividad"
            case "100000001":
                return "Otros"
            case "100000002":
                return "Venta de Bienes Registrables"

        }
    }

    const obtenerValorTipoSujeto = (Inscripcion) => {
        switch (Inscripcion) {
            case "100000000":
                return "Sujeto Obligado"
            case "100000001":
                return "Sujeto No Obligado"
        }
    }

    const obtenerActividadEsperada = (actividades) => {
        var actividad = ''
        var actividadAux = ''

        if (actividades.length > 0) {
            for (let index = 0; index < actividades.length; index++) {
                const element = actividades[index]
                actividadAux = obtenerValorActividad(element.value)
                if (actividad === '') {
                    actividad = actividadAux
                } else {
                    actividad = actividad + ',' + actividadAux
                }
            }
        }

        return actividad
    }

    const ArmarProposito = (valor) => {
        if (valor !== null) {
            var opcionesProposito = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(pro => {
                    var proposito = { value: pro, label: obtenerValorPropostio(pro) }
                    opcionesProposito.push(proposito);
                });
            } else {
                var proposito = { value: valor, label: obtenerValorPropostio(valor) }
                opcionesProposito.push(proposito);
            }

            setProposito(opcionesProposito)
        }
    }

    const ArmarMetodoEmision = (valor) => {
        if (valor !== null) {
            var opcionesMetodos = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(met => {
                    var metodos = { value: met, label: obtenerValorMetodoEmision(met) }
                    opcionesMetodos.push(metodos);
                });
            } else {
                var metodos = { value: valor, label: obtenerValorMetodoEmision(valor) }
                opcionesMetodos.push(metodos);
            }

            setMetodoEmision(opcionesMetodos)
        }
    }

    const ArmarActividad = (valor) => {
        //ebugger
        if (valor !== null) {
            var opcionesActividad = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(act => {
                    var actividad = { value: act, label: obtenerValorActividad(act) }
                    opcionesActividad.push(actividad);
                });
            } else {
                var actividad = { value: valor, label: obtenerValorActividad(valor) }
                opcionesActividad.push(actividad);
            }

            setActividadEsperada(opcionesActividad)
        }
    }

    const ArmarEstadoSocio = (valor) => {
        if (valor !== null) {
            var opcionesEstados = []
            var estado = { value: valor, label: obtenerValorEstadoDelSocio(valor) }
            opcionesEstados.push(estado)
            setEstadoSocio(opcionesEstados)
        }
    }

    const ArmarOrigenDeFondos = (valor) => {
        if (valor !== null) {
            var opcionesOrigenes = []

            if (valor.includes(',')) {
                var valores = valor.split(',')
                valores.forEach(val => {
                    var origenesF = { value: val, label: obtenerOrigenDeFondos(val) }
                    opcionesOrigenes.push(origenesF);
                });
            } else {
                var origenesF = { value: valor, label: obtenerOrigenDeFondos(valor) }
                opcionesOrigenes.push(origenesF);
            }

            setOrigenFondos(opcionesOrigenes)
            var contieneOtro = false
            opcionesOrigenes.forEach(element => {
                if (element.value === "100000001") {
                    contieneOtro = true
                }
            });
            setEsOtros(contieneOtro)
        }
    }

    const ArmarInscripcionGanancias = (valor) => {
        if (valor !== null) {
            var opcionesInscripcion = []
            var Inscripcion = { value: valor, label: obtenerValorInscripcionGanancias(valor) }
            opcionesInscripcion.push(Inscripcion)
            setInscripcionGanancias(opcionesInscripcion)
        }
    }

    const ArmarOrigenes = (valor) => {
        if (valor !== null) {
            var opcionesOrigenes = []
            var Origen = { value: valor, label: obtenerOrigenDeFondos(valor) }
            opcionesOrigenes.push(Origen)
            setInscripcionGanancias(opcionesOrigenes)
        }
    }

    const ArmarMetodo = (valor) => {
        if (valor !== null) {
            var opcionesMetodos = []
            var metodo = { value: valor, label: obtenerValorMetodoEmision(valor) }
            opcionesMetodos.push(metodo)
            setMetodoEmision(opcionesMetodos)
        }
    }

    const ArmarOpera = (valor) => {
        if (valor !== null) {
            var opcionesOpera = []
            var opera = { value: valor, label: obtenerValorOpera(valor) }
            opcionesOpera.push(opera)
            setOperaPorCuenta(opcionesOpera)
        }
    }

    const obtenerEstadoDelSocio = (valor) => {
        if (Array.isArray(valor)) {
            return obtenerValorEstadoDelSocio(valor[0].value)
        } else {
            return obtenerValorEstadoDelSocio(valor.value)
        }
    }

    const obtenerMetodoEmision = (valor) => {
        if (Array.isArray(valor)) {
            return obtenerValorMetodoEmision(valor[0].value)
        } else {
            return obtenerValorMetodoEmision(valor.value)
        }
    }

    const obtenerOpera = (valor) => {
        if (Array.isArray(valor)) {
            return obtenerValorOpera(valor[0].value)
        } else {
            return obtenerValorOpera(valor.value)
        }
    }

    const obtenerPropositoDeLaCuenta = (propositos) => {
        var proposito = '';
        var propositoAux;

        if (propositos.length > 0) {
            for (let index = 0; index < propositos.length; index++) {
                const element = propositos[index];
                propositoAux = obtenerValorPropostio(element.value)
                if (proposito === '') {
                    proposito = propositoAux
                } else {
                    proposito = proposito + ',' + propositoAux
                }
            }
        }

        return proposito
    }

    const obtenerBanco = (bancoid) => {
        var nombreBanco;
        bancos.filter((banco) => banco.new_bancoid == bancoid).map(item => {
            nombreBanco = item.new_name
        })
        return nombreBanco
    }

    const obtenerSociedadBolsaNom = (id) => {
        let nombreSociedad;
        socidadBolsaSocio.filter((item) => item._new_sociedaddebolsa_value === id).map(item => {
            nombreSociedad = item['_new_sociedaddebolsa_value@OData.Community.Display.V1.FormattedValue']
        })
        return nombreSociedad
    }

    const contactoNotificacionesOnChange = (value) => {
        setContactoNotificaciones(value)
    }

    const [errorArchivo, setErrorArchivo] = React.useState(false)

    const seleccionarArchivo = (e) => {
        console.log(e.target.files[0])

        const imgCliente = e.target.files[0]


        if (imgCliente === undefined) {
            console.log('no se selecciono ninguna imagen')
            return
        }

        if (imgCliente.type === "image/png" || imgCliente.type === "image/jpg" || imgCliente.type === "image/jpeg") {
            dispatch(actualizarFotoUid(imgCliente))
            setErrorArchivo(false)
        } else {
            setErrorArchivo(true)
        }
    }

    console.log(contactoNotificaciones);
    console.log(contactosNotificacionesOpciones);

    return (
        <animated.div className="container min-vh-100" style={fade}>
            <div className="row">
                <div className="col-12">
                    <div className="mt-3 w-100">
                        <MiCuenta />
                    </div>
                </div>
            </div>

            <div className="mt-3">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active text-dark fw-bolder" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                            <FontAwesomeIcon
                                icon={faCircle}
                                className="alerta-pendientes upload-file text-warning"
                            /> General
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bolder" id="relaciones-tab" data-bs-toggle="tab" data-bs-target="#relaciones" type="button" role="tab" aria-controls="home" aria-selected="false">
                            <FontAwesomeIcon
                                icon={faCircle}
                                className="alerta-pendientes upload-file text-warning"
                            /> Vinculaciones</button>

                    </li>
                    {
                        usuario.ALYC == true ?
                            <li class="nav-item" role="presentation">
                                <button class="nav-link text-dark fw-bolder" id="contact-tab" data-bs-toggle="tab" data-bs-target="#alyc" type="button" role="tab" aria-controls="alyc" aria-selected="false">
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        className="alerta-pendientes upload-file text-warning"
                                    /> Datos Alyc</button>
                            </li>
                            : null
                    }
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bolder" id="pyme-tab" data-bs-toggle="tab" data-bs-target="#pyme" type="button" role="tab" aria-controls="pyme" aria-selected="false">Certificados Pymes</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bolder" id="bolsas-tab" data-bs-toggle="tab" data-bs-target="#bolsas" type="button" role="tab" aria-controls="bolsas" aria-selected="false">Sociedad de Bolsa</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark fw-bolder" id="cuentas-tab" data-bs-toggle="tab" data-bs-target="#cuentas" type="button" role="tab" aria-controls="cuentas" aria-selected="false">Cuentas Bancarias</button>
                    </li>

                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="row mt-3">
                        <div className="col-12 col-md-4 col-lg-3 mb-3">
                            <div className="row p-2">
                                <div className="col-8">
                                    <div>
                                        <h6 className="fw-bolder">Información de la Cuenta</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                </div>
                                <div className="col-4">
                                    {/* <div className="dropdown float-end">
                                        <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faEllipsisV} className="fs-5 text-dark upload-file atras float-end" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <button className="btn border-0 adeltante dropdown-item text-light"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                >
                                                    Cambiar foto de Perfil
                                                </button>
                                            </li> */}
                                    {/* <li>
                                                <button className="btn border-0 adeltante dropdown-item text-light"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                >
                                                    Editar información
                                                </button>
                                            </li> */}

                                    {/* </ul>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row w-100 justify-content-center">
                                <div className="col-12">
                                    <div className="w-100 d-flex justify-content-center">
                                        {
                                            fotoURL ? (
                                                <img className="border-secondary foto-mi-perfil icono-circular"
                                                    src={fotoURL} alt=""
                                                />
                                            )
                                                : <img className="border-secondary foto-mi-perfil icono-circular"
                                                    src={perfiRandom} alt=""
                                                />
                                        }
                                    </div>
                                    <div className="w-100">
                                        <p className="text-center mt-4">{razonSocail}</p>
                                    </div>
                                    <ToastContainer />
                                    {
                                        errorArchivo &&
                                        <div className="alert alert-warning">
                                            Solo Archivos .png o .jpg
                                        </div>
                                    }

                                    <div class="input-group mb-3 mx-auto">
                                        <input
                                            type="file"
                                            class="form-control"
                                            id="inputGroupFile02"
                                            style={{ display: 'none' }}
                                            onChange={e => seleccionarArchivo(e)}
                                        />
                                        <div className="row w-100 justify-content-center">
                                            <label class="btn btn-dark" htmlFor="inputGroupFile02">Cambiar foto</label>
                                        </div>
                                    </div>



                                </div>
                                {/* <div className="col-12 mt-4">
                                        <div className="row w-auto d-flex justify-content-center">
                                            <div className="col-12 ">
                                                <div className="w-100 p-2 text-center">
                                                    <p className="fw-bolder">{cuenta != undefined ? cuenta.Name : '---'}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100  p-2 text-start">
                                                    <p className="m-0">CUIT</p>
                                                    <p className="fw-bolder">{cuenta != undefined != 0 ? cuenta.new_nmerodedocumento : '---'}</p>
                                                </div >
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100  p-2 text-center">
                                                    <p className="m-0">Telefono</p>
                                                    <p className="fw-bolder">{telefono != '' != 0 ? telefono : '---'}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100 p-2 text-end">
                                                    <p className="m-0">Ciudad</p>
                                                    <p className="fw-bolder">{localidad != '' != 0 ? localidad : '---'}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100  p-2 text-start">
                                                    <p className="m-0">Direccion</p>
                                                    <p className="fw-bolder">{calle != '' ? calle : '---'} {numero != '' ? numero : '---'}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100  p-2 text-center">
                                                    <p className="m-0">Piso</p>
                                                    <p className="fw-bolder">{piso != '' != 0 ? piso : '---'}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="w-100  p-2 text-end">
                                                    <p className="m-0">Depto</p>
                                                    <p className="fw-bolder">{depto != '' != 0 ? depto : '---'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <form name="docXcuenta" onSubmit={actualizarDatos}>
                                <div className="mb-3 h-100">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Razón Social</label>
                                                    <input type="text"
                                                        id="razonSocial"
                                                        name="razonSocial"
                                                        className="form-control form-control-sm"
                                                        value={razonSocail}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Calle</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setCalle(e.target.value)}
                                                        value={calle}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Número</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setNumero(e.target.value)}
                                                        value={numero}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion">Piso</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setPiso(e.target.value)}
                                                        value={piso}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Depto</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setDepto(e.target.value)}
                                                        value={depto}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Código Postal</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setCodigoPostal(e.target.value)}
                                                        value={codigoPostal}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion">Municipio / Partido / Comuna</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setMunicipio(e.target.value)}
                                                        value={municipio}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Localidad</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setLocalidad(e.target.value)}
                                                        value={localidad}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Provincia</label>
                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                        id="provincia"
                                                        onChange={e => provinciaOnChange(e)}
                                                        value={provincia}
                                                        name="provincia"
                                                        options={opcionesProvincias}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="BUSCAR..."
                                                        noOptionsMessage={() => 'Cargando opciones...'}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">País</label>
                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                        id="provincia"
                                                        onChange={e => paisOnChange(e)}
                                                        value={pais}
                                                        name="provincia"
                                                        options={opcionesPaises}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="BUSCAR..."
                                                        noOptionsMessage={() => 'Cargando opciones...'}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Teléfono</label>
                                                    <input type="text"
                                                        id="razon"
                                                        name="razon"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setTelefono(e.target.value)}
                                                        value={telefono}
                                                        placeholder="---"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Contacto de Notificaciones</label>
                                                    <Select className="basic-multi-select"
                                                        id="contactoNotificaciones"
                                                        onChange={e => contactoNotificacionesOnChange(e)}
                                                        value={contactoNotificaciones}
                                                        name="colors"
                                                        options={contactosNotificacionesOpciones}
                                                        classNamePrefix="select"
                                                        placeholder="BUSCAR..."
                                                        noOptionsMessage={() => 'Cargando opciones...'}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label lbl-precalificacion requerido">Inscripción Ganancias</label>
                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                        id="inscripcionGanancias"
                                                        onChange={e => ganciasOnChange(e)}
                                                        value={inscripcionGanancias}
                                                        name="Inscrip"
                                                        options={GananciasOpciones}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="BUSCAR..."
                                                        noOptionsMessage={() => 'Cargando opciones...'}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btn-cuenta"
                                        type="submit"
                                        name="btnSubmit"
                                        className="btn btn-primary btn-sm active mt-4"
                                    >Actualizar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div class="tab-pane fade" id="relaciones" role="tabpanel" aria-labelledby="relaciones-tab">
                    <RelacionesVinculacion />
                </div>
                <div class="tab-pane fade" id="pyme" role="tabpanel" aria-labelledby="pyme-tab">
                    <div className="row mt-3 pb-2">
                        <div className="col-8 mt-3 mt-sm-0">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row p-2">
                                    <div className="col-6">
                                        <div className="mt-2">
                                            <h6 className="fw-bolder">Certificado Pyme</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/* <div className="w-100 ">
                                            <div className="dropdown float-end">
                                                <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <FontAwesomeIcon icon={faEllipsisH} className="fs-5 text-dark upload-file atras float-end" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <button className="btn border-0 adeltante dropdown-item text-light"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModalCuentaBancaria"
                                                        >
                                                            Agregar Certificado Pyme
                                                        </button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad ">
                                        <div className="row p-3">
                                            <div className="col-3">
                                                Número de Registro
                                            </div>
                                            <div className="col-3">
                                                Vigencia Desde
                                            </div>
                                            <div className="col-3">
                                                Vigencia Hasta
                                            </div>
                                            <div className="col-3">
                                                Razón para el estado
                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner1">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group overflow-scroll lista-body">
                                            {
                                                certificados.map(item => {
                                                    return (
                                                        <li key={item.new_certificadopymeid} className="list-group-item h-100 p-0 pt-2 pb-2">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-3">
                                                                    <p className="text-lowercase mx-2 padding-lista-perfil m-0 fw-bolder" >{item.new_numeroderegistro}</p>
                                                                </div>
                                                                <div className="col-3 m-0 ">
                                                                    <p className="text-lowercase mx-2 m-0 fw-bolder">{(item.new_vigenciadesde) ? Moment(item.new_vigenciadesde).format("DD-MM-YYYY") : '-'}</p>
                                                                </div>
                                                                <div className="col-3 m-0 ">
                                                                    <p className="text-lowercase mx-2 m-0 fw-bolder">{(item.new_vigenciahasta) ? Moment(item.new_vigenciahasta).format("DD-MM-YYYY") : '-'}</p>
                                                                </div>
                                                                <div className="col-3 m-0 ">
                                                                    <p className="text-lowercase mx-2 m-0 fw-bolder">{obtenerRazonParaElEstado(item.statuscode)}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {certificados.length} {(certificados.length === 1) ? 'Certificado' : 'Certificados'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade cardSociedad" id="bolsas" role="tabpanel" aria-labelledby="bolsas-tab">
                    <div classNam="row ">
                        <div className="col-5 mt-3">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row p-2">
                                    <div className="col-6">
                                        <div className="mt-2">
                                            <h6 className="fw-bolder">Sociedad de Bolsa</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <div className="float-end">
                                                <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Sociedad de Bolsa">
                                                    <button
                                                        className="btn float-end p-0"
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalSociedadDeBolsa">
                                                        <PlusSquare size={22} className="mx-2" color="#000" />
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none car w-100">
                                    <div className="lista-header color-header pad ">
                                        <div className="row p-3">
                                            <div className="col-5">
                                                Sociedad de Bolsa
                                            </div>
                                            <div className="col-5 text-center">
                                                Cuenta Comitente
                                            </div>
                                            <div className="col-4 text-end">

                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner2">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group overflow-scroll lista-body ">
                                            {
                                                socidadBolsaSocio.length >= 1 && socidadBolsaSocio.map(item => {
                                                    return (
                                                        <li key={item.new_bancosporsocioid} className="list-group-item h-100 p-0 pt-2 pb-2">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-5 ">
                                                                    <p className="text-lowercase padding-lista-perfil m-0 fw-bolder" >{obtenerSociedadBolsaNom(item._new_sociedaddebolsa_value)}</p>
                                                                </div>
                                                                <div className="col-5 m-0 text-center">
                                                                    <p className="text-lowercase m-0 fw-bolder">{item.new_cuentacomitente}</p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalModificarCuentaSociedadPorSocio"
                                                                            onClick={e => obtenerIDSociedadXSocio(item.new_sociedaddebolsaporsocioid)}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Sociedad de Bolsa">
                                                                        <span
                                                                            style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivarCuentaSocio"
                                                                            onClick={e => inactivarSociedadEstado(item._new_sociedaddebolsa_value, item.new_cuentacomitente, item.new_sociedaddebolsaporsocioid, '1')}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {socidadBolsaSocio.length} {(socidadBolsaSocio.length === 1) ? 'Sociedad de Bolsa' : 'Sociedades de Bolsa'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade cardBancos" id="cuentas" role="tabpanel" aria-labelledby="cuentas-tab">
                    <div className="row mt-3">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card shadow border-0 h-auto d-flex justify-content-start pad">
                                <div className="row p-2">
                                    <div className="col-6">
                                        <div className="mt-2">
                                            <h6 className="fw-bolder">Cuentas Bancarias</h6>
                                            <hr className="hr-width hr-principal" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100 ">
                                            <div className="float-end">
                                                <span className="d-inline-block float-end mt-1" tabindex="0" data-bs-toggle="tooltip" title="Agregar Cuenta Bancaria">
                                                    <button
                                                        className="btn float-end p-0"
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalCuentaBancaria">
                                                        <PlusSquare size={22} className="mx-2" color="#000" />
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card doc-cards pad borde-none">
                                    <div className="lista-header color-header pad ">
                                        <div className="row p-3">
                                            <div className="col-4 ">
                                                Banco
                                            </div>
                                            <div className="col-3 text-center">
                                                CBU
                                            </div>
                                            <div className="col-3 text-center">
                                                Nro. de Cuenta
                                            </div>
                                            <div className="col-2 text-end">

                                            </div>
                                        </div>
                                        <div className="contenedor-spinner" id="spinner3">
                                            <div className="lds-roller float-none w-100 d-flex justify-content-center mx--1" id="spinner4"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        <ul className="list-group overflow-scroll lista-body">
                                            {
                                                bancosXSocio.map(item => {

                                                    return (

                                                        <li key={item.new_bancosporsocioid} className="list-group-item h-100 p-0 pt-2 pb-2">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-4 ">
                                                                    <p className="text-lowercase padding-lista-perfil m-0 fw-bolder" >{obtenerBanco(item._new_banco_value)}</p>
                                                                </div>
                                                                <div className="col-3 m-0 text-center">
                                                                    <p className="text-lowercase m-0 fw-bolder text-center">{(item.new_cbu) ? item.new_cbu : '---'}</p>
                                                                </div>
                                                                <div className="col-3 m-0 text-center">
                                                                    <p className="text-lowercase m-0 fw-bolder text-center">{(item.new_numerodecuenta) ? item.new_numerodecuenta : '---'}</p>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Editar">
                                                                        <span
                                                                            style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalModificarCuentaBancaria"
                                                                            onClick={e => obtenerID(item.new_bancosporsocioid)}
                                                                        >
                                                                            <Edit size={20} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-1 col-sm-1 m-0 p-0">
                                                                    <div tabindex="0" data-bs-toggle="tooltip" title="Eliminar Cuenta Bancaria">
                                                                        <span
                                                                            style={{ "cursor": "pointer" }}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#ModalInactivarCuentaBancaria"
                                                                            onClick={e => inactivarCuentasEstado(item._new_banco_value, item.new_cbu, item.new_numerodecuenta, item.new_bancosporsocioid, '1')}
                                                                        >
                                                                            <Trash2 size={20} color="#BC0202" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <div className="row p-3">
                                            <div className="col-12 color-header text-center c-azul fw-bolder">
                                                {bancosXSocio.length} {(bancosXSocio.length === 1) ? 'Cuenta Bancaria' : 'Cuentas Bancarias'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="alyc" role="tabpanel" aria-labelledby="alyc-tab">
                    <div className="row">
                        {
                            usuario.ALYC == true ?
                                // <div className="col-12 col-lg-12 info-alyc mt-3" >
                                //     <div className="card shadow p-2 border-0 h-auto d-flex justify-content-start pad mb-3">
                                //         <div className="row">
                                //             <div className="col-6">
                                //                 <div>
                                //                     <h6 className="fw-bolder">Información para Alyc</h6>
                                //                     <hr className="hr-width hr-principal" />
                                //                 </div>
                                //             </div>
                                //             <div className="col-6">
                                //                 <div className="w-100 ">
                                //                     <div className="dropdown float-end">
                                //                         <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                //                             <FontAwesomeIcon icon={faEllipsisH} className="fs-5 text-dark upload-file atras float-end" />
                                //                         </button>
                                //                         <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                //                             <li>
                                //                                 <button className="btn border-0 adeltante dropdown-item text-light"
                                //                                     data-bs-toggle="modal"
                                //                                     data-bs-target="#ModalAlyc"
                                //                                 >
                                //                                     Editar información
                                //                                 </button>
                                //                             </li>

                                //                         </ul>
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         <h6 className="fw-bolder">General</h6>
                                //         <div className="row w-auto d-flex justify-content-center p-2">
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2 ">
                                //                     <p className="m-0">Estado del Socio c/Alyc</p>
                                //                     <p className="fw-bolder">{estadoSocio != '' != 0 ? obtenerEstadoDelSocio(estadoSocio) : '---'}</p>
                                //                 </div >
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2">
                                //                     <p className="m-0">Actividad Esperada de la Cuenta</p>
                                //                     <p className="fw-bolder">{actividadEsperada != '' != 0 ? obtenerActividadEsperada(actividadEsperada) : '---'}</p>
                                //                 </div>
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2">
                                //                     <p className="m-0">Opera por Cuenta Propia</p>
                                //                     <p className="fw-bolder">{operaPorCuenta != '' != 0 ? obtenerOpera(operaPorCuenta) : '---'}</p>
                                //                 </div>
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2 ">
                                //                     <p className="m-0">Monto Estimado a Invertir</p>
                                //                     <p className="fw-bolder">{montoEstimado != '' ?
                                //                         <CurrencyFormat
                                //                             disabled
                                //                             className="sinborde fw-bolder text-success"
                                //                             thousandSeparator={true}
                                //                             prefix={'$'}
                                //                             placeholder="$0.00"
                                //                             value={montoEstimado}
                                //                         /> : '$0.00'}</p>
                                //                 </div>
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2 ">
                                //                     <p className="m-0">Propósito de la Cuenta</p>
                                //                     <p className="fw-bolder">{proposito != '' != 0 ? obtenerPropositoDeLaCuenta(proposito) : '---'}</p>
                                //                 </div>
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2">
                                //                     <p className="m-0">
                                //                         Otros</p>
                                //                     <p className="fw-bolder">{otros != '' != 0 ? otros : '---'}</p>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         <h6 className="fw-bolder">Método de Emisión</h6>
                                //         <div className="row p-2">
                                //             <div className="col-12">
                                //                 <div className="w-100  p-2">
                                //                     <p className="m-0">
                                //                         Método de Emisión de Ordenes</p>
                                //                     <p className="fw-bolder">{metodoEmision != '' != 0 ? obtenerMetodoEmision(metodoEmision) : '---'}</p>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         <h6 className="fw-bolder">Información Adicional</h6>
                                //         <div className="row w-auto d-flex justify-content-center p-2">
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2 ">
                                //                     <p className="m-0">Fecha Contrato Social</p>
                                //                     <p className="fw-bolder">{fechaContrato != 'undefined' != 0 ? Moment(fechaContrato).format("DD-MM-YYYY") : '---'}</p>
                                //                 </div >
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2 ">
                                //                     <p className="m-0">Fecha de Inscripción Registral</p>
                                //                     <p className="fw-bolder">{fechaInscripcion != '' != 0 ? Moment(fechaInscripcion).format("DD-MM-YYYY") : '---'}</p>
                                //                 </div>
                                //             </div>
                                //             <div className="col-4">
                                //                 <div className="w-100  p-2">
                                //                     <p className="m-0">Numero de Inscripción Registral</p>
                                //                     <p className="fw-bolder">{numeroInscripcion != '' != 0 ? numeroInscripcion : '---'}</p>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </div>
                                // </div>
                                <form name="Alyc" onSubmit={actualizarDatosInfoAlyc} className="mt-3">
                                    <h6 className="fw-bolder">General</h6>
                                    <div className="row w-auto p-2">
                                        <div className="col-4" style={{ display: 'none' }}>
                                            <div className="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Estado del Socio c/Alyc</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="estadoSocio"
                                                    onChange={e => esatdoSocioOnChange(e)}
                                                    value={estadoSocio}
                                                    name="colors"
                                                    options={EstadoDelSocioOpciones}
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Actividad Esperada de la Cuenta</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="actividadEsperada"
                                                    onChange={e => actividadEsperadaOnChange(e)}
                                                    value={actividadEsperada}
                                                    name="colors"
                                                    options={ActividadesEsperadasOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    isMulti
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Opera por Cuenta Propia</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="opera"
                                                    onChange={e => operaOnChange(e)}
                                                    value={operaPorCuenta}
                                                    name="opera"
                                                    options={OperaOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Monto Estimado a Invertir</label>
                                                    <CurrencyFormat
                                                        className="text-success"
                                                        id="montoEstimado"
                                                        name="montoEstimado"
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        className="form-control"
                                                        placeholder="$0.00"
                                                        value={montoEstimado}
                                                        onChange={e => setMontoEstimado(e.target.value)}
                                                    />
                                                    {/* <CurrencyFormat
                                                        id="montoEstimado"
                                                        name="montoEstimado"
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        className="form-control"
                                                        onChange={e => setMontoEstimado(e.target.value)}
                                                        value={montoEstimado}
                                                        placeholder="$0.00"
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Propósito de la Cuenta</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="proposito"
                                                    onChange={e => propositoOnChange(e)}
                                                    value={proposito}
                                                    name="proposito"
                                                    options={PropositoOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    isMulti
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div class="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Tipo de Sujeto Obligado</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="sujeto"
                                                    onChange={e => sujetoOnChange(e)}
                                                    value={tipoSujeto}
                                                    name="colors"
                                                    options={sujetoOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div class="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Orígenes de Fondos</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="inscripcionGanancias"
                                                    onChange={e => origenesOnChange(e)}
                                                    value={origenFondos}
                                                    name="colors"
                                                    options={OrigenFondosOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    isMulti
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                        {esOtros == true ?
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <div class="mb-3">
                                                        <label className="form-label fw-bolder lbl-precalificacion">Otros</label>
                                                        <input type="text"
                                                            id="otros"
                                                            name="otros"
                                                            className="form-control form-control-sm"
                                                            onChange={e => setOtros(e.target.value)}
                                                            value={otros}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                    <h6 className="fw-bolder">Método de Emisión</h6>
                                    <div className="row p-2">
                                        <div className="col-4">
                                            <div className="mb-2">
                                                <label className="form-label fw-bolder lbl-precalificacion">Método de Emisión de Ordenes</label>
                                                <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                    id="metodoEmision"
                                                    onChange={e => metodoEmisionOnChange(e)}
                                                    value={metodoEmision}
                                                    name="metodoEmision"
                                                    options={MetodosEmisionesOpciones}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Buscar..."
                                                    noOptionsMessage={() => 'Cargando opciones...'}
                                                    isMulti
                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="fw-bolder">Información Adicional</h6>
                                    <div className="row w-auto d-flex justify-content-center p-2">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Fecha Contrato Social</label>
                                                    <input type="date"
                                                        id="fechaContrato"
                                                        name="fechaContrato"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setFechaContrato(e.target.value)}
                                                        value={fechaContrato}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <div class="mb-3">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Fecha de Inscripción Registral</label>
                                                    <input type="date"
                                                        id="fechaInscripcion"
                                                        name="fechaInscripcion"
                                                        className="form-control form-control-sm"
                                                        onChange={e => setFechaInscripcion(e.target.value)}
                                                        value={fechaInscripcion}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div class="mb-3">
                                                <label className="form-label fw-bolder lbl-precalificacion">Número de Inscripción Registral</label>
                                                <input type="text"
                                                    id="numeroInscripcion"
                                                    name="numeroInscripcion"
                                                    className="form-control form-control-sm"
                                                    onChange={e => setNumeroInscripcion(e.target.value)}
                                                    value={numeroInscripcion}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btm-alyc"
                                        type="submit"
                                        name="btnSubmitAlyc"
                                        className="btn btn-sm btn-primary active btn-lg mt-4"
                                    >Actualizar</button>
                                </form>
                                : null
                        }
                    </div>
                </div>
                <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                {/* <div className="row">
                    <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                        <Toast className="half-black" show={show} autohide color="lime">
                            <Toast.Body className="text-white">
                                <div className="row p-2">
                                    {
                                        loading ?
                                            <Spinner animation="border" role="status" variant="primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            :
                                            <div className="col-1 mx-2">
                                                {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                            </div>
                                    }

                                    <div className="col-10 mt-1 ml-5">
                                        {mensaje}
                                    </div>
                                </div>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div> */}
            </div>
            <div className="row mt-5 mb-5">
                <div className="modal fade bd-example-modal-xl" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

                </div>
                <div className="modal fade bd-example-modal-xl" id="ModalAlyc" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Actualizar Información Alyc </h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInput"></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal fade bd-example-modal-xl container-fluid" id="ModalCuentaBancaria" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Agregar Cuenta Bancaria</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputCuenta"></button>
                                    </div>
                                </div>
                                <form name="Alyc" onSubmit={crearCuentaxSocio}>
                                    <div className="row w-auto d-flex justify-content-center p-2">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion requerido">Banco</label>
                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                        id="bancos"
                                                        onChange={e => bancoOnChange(e)}
                                                        value={bancoid}
                                                        name="colors"
                                                        options={bancosOpciones}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Buscar..."
                                                        noOptionsMessage={() => 'Cargando opciones...'}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">CBU</label>
                                                    <input type="text"
                                                        id="cbu"
                                                        name="cbu"
                                                        className="form-control"
                                                        onChange={e => setCbu(e.target.value)}
                                                        value={cbu}
                                                        placeholder="CBU"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Nro. Cuenta</label>
                                                    <input type="text"
                                                        id="nroCuenta"
                                                        name="nroCuenta"
                                                        className="form-control"
                                                        onChange={e => setNroCuenta(e.target.value)}
                                                        value={nroCuenta}
                                                        placeholder="NRO. CUENTA"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btn-cuentabancaria"
                                        type="submit"
                                        name="btnSubmitAlyc"
                                        className="btn btn-sm active btn-primary mt-4"
                                    >Agregar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div className="modal fade bd-example-modal-xl" id="ModalModificarCuentaBancaria" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Modificar Cuenta Bancaria</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputModificarCuenta"></button>
                                    </div>
                                </div>
                                <form name="modificar" onSubmit={modificarCuenta}>
                                    <div className="row w-auto d-flex justify-content-center p-2">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion requerido">Banco</label>
                                                    <Select
                                                        value={{ label: newBanco.label, value: newBanco.value }}
                                                        onChange={e => bancoOnChangeModificarCuenta(e)}
                                                        name="colors"
                                                        options={bancosOpciones}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Buscar..."
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">CBU</label>
                                                    <input
                                                        defaultValue={newCbu}
                                                        ref={newCbuRef}
                                                        type="text"
                                                        id="cbu"
                                                        name="cbu"
                                                        className="form-control"
                                                        onChange={e => setNewCbu(e.target.value)}
                                                        value={newCbu}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Nro. Cuenta</label>
                                                    <input
                                                        defaultValue={numCuent}
                                                        ref={numCuentRef}
                                                        type="text"
                                                        id="nroCuenta"
                                                        name="nroCuenta"
                                                        className="form-control"
                                                        onChange={e => setNumCuenta(e.target.value)}
                                                        value={numCuent}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btn-modificarbanco"
                                        type="submit"
                                        name="btnSubmit"
                                        className="btn btn-sm active btn-primary mt-4"
                                    >Modificar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div className="modal fade bd-example-modal-xl container-fluid" id="ModalSociedadDeBolsa" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Agregar Sociedad de Bolsa</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputSociedadDeBolsa"></button>
                                    </div>
                                </div>
                                <form name="SociedadBolsaSocio" onSubmit={crearSociedadBolsaSocio}>
                                    <div className="row w-auto d-flex justify-content-center p-2">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion requerido">Sociedad de Bolsa</label>
                                                    <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                                        id="sociedad"
                                                        onChange={e => sociedadOnChange(e)}
                                                        value={sociedadID}
                                                        name="colors"
                                                        options={opcionesSociedadBolsa}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Buscar..."
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Cuenta Comitente</label>
                                                    <input type="text"
                                                        id="comitente"
                                                        name="comitente"
                                                        className="form-control"
                                                        onChange={e => setCuentaComitente(e.target.value)}
                                                        value={cuentaComitente}
                                                        placeholder="CUENTA COMITENTE"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btn-sociedad"
                                        type="submit"
                                        name="btnSubmitSociedadBolsaSocio"
                                        className="btn btn-sm active btn-primary mt-4"
                                    >Agregar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div className="modal fade bd-example-modal-xl" id="ModalModificarCuentaSociedadPorSocio" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Modificar Sociedad de Bolsa</h6>
                                        <hr className="hr-width hr-principal" />
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputModificarSociedad"></button>
                                    </div>
                                </div>
                                <form name="modificar" onSubmit={modificarSociedadBolsa}>
                                    <div className="row w-auto d-flex justify-content-center p-2">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion requerido">Sociedad de Bolsa</label>
                                                    <Select
                                                        value={{ label: newSociedadValue.label, value: newSociedadValue.value }}
                                                        onChange={e => sociedadOnChangeModificar(e)}
                                                        name="colors"
                                                        options={opcionesSociedadBolsa}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Buscar..."
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mb-2">
                                                    <label className="form-label fw-bolder lbl-precalificacion">Cuenta Comitente</label>
                                                    <input
                                                        defaultValue={newCuentaComitente}
                                                        ref={RefCuentaComitent}
                                                        type="text"
                                                        id="cuentaComitente"
                                                        name="cuentaComitente"
                                                        className="form-control"
                                                        onChange={e => setNewCuentaComitente(e.target.value)}
                                                        value={newCuentaComitente}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        id="btn-modsocieda"
                                        type="submitModificar"
                                        name="btnSubmit"
                                        className="btn btn-sm active btn-primary mt-4"
                                    >Modificar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div className="modal fade bd-example-modal" id="ModalInactivarCuentaBancaria" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Eliminar Cuenta Bancaria</h6>
                                        <hr className="hr-width hr-principal" />
                                        <p>¿Estas seguro de eliminar esta cuenta?</p>
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputCuentaInactivar"></button>
                                    </div>
                                </div>
                                <button
                                    id="btn-eliminarbanco"
                                    className="btn btn-sm active btn-primary mt-4"
                                    onClick={inactivarCuentaExito}
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}
                </div>
                <div className="modal fade bd-example-modal" id="ModalInactivarCuentaSocio" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-8">
                                        <h6 className="fw-bolder">Eliminar Sociedad de Bolsa</h6>
                                        <hr className="hr-width hr-principal" />
                                        <p>¿Estas seguro de eliminar esta sociedad?</p>
                                    </div>
                                    <div className="col-4">
                                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close" id="myInputInactivarSociedad"></button>
                                    </div>
                                </div>
                                <button
                                    id="btn-elmsociedad"
                                    className="btn btn-sm active btn-primary mt-4"
                                    onClick={inactivarSociedadEstadoExito}
                                >
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                    <Notificacion show={show} mensaje={mensaje} error={error} loading={loading} />
                    {/* <div className="row">
                        <div className="col-4 position-fixed bottom-0 end-0 p-5 noti">
                            <Toast className="half-black" show={show} autohide color="lime">
                                <Toast.Body className="text-white">
                                    <div className="row p-2">
                                        {
                                            loading ?
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                :
                                                <div className="col-1 mx-2">
                                                    {error ? <FontAwesomeIcon icon={faTimesCircle} className="fs-3 upload-file atras" color="#dc3545" /> : <FontAwesomeIcon icon={faCheckCircle} className="fs-3 upload-file atras" color="#198754" />}
                                                </div>
                                        }

                                        <div className="col-10 mt-1 ml-5">
                                            {mensaje}
                                        </div>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div> */}

                </div>
            </div>
        </animated.div >
    )
}

export default withRouter(Cuenta)

