import axios from 'axios'
import { Profiler } from 'react'
import { Entidad, UrlApiDynamics } from '../Keys'


//Const
const dataInicial = {
    loading: false,
    relaciones: [],
    CreacionDatos: '',
    InactivacionDatos: '',
    relacionesFetch: []
}

//Types
const RELACIONES_EXITO = 'RELACIONES_EXITO'
const RELACIONES_FETCH_EXITO = 'RELACIONES_FETCH_EXITO'
const INACTIVAR_CUENTA = 'INACTIVAR_CUENTA'
const CREAR_CUENTA = 'CREAR_CUENTA'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function relacionesReducers(state = dataInicial, action) {
    switch (action.type) {
        case RELACIONES_EXITO:
            return { ...state, relaciones: action.payload }
        case RELACIONES_FETCH_EXITO:
            return { ...state, relacionesFetch: action.payload }
        case CREAR_CUENTA:
            return { ...state, CreacionDatos: action.CreacionDatos }
        case INACTIVAR_CUENTA:
            return { ...state, InactivacionDatos: action.InactivacionDatos }
        default:
            return { ...dataInicial }
    }
}

//Actions
export const obtenerRelaciones = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Relacionesvinculacion?filter=_new_cuentaid_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: RELACIONES_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const CrearRelacion = (accountid, tipo, cuitCuil = "", nombre = "", apellido = "", razonSocial = "",
    vinculado = "", tipoRelacion = "", porcentajeParticipacion = "", relacionAccionaria = "", porcentajeBeneficiario = "",
    cargo = "", descripcion = "", dni = "", fechaNacimiento = "", lugarNacimiento = "", correo = "", estadoCivil = "", nombreConyuge = "", dniConyuge = "", domicilioExterior = "", domicilioUSA = "", direccion = "",
    cp = "", ciudad = "", provincia = "", pais = "", ein = "", nit = "", id = "", tipoCarga = "", relacionid = "", estado = "",
    personaPolitica = "", funcion = "", ente = "", relacionPersona = "", fechaAlta = "", fechaBaja = "", tipoDocumentoC = "", 
    generoC = "", trabajaC = "", tipoSujeto = "", profesion = "", telefonoLaboral = "", correoCuenta = "") => async (dispatch) => {
        dispatch({
            type: LOADING,
            CreacionDatos: 'LOADING'
        })

        try {
            let tipoDoc = ""
            let sexo = ""
            let sujeto = ""
            let Pais = ""

            if(tipoDocumentoC !== undefined) {
                tipoDoc = tipoDocumentoC.value
            }
            if(generoC !== undefined) {
                sexo = generoC.value
            }
            if(tipoSujeto !== undefined) {
                sujeto = tipoSujeto.value
            }
            if(pais !== undefined) {
                Pais = pais.value
            }
            
            const response = await axios.post(`${UrlApiDynamics}Relacionesvinculacion?accountid=${accountid}&accionistas=${null}&tipo=${tipo}&cuitCuil=${cuitCuil}
            &nombre=${nombre}&apellido=${apellido}&razonSocial=${razonSocial}&vinculado=${vinculado.value}&tipoRelacion=${tipoRelacion.value}&porcentajeParticipacion=${porcentajeParticipacion}&relacionAccionaria=${relacionAccionaria.value}
            &porcentajeBeneficiario=${porcentajeBeneficiario}&cargo=${cargo}&descripcion=${descripcion}&dni=${dni}&fechaNacimiento=${fechaNacimiento}&lugarNacimiento=${lugarNacimiento}&correo=${correo}
            &estadoCivil=${estadoCivil.value}&nombreConyuge=${nombreConyuge}&dniConyuge=${dniConyuge}&domicilioExterior=${domicilioExterior}&domicilioUSA=${domicilioUSA}&direccion=${direccion}&cp=${cp}
            &ciudad=${ciudad}&provincia=${provincia}&pais=${Pais}&ein=${ein}&nit=${nit}&cuentaContactoID=${id}&tipoCarga=${tipoCarga}&relacionid=${relacionid}&estado=${estado}
            &personaPolitica=${personaPolitica}&funcion=${funcion}&ente=${ente}&relacionPersonaExpuesta=${relacionPersona}&fechaAlta=${fechaAlta}&fechaBaja=${fechaBaja}
            &tipoDocumentoC=${tipoDoc}&generoC=${sexo}&trabajaC=${trabajaC}&tipoSujeto=${sujeto}&profesion=${profesion}&telefonoLaboral=${telefonoLaboral}
            &correoCuenta=${correoCuenta}&cuit=${Entidad}`)
            dispatch({
                type: CREAR_CUENTA,
                payload: response.data,
                CreacionDatos: 'EXITO'
            })
        } catch (error) {
            dispatch({
                type: ERROR,
                CreacionDatos: 'ERROR'
            })
        }
    }

export const InactivarRelacion = (relacionid = "", estado = "") => async (dispatch) => {
    dispatch({
        type: LOADING,
        InactivacionDatos: 'LOADING'
    })

    try {
        const response = await axios.post(`${UrlApiDynamics}Relacionesvinculacion?relacionid=${relacionid}&estado=${estado}&cuit=${Entidad}`)
        dispatch({
            type: INACTIVAR_CUENTA,
            InactivacionDatos: 'EXITO'
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            InactivacionDatos: 'ERROR'
        })
    }
}

export const obtenerRelacionesFETCH = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "new_participacionaccionarias"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_participacionaccionaria'>" +
        "<attribute name='new_participacionaccionariaid'/>" +
        "<attribute name='new_cuentacontactovinculado'/> " +
        "<attribute name='new_tipoderelacion'/> " +
        "<attribute name='statecode'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_cuentaid' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "<link-entity name='contact' from='contactid' to='new_cuentacontactovinculado' link-type='outer' alias='ab'>" +
        "<attribute name='new_personaexpuestapoliticamente'/>" +
        "<attribute name='new_cargofuncinjerarquayenteendondetrabaja'/>" +
        "<attribute name='contactid'/> " +
        "<attribute name='new_fechaultimavalidacionidentidadrenaper'/>" +
        "<attribute name='new_resultadoultimavalidacionidentidadrenaper'/>" +
        "<attribute name='new_entedondetrabaja'/> " +
        "<attribute name='new_relacinconlapersonaexpuestapolticamente'/>" +
        "<attribute name='new_fechadealtabaja'/> " +
        "<attribute name='new_fechadebaja'/> " +
        "<attribute name='new_tienedomiciliofiscalenelexterior'/>" +
        "<attribute name='new_tienedomicilioenusa'/> " +
        "<attribute name='statuscode'/>" +
        "<attribute name='address3_stateorprovince'/> " +
        "<attribute name='address1_stateorprovince'/>" +
        "<attribute name='address3_country'/> " +
        "<attribute name='address1_country'/>" +
        "<attribute name='new_nrodedocumentoconyuge'/> " +
        "<attribute name='new_nrodedocumento'/>" +
        "<attribute name='firstname'/> " +
        "<attribute name='new_nit'/>" +
        "<attribute name='birthdate'/> " +
        "<attribute name='familystatuscode'/>" +
        "<attribute name='new_cuitcuil'/> " +
        "<attribute name='emailaddress1'/>" +
        "<attribute name='lastname'/> " +
        "<attribute name='address3_line1'/>" +
        "<attribute name='address3_postalcode'/> " +
        "<attribute name='address3_city'/>" +
        "<attribute name='address1_line1'/>" +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_city'/>" +
        "<attribute name='new_einss'/> " +  
        "<attribute name='new_tipodedocumentoconyuge'/> " +
        "<attribute name='new_generodelconyuge'/>" +
        "<attribute name='new_conyugetrabaja'/> " +
        "<attribute name='spousesname'/> " +
        "<attribute name='new_tiposujetoobligado'/> " +
        "<attribute name='new_lugardenacimiento'/> " +
        "<attribute name='new_paisdeorgen'/> " +
        "<attribute name='new_pais'/> " +
        "<attribute name='new_profesionoficioactividad'/> " +
        "<attribute name='telephone1'/> " +
        "<attribute name='new_correoelectrnicopararecibirestadodecuenta'/> " +
        "<attribute name='new_paisresidenciafiscalenelexterior'/> " +
        "</link-entity>" +
        "<link-entity name='account' from='accountid' to='new_cuentacontactovinculado' link-type='outer' alias='aa'>" +
        "<attribute name='name'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='emailaddress1'/> " +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        //debugger
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: RELACIONES_FETCH_EXITO,
            payload: response.data
        })
    }

    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}