import React from 'react'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { obtenerActividadesAFIP } from '../Redux/ActividadAFIP'
import { useSpring, animated, interpolate } from 'react-spring'
import CurrencyFormat from 'react-currency-format';
import { RecaptchaKey } from '../Keys'
import ReCAPTCHA from 'react-google-recaptcha'
import { faEllipsisH, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SolicitudAltaCuenta = ({ handleChange, state, documentos, token }) => {
    const dispatch = useDispatch()
    const [llamadaActividades, setLlamadaActividades] = React.useState(false)
    const [tipoSocietario, setTipoSocietario] = React.useState('')
    const [condicionImpositiva, setCondicionImpositiva] = React.useState('')
    const [actividades, setActividades] = React.useState([])
    const [actividad, setActividad] = React.useState('')
    const [facturacion, setFacturacion] = React.useState('')
    const [cuitReferidor, setCuitReferidor] = React.useState('')
    const [productoServicio, setProductoServicio] = React.useState([])
    const [robot, setRobot] = React.useState(false)
    const actividadesSelector = useSelector(store => store.actividadesAFIP.actividades)

    React.useEffect(async () => {
        if (token.length > 0) {

            if (state.actividadesAFIP.length > 0) {
                completarOpcionesActividades(state.actividadesAFIP)
            } else if (actividadesSelector.length > 0 && llamadaActividades === true) {
                completarOpcionesActividades(actividadesSelector)
                handleChange("actividadesAFIP")(actividadesSelector)
            } else if (llamadaActividades === false) {
                obtenerActividades()
                setLlamadaActividades(true)
            }
        }

        if (state) {
            debugger
            if (state.personeria !== '') {
                if (state.personeria === '100000000') {
                    var societario = document.getElementById('div-societario')
                    societario.style.display = 'block';
                } else if (state.personeria === '100000001') {
                    var condicion = document.getElementById('div-condicion')
                    condicion.style.display = 'block';
                }
            }

            if (state.actividadAFIP !== '') {
                var valorActividad
                if (actividadesSelector.length > 0) {
                    valorActividad = buscarActividadAFIP(state.actividadAFIP, actividadesSelector)
                } else {
                    valorActividad = buscarActividadAFIP(state.actividadAFIP, state.actividadesAFIP)
                }
                var opcionActividadAFIP = { value: state.actividadAFIP, label: valorActividad }
                setActividad(opcionActividadAFIP)
            }

            if (state.tipoSocietario !== '' && tipoSocietario === '') {
                var valorTipo = buscarTipoSocietario(state.tipoSocietario)
                setTipoSocietario(valorTipo)
            }

            if (state.condicionImpositiva !== '' && condicionImpositiva === '') {
                var condicion = buscarCondicionImpositiva(state.condicionImpositiva)
                setCondicionImpositiva(condicion)
            }

            if (state.facturacion !== '' && facturacion === '') {
                setFacturacion(state.facturacion)
            }
        }
    }, [actividadesSelector])

    const obtenerActividades = async () => {
        dispatch(obtenerActividadesAFIP(token))
    }

    const actividadOnChange = (value) => {
        setActividad(value)
        handleChange("actividadAFIP")(value)
    }

    const tipoSocietarioOnChange = (value) => {
        setTipoSocietario(value)
        handleChange("tipoSocietario")(value)
    }

    const facturacionOnChange = (valor) => {
        setFacturacion(valor.target.value)
        handleChange("facturacion")(valor)
    }

    const condicionImpositivaOnChange = (value) => {
        setCondicionImpositiva(value)
        handleChange("condicionImpositiva")(value)
    }

    const condicionImpositivaOpciones = [
        { value: '100000000', label: 'Autónomo' },
        { value: '100000001', label: 'Relación de Dependencia' },
        { value: '100000002', label: 'Otro' }
    ]

    const tiposSocietariosOpciones = [
        { value: '100000000', label: 'S.A.' },
        { value: '100000001', label: 'S.R.L.' },
        { value: '100000002', label: 'Cooperativa' },
        { value: '100000003', label: 'Mutual' },
        { value: '100000004', label: 'Gobierno' },
        { value: '100000005', label: 'S.A.S.' },
        { value: '100000006', label: 'Asociación Civil sin Fines de Lucro' },
        { value: '100000007', label: 'ONG' },
        { value: '100000008', label: 'Fundación' },
        { value: '100000009', label: 'LLC' }
    ]

    const completarOpcionesActividades = (actividades) => {
        const opcionesActividades = [];
        actividades.forEach(act => {
            if (act.new_codigo !== null) {
                var actividad = { value: act.new_actividadafipid, label: act.new_codigo + ' - ' + act.new_name }
            } else {
                var actividad = { value: act.new_actividadafipid, label: act.new_name }
            }

            opcionesActividades.push(actividad);
        });
        setActividades(opcionesActividades)
    }

    const cuitReferidorOnChange = (valor) => {
        setCuitReferidor(valor.target.value)
        handleChange("cuitReferidor")(valor)
    }

    const servicioOnChange = (value) => {
        let valores = '';
        value.forEach(element => {
            if (valores === '') {
                valores = element.value
            } else {
                valores = valores + ',' + element.value
            }
        });
        setProductoServicio(value)
        handleChange("servicio")(valores)
    }

    const buscarProducto = (valor) => {
        let valores = [];
        let opciones = valor.split(',')
        opciones.forEach(element => {
            switch (element) {
                case '100000000':
                    valores.push({ value: element, label: 'SGR' })
                    break;
                case '100000001':
                    valores.push({ value: element, label: 'ALYC' })
                    break;
            }
        });
        return valores
    }

    const buscarTipoSocietario = (valor) => {
        switch (valor) {
            case '100000000':
                return { value: valor, label: 'S.A' }
            case '100000001':
                return { value: valor, label: 'S.R.L' }
            case '100000000':
                return { value: valor, label: 'Cooperativa' }
            case '100000001':
                return { value: valor, label: 'Mutual' }
            case '100000000':
                return { value: valor, label: 'Gobierno' }
            case '100000001':
                return { value: valor, label: 'S.A.S' }
            case '100000000':
                return { value: valor, label: 'Asociación Civil sin Fines de Lucro' }
            case '100000001':
                return { value: valor, label: 'ONG' }
            case '100000000':
                return { value: valor, label: 'Fundación' }
            case '100000001':
                return { value: valor, label: 'LLC' }
        }
    }

    const buscarActividadAFIP = (valor, actividades = []) => {
        var nombreActividad = ''
        actividades.forEach(element => {
            if (element.new_actividadafipid === valor) {
                nombreActividad = element.new_name
            }
        });
        return nombreActividad
    }

    const buscarCondicionImpositiva = (valor) => {
        switch (valor) {
            case '100000000':
                return { value: valor, label: 'Autónomo' }
            case '100000001':
                return { value: valor, label: 'Relación de Dependencia' }
            case '100000002':
                return { value: valor, label: 'Otro' }
        }
    }

    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1, delay: 1500
        },
    })

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
        } else {
            valor = "False"
        }
        setRobot(true)
        handleChange("robot")(valor)
    }

    return (
        <animated.div style={fade}>
            <form>
                <div>
                    <h6 className="fw-bolder">Datos de la Cuenta</h6>
                    <hr className="hr-width hr-principal" />
                </div>
                <div className="row p-2">
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Actividad AFIP</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="actividad"
                                onChange={e => actividadOnChange(e)}
                                name="colors"
                                options={actividades}
                                className="basic-multi-select"
                                classNamePrefix="Seleccionar"
                                placeholder="BUSCA TU CÓDIGO DE ACTIVIDAD AFIP..."
                                value={actividad}
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ display: 'none' }} id="div-societario">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Tipo Societario</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="tipoSocietario"
                                onChange={e => tipoSocietarioOnChange(e)}
                                value={tipoSocietario}
                                name="productos"
                                options={tiposSocietariosOpciones}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="BUSCAR..."
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ display: 'none' }} id="div-condicion">
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Condición Impositiva</label>
                            <Select className="form-select titulo-notificacion form-select-lg mb-3 fw-bolder h6"
                                id="condicionImpositiva"
                                onChange={e => condicionImpositivaOnChange(e)}
                                value={condicionImpositiva}
                                name="productos"
                                options={condicionImpositivaOpciones}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="BUCAR..."
                                noOptionsMessage={() => 'Cargando opciones...'}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" >
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion requerido">Facturación/Ingresos Último Año</label>
                            <CurrencyFormat
                                name="facturacion"
                                thousandSeparator={true}
                                prefix={'$'}
                                id="facturacion"
                                className="form-control"
                                onChange={e => facturacionOnChange(e)}
                                value={facturacion}
                                placeholder="$0.00"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6" >
                        <div className="mb-4">
                            <label className="form-label fw-bolder lbl-precalificacion">CUIT del Referidor</label>
                            <input type="text"
                                id="razon"
                                name="razon"
                                className="form-control"
                                onChange={e => cuitReferidorOnChange(e)}
                                value={cuitReferidor}
                                placeholder="(sin espacio ni guiones)"
                                maxLength="99"
                            />
                        </div>
                    </div>
                </div>
            </form>
            {/* <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-2 mt-3">
                    <div className="card shadow p-3 border-0 h-auto d-flex justify-content-start pad">
                        <div>
                            <h6 className="fw-bolder">Documentación Necesaria</h6>
                            <hr className="hr-width hr-principal" />
                        </div>
                        <div className="card doc-cards pad borde-none">
                            <div className="lista-header color-header pad">
                                <div className="row p-3">
                                    <div className="col-6 col-sm-6 ">
                                        Documento
                                    </div>
                                </div>
                                <ul className="list-group w-100 overflow-auto mh-100 shadow-sm">
                                    {
                                        documentos.map(item => {
                                            return (
                                                <li key={item.new_documentacionid} className="list-group-item borde-0">
                                                    <div className="row align-items-center pt-1 pb-1">
                                                        <div className="col-8 col-sm-8 m-0 p-0 text-start">
                                                            <p className="text-lowercase m-0 color-textp-lista texto-lista" >{item.new_name}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </ animated.div>
    )
}

export default SolicitudAltaCuenta
